import React, { useEffect, useContext } from 'react'
import { isArray, keys as lkeys, max, min, range } from 'lodash'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { useValidatedData } from '../hooks/useData'
import StatisticsPyramid from './StatisticsPyramid'
import type { PyramidData } from './StatisticsPyramid'
import type { WidgetProps } from '../types'
import { statisticsDataSchema, type StatisticsData } from './legacyTypes'

import StatisticsTable from './LegacyStatisticsTable'
import MessageComponent from '../AssociationsGraphWidget/MessageComponent'
import { ExportDataContext } from '../../../../components/widget/WidgetFactory/ExportDataContext'
import Loading from '../shared/Loading'

const STATISTICS_BREAK_POINT = 1200
const STATISTICS_MIN_WIDTH = 650

interface StatisticsMetadata {
  encoding_dict: { [dictName: string]: string[] }
  operations: Array<{
    operation_name: string
    fn: string
    partition_variables: Array<{ name: string }>
    aggregation_variable: { name: string }
  }>
}

interface PartitionedPyramidData {
  [sex: number]: { [age: number]: { value: number } }
}

interface StatisticsArgs {
  [id: string]: never
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildPyramid(result: any): PyramidData[] {
  const ageKeys = (result: PartitionedPyramidData): number[] =>
    [...lkeys(result[0] ?? []), ...lkeys(result[1] ?? [])].map((i) => Number(i))
  const maxValue = max(ageKeys(result)) ?? 0
  const minValue = min(ageKeys(result)) ?? 0
  if (lkeys(result).length === 0) {
    return []
  }
  return range(minValue, maxValue + 1).map((age) => ({
    group: String(age),
    trace0: result?.[0]?.[age]?.value ?? 0,
    trace1: result?.[1]?.[age]?.value ?? 0,
  }))
}

function modifyResults(
  statisticsData: StatisticsData,
  customStatisticsData: StatisticsData,
): StatisticsData {
  const modifiedStatistics: StatisticsData = []
  let remainingStatistics = statisticsData
    .concat(customStatisticsData)
    .filter((op) => op.operation_name !== 'population_pyramid')
  const episodesCountResult = remainingStatistics.find(
    (op) => op.operation_name === 'episodes_counts',
  )?.result
  if (
    episodesCountResult !== undefined &&
    'value' in episodesCountResult &&
    isArray(episodesCountResult.value) &&
    episodesCountResult.value.length > 0
  ) {
    let totalEpisodes = 0
    if (!isArray(episodesCountResult.value[0])) {
      totalEpisodes = parseInt(episodesCountResult.value[1] as string)
    } else {
      totalEpisodes = (
        episodesCountResult.value as Array<[string, number]>
      ).reduce((accumulated, currentValue) => accumulated + currentValue[1], 0)
    }
    modifiedStatistics.push({
      fn: 'count',
      operation_name: 'episodes',
      result: { value: totalEpisodes },
      partition_variables: [],
    })
  }

  remainingStatistics = remainingStatistics.filter(
    (op) => op.operation_name !== 'episodes_counts',
  )
  const populationPyramid = statisticsData
    .concat(customStatisticsData)
    .find((op) => op.operation_name === 'population_pyramid')

  if (
    populationPyramid === undefined ||
    populationPyramid.partition_variables.length !== 2 ||
    populationPyramid.partition_variables[0].name !== 'sex'
  ) {
    return modifiedStatistics.concat(remainingStatistics)
  }
  remainingStatistics = remainingStatistics.filter(
    (op) => op.operation_name !== 'total_count',
  )
  const patientsSex: { [key: string]: { value: number } } = {}
  Object.entries(populationPyramid.result).forEach(([sexValue, data]) => {
    const countData = (
      Object.values(data) as unknown as Array<{ value: number }>
    ).reduce((accumulated, currentValue) => accumulated + currentValue.value, 0)
    patientsSex[sexValue] = { value: countData }
  })
  modifiedStatistics.push({
    fn: 'count',
    operation_name: 'total_count',
    result: patientsSex,
    partition_variables: [{ name: 'sex' }],
  })
  remainingStatistics.forEach((stat) => {
    if (stat.operation_name !== 'mean_age') {
      return
    }
    stat.options = { ...stat.options, counts: patientsSex }
  })
  return modifiedStatistics.concat(remainingStatistics)
}

const LegacyStatistics = ({
  outputs,
  experimentId,
  folderId,
  dictionary,
  dimensions,
  appId,
}: WidgetProps): React.JSX.Element => {
  if (
    outputs.statistics === undefined &&
    outputs.custom_statistics === undefined
  ) {
    throw new Error('Statistics not recognized.')
  }
  const { t } = useTranslation()
  const [{ loading, value, error }] = useValidatedData<
    StatisticsData,
    StatisticsMetadata,
    StatisticsArgs
  >(outputs.statistics, {}, { data: statisticsDataSchema })
  if (!loading && error !== undefined) throw error

  const [custom] = useValidatedData<
    StatisticsData,
    StatisticsMetadata,
    StatisticsArgs
  >(outputs.custom_statistics, {}, { data: statisticsDataSchema })
  if (!custom.loading && custom.error !== undefined) throw custom.error
  const { onResultIdChange } = useContext(ExportDataContext)

  useEffect(() => {
    onResultIdChange(outputs.statistics)
  }, [outputs.statistics])
  if (loading || value === undefined) {
    return <Loading />
  }
  const remainingStatistics = modifyResults(
    value.results,
    custom.value?.results ?? [],
  )
  const pyramid = buildPyramid(
    value?.results.find((o) => o.operation_name === 'population_pyramid')
      ?.result ?? [],
  )
  if (pyramid.length === 0) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <MessageComponent message={t('statistics.noResultsWarning')} />
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container alignItems="start" spacing={1}>
      <Grid
        item
        xs={(dimensions?.width ?? 0) < STATISTICS_BREAK_POINT ? 12 : 6}
        style={{ minWidth: STATISTICS_MIN_WIDTH, marginBottom: '50px' }}
      >
        <Grid
          key="statisticsPyramid"
          item
          xs={(dimensions?.width ?? 0) < STATISTICS_BREAK_POINT ? 12 : 6}
          style={{ minWidth: STATISTICS_MIN_WIDTH }}
        >
          {pyramid.length > 0 && (
            <StatisticsPyramid
              data={pyramid}
              experimentId={experimentId}
              folderId={folderId}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs>
        <StatisticsTable
          statistics={remainingStatistics}
          encodingDict={value.metadata.encoding_dict}
          datasetDict={dictionary}
          appId={appId}
        />
      </Grid>
    </Grid>
  )
}

export default LegacyStatistics
