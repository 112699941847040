import React from 'react'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circular: { width: '20%', height: '20%', margin: '15%' },
})

const Loading = (): React.JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CircularProgress size={100} className={classes.circular} />
    </div>
  )
}

export default Loading
