import React, { useEffect, useState } from 'react'
import MicIcon from '@mui/icons-material/Mic'
import { IconButton } from '@mui/material'

interface AudioRecorderProps {
  onAudioFile: (audioBlob: Blob) => void
  pushToTalkEnabled: boolean
}

export default function AudioRecorder({
  onAudioFile,
  pushToTalkEnabled,
}: AudioRecorderProps): React.JSX.Element {
  const [isRecording, setIsRecording] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)

  const handleMicClick = async (): Promise<void> => {
    if (isRecording) {
      setIsRecording(false)
      mediaRecorder?.stop()
      mediaStream?.getTracks().forEach((track) => {
        track.stop()
      })
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      setMediaStream(stream)
      const recorder = new MediaRecorder(stream)
      setMediaRecorder(recorder)
      recorder.start()
      setIsRecording(true)

      const audioChunks: Blob[] = []
      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data)
      }

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })
        onAudioFile(audioBlob)
      }
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.code === 'Space' && !isRecording && pushToTalkEnabled) {
        handleMicClick().catch((e) => {
          console.error(e)
        })
      }
    }

    const handleKeyUp = (event: KeyboardEvent): void => {
      if (event.code === 'Space' && isRecording) {
        handleMicClick().catch((e) => {
          console.error(e)
        })
      }
    }
    setTimeout(() => {
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyUp)
    }, 200)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [isRecording, mediaRecorder, pushToTalkEnabled])

  return (
    <IconButton
      onClick={() => {
        handleMicClick().catch((e) => {
          console.error(e)
        })
      }}
    >
      <MicIcon color={isRecording ? 'error' : 'primary'} />
    </IconButton>
  )
}
