import React, { useRef, useState, useEffect } from 'react'

const AutoScroller = ({
  items,
  children,
}: {
  items: unknown[]
  children: React.ReactNode
}) => {
  const progressContainerRef = useRef<HTMLDivElement>(null)
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)
  // Only scroll to bottom when nodes change AND we should auto-scroll
  useEffect(() => {
    if (progressContainerRef.current && shouldAutoScroll) {
      progressContainerRef.current.scrollTop =
        progressContainerRef.current.scrollHeight
    }
  }, [items, shouldAutoScroll])

  // Handle scroll events to determine if we should auto-scroll
  const handleScroll = () => {
    if (progressContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        progressContainerRef.current
      // Consider "at bottom" if within 20px of the bottom
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 20
      setShouldAutoScroll(isAtBottom)
    }
  }

  return (
    <div
      className="flex-1 h-full overflow-y-auto bg-white"
      ref={progressContainerRef}
      onScroll={handleScroll}
    >
      {children}
    </div>
  )
}

export default AutoScroller
