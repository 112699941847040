import React, { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3'
import makeStyles from '@mui/styles/makeStyles'
import { map } from 'lodash'
import { useResizeObserver } from '../hooks/useResizeObserver'

const MIN_WIDTH = 600
const INITIAL_WIDTH = 800

export interface HeatmapData {
  x: string
  y: string
  y_title: string
  value: number
  text: string
  type: string
}

const render = (
  svgId: string,
  data: HeatmapData[],
  onCellClick: (d: HeatmapData) => void,
  { width }: { width: number },
  title: string,
  numClust: number,
): void => {
  if (width < MIN_WIDTH) width = MIN_WIDTH
  const margin = { top: 35, right: 25, bottom: 25, left: 250 }
  width = width - margin.left - margin.right
  const height = (data.length / numClust) * 30

  // append the svg object to the body of the page
  d3.select(`#${svgId}`).selectAll('*').remove()
  const svg = d3
    .select(`#${svgId}`)
    // .append('svg')
    // .attr('class', svgId)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  // Labels of row and columns -> unique identifier of the column called 'group' and 'variable'
  const myGroups = map(data, (d) => d.x)
  const myVars = map(data, (d) => d.y)

  // Build X scales and axis:
  const xAxis = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.05)
  svg
    .append('g')
    .style('font-size', 15)
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(xAxis).tickSize(0))
    .select('.domain')
    .remove()

  // Build Y scales and axis:
  const yAxis = d3.scaleBand().range([height, 0]).domain(myVars).padding(0.05)
  svg
    .append('g')
    .style('font-size', 15)
    .call(d3.axisLeft(yAxis).tickSize(0))
    .select('.domain')
    .remove()

  // Build color scale
  const myColor = d3
    .scaleSequential(() => {})
    .interpolator(d3.interpolateReds)
    .domain([0, 1]) as unknown as (val: number) => string
  // .domain([minValue, maxValue])

  const parentDiv = (d3.select(`#${svgId}`)?.nodes()?.[0] as HTMLDivElement)
    ?.parentElement
  let tooltip: HTMLDivElement | null = null
  if (parentDiv !== null && parentDiv.childElementCount === 1) {
    tooltip = document.createElement('div')
    tooltip.style.position = 'absolute'
    tooltip.style.backgroundColor = 'white'
    tooltip.style.padding = '5px'
    tooltip.style.border = 'solid'
    tooltip.style.borderWidth = '2px'
    tooltip.style.borderRadius = '5px'
    tooltip.style.opacity = '0'
    parentDiv.append(tooltip)
  } else {
    tooltip =
      parentDiv !== null ? (parentDiv.children[1] as HTMLDivElement) : null
  }

  // Three function that change the tooltip when user hover / move / leave a cell
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseover = function (this: any): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '1'
      d3.select(this).style('stroke', 'black').style('opacity', 1)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mousemove = function (this: any, event: any, d: HeatmapData): void {
    const title = d.text + ' ' + d.y_title
    if (tooltip !== null) {
      tooltip.innerHTML = title
      const [x, y] = d3.pointer(event, this)
      tooltip.style.left = `${x + 300}px`
      tooltip.style.top = `${y}px`
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseleave = function (this: any): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '0'
    }
    d3.select(this).style('stroke', 'none')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClick = function (_e: any, d: HeatmapData): void {
    onCellClick(d)
  }

  svg
    .append('text')
    .attr('x', width / 2)
    .attr('y', 0 - margin.top / 2)
    .attr('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('text-decoration', 'underline')
    .text(title)

  // add the squares
  svg
    .selectAll()
    .data(data, (d) => (d === undefined ? '' : `${d.x ?? ''}:${d.y ?? ''}`))
    .enter()
    .append('rect')
    .attr('x', (d: HeatmapData) => xAxis(d.x) ?? 0)
    // @ts-expect-error something with typescript
    .attr('y', (d) => yAxis(d.y))
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('width', xAxis.bandwidth())
    .attr('height', yAxis.bandwidth())
    .style('fill', (d: HeatmapData) => myColor(d.value))
    .style('stroke-width', 4)
    .style('stroke', 'none')
    .style('opacity', 0.8)
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)
    .on('click', onClick)
}

const useStyles = makeStyles({
  svg: {
    overflow: 'visible',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    position: 'relative',
  },
})

interface ClusteringHeatmapProps {
  data: HeatmapData[]
  title: string
  experimentId: string
  folderId: string
  numClust: number
  onCellClick: (d: HeatmapData) => void
}

const ClusteringHeatmap = ({
  data,
  onCellClick,
  experimentId,
  title,
  numClust,
  folderId,
}: ClusteringHeatmapProps): React.JSX.Element => {
  const chartClass = `svg-${experimentId}-${folderId}`
  const [svgId] = useState(
    `svg-${experimentId}-${folderId}-${String(Math.random()).slice(2)}`,
  )
  const wrapperRef = useRef<HTMLDivElement | undefined>(undefined)
  const dimensions = useResizeObserver(wrapperRef)
  const classes = useStyles()
  useEffect(() => {
    render(
      svgId,
      data,
      onCellClick,
      dimensions ?? { width: INITIAL_WIDTH },
      title,
      numClust,
    )
  }, [data, dimensions])
  return (
    <div
      // @ts-expect-error Something with refs
      ref={wrapperRef}
      className={classes.root}
      style={{ height: (data.length / numClust) * 30 + 60 }}
    >
      <svg id={svgId} className={`${chartClass} ${classes.svg}`} />
    </div>
  )
}

export default ClusteringHeatmap
