import React, { useState } from 'react'
import _, { omit } from 'lodash'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import type { Extend } from '../../../../lib/types'
import type { FieldProps, FieldError } from '../../../form/types'
import FieldFactory from '../../../form/FieldFactory'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import FieldWrapper from '../../../form/FieldWrapper'

type Props = Extend<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FieldProps<{ [k: string]: any } | undefined>,
  {
    properties: FieldFactoryProps
    noDash?: boolean
    parentIsObject?: boolean
  }
>

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
    marginLeft: theme.spacing(0.5),
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    borderRight: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  row: {
    '& > td': {
      border: 'none',
    },
    '& > td:last-child': {
      width: theme.spacing(4.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

const ObjectField = ({
  required,
  label,
  value: initValue,
  properties,
  onChange,
  noDash,
  datasetDictionary,
  appId,
  error,
  onError,
  parentIsObject = false,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const value = initValue ?? {}
  const [originalValue] = useState(initValue)
  const onReset = (): void => {
    onChange?.(originalValue)
  }
  const onClear = (): void => {
    onChange?.(undefined)
  }

  const onRowChange = (k: string, childIsObject: boolean) => (v: unknown) => {
    let val
    if (childIsObject) {
      val = value[k] !== v ? { ...value, ...(v as object) } : value
    } else {
      val = value[k] !== v ? { ...value, [k]: v } : value
    }

    if (!_.isEqual(value, val)) {
      onChange?.(val)
    }
  }
  const vlabel =
    datasetDictionary?.list[properties.prefix ?? label ?? ''] ??
    properties.prefix ??
    label
  return (
    <FieldWrapper
      required={required}
      label={vlabel}
      onReset={onReset}
      onClear={onClear}
    >
      <Table
        className={noDash === undefined ? classes.table : ''}
        size="small"
        padding="none"
      >
        <TableBody>
          {_.map(properties, (items, k) => [k, items])
            .sort((a, b) => (a[1].order > b[1].order ? 1 : -1))
            .map(([k, items]) => (
              <TableRow className={classes.row} key={k}>
                <TableCell>
                  <FieldFactory
                    error={
                      (error as { [label: string]: FieldError })?.[k] ?? {}
                    }
                    onError={(errorFn) => {
                      if (onError !== undefined) {
                        const newSubError = errorFn(
                          ((error as { [label: string]: FieldError })?.[k] ??
                            {}) as FieldError,
                        )
                        if (_.keys(newSubError).length > 0) {
                          onError((oldError) => ({
                            ...oldError,
                            [k]: newSubError,
                          }))
                        } else {
                          onError((oldError) => omit(oldError, k))
                        }
                      }
                    }}
                    label={k}
                    {...items}
                    prefix={properties.prefix}
                    appId={appId}
                    parentIsObject
                    value={
                      items.type !== 'object'
                        ? value[parentIsObject ? `${label}.${k}` : k]
                        : value
                    }
                    onChange={onRowChange(
                      parentIsObject ? `${label}.${k}` : k,
                      items.type === 'object',
                    )}
                    datasetDictionary={datasetDictionary}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </FieldWrapper>
  )
}

export default ObjectField
