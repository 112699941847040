import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
// Import this way to reduce bundle size
// https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking
// https://fontawesome.com/icons?d=gallery&m=free
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons/faProjectDiagram'
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons/faBoxArchive'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons/faSearchMinus'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons/faArrowsAlt'
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons/faDeleteLeft'
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench'
import { faPlusSquare as farPlusSquare } from '@fortawesome/free-regular-svg-icons/faPlusSquare'
import { faEdit as farEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle'
import { faCopy as farCopy } from '@fortawesome/free-regular-svg-icons/faCopy'
import { faTrashAlt as farTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt'
import { faFolderOpen as farFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen'
import { faFolder as farFolder } from '@fortawesome/free-regular-svg-icons/faFolder'
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt'
import { faEye as farEye } from '@fortawesome/free-regular-svg-icons/faEye'
import { faEyeSlash as farEyeSlash } from '@fortawesome/free-regular-svg-icons/faEyeSlash'
import { faChartBar } from '@fortawesome/free-regular-svg-icons/faChartBar'
import { faUserGear } from '@fortawesome/free-solid-svg-icons/faUserGear'
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons'
import { faMaximize as farMaximize } from '@fortawesome/free-solid-svg-icons/faMaximize'
import { faMinimize as farMinimize } from '@fortawesome/free-solid-svg-icons/faMinimize'
import { faClockRotateLeft as farClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft'
/* eslint-disable @typescript-eslint/no-empty-object-type */
interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

// solid icons

export const AlertIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faExclamationCircle} />
)
export const CloseIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faTimes} />
)
export const HomeIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faHome} />
)
export const LogoutIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faPowerOff} />
)
export const ProjectIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faProjectDiagram} />
)
export const DatasetIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faDatabase} />
)
export const MenuIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faBars} />
)
export const CloseMenuIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faChevronLeft} />
)
export const SendIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faPaperPlane} />
)
export const HourglassIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faHourglassHalf} />
)
export const FilterIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faFilter} />
)
export const ExperimentIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faChartBar} />
)
export const InfoIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faInfoCircle} />
)

export const SuccessIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faCheckCircle} />
)
export const ErrorIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faExclamationTriangle} />
)
export const ProcessingIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faCogs} />
)
export const MoreVerticalIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faEllipsisV} />
)
export const UndoIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faUndo} />
)
export const AngleRightIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faAngleRight} />
)
export const AngleDownIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faAngleDown} />
)
export const QuestionIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faQuestion} />
)
export const QuestionCircleIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faCircleQuestion} />
)
export const ExportIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faDownload} />
)
export const ListOlIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faListOl} />
)
export const PlusCircleIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faPlusCircle} />
)
export const PlusIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faPlus} />
)
export const ChevronRightIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faChevronRight} />
)
export const ChevronDownIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faChevronDown} />
)

export const RegularMaximizeIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farMaximize} />
)

export const RegularMinimizeIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farMinimize} />
)

export const RegularClockRotateLeftIcon = (
  props: IconProps,
): React.JSX.Element => <FontAwesomeIcon {...props} icon={farClockRotateLeft} />

// regular icons

export const RegularAddIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farPlusSquare} />
)
export const RegularEditIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farEdit} />
)
export const RegularCopyIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farCopy} />
)
export const RegularDeleteIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farTrashAlt} />
)
export const RegularShareIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faShareFromSquare} />
)
export const RegularDeleteLeftIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faDeleteLeft} />
)
export const RegularFileIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farFileAlt} />
)
export const RegularFolderIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farFolder} />
)
export const RegularOpenFolderIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farFolderOpen} />
)
export const RegularEyeIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farEye} />
)
export const RegularEyeSlashIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farEyeSlash} />
)

export const RegularQuestionIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={farQuestionCircle} />
)

export const ArchiveIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faBoxArchive} />
)
export const SearchIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faSearch} />
)
export const SearchPlusIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faSearchPlus} />
)
export const SearchMinusIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faSearchMinus} />
)
export const ArrowsAlt = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faArrowsAlt} />
)

export const UserIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faUserGear} />
)
export const WrenchIcon = (props: IconProps): React.JSX.Element => (
  <FontAwesomeIcon {...props} icon={faWrench} />
)
