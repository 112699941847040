import React, { useState } from 'react'
import { isEqual, omit, isNaN, isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import type { Extend } from '../../../../lib/types'
import type { FieldProps, FieldError } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'

type Props = Extend<
  FieldProps<
    | {
        lte: number
        gte: number
      }
    | undefined
  >,
  {
    min: number
    max: number
    rangedDefined: boolean
  }
>

const useStyles = makeStyles((theme) => ({
  field: {
    width: '50%',
    padding: theme.spacing(1),
  },
}))

export default function RangeNumericField({
  required,
  label,
  value: initValue,
  min,
  max,
  onChange,
  datasetDictionary,
  error,
  onError,
  fullName,
  advanced,
  rangedDefined,
}: Props): React.JSX.Element {
  const classes = useStyles()
  const value = initValue ?? { lte: max, gte: min }
  const [originalValue] = useState(initValue)
  const { lte, gte } = value
  const onReset = (): void => {
    onChange?.(originalValue)
  }
  const onClear = (): void => {
    onChange?.(undefined)
  }
  const { t } = useTranslation()
  const vlabel
    = datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
  return (
    <FieldWrapper
      advanced={advanced}
      required={required}
      label={
        vlabel !== undefined
          ? `${vlabel} ${rangedDefined ? `(${gte} - ${lte})` : ''}`
          : undefined
      }
      onReset={onReset}
      onClear={onClear}
      error={
        error !== null && label !== undefined
          ? (error as { [label: string]: string })?.[label]
          : undefined
      }
    >
      <div>
        <TextField
          label="Min"
          className={classes.field}
          value={gte}
          inputProps={{ min, max }}
          variant="standard"
          onChange={(e) => {
            const v = Number(e.target.value)
            if (isNaN(v)) {
              return
            }
            if (label !== undefined) {
              if (v > lte) {
                onError?.((e: FieldError) => ({
                  ...e,
                  [label]: `${t('fieldErrors.rangeError', { min, max })} ${t(
                    'fieldErrors.rangeErrorVals',
                    { gte: v, lte },
                  )}`,
                }))
              } else {
                onError?.((e) => {
                  if (isArray(e)) {
                    throw new Error(
                      'Unexpected list of errors in RangeNumericField',
                    )
                  }
                  return omit(e, label)
                })
              }
            }
            if (e.target.value.endsWith('.') || e.target.value.endsWith('.0')) {
              onChange?.({
                // @ts-expect-error Setting string because of Number(x.) => x
                gte: e.target.value,
                lte,
              })
            } else if (!isEqual(v, gte)) {
              onChange?.({
                gte: v,
                lte,
              })
            }
          }}
        />
        <TextField
          label="Max"
          className={classes.field}
          value={lte}
          inputProps={{ min, max }}
          variant="standard"
          onChange={(e) => {
            const v = Number(e.target.value)
            if (isNaN(v)) {
              return
            }
            if (label !== undefined) {
              if (v < gte) {
                onError?.((e: FieldError) => ({
                  ...e,
                  [label]: `${t('fieldErrors.rangeError', { min, max })} ${t(
                    'fieldErrors.rangeErrorVals',
                    { gte, lte: v },
                  )}`,
                }))
              } else {
                onError?.((e) => {
                  if (isArray(e)) {
                    throw new Error(
                      'Unexpected list of errors in RangeNumericField',
                    )
                  }
                  return omit(e, label)
                })
              }
            }
            if (e.target.value.endsWith('.') || e.target.value.endsWith('.0')) {
              onChange?.({
                // @ts-expect-error Setting string because of Number(x.) => x
                lte: e.target.value,
                gte,
              })
            } else if (!isEqual(v, lte)) {
              onChange?.({ gte, lte: v })
            }
          }}
        />
      </div>
    </FieldWrapper>
  )
}
