import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Badge from '@mui/material/Badge'
import { THEME_NAME, DEPRECATED_FEATURES } from '../../../constants/env'
import type { TreeItem } from '../../../api/providers'
import { getDataset } from '../../../api/providers'
import { getRootFolder } from '../../../api/providers/folder'
import {
  getFilterPipelines,
  getExperimentPipelines,
} from '../../../api/providers/dataset'
import type { AppState, RequestState } from '../../../store/types'
import { logout, toggleMenu } from '../../../store/actions'
import { selectedMenuItem as selectedMenuItemSelector } from '../../../store/selectors'
import { getDatasetDictionary } from '../../../api/providers/dictionary'
import {
  LogoutIcon,
  MenuIcon,
  RegularQuestionIcon,
  FilterIcon,
  ExperimentIcon,
  UserIcon,
} from '../icons'
import Loading from '../Loading'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { ByAmalfi } from './AmalfiLogos'
import EnableFeatureFlags from './EnableFeatureFlags'

const useStyles = makeStyles((theme) => {
  return {
    root: { zIndex: theme.zIndex.drawer + 1 },
    title: {
      [theme.breakpoints.up('lg')]: { marginLeft: theme.spacing(2) },
      marginRight: theme.spacing(2),
    },
    divider: {
      height: theme.spacing(3),
      margin: theme.spacing(1),
      background: 'rgba(0, 0, 0, 0.6)',
      borderRight: '1px solid rgba(255, 255, 255, 0.6)',
    },
    groupLabel: {
      margin: 5,
      paddingTop: 4,
      fontSize: theme.typography.body2.fontSize,
    },
    rightAlign: { flexGrow: 1 },
    pipelineContainer: {
      borderRadius: '5%',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
      backgroundColor: 'rgba(0,0,0,0.2)',
      '&:hover': { backgroundColor: 'rgba(0,0,0,0.4)' },
    },
    groupContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'wrap',
      margin: theme.spacing(1),
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'uppercase',
      textAlign: 'center',
      alignItems: 'center',
    },
    innerSpan: {
      marginTop: 5,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 'bold',
    },
    logoAnis: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.25),
    },
    toolbarDense: { minHeight: 60, flexFlow: 'wrap' },
  }
})

const Header = (): React.JSX.Element => {
  const dispatch = useDispatch()
  const request = useSelector((state: AppState): RequestState => state.request)
  const selectedMenuItem = useSelector(
    (state: AppState): TreeItem | undefined => selectedMenuItemSelector(state),
  )
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const { t } = useTranslation()
  const [openFeatureFlags, setOpenFeatureFlags] = useState(false)
  const [folderId, setFolderId] = useState<string | undefined>()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [filterActions, setFilterActions] = useState<
    Array<{ title: string; pipelineId: string }>
  >([])
  const [experimentActions, setExperimentActions] = useState<
    Array<{ title: string; pipelineId: string }>
  >([])
  const [datsetDictionary, setDatasetDictionary] = useState<{
    list: { [key: string]: string }
  } | null>(null)
  const toggleConfirmDialog = (): void => {
    setConfirmDialogOpen(!confirmDialogOpen)
  }

  useAsync(async () => {
    if (selectedMenuItem !== undefined) {
      let folderId: string
      if (selectedMenuItem.type === 'experiment') {
        folderId = selectedMenuItem.folderId
      } else {
        folderId = selectedMenuItem.id
      }
      const project = await getRootFolder(folderId)
      const dataset = await getDataset(project.datasetId)
      const filterPipelines = getFilterPipelines(dataset)
      const experimentPipelines = getExperimentPipelines(dataset)
      const dictionary = await getDatasetDictionary([project])
      setDatasetDictionary(dictionary)
      setFolderId(folderId)
      setFilterActions(
        filterPipelines.map((x) => ({ title: x.name, pipelineId: x.id })),
      )
      setExperimentActions(
        experimentPipelines
          .filter((n) => n.name !== 'Basic Statistics')
          .map((x) => ({ title: x.name, pipelineId: x.id })),
      )
    } else {
      setFolderId(undefined)
      setFilterActions([])
      setExperimentActions([])
    }
  }, [selectedMenuItem])

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent): void => {
      if (ev.altKey && ev.code.startsWith('Digit')) {
        const index = parseInt(ev.code.replace('Digit', ''), 10) - 1
        const actions = [...filterActions, ...experimentActions]
        if (index < actions.length) {
          const { pipelineId } = actions[index]
          const route =
            index >= filterActions.length
              ? `/create-experiment/${folderId}`
              : `/create-filter/${folderId}`
          if (location.pathname === '/') {
            navigate(route, { state: { pipelineId } })
          }
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [filterActions, experimentActions, folderId, navigate])

  const logoName = `logo-${THEME_NAME}-appbar`
  const titleLabel = t('app.title')
  return (
    <AppBar position="fixed" className={classes.root} id="app-header">
      <Toolbar
        variant="dense"
        classes={{ dense: classes.toolbarDense }}
        disableGutters
      >
        <IconButton
          color="inherit"
          onClick={() => dispatch(toggleMenu() as { type: string })}
          size="large"
        >
          <MenuIcon size="sm" />
        </IconButton>

        <img
          src={`/logos/${logoName}.png`}
          alt="logo-anis"
          className={`${classes.logoAnis} !important`}
          title={titleLabel}
          style={{ height: 40 }}
        />
        {THEME_NAME === 'traject' && (
          <>
            <ByAmalfi height={24} />
            <img
              src={'/logos/FGS-negatiu-blanc.png'}
              alt="logo-stpau"
              className={classes.logoAnis}
              title={titleLabel}
              style={{ padding: 2 }}
              height="45"
            />
          </>
        )}
        {DEPRECATED_FEATURES &&
          [filterActions, experimentActions]
            .filter((x) => x.length > 0)
            .map((actions, i) => (
              <div key={i} className={classes.groupContainer}>
                <Divider orientation="vertical" className={classes.divider} />
                {i === 0 ? (
                  <div className={classes.groupLabel}>
                    <Badge badgeContent="+">
                      <FilterIcon fixedWidth />
                    </Badge>
                    &nbsp;
                    {t('button.addFilter')}:
                  </div>
                ) : (
                  <div className={classes.groupLabel}>
                    <Badge badgeContent="+">
                      <ExperimentIcon fixedWidth />
                    </Badge>
                    &nbsp;
                    {t('button.addExperiment')}:
                  </div>
                )}
                {actions.map(({ title, pipelineId }, j) => {
                  if (folderId === undefined) {
                    throw new Error('undefined folderId')
                  }
                  const route =
                    i > 0
                      ? `/create-experiment/${folderId}`
                      : `/create-filter/${folderId}`
                  return (
                    <Button
                      className={classes.pipelineContainer}
                      key={j}
                      // eslint-disable-next-line no-constant-binary-expression
                      title={datsetDictionary?.list[title] ?? '' ?? title}
                      color="inherit"
                      variant="contained"
                      onClick={() => {
                        navigate(route, { state: { pipelineId } })
                      }}
                    >
                      {datsetDictionary?.list[title ?? ''] ?? title}
                    </Button>
                  )
                })}
              </div>
            ))}
        <div className={classes.rightAlign} />
        <IconButton
          color="inherit"
          component={Link}
          to="https://docs.amalfianalytics.com/docs/anis/intro"
          target="_blank"
          size="large"
        >
          <RegularQuestionIcon size="sm" title="Help" />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => setOpenFeatureFlags(true)}
          size="large"
        >
          <UserIcon size="xs" title="User Settings" />
        </IconButton>
        <IconButton color="inherit" onClick={toggleConfirmDialog} size="large">
          <LogoutIcon size="sm" />
        </IconButton>
      </Toolbar>
      <Loading hidden={request.count === 0} />
      <ConfirmDialog
        open={confirmDialogOpen}
        title={t('exitDialog.title')}
        message={t('exitDialog.message')}
        onCancel={toggleConfirmDialog}
        onAccept={() => {
          toggleConfirmDialog()
          dispatch(logout() as { type: string })
        }}
      />
      <EnableFeatureFlags
        open={openFeatureFlags}
        onClose={() => setOpenFeatureFlags(false)}
      />
    </AppBar>
  )
}

export default Header
