import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'
import { CloseIcon } from '../../../icons'

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  grouped: {
    margin: theme.spacing(0.1),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  groupedSizeSmall: {
    padding: 5,
    margin: 1,
    height: 25,
  },
  box: {
    paddingLeft: theme.spacing(0.1),
  },
}))

interface Props {
  notValue: string | null
  conditionValue: string
  onNewGroup: () => void
  onNewRule: () => void
  onPressAllAny: (v: string) => void
  onPressNot: (v: string) => void
  onDelete?: () => void
}

const TopBar = ({
  onNewGroup,
  onNewRule,
  onPressAllAny,
  onPressNot,
  onDelete,
  notValue,
  conditionValue,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box display="flex" p={1} className={classes.box}>
      <Box p={1} className={classes.box}>
        <Paper elevation={0} className={classes.paper}>
          <ToggleButtonGroup
            classes={{
              grouped: classes.grouped,
              // groupedSizeSmall: classes.groupedSizeSmall,
            }}
            size="small"
            exclusive
            onChange={(_e, val) => {
              onPressNot(val)
            }}
            value={notValue}
          >
            <ToggleButton value="not">NOT</ToggleButton>
          </ToggleButtonGroup>

          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <ToggleButtonGroup
            classes={{
              grouped: classes.grouped,
              // groupedSizeSmall: classes.groupedSizeSmall,
            }}
            exclusive
            size="small"
            value={conditionValue}
            onChange={(_e, val) => {
              if (val !== undefined || val.length > 0) {
                onPressAllAny(val)
              }
            }}
          >
            <ToggleButton value="all">AND</ToggleButton>
            <ToggleButton value="any">OR</ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      </Box>
      <Box p={1} flexGrow={1} />

      <Box p={1}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            onNewRule()
          }}
        >
          {t('fields.advanced.addRule')}
        </Button>
      </Box>
      <Box p={1}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            onNewGroup()
          }}
        >
          {t('fields.advanced.addGroup')}
        </Button>
      </Box>
      {onDelete !== undefined && (
        <Box p={1}>
          <IconButton
            size="small"
            onClick={() => {
              onDelete()
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default TopBar
