import _ from 'lodash'
import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  getRootFolder,
  getFolderFilters,
  getFilterPipelines,
  getPipelineFields,
  getParentFolders,
  getUserVariables,
  getDataset,
} from '../../../../../api/providers'
import { createFilter, toggleMenuItem } from '../../../../../store/actions'
import { getDatasetDictionary } from '../../../../../api/providers/dictionary'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import FieldFactory from '../../../form/FieldFactory'
import TextField from '../../../form/fields/TextField'
import {
  newAvailName,
  isEmpty,
  cleanPipelineValues,
} from '../../../../lib/util'
import ModalDialog from '../../../dialogs/ModalDialog'
import type { FieldError } from '../../../form/types'

interface Props {
  createFilter: typeof createFilter
  toggleMenuItem: typeof toggleMenuItem
}

const CreateFilterPage = ({
  createFilter,
  toggleMenuItem,
}: Props): React.JSX.Element => {
  const match = useMatch('/create-filter/:folderId')
  const navigate = useNavigate()
  const location = useLocation()
  const folderId = match?.params.folderId as string
  const initialPipelineId =
    (location.state.pipelineId as string | undefined) ?? ''
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [fieldError, setFieldError] = useState<FieldError>({})
  const [name, setName] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [advanced, setAdvanced] = useState(false)
  const pipelineId = initialPipelineId
  const [values, setValues] = useState<{
    [moduleId: string]: { [fieldName: string]: unknown }
  }>({})
  const [selectedUserVariable, setSelectedUserVariable] = useState<
    string[] | { eql: string } | { in: string[] } | undefined
  >(undefined)
  const [datasetDictionary, setDatasetDictionary] =
    useState<DictionaryContent | null>(null)

  // loads initial data
  const state = useAsync(async () => {
    const project = await getRootFolder(folderId)
    const pipelines = await getFilterPipelines(project.datasetId)
    const dataset = await getDataset(project.datasetId)
    const filterNames = _.map(await getFolderFilters(folderId), 'name')
    const path = _.map(await getParentFolders(folderId), 'name')
    const userVariables = await getUserVariables(project.id)
    const dictionary = await getDatasetDictionary([project])
    setDatasetDictionary(dictionary)
    setPlaceholder(
      newAvailName(dictionary?.list[pipelineId] ?? pipelineId, filterNames),
    )
    return {
      datasetId: project.datasetId,
      appId: dataset.app_id,
      pipelines,
      filterNames,
      path,
      userVariables,
    }
  }, [folderId, pipelineId])
  const stateError = state.error !== undefined ? String(state.error) : undefined
  if (state.error !== undefined) {
    console.error(state.error)
  }
  const datasetId = state.value?.datasetId ?? ''
  const appId = state.value?.appId
  const path = state.value?.path ?? []
  // loads pipeline fields
  const fieldsState = useAsync(async () => {
    const fieldsGroup =
      datasetId.length > 0 && pipelineId.length > 0
        ? await getPipelineFields(datasetId, pipelineId)
        : {}
    const defValues = _.mapValues(fieldsGroup, (fields) => {
      return _.mapValues(
        _.keyBy(fields, (x) => x.name),
        (x) => x.default,
      )
    })

    setValues(defValues)
    return fieldsGroup
  }, [datasetId, pipelineId])
  const fieldsGroup = fieldsState.value ?? {}
  const onFieldChange =
    (moduleId: string, fieldName: string) =>
    (value?: unknown): void => {
      const newFieldValues = { ...values }
      newFieldValues[moduleId][fieldName] = value
      setValues(newFieldValues)
    }

  const onCancel = (): void => {
    navigate('/')
  }

  const onChangeSelectedUserVariable = (
    val: string[] | { eql: string } | { in: string[] } | undefined,
  ): void => {
    setSelectedUserVariable(val)
  }

  const onSubmit = (): void => {
    const requiredFields = _.some(fieldsGroup, (fields, moduleId) =>
      fields.some(
        ({ required, name, type }) =>
          required &&
          isEmpty(values[moduleId][name]) &&
          !(
            selectedUserVariable !== undefined &&
            type === 'userVariableSelection'
          ),
      ),
    )
    if (requiredFields || pipelineId.length === 0) {
      const formRequiredFields = t('form.requiredFields')
      setError(formRequiredFields)
      return
    }
    for (const mod in values) {
      for (const f in values[mod]) {
        if (f === '$userVariable') {
          const v = (state.value?.userVariables ?? []).find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (v: any) =>
              v.name ===
              (selectedUserVariable !== undefined &&
                'eql' in selectedUserVariable &&
                selectedUserVariable.eql),
          )
          values[mod]._userVariable = v
        }
      }
    }
    // FIXME This is a FIX to make it work with the new row filters. It should be refactored at some point.
    if (selectedUserVariable !== undefined) {
      let fname = ''
      if ('modules.preprocessing.filters.row_filter.RowFilter' in values) {
        fname = 'modules.preprocessing.filters.row_filter.RowFilter'
      } else if (
        'iris_backend.functionalities.case_mix.pipelines.patients_filter.compute_patients_filter' in
        values
      ) {
        fname =
          'iris_backend.functionalities.case_mix.pipelines.patients_filter.compute_patients_filter'
      }
      const mod = values[fname]
      if (mod !== undefined) {
        const val = mod.assignment
        if (val !== undefined) {
          values[fname] = { all: [mod] }
        }
      }
    }
    createFilter({
      name: name.length > 0 ? name : placeholder,
      folderId,
      pipelineId,
      values: cleanPipelineValues(values) as {
        [moduleId: string]: { [fieldName: string]: unknown }
      },
    })
    toggleMenuItem(folderId, true)
    navigate('/')
  }
  const createFilterDialogName = t('createFilterDialog.name')
  const actions = [
    { label: t('button.cancel'), onClick: onCancel, type: 'close' },
    {
      label: t('button.continue'),
      disabled:
        state.loading ||
        pipelineId.length === 0 ||
        Object.keys(fieldError ?? {}).length > 0,
      type: 'submit',
      onClick: onSubmit,
    },
  ]
  return (
    <ModalDialog
      title={`${t('createFilterDialog.title')}${
        pipelineId.length > 0 ? ':' : ''
      } ${datasetDictionary?.list[pipelineId] ?? pipelineId}`}
      path={path}
      error={stateError ?? error}
      actions={actions}
    >
      <FormGroup>
        {!state.loading && appId !== undefined && (
          <>
            {pipelineId.length > 0 && (
              <TextField
                required
                important
                label={createFilterDialogName}
                placeholder={placeholder}
                value={name}
                onChange={setName}
              />
            )}
            {_.map(fieldsGroup, (fields, moduleId) =>
              fields.map(({ name, ...rest }, i) => {
                return (
                  <div key={`${moduleId}:${i}`}>
                    {(name === '__root' ||
                      (pipelineId === 'Episodes Filter' && i === 0)) && (
                      <FormControlLabel
                        control={<Switch color="primary" />}
                        label={t('fields.advanced.advancedFilters')}
                        // classes={{ label: classes.label }}
                        value={advanced}
                        onChange={(e) => {
                          // @ts-expect-error Checked does exist
                          const val = e.target.checked as boolean
                          if (val) {
                            onFieldChange(
                              moduleId,
                              name,
                            )?.({ all_query: [{ all: [{}] }] })
                          } else {
                            onFieldChange(moduleId, name)?.({ all: [{}] })
                          }
                          setFieldError?.(() => ({}))
                          setAdvanced?.(val)
                        }}
                      />
                    )}
                    <FieldFactory
                      key={`${moduleId}:${i}`}
                      prefix=""
                      label={name}
                      value={values[moduleId][name]}
                      onChange={onFieldChange(moduleId, name)}
                      error={fieldError}
                      onError={setFieldError}
                      userVariables={_.get(state.value, 'userVariables')}
                      onChangeSelectedUserVariable={
                        onChangeSelectedUserVariable
                      }
                      selectedUserVariable={selectedUserVariable}
                      datasetDictionary={datasetDictionary}
                      appId={appId}
                      advanced={advanced}
                      setAdvanced={setAdvanced}
                      {...rest}
                    />
                  </div>
                )
              }),
            )}
          </>
        )}
      </FormGroup>
    </ModalDialog>
  )
}

const mapDispatchToProps = { createFilter, toggleMenuItem }

export default connect(null, mapDispatchToProps)(CreateFilterPage)
