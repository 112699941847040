import { NEW_FILTER_DOWNLOAD, END_FILTER_DOWNLOAD } from './types'
import type { DownloadsAction, DownloadsState, FilterDownload } from './types'

const initState: DownloadsState = {
  currentFilterDownloads: [],
}

export default (
  state: DownloadsState = initState,
  action: DownloadsAction,
): DownloadsState => {
  if (action.type === NEW_FILTER_DOWNLOAD) {
    const newDownload: FilterDownload = {
      experimentId: action.payload.experimentId,
      newExperimentId: action.payload.newExperimentId,
      resultId: action.payload.resultId,
    }
    return {
      ...state,
      currentFilterDownloads: [...state.currentFilterDownloads, newDownload],
    }
  }
  if (action.type === END_FILTER_DOWNLOAD) {
    return {
      ...state,
      currentFilterDownloads: state.currentFilterDownloads.filter(
        (f) => f.experimentId !== action.payload.experimentId,
      ),
    }
  }
  return state
}
