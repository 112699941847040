import React from 'react'
import type { ReactNode, ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import type { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import type { ButtonProps } from '@mui/material/Button'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import type { Extend } from '../../../lib/types'
import SubmitButton from '../../buttons/SubmitButton'
import Loading from '../../Loading'
import Box from '@mui/material/Box'

type Props = Extend<
  Omit<DialogProps, 'open' | 'fullWidth'>,
  {
    title: string
    description?: string
    path?: string[]
    error?: string | ReactElement
    helper?: string
    loading?: boolean
    actions: Array<
      Extend<
        ButtonProps,
        {
          label: string
          disabled?: boolean
          type: string
          onClick: () => void
        }
      >
    >
    children: ReactNode
  }
>

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleTop: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  activeBreadcrumb: {
    color: theme.palette.primary.main,
  },
  errorContainer: {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
}))

const ModalDialog = ({
  title,
  path,
  error,
  actions,
  children,
  helper,
  description,
  maxWidth = 'md',
  loading = false,
  ...rest
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const onClose = actions.find((a) => a.type === 'close')
  const onSubmit = actions.find((a) => a.type === 'submit')
  return (
    <Dialog
      open
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose?.onClick}
      onKeyDown={(ev) => {
        if (onSubmit?.onClick !== undefined && ev.key === 'Enter') {
          ev.preventDefault()
          onSubmit.onClick()
        }
      }}
      {...rest}
    >
      <DialogTitle
        variant="h5"
        className={`${classes.title} ${classes.titleTop}`}
      >
        {title}
      </DialogTitle>
      <Loading hidden={!loading} />
      {path !== undefined && (
        <DialogTitle className={classes.title}>
          <div className={classes.container}>
            <div>
              <Breadcrumbs>
                {path.map((label, i) => (
                  <Typography
                    key={i}
                    color={
                      i < path.length - 1 ? 'textSecondary' : 'textPrimary'
                    }
                    className={
                      i === path.length - 1 ? classes.activeBreadcrumb : ''
                    }
                  >
                    {label}
                  </Typography>
                ))}
              </Breadcrumbs>
            </div>
            {helper !== undefined && (
              <div>
                <Typography color="textSecondary" variant="body1">
                  &nbsp;{helper}
                </Typography>
              </div>
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <Box className={classes.errorContainer}>
        {error !== undefined && (
          <DialogContentText color="error">{error}</DialogContentText>
        )}
      </Box>
      {description !== undefined && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {actions.map(({ type, label, disabled, ...rest }, key) => {
          const ButtonType = type === 'submit' ? SubmitButton : Button
          return (
            <ButtonType key={key} {...rest} disabled={disabled}>
              {label}
            </ButtonType>
          )
        })}
      </DialogActions>
    </Dialog>
  )
}

export default ModalDialog
