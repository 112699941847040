import React from 'react'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../types'
import FieldWrapper from '../../FieldWrapper'
import SelectInput from '../../inputs/SelectInput'
import type { SelectInputProps } from '../../inputs/SelectInput'
import { omit } from 'lodash'
type Props = Extend<SelectInputProps, FieldProps<string>>

const SelectField = ({
  value,
  label,
  required,
  onChange,
  ...rest
}: Props): React.JSX.Element => (
  <FieldWrapper required={required} label={label}>
    <SelectInput
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value)
      }}
      {...omit(rest, ['onError', 'error'])}
    />
  </FieldWrapper>
)

export default SelectField
