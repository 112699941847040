import _ from 'lodash'
import { storage } from '../../../lib/storage'
import { LayoutTree, LayoutNode } from '../types'
import type { LayoutItem } from '../types'

export const getLayoutItems = (): LayoutItem[] => {
  const cachedItems = storage().getItem('layoutItems')
  const items: Array<[string, string, string]> =
    cachedItems !== null ? JSON.parse(cachedItems) : []

  return items.map((x) => ({ experimentId: x[0], name: x[1], folderId: x[2] }))
}

export const setLayoutItems = (items: LayoutItem[]): void => {
  storage().setItem(
    'layoutItems',
    JSON.stringify(items.map((x) => [x.experimentId, x.name, x.folderId])),
  )
}

export const getLayoutTree = (): LayoutTree => {
  const cachedTree = storage().getItem('layoutTree')
  const defaultTree = {
    global: {},
    borders: [],
    layout: {
      type: 'row',
      weight: 100,
      children: [],
    },
  }

  return LayoutTree.fromJson(
    cachedTree !== null ? JSON.parse(cachedTree) : defaultTree,
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSerializedLayoutTree = (): any => {
  const cachedTree = storage().getItem('layoutTree')
  const defaultTree = {
    global: {},
    borders: [],
    layout: {
      type: 'row',
      weight: 100,
      children: [],
    },
  }

  return cachedTree !== null ? JSON.parse(cachedTree) : defaultTree
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLayoutTree = (tree: LayoutTree | any): void => {
  if (typeof tree.toJson === 'function') {
    // It's a LayoutTree instance
    storage().setItem('layoutTree', JSON.stringify(tree.toJson()))
  } else {
    // It's already a serialized object
    storage().setItem('layoutTree', JSON.stringify(tree))
  }
}

export const buildLayout = (
  tree: LayoutTree,
  items: LayoutItem[],
): LayoutTree => {
  const experimentIds = _.map(items, 'experimentId')
  const folderIds = _.map(items, 'folderId')
  const selectedLayoutItem = _.head(items)

  // deletes empty or missing items
  const ret = (function removeMissingItems(layout: LayoutNode): LayoutNode {
    const children = layout.getChildren()

    return new LayoutNode({
      ...layout,
      ...(children.length > 0 && {
        children: children
          .filter(
            ({ config }) =>
              config === undefined ||
              (experimentIds.includes(config.experimentId) &&
                folderIds.includes(config.folderId)),
          )
          .filter(
            ({ children }) => children === undefined || children.length > 0,
          )
          .map(removeMissingItems),
      }),
    })
  })(tree.layout)

  if (experimentIds.length > 0) {
    // adds additional items
    const tabItems = ret.getItems().filter(({ type }) => type === 'tab')
    for (const item of items) {
      if (
        !tabItems.some(
          ({ config }) =>
            config !== undefined &&
            config.experimentId === item.experimentId &&
            config.folderId === item.folderId,
        )
      ) {
        const tabsetItem = ret.findOrCreate('tabset')
        tabsetItem.addItem(
          new LayoutNode({
            type: 'tab',
            name: item.name,
            config: {
              experimentId: item.experimentId,
              folderId: item.folderId,
            },
          }),
        )
      }
    }

    // opens the first item
    const [id] = experimentIds
    const [firstFolderId] = folderIds
    const [{ item, index }] = ret
      .getItems()
      .map((item) => {
        const children = item.getChildren()
        const index = children.findIndex(
          ({ config }) =>
            config !== undefined &&
            config.experimentId === id &&
            config.folderId === firstFolderId,
        )
        return { item, index }
      })
      .filter(({ index }) => index > -1)
    item.selected = index

    // highlight selected item
    for (const item of ret.getItems()) {
      const { config, type } = item

      if (type === 'tab') {
        item.name = _.trim(item.name, '[ ]')
        if (
          config !== undefined &&
          selectedLayoutItem !== undefined &&
          config.experimentId === selectedLayoutItem.experimentId &&
          config.folderId === selectedLayoutItem.folderId
        ) {
          item.name = `[ ${selectedLayoutItem.name} ]`
        }
      }
    }
  }

  return new LayoutTree(ret)
}
