import React, { useState } from 'react'
import { type VarDict, setupVarDict } from '../StatisticsWidget/StatisticsTable'
import type {
  EncodingDict,
  StatisticsClusteringData,
  StatisticsData,
  StatisticsMean,
  StatisticsCount,
  StatisticsTopCounts,
  StatisticsCountFilter,
  StatisticsAvgTime,
  StatisticsReadmissions,
} from '../StatisticsWidget/types'
import { type Dictionary } from '../types'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import { range, round, uniq, flatten, sortBy } from 'lodash'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useDictionaries } from '../hooks/useDictionary'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import ArrowButton from '../../../buttons/ArrowButton'
// import Tooltip from '@mui/material/Tooltip'

interface ClusteringTableProps {
  encodingDict: EncodingDict
  datasetDict: Dictionary
  appId: string
  statistics: StatisticsClusteringData
}
function computeValue(
  statistics:
    | StatisticsCount
    | StatisticsMean
    | StatisticsCountFilter
    | StatisticsAvgTime
    | StatisticsReadmissions,
  parts: Array<string | number | null>,
  type: 'mean' | 'count' | 'count_filter' | 'avg_time' | 'readmissions',
): string | number | undefined {
  if (type === 'count_filter' || type === 'readmissions') {
    const stat = statistics as StatisticsCountFilter | StatisticsReadmissions
    if (stat.result.length === 0) {
      return 0
    }
    return `${stat.result[0].value} (${round(
      stat.result[0].percent * 100,
      2,
    )}%)`
  }
  statistics = statistics as StatisticsCount | StatisticsMean
  const total = statistics.result.find((stat) => stat.partition === undefined)
  let value = total?.value ?? ''
  if (statistics.args.partition_variables === undefined) {
    if (total !== undefined && 'percent' in total) {
      value += ` (${round(Number(total.percent) * 100, 2)}%)`
    }
    return value
  }
  const partitionStats = statistics.result.filter(
    (stat) => stat.partition !== undefined,
  )

  const stats: Array<string | number> = []
  parts.forEach((part) => {
    const index = partitionStats
      .map((p) => p.partition?.[0])
      .findIndex((p) => p === part)
    if (index === -1) {
      stats.push(type === 'count' ? '0 0%' : '-')
      return
    }
    const stat = partitionStats[index]
    const val
      = 'percent' in stat
        ? `${stat.value} ${total?.value === undefined ? '(' : ''}${round(
            Number(stat.percent) * 100,
            2,
          )}%${total?.value === undefined ? ')' : ''}`
        : stat.value
    stats.push(val)
  })
  value = `${value} ${total?.value !== undefined ? '(' : ''}${stats.join(
    ', ',
  )}${total?.value !== undefined ? ')' : ''}`

  return value
}

function computeStatName(
  operationName: string,
  statistics: Array<StatisticsCount | StatisticsMean>,
  varDicts: VarDict,
): { name: string; parts: Array<string | number | null> } {
  const dict = varDicts[statistics[0].args.partition_variables?.[0] ?? '']?.list
  let name = `${operationName}`
  const allPartitions = uniq(
    flatten(
      statistics.map((stat) =>
        flatten(
          stat.result
            .filter((r) => r.partition !== undefined)
            .map((r) => r.partition),
        ),
      ),
    ),
  )
  const parts = sortBy(
    Array.from(allPartitions) as Array<string | number | null>,
  )
  if (parts.length > 0) {
    name += ` (${parts
      .map((item) => {
        const part = String(item)
        return dict?.[part] ?? part ?? ''
      })
      .join(', ')})`
  }
  return { name, parts }
}

interface TopCountsTableProps {
  data: StatisticsTopCounts[]
  operationName: string
  varDicts: VarDict
  varName: string
  collapsed?: boolean
}

const TopCountsTable = ({
  data,
  operationName,
  varDicts,
  varName,
  collapsed = false,
}: TopCountsTableProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(!collapsed)

  const results = data.map((r) => r.result)

  const topCountsKeys = uniq(
    flatten(data.map((r) => r.result.map((rr) => rr.key))),
  ).filter((key) => key !== null)
  const hideKey = data.some((r) => r.args.hide_key === true)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: open ? 'none' : '1px solid black',
        }}
      >
        <h3>{varDicts.datasetDict?.list[operationName] ?? operationName}</h3>
        <ArrowButton
          onClick={() => {
            setOpen(!open)
          }}
          isOpen={!collapsed}
        />
      </div>
      <TableContainer component={Paper}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size="small">
            <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
              <TableRow>
                {!hideKey && (
                  <TableCell
                    style={{
                      backgroundColor: 'darkgrey',
                      width: '1%',
                    }}
                  >
                    <b>{varDicts.datasetDict?.list.code ?? 'Code'}</b>
                  </TableCell>
                )}
                <TableCell
                  style={{
                    backgroundColor: 'darkgrey',
                    width: '3%',
                  }}
                >
                  <b>
                    {t('createExperimentDialog.description') ?? 'Description'}
                  </b>
                </TableCell>
                {results.map((_cluster, clusterId) => (
                  <TableCell
                    key={`Cluster=${clusterId}`}
                    style={{
                      width: `${round(95 / (data.length + 1), 1)}%`,
                    }}
                  >
                    <b>
                      {varDicts.datasetDict?.list.cluster ?? 'Cluster'}{' '}
                      {clusterId + 1}
                    </b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {topCountsKeys.map((key) => (
                <TableRow key={`TopCountIdRow=${key}`}>
                  {!hideKey && (
                    <TableCell key={`TopCountId=${key}`}>
                      <b>{key}</b>
                    </TableCell>
                  )}
                  <TableCell key={`TopCountDescr=${key}`}>
                    <b>{varDicts[varName]?.list[String(key)] ?? key ?? ''}</b>
                  </TableCell>
                  {results.map((res, index) => {
                    const result = res.find((r) => r.key === key)
                    let val = '-'
                    if (result !== undefined) {
                      val = String(result.value)
                      if ('percent' in result) {
                        val += ` (${round(Number(result.percent) * 100, 2)}%)`
                      }
                    }

                    return (
                      <TableCell key={`TopCountValue=${key}ClusterId=${index}`}>
                        {val}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableContainer>
    </>
  )
}

const ClusteringTable = ({
  appId,
  datasetDict,
  encodingDict,
  statistics,
}: ClusteringTableProps): React.JSX.Element => {
  const [dicts] = useDictionaries(encodingDict, appId)
  const varDicts = setupVarDict(encodingDict, dicts)
  if (datasetDict !== null && datasetDict !== undefined) {
    varDicts.datasetDict = { list: datasetDict.list }
  }

  const modifiedClusterStatistics: {
    [key: string]: StatisticsData & {
      order: number
      fn: string
      operation_name: string
    }
  } = {}
  statistics.forEach((clusterStatistics) => {
    clusterStatistics.result.forEach((statistic, order) => {
      if (modifiedClusterStatistics[statistic.operation_name] === undefined) {
        modifiedClusterStatistics[statistic.operation_name] = {
          result: [],
          order,
          fn: statistic.fn,
          operation_name: statistic.operation_name,
        }
      }
      modifiedClusterStatistics[statistic.operation_name].result.push({
        ...statistic,
      })
    })
  })
  const clusterStats = Object.values(modifiedClusterStatistics)

  const tableComponents = clusterStats
    .filter((stats) => stats.fn !== 'top_counts')
    .map((stat) => {
      const { name, parts } = computeStatName(
        varDicts.datasetDict?.list[stat.operation_name] ?? stat.operation_name,
        stat.result as Array<StatisticsCount | StatisticsMean>,
        varDicts,
      )

      return (
        <TableRow key={`Row=${stat.operation_name}`}>
          <TableCell
            key={`Name=${stat.operation_name}`}
            style={{
              backgroundColor: 'darkgray',
            }}
          >
            <b>{name}</b>
          </TableCell>
          {stat.result.map((clusterStat, clusterId) => (
            // <Tooltip
            //   title="Hola :)"
            //   placement="top"
            //   arrow
            //   key={`Cluster=${clusterId}`}
            // >
            <TableCell key={`Cluster=${clusterId}`}>
              <>
                {clusterStat.fn !== 'top_counts'
                  && clusterStat.fn !== 'min_max_value'
                  && computeValue(clusterStat, parts, clusterStat.fn)}
              </>
            </TableCell>
            // </Tooltip>
          ))}
        </TableRow>
      )
    })

  const topCountsComponents = clusterStats
    .filter((stats) => stats.fn === 'top_counts')
    .map((stats, index) => (
      <TopCountsTable
        data={stats.result as StatisticsTopCounts[]}
        key={stats.operation_name}
        operationName={stats.operation_name}
        varDicts={varDicts}
        varName={(stats.result as StatisticsTopCounts[])[0].args.by}
        collapsed={index > 1}
      />
    ))

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: 'darkgray',
                  width: '5%',
                }}
              ></TableCell>
              {range(1, statistics.length + 1).map((clusterId) => (
                <TableCell
                  key={`Cluster=${clusterId}`}
                  style={{
                    width: `${round(95 / (statistics.length + 1), 1)}%`,
                  }}
                >
                  <b>
                    {datasetDict?.list.cluster ?? 'Cluster'} {clusterId}
                  </b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableComponents}</TableBody>
        </Table>
      </TableContainer>
      {topCountsComponents}
    </>
  )
}

export default ClusteringTable
