import { memoize } from 'lodash'
import Cookies from 'universal-cookie'
import axios from 'axios'
import type { AxiosInstance, AxiosError } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {
  LOGIN_URL,
  SOIL_URL,
  API_PATH,
  REFRESH_PATH,
  APPLICATION_ID,
  LOGOUT_PATH,
  FILTER_ROLE,
  API_PATH_SERVICES,
} from '../../constants/env'
import { clearStorage } from '../../store/lib/storage'

const RECENT_SESSION = 10 * 60 * 1000
const REFRESH_TIME = 15 * 60 * 1000
let autoRefreshInterval: NodeJS.Timeout | null = null

export function hasValidSession(): boolean {
  const cookie = new Cookies()
  const exp = cookie.get('app.at_exp')
  return exp !== undefined
    ? new Date(exp * 1000 - 60 * 1000) > new Date()
    : false
}

function recentSession(): boolean {
  // the session has been refreshed recently
  const cookie = new Cookies()
  const exp = cookie.get('app.at_exp') ?? new Date().getTime() / 1000
  return new Date().getTime() - exp * 1000 < RECENT_SESSION
}

export const getUserIdFromJwt = (): string | undefined => {
  const cookie = new Cookies()
  const jwt = cookie.get<string>('app.idt')
  if (!jwt) return undefined

  try {
    const payload = jwt.split('.')[1]
    const decodedPayload = atob(payload)
    const parsedPayload = JSON.parse(decodedPayload)
    return (parsedPayload.sub as string) || undefined
  } catch (error) {
    console.error('Error decoding JWT:', error)
    return undefined
  }
}

interface UserApplication {
  id: string
  name: string
  roles: string[]
  soil: string
}

export const getUserApplications = memoize(
  async (): Promise<UserApplication[]> => {
    const response = await axios.get(`${SOIL_URL}/applications/`, {
      withCredentials: true,
    })
    return response.data.userApplications
  },
)

export const getSoil = memoize(async (appId: string): Promise<string> => {
  const app = (await getUserApplications()).find((app) => app.id === appId)
  if (app === undefined) {
    throw new Error(`Application ${appId} not found`)
  }
  return app.soil
})

const refreshAuthLogic = async (
  failedRequest: AxiosError<string>,
): Promise<void> => {
  if (!recentSession()) {
    // avoid loops, hopefully
    await autoRefreshLogic()
    return
  }
  await Promise.reject(failedRequest)
}

async function createHttpInstance(
  applicationId: string,
): Promise<AxiosInstance> {
  if (applicationId === undefined) {
    throw new Error('Application id is undefined')
  }
  const headers: { ['Application-Id']?: string } = {
    'Application-Id': applicationId,
  }
  const soilUrl = await getSoil(applicationId)
  const axiosInstance = axios.create({
    baseURL: `${soilUrl}${API_PATH}`,
    headers,
    withCredentials: true,
  })
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    skipWhileRefreshing: false,
  })
  return axiosInstance
}

async function createHttpServicesInstance(
  applicationId: string,
): Promise<AxiosInstance> {
  if (applicationId === undefined) {
    throw new Error('Application id is undefined')
  }
  const headers: { ['Application-Id']?: string } = {
    'Application-Id': applicationId,
  }

  const soilUrl = await getSoil(applicationId)
  const axiosInstance = axios.create({
    baseURL: `${soilUrl}${API_PATH_SERVICES}`,
    headers,
    withCredentials: true,
  })
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    skipWhileRefreshing: false,
  })
  return axiosInstance
}

async function autoRefreshLogic(): Promise<void> {
  try {
    await axios.post(
      `${LOGIN_URL}${REFRESH_PATH}?client_id=${APPLICATION_ID}`,
      {},
      { withCredentials: true },
    )
    console.info('Refreshed token succesfully.')
  } catch (error) {
    console.info(
      'Failed refresh token with code',
      (error as AxiosError).response?.status,
      'logging out.',
    )
    if (autoRefreshInterval !== null) {
      clearInterval(autoRefreshInterval)
      autoRefreshInterval = null
    }
    clearStorage()
    location.href = `${LOGIN_URL}${LOGOUT_PATH}?client_id=${APPLICATION_ID}&post_logout_redirect_uri=${encodeURIComponent(
      `${location.protocol}//${location.host}/`,
    )}`
  }
}

export async function getAppIds(): Promise<string[]> {
  const userApplications = await getUserApplications()
  const apps: Array<{ id: string }> = userApplications.filter((app) =>
    app.roles.includes(FILTER_ROLE),
  )
  return apps.map((app) => app.id)
}

export const http = memoize(async (appId: string): Promise<AxiosInstance> => {
  if (autoRefreshInterval === null) {
    if (!recentSession()) {
      autoRefreshLogic()
        .then(() => {})
        .catch(() => {})
    }
    autoRefreshInterval = setInterval(() => {
      void autoRefreshLogic()
    }, REFRESH_TIME)
  }
  return await createHttpInstance(appId)
})

export const httpServices = memoize(
  async (appId: string): Promise<AxiosInstance> => {
    if (autoRefreshInterval === null) {
      if (!recentSession()) {
        autoRefreshLogic()
          .then(() => {})
          .catch(() => {})
      }
      autoRefreshInterval = setInterval(() => {
        void autoRefreshLogic()
      }, REFRESH_TIME)
    }
    return await createHttpServicesInstance(appId)
  },
)
