import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import { getStore } from '../../../../api/providers'
import ProjectRow from '../row/ProjectRow'

interface Props {
  projectId: string
}

const ProjectDetails = ({ projectId }: Props): React.JSX.Element => {
  const [selectedId, setSelectedId] = useState<string | undefined>(projectId)

  // loads initial data
  const state = useAsync(getStore)
  const store = state.value

  useEffect(() => {
    setSelectedId(projectId)
  }, [projectId])

  return (
    <>
      {store !== undefined && (
        <ProjectRow
          projectId={projectId}
          expanded={projectId === selectedId}
          onSelect={setSelectedId}
        />
      )}
    </>
  )
}

export default ProjectDetails
