import React from 'react'
import {
  APPLICATION_ID,
  LOGIN_URL,
  LOGIN_PATH,
} from '../../../../../constants/env'

const clientId = APPLICATION_ID
const redirectUri = encodeURIComponent(
  `${location.protocol}//${location.host}/`,
)
const LOGIN_URI = `${LOGIN_URL}${LOGIN_PATH}?client_id=${clientId}&redirect_uri=${redirectUri}&state=${Math.random()
  .toString()
  .slice(2)}`

const LoginPage = (): React.JSX.Element => {
  window.location.href = LOGIN_URI
  return (
    <div>
      Automatically redirecting to <a href={LOGIN_URI}>{LOGIN_URI}</a>
    </div>
  )
}

export default LoginPage
