import { combineReducers } from 'redux'
import menuReducers from './namespaces/menu/reducers'
import requestReducers from './namespaces/request/reducers'
import layoutReducers from './namespaces/layout/reducers'
import downloadReducers from './namespaces/downloads/reducers'

export default combineReducers({
  request: requestReducers,
  menu: menuReducers,
  layout: layoutReducers,
  download: downloadReducers,
})
