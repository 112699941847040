import _ from 'lodash'
import type { CallEffect, PutEffect } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import {
  createFilter as createFilterRequest,
  getRootFolder,
  getDataset,
} from '../../../api/providers'
import type { Project, Dataset } from '../../../api/providers'
import { STATISTICS_PIPELINE_ID } from '../../../constants/api'
import { requestStart, requestStop, requestFail } from '../request/actions'
import { getMenuItems, selectMenuItem } from '../menu/actions'
import type { FilterCreateAction } from './types'
import { createExperiment } from '../experiment/actions'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'
import type { MenuItemsGetAction, MenuSelectItemAction } from '../menu/types'

export function* createStatisticsFromFilter(
  folderId: string,
  folderName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const rootFolder: Project = yield call(getRootFolder, folderId)
  const dataset: Dataset = yield call(getDataset, rootFolder.datasetId)
  const statistics = dataset.metadata.pipelines.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (f: any) => f.name === STATISTICS_PIPELINE_ID,
  )
  if (statistics === undefined) {
    console.error('Basic Statistics pipeline not found in this dataset.')
    return
  }
  const values = _.zipObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.module),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.default),
  ) as unknown as { [moduleId: string]: { [fieldName: string]: unknown } }
  yield put(
    createExperiment({
      name: '__statistics__' + folderName,
      folderId,
      pipelineId: STATISTICS_PIPELINE_ID,
      values,
    }),
  )
}

export function* createFilter(
  action: FilterCreateAction,
): Generator<
  | PutEffect<RequestStartAction>
  | PutEffect<MenuItemsGetAction>
  | PutEffect<MenuSelectItemAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>
  | CallEffect<string>,
  void,
  string
> {
  try {
    yield put(requestStart())
    const id: string = yield call(createFilterRequest, action.payload)
    yield put(getMenuItems())
    yield put(selectMenuItem(action.payload.folderId, id))
    yield createStatisticsFromFilter(id, action.payload.name)
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
