import React from 'react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import type { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import type { Extend } from '../../../lib/types'

type Props = Extend<
  DialogProps,
  {
    title: ReactNode
    message: ReactNode
    acceptLabel?: ReactNode
    onAccept: () => void
  }
>

const AlertDialog = ({
  open,
  title,
  message,
  acceptLabel,
  onAccept,
  ...rest
}: Props): React.JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onAccept}>
          {acceptLabel ?? t('button.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
