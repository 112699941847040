import {
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { REQUEST_START, REQUEST_STOP } from './namespaces/request/types'
import reducers from './reducers'
import sagas from './sagas'

// list of actions that we don't want to appear in the Redux DevTools extension
const actionsDenylist = [REQUEST_START, REQUEST_STOP]

// Create the serializable state invariant middleware
const serializableMiddleware = createSerializableStateInvariantMiddleware({
  // Ignore these action types
  ignoredActions: actionsDenylist,
  // Ignore these field paths in the state
  ignoredPaths: [],
})

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      // Disable the built-in serializability check in favor of our custom one
      serializableCheck: false,
    }).concat(sagaMiddleware, serializableMiddleware),
  devTools: { actionsDenylist },
})

sagaMiddleware.run(sagas)

export default store
