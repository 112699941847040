export const PROJECT_CREATE = 'PROJECT_CREATE'

export interface ProjectCreateAction {
  type: typeof PROJECT_CREATE
  payload: {
    name: string
    datasetId: string
  }
}

export type ProjectAction = ProjectCreateAction
