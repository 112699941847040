import type { AxiosError } from 'axios'
export const REQUEST_START = 'REQUEST_START'
export const REQUEST_STOP = 'REQUEST_STOP'
export const REQUEST_FAIL = 'REQUEST_FAIL'
export const REQUEST_DISMISS_ERRORS = 'REQUEST_DISMISS_ERRORS'

export interface RequestStartAction {
  type: typeof REQUEST_START
}

export interface RequestStopAction {
  type: typeof REQUEST_STOP
}

export interface RequestFailAction {
  type: typeof REQUEST_FAIL
  error: AxiosError
}

export interface RequestDismissErrors {
  type: typeof REQUEST_DISMISS_ERRORS
}

export type RequestAction =
  | RequestStartAction
  | RequestStopAction
  | RequestFailAction
  | RequestDismissErrors

export interface RequestState {
  count: number
  errors: AxiosError[]
}
