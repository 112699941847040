import { AUTH_LOGOUT, AUTH_LOGOUT_SUCCESS } from './types'
import type { AuthLogoutAction, AuthLogoutSuccessAction } from './types'

export const logout = (): AuthLogoutAction => ({
  type: AUTH_LOGOUT,
})

export const logoutSuccess = (): AuthLogoutSuccessAction => ({
  type: AUTH_LOGOUT_SUCCESS,
})
