import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Grid from '@mui/material/Grid'
import DateListInput from '../../../form/inputs/DateListInput'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import SelectField from '../../../form/fields/SelectField'

type Value =
  | { eql: string }
  | { in: string[] }
  | { gte: string; lte: string }
  | undefined

const decode = (value: Value): { operation: string; items: string[] } => {
  const keys = _.keys(value)

  if (_.includes(keys, 'in')) {
    return {
      operation: 'in',
      items: _.get(value, 'in', []),
    }
  } else if (_.includes(keys, 'gte') && _.includes(keys, 'lte')) {
    return {
      operation: 'interval',
      items: [_.get(value, 'gte', ''), _.get(value, 'lte', '')],
    }
  }

  return {
    operation: 'eql',
    items: [_.get(value, 'eql', '')],
  }
}

const encode = (operation: string, items: string[]): Value => {
  if (operation === 'in') {
    return { in: items }
  } else if (operation === 'interval') {
    return {
      gte: items[0],
      lte: items[1],
    }
  }

  return { eql: items[0] }
}

const parseOperations = (ops: string[]): string[] => {
  const res = [...ops]

  if (res.includes('lte') && res.includes('gte')) {
    res.push('interval')
  }
  return res.length > 0
    ? res.filter((r) => !['lte', 'gte'].includes(r))
    : ['eql']
}

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginBottom: theme.spacing(0.5),
  },
}))

type Props = Extend<
  FieldProps<Value>,
  {
    format: string
    operations: string[]
  }
>

const LegacyDateField = ({
  required,
  label,
  value: initValue,
  onChange,
  format,
  operations = ['lte', 'gte'],
  datasetDictionary,
  appId,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const value = decode(initValue)
  const [originalValue] = useState(initValue)
  const { operation, items } = value
  const ops = parseOperations(operations)
  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.(undefined)
  const toLabel = t('fields.date.to')
  return (
    <FieldWrapper
      required={required}
      label={datasetDictionary?.list[label ?? ''] ?? label}
      onReset={onReset}
      onClear={onClear}
    >
      <Grid container spacing={1} alignItems="flex-end">
        {ops.length > 1 && (
          <Grid item xs={3}>
            <SelectField
              appId={appId}
              fullWidth
              value={operation}
              options={ops.map((item) => ({
                value: item,
                label: t(`fields.string.${item}`),
              }))}
              onChange={(v) => onChange?.(encode(v, items))}
            />
          </Grid>
        )}
        <Grid item xs>
          {(() => {
            if (operation === 'in') {
              return (
                <DateListInput
                  addLabel={t('fields.date.addLabel')}
                  format={format}
                  value={items.filter((item) => !_.isEmpty(item))}
                  onChange={(v) => {
                    onChange?.({ in: v })
                  }}
                />
              )
            } else if (operation === 'interval') {
              const gte = items[0]
              const lte = items[1]
              const fromDate = t('fields.date.from')
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DatePicker
                      className={classes.datePicker}
                      format="DD/MM/YYYY"
                      label={fromDate}
                      slotProps={{
                        textField: { variant: 'standard', fullWidth: true },
                      }}
                      value={gte.length > 0 ? moment(gte, format) : null}
                      onChange={(v) => {
                        if (v !== null) {
                          onChange?.({
                            gte: typeof v === 'string' ? v : v.format(format),
                            lte,
                          })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      className={classes.datePicker}
                      format="DD/MM/YYYY"
                      label={toLabel}
                      value={lte.length > 0 ? moment(lte, format) : null}
                      slotProps={{
                        textField: { variant: 'standard', fullWidth: true },
                      }}
                      onChange={(v) => {
                        if (v !== null) {
                          onChange?.({
                            gte,
                            lte: typeof v === 'string' ? v : v.format(format),
                          })
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }

            return (
              <DatePicker
                className={classes.datePicker}
                format="L"
                value={
                  items[0] !== undefined && items[0].length > 0
                    ? moment(items[0], format)
                    : null
                }
                onChange={(v) => {
                  if (v !== null) {
                    onChange?.({ eql: v.format(format) })
                  }
                }}
              />
            )
          })()}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default LegacyDateField
