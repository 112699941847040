import React from 'react'
import moment from 'moment'
import { map, keys, values as lvalues } from 'lodash'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { replaceParallel } from '../../../lib/util'

interface Props {
  values: Array<{
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  }>
  dictionary?: {
    list: {
      [key: string]: string
    }
  }
}

interface Dictionary {
  list: {
    [key: string]: string
  }
}

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(0.75),
  },
  summary: {
    overflow: 'hidden',
  },
  label: {
    color: theme.palette.text.secondary,
  },
  subdiv: {
    paddingLeft: 15,
  },
  joiner: {
    // color: theme.palette.primary.main
  },
}))

const FromatCondition = ({
  type,
  values,
}: {
  type: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any[]
}): React.JSX.Element => {
  const { t } = useTranslation()

  try {
    if (type[0] === 'eql') {
      return <>{String(values)}</>
    }
    if (type[0] === 'regexp') {
      const v = replaceParallel(
        ['\\.', '.'],
        ['.', '?'],
        values[0].replace(/\.\*/g, '*'),
      )
      return <>{v}</>
    }
    if (type[0] === 'gte' || type[0] === 'lte') {
      let lte = values[0]
      let gte = values[1]
      if (type[0] === 'gte') {
        gte = values[0]
        lte = values[1]
      }
      const asDateG = moment(gte, 'YYYYMMDD', true)
      const asDateL = moment(lte, 'YYYYMMDD', true)
      return (
        <>
          {t('experimentDetails.between', {
            max: asDateG.isValid() ? asDateG.format('DD/MM/YYYY') : lte,
            min: asDateL.isValid() ? asDateL.format('DD/MM/YYYY') : gte,
          })}
        </>
      )
    }
  } catch (_e) {
    console.error(
      'Error parsing' + JSON.stringify(type) + JSON.stringify(values),
    )
  }

  return <>{JSON.stringify(values.length === 1 ? values[0] : values)}</>
}

const FormatSubpopulationVariable = ({
  values,
  joiner,
  dictionary,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  joiner: string
  dictionary: Dictionary
}): React.JSX.Element => {
  const classes = useStyles()

  const joinerE = (
    <div className={`${classes.subdiv} ${classes.joiner}`}>{joiner}</div>
  )
  const res = map(values, (val, k) => (
    <div key={k} className={classes.subdiv}>
      {dictionary.list[k] ?? k}:{' '}
      <FromattedSubpopulation
        type={keys(val)}
        values={lvalues(val)}
        dictionary={dictionary}
      />
    </div>
  )).reduce(
    (acc: React.ReactNode | null, x: React.ReactNode) =>
      acc === null ? (
        x
      ) : (
        <>
          {acc}
          {joinerE}
          {x}
        </>
      ),
    null,
  )
  return <>{res}</>
}

const FromattedSubpopulation = ({
  type,
  values,
  dictionary,
}: {
  type: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any[]
  dictionary: Dictionary
}): React.JSX.Element => {
  const classes = useStyles()
  let joiner: string | null = null
  let innerQ = false
  if (type[0] === 'all') {
    joiner = 'AND'
  } else if (type[0] === 'any') {
    joiner = 'OR'
  } else if (type[0] === 'any_query') {
    joiner = 'OR'
    innerQ = true
  } else if (type[0] === 'all_query') {
    joiner = 'AND'
    innerQ = true
  } else if (type[0] === 'not_query') {
    return (
      <div className={classes.subdiv}>
        NOT
        <FromattedSubpopulation
          type={keys(values[0])}
          values={lvalues(values[0])}
          dictionary={dictionary}
        />
      </div>
    )
  }
  if (joiner === null) {
    return <FromatCondition type={type} values={values} />
  }
  const joinerE = (
    <div className={`${classes.subdiv} ${classes.joiner}`}>{joiner}</div>
  )
  const res = values[0]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((value: any, i: number) => (
      <div key={i} className={classes.subdiv}>
        {innerQ ? (
          <FromattedSubpopulation
            type={keys(value)}
            values={lvalues(value)}
            dictionary={dictionary}
          />
        ) : (
          <FormatSubpopulationVariable
            values={value}
            joiner={joiner}
            dictionary={dictionary}
          />
        )}
      </div>
    ))
    .reduce(
      (acc: React.ReactNode | null, x: React.ReactNode) =>
        acc === null ? (
          x
        ) : (
          <>
            {acc}
            {joinerE}
            {x}
          </>
        ),
      null,
    )
  return <>{res}</>
}

const SubpopulationGrid = ({
  values,
  dictionary,
}: Props): React.JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid className={classes.row} container spacing={1}>
        <Grid item className={classes.label}>
          <Typography variant="body2">
            {dictionary !== undefined
              ? dictionary.list[values[0].label]
              : values[0].label}
            :
          </Typography>
        </Grid>
        <Grid item xs>
          {values[0].value}
        </Grid>
      </Grid>
      {values.length > 1 && (
        <FromattedSubpopulation
          type={[values[1].label]}
          values={[JSON.parse(values[1].value)]}
          dictionary={dictionary ?? { list: {} }}
        />
      )}
    </Grid>
  )
}

export default SubpopulationGrid
