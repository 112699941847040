import _, { omit } from 'lodash'
import type { Dataset } from './dataset'

export interface Module {
  id: string
  metadata: {
    args: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [name: string]: any
    }
  }
}

export interface Field {
  name: string
  type: string
  required: boolean
  operations: string[]
  [key: string]: unknown
}

export const getModule = (ds: Dataset, moduleId: string): Module => {
  const modules = getModules(ds)
  const module = modules.find((x) => x.id === moduleId)

  if (module === undefined) {
    throw new Error('Module not found')
  }

  return module
}

export const getModules = (ds: Dataset): Module[] => {
  const { available_modules: availModules } = ds.metadata

  return _.map(availModules, ({ args, ...rest }, id) => ({
    id,
    metadata: {
      args,
      ...omit(rest, ['inputs', 'outputs']),
    },
  }))
}

export const getModuleFields = (
  ds: Dataset,
  moduleId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pipeOverride?: any,
): Field[] => {
  let args
  try {
    args = getModule(ds, moduleId).metadata.args
  } catch (_exception) {
    args = pipeOverride
  }

  const result = _.sortBy(
    _.map(args, ({ type, required, operations, order, ...rest }, name) => ({
      name,
      type: type ?? 'unknown',
      required: required ?? false,
      operations: operations ?? [],
      order: order ?? 0,
      ...rest,
    })),
    'order',
  )
  return result
}
