import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  Typography,
  Button,
  Divider,
  Box,
  CircularProgress,
} from '@mui/material'
import { Link } from 'react-router-dom'

import { usePostHog, useActiveFeatureFlags } from 'posthog-js/react'
import { EarlyAccessFeature } from 'posthog-js'
import { APPLICATION_ID, BETA_FEATURES } from '@/constants/env'

// Custom hook for handling feature flags
function useFeatureFlags() {
  const posthogInstance = usePostHog()
  const activeFlags = useActiveFeatureFlags()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [featureFlags, setFeatureFlags] = useState<EarlyAccessFeature[]>([])

  const loadFeatureFlags = () => {
    try {
      setLoading(true)
      setError(null)
      posthogInstance.getEarlyAccessFeatures(
        (features) => {
          setFeatureFlags(features.reverse())
          setLoading(false)
        },
        true,
        ['beta'],
      )
    } catch (err) {
      console.error('Error loading feature flags:', err)
      setError('Failed to load feature flags')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (BETA_FEATURES) {
      loadFeatureFlags()
    }
  }, [])

  // Toggle feature flag in PostHog
  const setFeatureFlag = (key: string | null, value: boolean) => {
    try {
      if (key) {
        posthogInstance.updateEarlyAccessFeatureEnrollment(key, value)
      }
    } catch (err) {
      console.error(`Error toggling feature flag ${key}:`, err)
      setError(`Failed to toggle ${key}`)
    }
  }

  return {
    loading,
    error,
    featureFlags: featureFlags.map((feature) => ({
      ...feature,
      enabled: activeFlags.includes(feature.flagKey || ''),
    })),
    setFeatureFlag,
  }
}

interface EnableFeatureFlagsProps {
  open: boolean
  onClose: () => void
}

const ManageAccountContent = (): React.JSX.Element => {
  return (
    <>
      <DialogTitle>User Settings</DialogTitle>
      <DialogContent>
        <Button
          color="primary"
          component={Link}
          to={`https://auth.amalfianalytics.com/account/?client_id=${APPLICATION_ID}`}
          target="_blank"
          variant="outlined"
        >
          Click here to manage your account settings
        </Button>
      </DialogContent>
      <Divider />
    </>
  )
}

const ManageFeatureFlagsContent = (): React.JSX.Element => {
  const { loading, error, featureFlags, setFeatureFlag } = useFeatureFlags()
  return (
    <>
      <DialogTitle>
        Early Access Features
        <Typography variant="body2" color="text.secondary">
          Enable or disable experimental features
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body2" color="text.secondary">
            {error}
          </Typography>
        ) : (
          <>
            {featureFlags.map((feature) => (
              <div key={feature.flagKey} className="mb-2">
                <FormControlLabel
                  key={feature.flagKey}
                  control={
                    <Switch
                      checked={feature.enabled}
                      onChange={(e) =>
                        setFeatureFlag(feature.flagKey, e.target.checked)
                      }
                    />
                  }
                  label={
                    <>
                      {feature.name}&nbsp;
                      <Typography
                        variant="caption"
                        component="span"
                        color="text.secondary"
                      >
                        {feature.description}
                      </Typography>
                    </>
                  }
                />
                <div />
              </div>
            ))}
          </>
        )}
      </DialogContent>
      <Divider />
    </>
  )
}

export const EnableFeatureFlags: React.FC<EnableFeatureFlagsProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <ManageAccountContent />
      {BETA_FEATURES && <ManageFeatureFlagsContent />}
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnableFeatureFlags
