import React, { useState } from 'react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Popover from '@mui/material/Popover'
import type { PopoverProps } from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
}))

interface Props {
  format: string
  value: string[]
  addLabel: string
  onChange?: (value: string[]) => void
}

const DeleteListInput = ({
  format,
  value,
  addLabel,
  onChange,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [addAnchorEl, setAddAnchorEl] = useState<HTMLElement | null>(null)
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLElement | null>(null)

  const onAddItem = (v: moment.Moment | null): void => {
    if (v !== null && onChange !== undefined) {
      onChange([...value, v.format(format)])
      setAddAnchorEl(null)
    }
  }

  const onEditItem = (v: moment.Moment | null): void => {
    if (v !== null && onChange !== undefined) {
      onChange(
        value.map((item, i) => (i === selectedIndex ? v.format(format) : item)),
      )
      setEditAnchorEl(null)
    }
  }

  const onDeleteItem = (i: number) => (): void => {
    onChange?.([...value.filter((_, j) => i !== j)])
  }

  const DatePopover = ({
    children,
    ...rest
  }: PopoverProps): React.JSX.Element => (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...rest}
    >
      {children}
    </Popover>
  )

  return (
    <>
      <Grid className={classes.root} container spacing={1}>
        {value.map((item, i) => (
          <Grid key={i} item>
            <Chip
              title={moment(item, format).format('LL')}
              size="small"
              label={moment(item, format).format('L')}
              onClick={(e) => {
                setSelectedIndex(i)
                setEditAnchorEl(e.currentTarget)
              }}
              onDelete={onDeleteItem(i)}
            />
          </Grid>
        ))}
        <Grid key={value.length} item>
          <Chip
            size="small"
            color="secondary"
            label={addLabel}
            onClick={(e) => {
              setAddAnchorEl(e.currentTarget)
            }}
          />
        </Grid>
      </Grid>
      <DatePopover
        open={editAnchorEl !== null}
        anchorEl={editAnchorEl}
        onClose={() => {
          setEditAnchorEl(null)
        }}
      >
        <DatePicker
          value={moment(value[selectedIndex], format)}
          onChange={onEditItem}
        />
      </DatePopover>
      <Popover
        open={addAnchorEl !== null}
        anchorEl={addAnchorEl}
        onClose={() => {
          setAddAnchorEl(null)
        }}
      >
        <DatePicker value={null} onChange={onAddItem} />
      </Popover>
    </>
  )
}

export default DeleteListInput
