import React, { useState } from 'react'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import Catch from './functional-error-boundary'

const ErrorComponent = ({
  error,
  experimentId,
  folderId,
}: {
  error: Error
  experimentId?: string
  folderId?: string
}): React.JSX.Element => {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  return (
    <div className="error-screen">
      <h2>{t('errorBoundary.anErrorHasOcurred')}</h2>
      <Link
        component="button"
        onClick={() => {
          setShowDetails(!showDetails)
        }}
      >
        {showDetails
          ? t('errorBoundary.hideDetails')
          : t('errorBoundary.showDetails')}
      </Link>
      {showDetails && (
        <p
          style={{
            width: '95%',
            borderWidth: 1,
            borderStyle: 'solid',
            padding: 5,
            margin: 5,
          }}
        >
          Id: {`${experimentId}-${folderId}`}
          <br />
          {error.stack}
        </p>
      )}

      <h4>
        {t('errorBoundary.sendEmailTo')} &nbsp;
        <a
          href={`mailto:${t('errorBoundary.supportEmail')}?subject=${t(
            'errorBoundary.anisError',
          )}&body=${t('errorBoundary.addDetails')} Id: ${`${experimentId}-${folderId}`}%0D%0A%0D%0A${error.stack ?? ''}`}
        >
          {t('errorBoundary.supportEmail')}
        </a>{' '}
        &nbsp;
        {t('errorBoundary.reproduceSteps')}
      </h4>
    </div>
  )
}

const ErrorBoundary = Catch(function ErrorBoundary(
  {
    children,
    experimentId,
    folderId,
  }: { children: React.ReactNode; experimentId?: string; folderId?: string },
  error?: Error,
) {
  if (error !== undefined) {
    return (
      <ErrorComponent
        error={error}
        experimentId={experimentId}
        folderId={folderId}
      />
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
})

export default ErrorBoundary
