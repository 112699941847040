import { APPLICATION_ID } from '@/constants/env'
import { getAppIds, http, httpServices } from '../../lib/http'
import { uniqId } from '../../lib/utils'
import { memoize, find } from 'lodash'

interface ElasticParams {
  type: 'soil.storage.elasticsearch.Elasticsearch'
  params: { index: string }
}

interface LegacyIndexes {
  indexed: ElasticParams
}
interface PatientsIndex {
  patients_index: ElasticParams
}
interface CompoundParams {
  type: 'soil.storage.compound_storage.CompoundStorage'
  params: {
    storages: LegacyIndexes | PatientsIndex
  }
}

export interface Pipeline {
  type: string
  name: string
  pipe: Array<{
    module: string
    inputs: string[]
    outputs: string[]
    default?: {
      [fieldName: string]: unknown
    }
    args?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }
  }>
}
export interface Dataset {
  id: string
  app_id: string
  name: string
  alias?: string
  type?: string
  data?: ElasticParams | CompoundParams
  metadata: {
    dictionary?: string
    downloadable?: boolean
    encoding_dict?: {
      [key: string]: [string]
    }
    // Available Modules is deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    available_modules?: any
    pipelines: Pipeline[]
  }
}

type BackendDataset = Omit<Dataset, 'id'> & { _id: string }

// Do not use this function. Use getDataset instead.
export const getDatasetInner = memoize(
  async (datasetId: string, appId: string): Promise<Dataset> => {
    console.error('getDatasetInner', datasetId, appId)

    const url = `/v2/results/${datasetId}/`
    const doc = await (await http(appId)).get<BackendDataset>(url)
    const { _id, ...dataset } = doc.data
    return { id: _id, ...dataset }
  },
)

const getDatasetWithAppId = async (
  datasetId: string,
  appId: string,
): Promise<Dataset | undefined> => {
  return await getDatasetAlias(datasetId, appId)
}

export const getDatasetByAlias = async (
  datasetAlias: string,
): Promise<Dataset> => {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const datasets = await getDatasets(appId)
    const dataset = find(datasets, (dataset) => dataset.alias === datasetAlias)
    if (dataset !== undefined) {
      return dataset
    }
  }
  throw new Error(`Dataset ${datasetAlias} not found.`)
}

export const getDataset = memoize(
  async (datasetId: string): Promise<Dataset> => {
    const appIds = await getAppIds()
    for (const appId of appIds) {
      const dataset = await getDatasetWithAppId(datasetId, appId)
      if (dataset !== undefined) {
        return dataset
      }
    }
    throw new Error(`Dataset ${datasetId} not found.`)
  },
)

const getDatasetAlias = async (
  datasetId: string,
  appId: string,
): Promise<Dataset | undefined> => {
  try {
    const mapping = await getDatasetsFromServices(appId)
    return find(mapping, (dataset) => dataset.id === datasetId)
  } catch (error) {
    console.error(
      `Error fetching dataset ${datasetId} from services ${appId}`,
      error,
    )
    throw new Error(
      `Error fetching dataset ${datasetId} from services ${appId}`,
    )
  }
}

const getDatasetsFromServices = memoize(
  async (appId: string): Promise<Dataset[]> => {
    if (appId === APPLICATION_ID) {
      return []
    }
    const doc = await (
      await httpServices(appId)
    ).get<{
      result: Dataset[]
      message?: string
      // the uniqid is used to bypass something that avoids parallel requests
    }>('datasets?id=' + uniqId(), { timeout: 5000 })
    return doc.data.result
  },
)

export const getDatasets = memoize(
  async (appId: string): Promise<Dataset[]> => {
    try {
      const datasets = await getDatasetsFromServices(appId)
      return datasets
    } catch (_error) {
      console.error('Could not get datasets from services for appId', appId)
      throw new Error(`Could not get datasets from services for appId ${appId}`)
    }
  },
)
