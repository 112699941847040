import { getStore } from './providers/store'
import {
  getDataset,
  getPipelinePipes as _getPipelinePipes,
  getPipelineFields as _getPipelineFields,
  getFilterPipelines as _getFilterPipelines,
  getExperimentPipelines as _getExperimentPipelines,
  getModuleFields as _getModule,
  getModules as _getModules,
  getModuleFields as _getModuleFields,
  getPipeline as _getPipeline,
  getPipelines as _getPipelines,
} from './providers/dataset'
import type { Pipeline, Field, Module } from './providers/dataset'
import type { Pipe } from './providers/dataset/pipeline'
import { getTreeItems as _getTreeItems } from './providers/tree'
import type { TreeItem } from './providers/tree'

import { deleteFolder } from './providers/folder'

export { getFilter, getParentFilters, createFilter } from './providers/filter'
export {
  getFolder,
  getParentFolders,
  getRootFolder,
  getFolderFilters,
  getFolderExperiments,
  updateFolder,
  deleteFolder,
} from './providers/folder'

export {
  getProjectStructure,
  createProject,
  getProject,
} from './providers/project'

// state
export type { State } from './providers/state'
export {
  createState,
  getState,
  updateState,
  getStates,
} from './providers/state'

// store
export type { Store } from './providers/store'
export {
  getStore,
  updateStore,
  createStore,
  innerGetStore,
} from './providers/store'

// dictionary
export { getDictionary } from './providers/dictionary'

// dataset
export type { Dataset, Field, Module, Pipeline } from './providers/dataset'
export { getDataset, getDatasets } from './providers/dataset'
export const getModule = async (
  datasetId: string,
  moduleId: string,
): Promise<Field[]> => _getModule(await getDataset(datasetId), moduleId)
export const getModules = async (datasetId: string): Promise<Module[]> =>
  _getModules(await getDataset(datasetId))
export const getModuleFields = async (
  datasetId: string,
  moduleId: string,
): Promise<Field[]> => _getModuleFields(await getDataset(datasetId), moduleId)
export const getPipeline = async (
  datasetId: string,
  pipelineId: string,
): Promise<Pipeline> => _getPipeline(await getDataset(datasetId), pipelineId)
export const getPipelines = async (datasetId: string): Promise<Pipeline[]> =>
  _getPipelines(await getDataset(datasetId))
export const getPipelinePipes = async (
  datasetId: string,
  pipelineId: string,
): Promise<Pipe[]> => _getPipelinePipes(await getDataset(datasetId), pipelineId)
export const getPipelineFields = async (
  datasetId: string,
  pipelineId: string,
): Promise<{ [moduleId: string]: Field[] }> =>
  _getPipelineFields(await getDataset(datasetId), pipelineId)
export const getFilterPipelines = async (
  datasetId: string,
): Promise<Pipeline[]> => _getFilterPipelines(await getDataset(datasetId))
export const getExperimentPipelines = async (
  datasetId: string,
): Promise<Pipeline[]> => _getExperimentPipelines(await getDataset(datasetId))

// tree
export type { TreeItem, ExperimentItem } from './providers/tree'
export const getTreeItems = async (appId: string): Promise<TreeItem[]> =>
  _getTreeItems((await getStore(appId)).store)

// folder
export type { Folder } from './providers/folder'

// project
export type { Project, FolderStructure } from './providers/project'

export const deleteProject = deleteFolder

// filter
export type { Filter } from './providers/filter'
export const deleteFilter = deleteFolder

// experiment
export type { Experiment } from './providers/experiment'
export {
  STATUS_WAITING,
  STATUS_EXECUTING,
  STATUS_ERROR,
  STATUS_DONE,
  type STATUS,
  getExperimentData,
  getExperimentStatus,
  getExperimentLogs,
  getExperiment,
  createExperiment,
  updateExperiment,
  deleteExperiment,
  createExperimentFromPlan,
  getRawExperiment,
} from './providers/experiment'

// user variable
export { createUserVariable, getUserVariables } from './providers/userVariable'
