import _ from 'lodash'

export const LAYOUT_OPEN_EXPERIMENT = 'LAYOUT_OPEN_EXPERIMENT'
export const LAYOUT_OPEN_EXPERIMENT_SUCCESS = 'LAYOUT_OPEN_EXPERIMENT_SUCCESS'
export const LAYOUT_CLOSE_EXPERIMENT = 'LAYOUT_CLOSE_EXPERIMENT'
export const LAYOUT_CLOSE_EXPERIMENT_SUCCESS = 'LAYOUT_CLOSE_EXPERIMENT_SUCCESS'
export const LAYOUT_UPDATE_ITEM = 'LAYOUT_UPDATE_ITEM'
export const LAYOUT_UPDATE_ITEM_SUCCESS = 'LAYOUT_UPDATE_ITEM_SUCCESS'
export const LAYOUT_SAVE_TREE = 'LAYOUT_SAVE_TREE'

export interface LayoutItem {
  experimentId: string
  folderId: string
  name: string
}

interface Node {
  type: string
  id?: string
  name?: string
  selected?: number
  children?: Node[]
  config?: {
    experimentId: string
  }
}

export class LayoutNode implements Node {
  type: string
  id?: string
  name?: string
  selected?: number
  children?: LayoutNode[]
  config?: {
    experimentId: string
    folderId: string
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({ type, name, selected, children, config }: any) {
    this.type = type
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    name !== undefined && (this.name = name)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selected !== undefined && (this.selected = selected)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    children !== undefined && (this.children = children)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    config !== undefined && (this.config = config)
  }

  getItems(): LayoutNode[] {
    const children = this.getChildren()
    const items = _.flatten(children.map((item) => item.getItems()))

    return children.concat(items)
  }

  getChildren(): LayoutNode[] {
    return this.children ?? []
  }

  addItem(item: LayoutNode): void {
    this.children = [...this.getChildren(), item]
  }

  findOrCreate(type: string): LayoutNode {
    let ret = this.getItems().find((item) => item.type === type)

    if (ret === undefined) {
      ret = new LayoutNode({ type })
      this.addItem(ret)
    }

    return ret
  }
}

export class LayoutTree {
  layout: LayoutNode

  constructor(layout: LayoutNode) {
    this.layout = layout
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(tree: any): LayoutTree {
    const parse = (node: Node): LayoutNode => {
      const { children } = node

      return new LayoutNode({
        ...node,
        ...(children !== undefined
          ? {
              children: children.map(parse),
            }
          : {}),
      })
    }

    return new LayoutTree(parse(tree.layout))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJson(): any {
    // Convert the layout node to a plain serializable object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const serializeNode = (node: LayoutNode): any => {
      const { type, id, name, selected, children, config } = node
      return {
        type,
        ...(id !== undefined ? { id } : {}),
        ...(name !== undefined ? { name } : {}),
        ...(selected !== undefined ? { selected } : {}),
        ...(children !== undefined
          ? {
              children: children.map(serializeNode),
            }
          : {}),
        ...(config !== undefined ? { config } : {}),
      }
    }

    return {
      global: {},
      borders: [],
      layout: serializeNode(this.layout),
    }
  }

  getSelectedNode(): LayoutNode | undefined {
    const selectedNode = this.findSelectedNode(this.layout)
    if (selectedNode !== undefined) {
      selectedNode.name = selectedNode.name?.replace(/^\[ ?| ?\]$/g, '')
    }
    return selectedNode
  }

  private findSelectedNode(node: LayoutNode): LayoutNode | undefined {
    if (node.selected !== undefined) {
      return node.getChildren()[node.selected]
    }
    const children = node.getChildren()
    for (const child of children) {
      const selectedNode = this.findSelectedNode(child)
      if (selectedNode !== undefined) {
        return selectedNode
      }
    }
    if (node.name?.match(/^\[ .* \]$/) !== null) {
      return node
    }

    return undefined
  }
}

export interface LayoutOpenExperimentAction {
  type: typeof LAYOUT_OPEN_EXPERIMENT
  payload: {
    experimentId: string
    folderId: string
  }
}

export interface LayoutOpenExperimentSuccessAction {
  type: typeof LAYOUT_OPEN_EXPERIMENT_SUCCESS
}

export interface LayoutCloseExperimentAction {
  type: typeof LAYOUT_CLOSE_EXPERIMENT
  payload: {
    experimentId: string
    folderId: string
  }
}

export interface LayoutCloseExperimentSuccessAction {
  type: typeof LAYOUT_CLOSE_EXPERIMENT_SUCCESS
}

export interface LayoutUpdateItemAction {
  type: typeof LAYOUT_UPDATE_ITEM
  payload: LayoutItem
}

export interface LayoutUpdateItemSuccessAction {
  type: typeof LAYOUT_UPDATE_ITEM_SUCCESS
}

export interface LayoutSaveTree {
  type: typeof LAYOUT_SAVE_TREE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

export type LayoutAction =
  | LayoutOpenExperimentAction
  | LayoutOpenExperimentSuccessAction
  | LayoutCloseExperimentAction
  | LayoutCloseExperimentSuccessAction
  | LayoutUpdateItemAction
  | LayoutUpdateItemSuccessAction
  | LayoutSaveTree

export interface LayoutState {
  items: LayoutItem[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tree: any
}
