import { getRootFolder } from '@/api/providers'
import { getDataset } from '@/api/providers/dataset'

import { Dataset } from '@/api/providers/dataset'
import { Project } from '@/api/providers/project'
import { STATISTICS_PIPELINE_ID } from '@/constants/api'
import { createExperiment } from '@/store/namespaces/experiment/actions'
import { Dispatch } from 'redux'
import { zipObject } from 'lodash'

export async function createStatisticsFromProject(
  projectId: string,
  projectName: string,
  dispatch: Dispatch,
): Promise<void> {
  const rootFolder: Project = await getRootFolder(projectId)
  const dataset: Dataset = await getDataset(rootFolder.datasetId)
  const statistics = dataset.metadata.pipelines.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (f: any) => f.name === STATISTICS_PIPELINE_ID,
  )
  if (statistics === undefined) {
    console.error('Basic Statistics pipeline not found in this dataset.')
    return
  }
  const values = zipObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.module),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.default),
  ) as unknown as { [moduleId: string]: { [fieldName: string]: unknown } }

  dispatch(
    // @ts-expect-error
    createExperiment({
      name: '__statistics__' + projectName,
      folderId: rootFolder.id,
      pipelineId: STATISTICS_PIPELINE_ID,
      values,
    }),
  )
}
