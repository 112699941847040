import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import type { Theme } from '@mui/material'
import type { Dictionary } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }),
)

export interface CheckboxState {
  checkboxId: string
  state: boolean
}

interface CheckboxesGroupProps {
  checkboxesState: CheckboxState[]
  onChange: (checkedId: string) => void
  dictionary: Dictionary
  error: boolean
}

export default function CheckboxesGroup({
  checkboxesState,
  onChange,
  error,
  dictionary,
}: CheckboxesGroupProps): React.JSX.Element {
  const classes = useStyles()

  const { t } = useTranslation()
  return (
    <div>
      <FormControl
        required
        error={!!error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">{t('checkboxGroup.legend')}</FormLabel>
        <FormGroup>
          {checkboxesState.map((checkboxState) => (
            <FormControlLabel
              key={checkboxState.checkboxId}
              control={
                <Checkbox
                  checked={checkboxState.state}
                  onChange={() => {
                    onChange(checkboxState.checkboxId)
                  }}
                />
              }
              label={
                dictionary?.list[checkboxState.checkboxId]
                ?? checkboxState.checkboxId
              }
            />
          ))}
        </FormGroup>
        {error && <FormHelperText>{t('checkboxGroup.error')}</FormHelperText>}
      </FormControl>
    </div>
  )
}
