export const icd10 = [
  {
    color: '#FFFF00',
    description: 'Malalties contagioses i par\u00e0sites',
    id: '1',
    rangeFin: 'B99',
    rangeIni: 'A00',
  },
  {
    color: '#1CE6FF',
    description: 'Neopl\u00e0sies',
    id: '2',
    rangeFin: 'D49',
    rangeIni: 'C00',
  },
  {
    color: '#FF34FF',
    description:
      "Endocr\u00ed, malalties nutritives i metab\u00f2liques, i desordres d'immunitat",
    id: '3',
    rangeFin: 'D99',
    rangeIni: 'D50',
  },
  {
    color: '#800000',
    description: 'Malalties de la sang i \u00f2rgans que formen sang',
    id: '4',
    rangeFin: 'E99',
    rangeIni: 'E00',
  },
  {
    color: '#008941',
    description: 'Desordres mentals',
    id: '5',
    rangeFin: 'F99',
    rangeIni: 'F00',
  },
  {
    color: '#006FA6',
    description: 'Malalties del sistema nervi\u00f3s',
    id: '6',
    rangeFin: 'G99',
    rangeIni: 'G00',
  },
  {
    color: '#A30059',
    description: 'Malalties dels \u00f2rgans de sentit',
    id: '7',
    rangeFin: 'H99',
    rangeIni: 'H00',
  },
  {
    color: '#EA1A1C',
    description: 'Malalties del sistema circulatori',
    id: '8',
    rangeFin: 'I99',
    rangeIni: 'I00',
  },
  {
    color: '#7A4900',
    description: 'Malalties del sistema respiratori',
    id: '9',
    rangeFin: 'J99',
    rangeIni: 'J00',
  },
  {
    color: '#0000A6',
    description: 'Malalties del sistema digestiu',
    id: '10',
    rangeFin: 'K99',
    rangeIni: 'K00',
  },
  {
    color: '#63FFAC',
    description: 'Diseases of the skin and subcutaneous tissue',
    id: '11',
    rangeFin: 'L99',
    rangeIni: 'L00',
  },
  {
    color: '#B79762',
    description: 'Diseases of the musculoskeletal system and connective tissue',
    id: '12',
    rangeFin: 'M99',
    rangeIni: 'M00',
  },
  {
    color: '#004D43',
    description: 'Diseases of the genitourinary system',
    id: '13',
    rangeFin: 'N99',
    rangeIni: 'N00',
  },
  {
    color: '#8a2be2',
    description: 'Pregnancy, childbirth and the puerperium',
    id: '14',
    rangeFin: 'O99',
    rangeIni: 'O00',
  },
  {
    color: '#997D87',
    description: 'Certain conditions originating in the perinatal period',
    id: '15',
    rangeFin: 'P99',
    rangeIni: 'P00',
  },
  {
    color: '#5A0007',
    description:
      'Congenital malformations, deformations and chromosomal abnormalities',
    id: '16',
    rangeFin: 'Q99',
    rangeIni: 'Q00',
  },
  {
    color: '#f0b215',
    description:
      'Symptoms, signs and abnormal clinical and laboratory findings, not elsewhere classified',
    id: '17',
    rangeFin: 'R99',
    rangeIni: 'R00',
  },
  {
    color: '#FEFFE6',
    description:
      'Factors influencing health status and contact with health services',
    id: '18',
    rangeFin: 'Z99',
    rangeIni: 'Z00',
  },
]
