import React, { useEffect } from 'react'
import { hasValidSession, getUserIdFromJwt } from '../api/lib/http'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import HomePage from './components/app/pages/HomePage'
import LoginPage from './components/app/pages/LoginPage'
import type { Theme } from '@mui/material/styles'
import Cookies from 'universal-cookie'
import useMount from 'react-use/lib/useMount'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { POSTHOG_HOST, POSTHOG_API_KEY } from '../constants/env'
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 1,
    },
  },
})

const LoginRedirect = (): React.JSX.Element => {
  const location = useLocation()
  const page = location.state?.referrer ?? '/'
  return hasValidSession() === true ? (
    <Navigate to={page} replace />
  ) : (
    <LoginPage />
  )
}

const HomeRedirect = (): React.JSX.Element => {
  const location = useLocation()
  const referrer = location.pathname
  return hasValidSession() === true ? (
    <HomePage />
  ) : (
    <Navigate to={{ pathname: '/login' }} state={{ referrer }} replace />
  )
}

const setLocaleCookie = (): void => {
  const params = new URLSearchParams(window.location.search)
  const locale = params.get('locale')
  const cookie = new Cookies()
  const cookieLocale = cookie.get('app.locale')

  if (locale !== null && locale !== cookieLocale) {
    cookie.set('app.locale', locale)
  }
}

const UI = (): React.JSX.Element => {
  useMount(setLocaleCookie)
  const posthog = usePostHog()
  const userId = getUserIdFromJwt()
  useEffect(() => {
    if (userId !== undefined) {
      posthog.identify(userId)
    }
  }, [posthog, userId])
  return (
    <PostHogProvider
      apiKey={POSTHOG_API_KEY}
      options={{
        api_host: POSTHOG_HOST,
        disable_persistence: true,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginRedirect />} />
              <Route path="/*" element={<HomeRedirect />} />
            </Routes>
          </BrowserRouter>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            theme="light"
          />
        </LocalizationProvider>
      </QueryClientProvider>
    </PostHogProvider>
  )
}

export default UI
