export const NEW_FILTER_DOWNLOAD = 'NEW_FILTER_DOWNLOAD'
export const START_FILTER_DOWNLOAD = 'START_FILTER_DOWNLOAD'
export const END_FILTER_DOWNLOAD = 'END_FILTER_DOWNLOAD'

export interface StartFilterDownloadsAction {
  type: typeof START_FILTER_DOWNLOAD
  payload: {
    experimentId: string
    folderId: string
    downloadPerPatient: boolean
    format?: string
  }
}

export interface NewFilterDownloadsAction {
  type: typeof NEW_FILTER_DOWNLOAD
  payload: {
    experimentId: string
    newExperimentId: string
    resultId: string
  }
}

export interface EndFilterDownloadsAction {
  type: typeof END_FILTER_DOWNLOAD
  payload: {
    experimentId: string
  }
}

export type DownloadsAction =
  | NewFilterDownloadsAction
  | EndFilterDownloadsAction

export interface FilterDownload {
  experimentId: string
  newExperimentId: string
  resultId: string
}

export interface DownloadsState {
  currentFilterDownloads: FilterDownload[]
}
