import React from 'react'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../types'
import FieldWrapper from '../../FieldWrapper'
import MultiSelectInput from '../../inputs/MultiSelectInput'
import type { MultiSelectInputProps } from '../../inputs/MultiSelectInput'
import { omit } from 'lodash'
type Props = Extend<MultiSelectInputProps, FieldProps<string[]>>

const MultiSelectField = ({
  label,
  required,
  onChange,
  ...rest
}: Props): React.JSX.Element => (
  <FieldWrapper required={required} label={label}>
    <MultiSelectInput
      {...omit(rest, ['onError', 'error'])}
      onChange={(e) => {
        const { value } = e.target
        onChange?.(value.map((item) => item.toString()))
      }}
    />
  </FieldWrapper>
)

export default MultiSelectField
