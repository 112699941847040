import { marked } from 'marked'
import html2pdf from 'html2pdf.js'

export const downloadPdf = async (
  title: string,
  markdown: string,
): Promise<void> => {
  const html = marked(markdown)
  const element = document.createElement('div')
  element.innerHTML = `
    <div style="
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
      font-size: 0.875rem;
      line-height: 1.6;
      padding: 20px;
      max-width: 100%;
      margin: 0;
      color: #333;
      word-break: break-word;
      overflow-wrap: break-word;
    ">
      <style>
        h1, h2, h3, p, ul, li, blockquote, pre, table, img {
          page-break-inside: avoid;
        }
        h1 { 
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #333;
        }
        h2 { 
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #333;
        }
        h3 { 
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #333;
        }
        p { 
          margin: 0.75rem 0;
          color: #333;
        }
        ul { 
          list-style-type: disc;
          padding-left: 1.5rem;
          margin-bottom: 1rem;
        }
        ul > li {
          margin: 0.5rem 0;
          color: #333;
        }
        strong {
          font-weight: 700;
          color: #333;
        }
        em {
          font-style: italic;
          color: #333;
        }
        code { 
          background: #f5f5f5;
          padding: 0.2rem 0.4rem;
          border-radius: 0.375rem;
          font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
          color: #666;
          white-space: pre-wrap;
          word-break: break-word;
          margin: 0.2rem 0;
          display: inline-block;
        }
        pre {
          background: #f5f5f5;
          padding: 0.8rem;
          border-radius: 0.375rem;
          overflow-x: auto;
          margin: 0.6rem 0;
          white-space: pre-wrap;
          word-break: break-word;
        }
        pre code {
          background: none;
          padding: 0;
        }
        blockquote {
          border-left: 2px solid #d1d5db;
          padding-left: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          color: #666;
          opacity: 0.75;
        }
        a {
          color: #2563eb;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #d1d5db;
          margin: 0.6rem 0;
        }
        th {
          padding: 0.5rem;
          text-align: left;
          font-weight: 600;
          border: 1px solid #d1d5db;
          color: #333;
        }
        td {
          padding: 0.5rem;
          border: 1px solid #d1d5db;
          color: #333;
        }
        img {
          width: 100%;
          height: auto;
          border-radius: 0.375rem;
          margin: 0.6rem 0;
        }
      </style>
      ${html}
    </div>
  `

  const opt = {
    margin: 0.5,
    filename: `${title}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      scale: 2,
      useCORS: true,
      logging: false,
      letterRendering: true,
      scrollY: 0,
      windowWidth: 1024,
      windowHeight: 768,
    },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
  }

  await html2pdf().set(opt).from(element).save()
}
