import React from 'react'
import _, { omit } from 'lodash'
import type { ChangeEventHandler } from 'react'
import Select from '@mui/material/Select'
import type { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import type { Extend } from '../../../../lib/types'

type ReactText = string | number

export type MultiSelectInputProps = Extend<
  SelectProps,
  {
    value: ReactText[]
    options: Array<{ value: ReactText; label: string }>
    onChange?: ChangeEventHandler<{ value: ReactText[] }>
  }
>

const MultiSelectInput = ({
  value,
  options,
  onChange,
  ...rest
}: MultiSelectInputProps): React.JSX.Element => {
  const renderValue = (value: string[]): string =>
    options
      .filter((option) => _.indexOf(value, option.value) > -1)
      .map((option) => option.label)
      .join(', ')
  const newRest = omit(rest, 'appId')

  return (
    <Select
      multiple
      renderValue={(value) => renderValue(value as string[])}
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e: any) => {
        onChange?.(e)
      }}
      variant="standard"
      {...newRest}
    >
      {options.map((option, key) => (
        <MenuItem key={key} value={option.value}>
          <Checkbox checked={_.indexOf(value, option.value) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default MultiSelectInput
