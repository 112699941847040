import React from 'react'
import {
  map,
  round,
  isNumber,
  mapValues,
  isArray,
  range,
  sumBy,
  values,
} from 'lodash'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import type {
  StatisticsData,
  StatisticsValue,
  StatisticsMean,
  StatisticsCount,
  StatisticsTopCounts,
  StatisticsReadmissions,
  StatisticsAvgStayTime,
  StatisticsFirstLastEvent,
  StatisticsDeathRatio,
  StatisticsCountPatientsFilterSchema,
} from './legacyTypes'
import { useDictionaries } from '../hooks/useDictionary'
import type { Dictionary } from '../types'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface EncodingDict {
  [dictName: string]: string[]
}

interface VarDict {
  [dict_var: string]: { list: { [key: string]: string } } | undefined
}

interface Props {
  statistics: StatisticsData
  encodingDict: EncodingDict
  datasetDict: Dictionary
  appId: string
  clustering?: number
}

function NotImplementedStatistic({
  varDicts,
  ...statisticsValue
}: StatisticsValue & { varDicts: VarDict }): React.JSX.Element {
  const opName =
    varDicts.datasetDict?.list[statisticsValue.operation_name] ??
    statisticsValue.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      <TableCell>{JSON.stringify(statisticsValue.result)}</TableCell>
    </TableRow>
  )
}

function meanStatisticValue(
  component: React.JSX.Element[],
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any,
  partitionVariables: Array<{ name: string }>,
  varDicts: VarDict,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any,
): void {
  if ('value' in result) {
    if (result.value === null) {
      component.push(<TableCell key={key}>{'-'}</TableCell>)
      return
    } else if (isNumber(result.value)) {
      component.push(
        <TableCell key={key}>{String(round(result.value, 2))}</TableCell>,
      )
      return
    }
  }
  const partitionVariable = partitionVariables[0].name
  const partitionDict = varDicts[partitionVariable]?.list
  const partitions = map(
    result as { [key: string]: { value: number } },
    (v, k) => `${partitionDict?.[k] ?? k}: ${round(v.value, 2)}`,
  ).join(', ')
  if (options?.counts !== undefined) {
    const total = sumBy(values(options.counts), 'value')
    const average = round(
      sumBy(
        map(
          result as { [key: string]: { value: number } },
          (v, k) => (v.value * options.counts[k].value) / total,
        ),
      ),
      2,
    )

    component.push(
      <TableCell key={key}>
        {average} ({partitions})
      </TableCell>,
    )
  } else {
    component.push(<TableCell key={key}>{partitions}</TableCell>)
  }
}

function MeanStatistic({
  varDicts,
  ...statistics
}: StatisticsMean & { varDicts: VarDict }): React.JSX.Element {
  if (statistics.partition_variables.length > 2) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }

  const components: React.JSX.Element[] = []
  if (
    statistics.partition_variables.filter(
      (partitionVariable) => partitionVariable.name === 'clusters',
    ).length !== 0
  ) {
    Object.entries(statistics.result).forEach(([key, result]) => {
      meanStatisticValue(
        components,
        `MeanStatisticCluster=${key}`,
        result,
        statistics.partition_variables.slice(1, 2),
        varDicts,
        statistics.options,
      )
    })
  } else {
    meanStatisticValue(
      components,
      `${statistics.operation_name}`,
      statistics.result,
      statistics.partition_variables,
      varDicts,
      statistics.options,
    )
  }

  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      {components}
    </TableRow>
  )
}

function countStatisticValue(
  component: React.JSX.Element[],
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any,
  partitionVariables: Array<{ name: string }>,
  varDicts: VarDict,
  operationName: string,
  percentage?: number,
): void {
  if ('value' in result && isNumber(result.value)) {
    let val = String(round(result.value, 2))
    if (percentage !== undefined) {
      val = `${val} (${round((result.value / percentage) * 100, 2)}%)`
    }
    component.push(<TableCell key={key}>{val}</TableCell>)
    return
  }

  const partitionVariable = partitionVariables[0].name
  const partitionDict = varDicts[partitionVariable]?.list
  if (partitionVariable === 'sex' && Object.keys(result).length === 1) {
    const sexMissing = (parseInt(Object.keys(result)[0]) + 1) % 2
    const valueMissing = { [sexMissing]: { value: 0 } }
    result = { ...valueMissing, ...result }
  }
  if (operationName === 'total_count') {
    const totalValue = (
      Object.values(result) as unknown as Array<{ value: number }>
    ).reduce((accumulated, currentValue) => accumulated + currentValue.value, 0)
    const partitionValue = map(
      result as { [key: string]: { value: number } },
      (v, k) =>
        `${partitionDict?.[k] ?? k}: ${round(
          (v.value / totalValue) * 100,
          1,
        )}%`,
    ).join(', ')
    component.push(
      <TableCell key={key}>{`${totalValue} (${partitionValue})`}</TableCell>,
    )
    return
  } else if (operationName === 'count_by_sex') {
    const totalValue = (
      Object.values(result) as unknown as Array<{ value: number }>
    ).reduce((accumulated, currentValue) => accumulated + currentValue.value, 0)
    const partitionValue = map(
      result as { [key: string]: { value: number } },
      (v) => `${v.value} (${round((v.value / totalValue) * 100, 1)}%)`,
    ).join(', ')
    component.push(<TableCell key={key}>{partitionValue}</TableCell>)
    return
  }
  component.push(
    <TableCell key={key}>
      {map(
        result as { [key: string]: { value: number } },
        (v, k) => `${partitionDict?.[k] ?? k}: ${round(v.value, 2)}`,
      ).join(', ')}
    </TableCell>,
  )
}

function CountStatistic({
  varDicts,
  percentage,
  ...statistics
}: (StatisticsCount | StatisticsCountPatientsFilterSchema) & {
  varDicts: VarDict
  percentage?: number | { [clusterId: string]: number }
}): React.JSX.Element {
  if (statistics.partition_variables.length > 2) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  const components: React.JSX.Element[] = []
  if (
    statistics.partition_variables.filter(
      (partitionVariable) => partitionVariable.name === 'clusters',
    ).length !== 0
  ) {
    const perClusterPercentage =
      statistics.options !== undefined &&
      'per_cluster_percentage' in statistics.options &&
      statistics.options.per_cluster_percentage
    const totalValue = Object.values(statistics.result).reduce(
      (accumulated, currentValue) => accumulated + currentValue.value,
      0,
    )

    Object.entries(statistics.result).forEach(([key, result]) => {
      countStatisticValue(
        components,
        `CountStatisticCluster=${key}`,
        result,
        statistics.partition_variables.slice(1, 2),
        varDicts,
        statistics.operation_name,
        perClusterPercentage && percentage !== undefined
          ? (percentage as { [clusterId: string]: number })[key]
          : totalValue,
      )
    })
  } else {
    countStatisticValue(
      components,
      `${statistics.operation_name}`,
      statistics.result,
      statistics.partition_variables,
      varDicts,
      statistics.operation_name,
    )
  }
  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      {components}
    </TableRow>
  )
}

function ReadmissionRatioStatistic({
  varDicts,
  ...statistics
}: StatisticsReadmissions & { varDicts: VarDict }): React.JSX.Element {
  if (
    statistics.partition_variables.length > 1 ||
    (statistics.partition_variables.length === 1 &&
      statistics.partition_variables[0].name !== 'clusters')
  ) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  const components: React.JSX.Element[] = []
  if (
    statistics.partition_variables.length === 0 &&
    'value' in statistics.result &&
    isNumber(statistics.result.value)
  ) {
    components.push(
      <TableCell key="ReadmissionRatioStatistic">{`${round(
        statistics.result.value * 100,
        2,
      )}%`}</TableCell>,
    )
  } else {
    Object.entries(statistics.result).forEach(([key, result]) => {
      components.push(
        <TableCell key={`ReadmissionRatioStatisticCluster=${key}`}>{`${round(
          result.value * 100,
          2,
        )}%`}</TableCell>,
      )
    })
  }
  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      {components}
    </TableRow>
  )
}

function AvgStayTimeStatistic({
  varDicts,
  ...statistics
}: StatisticsAvgStayTime & { varDicts: VarDict }): React.JSX.Element {
  if (
    statistics.partition_variables.length > 1 ||
    (statistics.partition_variables.length === 1 &&
      statistics.partition_variables[0].name !== 'clusters')
  ) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  const components: React.JSX.Element[] = []
  if (
    statistics.partition_variables.length === 0 &&
    'value' in statistics.result &&
    isNumber(statistics.result.value)
  ) {
    components.push(
      <TableCell key="AverageStayTimeStatistic">{`${round(
        statistics.result.value,
        2,
      )}`}</TableCell>,
    )
  } else {
    Object.entries(statistics.result).forEach(([key, result]) => {
      components.push(
        <TableCell key={`AvgStayTimeStatisticCluster=${key}`}>{`${round(
          result.value,
          2,
        )}`}</TableCell>,
      )
    })
  }
  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      {components}
    </TableRow>
  )
}

function DeathRatioStatistic({
  varDicts,
  ...statistics
}: StatisticsDeathRatio & { varDicts: VarDict }): React.JSX.Element {
  if (
    statistics.partition_variables.length > 1 &&
    statistics.partition_variables[0].name !== 'clusters'
  ) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  let component: React.JSX.Element | React.JSX.Element[] | undefined
  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  if (
    statistics.partition_variables.length === 0 &&
    'value' in statistics.result &&
    isNumber(statistics.result.value)
  ) {
    component = <TableCell>{`${round(statistics.result.value, 2)}`}</TableCell>
  } else {
    component = Object.entries(
      statistics.result as { [cluster: number]: { value: number } },
    ).map(([key, result]) => (
      <TableCell key={`DeathRatioCluster=${key}`}>
        {result.value === 0 ? '-' : result.value}
      </TableCell>
    ))
  }
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      {component}
    </TableRow>
  )
}

function FirstLastEventStatistic({
  varDicts,
  ...statistics
}: StatisticsFirstLastEvent & { varDicts: VarDict }): React.JSX.Element {
  let val = ''
  if (statistics.partition_variables.length > 0) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  if (
    'value' in statistics.result &&
    isArray(statistics.result.value) &&
    statistics.result.value.length === 2
  ) {
    const [first, last] = statistics.result.value
    let dateFormat = 'yyyy/MM/dd'
    if (
      statistics.options !== undefined &&
      'date_format' in statistics.options
    ) {
      dateFormat = statistics.options.date_format
    }
    const parseDate = (date: string): Date => {
      const year = parseInt(date.substring(0, 4))
      const month = parseInt(date.substring(4, 6)) - 1
      const day = parseInt(date.substring(6, 8))
      return new Date(year, month, day)
    }
    val = `${format(parseDate(first), dateFormat)}, ${format(
      parseDate(last),
      dateFormat,
    )}`
  }
  const opName =
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      <TableCell>{val}</TableCell>
    </TableRow>
  )
}

function StatisticsComponent({
  statistics,
  varDicts,
  percentage,
}: {
  statistics: StatisticsValue
  varDicts: VarDict
  percentage?: number | { [clusterId: string]: number }
}): React.JSX.Element {
  switch (statistics.fn) {
    case 'mean':
      return (
        <MeanStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'count':
    case 'count_patients_filter':
      return (
        <CountStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          percentage={percentage}
          {...statistics}
        />
      )
    case 'readmissions_ratio':
      return (
        <ReadmissionRatioStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'avg_stay_time':
      return (
        <AvgStayTimeStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'first_last_event':
      return (
        <FirstLastEventStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'death_ratio':
      return (
        <DeathRatioStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    default:
      return (
        <NotImplementedStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
  }
}
function setupVarDict(
  encodingDict: EncodingDict,
  dicts: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [dict_name: string]: any
  },
): VarDict {
  return mapValues(encodingDict, (v) => dicts[v[0]])
}

const TopCountsTable = ({
  varDicts,
  percentage,
  clusters,
  ...topCounts
}: StatisticsTopCounts & {
  varDicts: VarDict
  percentage?: number | { [clusterId: string]: number }
  clusters?: number
}): React.JSX.Element => {
  const { t } = useTranslation()
  const opName =
    varDicts.datasetDict?.list[topCounts.operation_name] ??
    topCounts.operation_name
  const rawVarName = topCounts.aggregation_variable?.name ?? ''
  if (
    topCounts.partition_variables.length > 1 ||
    (topCounts.partition_variables.length === 1 &&
      topCounts.partition_variables[0].name !== 'clusters') ||
    Object.keys(topCounts.result).length === 0
  ) {
    return (
      <NotImplementedStatistic
        key={topCounts.operation_name}
        varDicts={varDicts}
        {...topCounts}
      />
    )
  }
  const counts = (topCounts.result as { value: Array<[string, number]> }).value
  if (topCounts.partition_variables.length === 1 && clusters !== undefined) {
    const topCountsKeys: { [topCountId: string]: Array<number | string> } = {}
    Object.entries(topCounts.result).forEach(
      ([clusterId, clusterTopCounts]: [
        string,
        { value: Array<[string, number]> },
      ]) => {
        clusterTopCounts.value.forEach(([key, value]) => {
          if (key in topCountsKeys) {
            topCountsKeys[key][parseInt(clusterId)] = value
          } else {
            topCountsKeys[key] = Array(clusters).fill('-')
            topCountsKeys[key][parseInt(clusterId)] = value
          }
        })
      },
    )
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3>{opName}</h3>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: 'darkgrey',
                  }}
                >
                  <b>{varDicts.datasetDict?.list.code ?? 'Code'}</b>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: 'darkgrey',
                  }}
                >
                  <b>
                    {t('createExperimentDialog.description') ?? 'Description'}
                  </b>
                </TableCell>
                {range(1, clusters + 1).map((clusterId) => (
                  <TableCell
                    key={`Cluster=${clusterId}`}
                    style={{
                      width: `${round(75 / (clusters + 1), 1)}%`,
                    }}
                  >
                    <b>
                      {varDicts.datasetDict?.list.cluster ?? 'Cluster'}{' '}
                      {clusterId}
                    </b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(topCountsKeys).map(
                ([topCountsId, topCountvalues]) => (
                  <TableRow key={`TopCountIdRow=${topCountsId}`}>
                    <TableCell key={`TopCountId=${topCountsId}`}>
                      <b>{topCountsId}</b>
                    </TableCell>
                    <TableCell
                      key={`TopCountDescr=${topCountsId}`}
                      style={{ textOverflow: 'ellipsis', width: '25%' }}
                    >
                      <b>{varDicts[rawVarName]?.list[topCountsId] ?? ''}</b>
                    </TableCell>
                    {topCountvalues.map((value, index) => (
                      <TableCell
                        key={`TopCountValue=${value}ClusterId=${index}`}
                      >
                        {typeof percentage !== 'number' &&
                        percentage !== undefined &&
                        value !== '-'
                          ? `${value} (${round(
                              ((typeof value === 'string'
                                ? parseInt(value)
                                : value) /
                                percentage[index]) *
                                100,
                              2,
                            )}%)`
                          : value}
                      </TableCell>
                    ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>{opName}</h3>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
            <TableRow>
              <TableCell>
                <b>{varDicts.datasetDict?.list.code ?? 'Code'}</b>
              </TableCell>
              <TableCell>
                <b>
                  {t('createExperimentDialog.description') ?? 'Description'}
                </b>
              </TableCell>
              <TableCell key="BasicTopCounts">
                <b>{varDicts.datasetDict?.list.episodes ?? 'Episodes'}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {counts.map((row) => (
              <TableRow key={row[0]}>
                <TableCell>{row[0]}</TableCell>
                <TableCell style={{ textOverflow: 'ellipsis', width: '65%' }}>
                  {varDicts[rawVarName]?.list[row[0]] ?? ''}
                </TableCell>
                <TableCell>
                  {typeof percentage === 'number'
                    ? String(row[1]) +
                      ' (' +
                      String(round((row[1] / percentage) * 100, 2)) +
                      '%)'
                    : row[1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const LegacyStatisticsTable = ({
  statistics,
  encodingDict,
  datasetDict,
  appId,
  clustering,
}: Props): React.JSX.Element => {
  encodingDict['events.discharge_type'] = ['dischargeType']
  const [dicts] = useDictionaries(encodingDict, appId)
  const varDicts = setupVarDict(encodingDict, dicts)
  if (datasetDict !== null && datasetDict !== undefined) {
    varDicts.datasetDict = { list: datasetDict.list }
  }
  const topCounts: StatisticsTopCounts[] = statistics
    .filter((stats) => stats.fn === 'top_counts')
    .filter((stats) => stats.operation_name !== 'episodes_counts')
  let totalCount: number | { [clusterId: string]: number } | undefined = (
    statistics.find((stats) => stats.operation_name === 'episodes')?.result as
      | {
          value: number
        }
      | undefined
  )?.value
  if (clustering !== undefined) {
    const perClusterCount = statistics.find(
      (stats) => stats.operation_name === 'total_count',
    )?.result as { [clusterId: number]: { value: number } } | undefined
    if (perClusterCount !== undefined) {
      const perClusterCountMap: { [clusterId: string]: number } = {}
      Object.entries(perClusterCount).forEach(([clusterId, values]) => {
        perClusterCountMap[clusterId] = values.value
      })
      totalCount = perClusterCountMap
    }
  }
  const topCountsTable = topCounts.map((topCountsStat) => (
    <TopCountsTable
      key={topCountsStat.operation_name}
      varDicts={varDicts}
      percentage={totalCount}
      clusters={clustering}
      {...topCountsStat}
    />
  ))
  const tableComponents = statistics
    .filter((stats) => stats.fn !== 'top_counts')
    .map((stats) => (
      <StatisticsComponent
        statistics={stats}
        varDicts={varDicts}
        percentage={totalCount}
        key={stats.operation_name}
      />
    ))
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          {clustering !== undefined ? (
            <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: 'darkgray',
                  }}
                ></TableCell>
                {range(1, clustering + 1).map((clusterId) => (
                  <TableCell key={`Cluster=${clusterId}`}>
                    <b>
                      {datasetDict?.list.cluster ?? 'Cluster'} {clusterId}
                    </b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : (
            <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>{varDicts.datasetDict?.list.value ?? 'Value'}</b>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>{tableComponents}</TableBody>
        </Table>
      </TableContainer>
      {topCountsTable}
    </>
  )
}

export default LegacyStatisticsTable
