export const FOLDER_UPDATE = 'FOLDER_UPDATE'
export const FOLDER_DELETE = 'FOLDER_DELETE'
export const FOLDER_ARCHIVE = 'FOLDER_ARCHIVE'

export interface FolderDeleteAction {
  type: typeof FOLDER_DELETE
  payload: {
    folderId: string
  }
}

export interface FolderUpdateAction {
  type: typeof FOLDER_UPDATE
  payload: {
    id: string
    name: string
  }
}

export interface FolderArchiveAction {
  type: typeof FOLDER_ARCHIVE
  payload: {
    folderId: string
    appId: string
  }
}

export type FolderAction =
  | FolderDeleteAction
  | FolderUpdateAction
  | FolderArchiveAction
