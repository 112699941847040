// @ts-expect-error Load config
const config = window.CONFIG ?? {}

export const FALLBACK_LANG = config.fallbackLang ?? 'en'
// export const INTERVAL_CHECK = parseInt(process.env.INTERVAL_CHECK || '2000', 10)
export const INTERVAL_CHECK = 2000
export const SOIL_URL = config.soilUrl ?? 'https://soil.amalfianalytics.com'
export const API_PATH = config.apiPath ?? '/api'
export const API_PATH_SERVICES: string = config.apiPathServices ?? '/services'
export const LOGIN_URL = config.loginUrl ?? 'https://auth.amalfianalytics.com'
export const TIMEOUT = config.timeout ?? '5s'
export const LOGIN_PATH = config.loginPath ?? '/app/login'
export const REFRESH_PATH = config.refreshPath ?? '/app/refresh'
export const LOGOUT_PATH = config.refreshPath ?? '/app/logout'
export const APPLICATION_ID = config.applicationId
export const THEME_NAME: string = config.themeName ?? 'anis'
export const FILTER_ROLE: string = config.filterRole ?? 'anis'
export const LLM: boolean = config.llm ?? false
export const DEPRECATED_FEATURES: boolean = config.deprecatedFeatures ?? true
export const BETA_FEATURES: boolean = config.betaFeatures ?? false
export const LLM_URL: string = config.llmUrl ?? ''
export const POSTHOG_API_KEY: string = config.posthogApiKey ?? ''
export const POSTHOG_HOST: string =
  config.posthogHost ?? 'https://eu.i.posthog.com'
export const SCAN_ENABLED: boolean = config.scanEnabled ?? false
