import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import { requestStart, requestStop, requestFail } from '../request/actions'
import {
  createProject as createProjectRequest,
  getRootFolder,
  getDataset,
} from '../../../api/providers'
import { STATISTICS_PIPELINE_ID } from '../../../constants/api'
import { getMenuItems } from '../menu/actions'
import type { ProjectCreateAction } from './types'
import { createExperiment } from '../experiment/actions'
import type { Project } from '../../../api/providers/project'
import type { Dataset } from '../../../api/providers/dataset'

export function* createStatisticsFromProject(
  projectId: string,
  projectName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const rootFolder: Project = yield call(getRootFolder, projectId)
  const dataset: Dataset = yield call(getDataset, rootFolder.datasetId)
  const statistics = dataset.metadata.pipelines.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (f: any) => f.name === STATISTICS_PIPELINE_ID,
  )
  if (statistics === undefined) {
    console.error('Basic Statistics pipeline not found in this dataset.')
    return
  }
  const values = _.zipObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.module),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statistics.pipe.map((p: any) => p.default),
  ) as unknown as { [moduleId: string]: { [fieldName: string]: unknown } }

  yield put(
    createExperiment({
      name: '__statistics__' + projectName,
      folderId: rootFolder.id,
      pipelineId: STATISTICS_PIPELINE_ID,
      values,
    }),
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* createProject(action: ProjectCreateAction): any {
  try {
    yield put(requestStart())
    const projectId = yield call(createProjectRequest, action.payload)
    yield createStatisticsFromProject(projectId, action.payload.name)
    yield put(getMenuItems())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
