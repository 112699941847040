import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reverse } from 'lodash'
import { useCopyToClipboard, useInterval } from 'react-use'
import makeStyles from '@mui/styles/makeStyles'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { getExperimentLogs } from '../../../../../api/providers'

const useStyles = makeStyles(() => ({
  // centers the inner content vertically and horizontally
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  textArea: { width: '100%' },
}))

const formatLogs = (
  logs: Array<{
    date: string
    level: string
    message: string
    module_id: string
  }>,
): string => {
  reverse(logs)
  return logs
    .map((log) => {
      return `${log.level} ${log.date} ${log.module_id} ${log.message}`
    })
    .join('\n')
}

interface Props {
  experimentId: string
  folderId: string
  realtime?: boolean
}

const ExperimentLogsBox = ({
  experimentId,
  folderId,
  realtime,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const [showLogs, setShowLogs] = useState(false)
  const [logs, setLogs] = useState<string | null>(null)
  const [clipboard, copyToClipboard] = useCopyToClipboard()

  const { t } = useTranslation()

  useEffect(() => {
    getExperimentLogs(experimentId, folderId)
      .then(
        (
          l: Array<{
            date: string
            level: string
            message: string
            module_id: string
          }>,
        ) => {
          setLogs(formatLogs(l))
        },
      )
      .catch((error) => {
        console.error('Error awaiting logs.', error)
      })
  }, [])
  useInterval(async () => {
    if (realtime ?? logs === null) {
      const l = await getExperimentLogs(experimentId, folderId)
      setLogs(formatLogs(l))
    }
  }, 1000)

  return (
    <Box className={classes.container}>
      {showLogs && (
        <TextareaAutosize
          className={classes.textArea}
          minRows={10}
          value={logs ?? ''}
          onResize={() => {}}
          onResizeCapture={() => {}}
          readOnly
        />
      )}
      <div>
        <Link
          component="button"
          onClick={() => {
            setShowLogs(!showLogs)
          }}
        >
          {showLogs ? t('logs.hide') : t('logs.show')}
        </Link>
        &nbsp;
        {showLogs && (
          <Link
            component="button"
            onClick={() => {
              if (logs !== null) {
                copyToClipboard(logs)
              }
            }}
          >
            {clipboard.value !== undefined
              ? 'Copied logs to clipboard'
              : 'Copy to clipboard'}
          </Link>
        )}
      </div>
    </Box>
  )
}

export default ExperimentLogsBox
