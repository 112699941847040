import React, { useState } from 'react'
import Input from '@mui/material/Input'
import type { InputProps } from '@mui/material/Input'
import type { Extend } from '../../../../lib/types'
import FieldWrapper from '../../FieldWrapper'

type Props = Extend<
  InputProps,
  {
    label?: string
    value: string
    onChange?: (value: string) => void
    important?: boolean
  }
>

const TextField = ({
  required,
  label,
  value,
  onChange,
  important,
  ...rest
}: Props): React.JSX.Element => {
  const [originalValue] = useState(value)
  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.('')

  return (
    <FieldWrapper
      required={required}
      label={label}
      onReset={onReset}
      onClear={onClear}
      important={important}
    >
      <Input
        {...rest}
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value)
        }}
      />
    </FieldWrapper>
  )
}

export default TextField
