import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useMatch } from 'react-router-dom'
import { deleteExperiment, toggleMenuItem } from '../../../../../store/actions'
import ConfirmDialog from '../../../dialogs/ConfirmDialog'
import type { MenuToggleOpenItemAction } from '../../../../../store/namespaces/menu/types'

interface Props {
  toggleMenuItem: (
    folderId: string,
    isOpen?: boolean,
  ) => MenuToggleOpenItemAction
  deleteExperiment: typeof deleteExperiment
}

const DeleteExperimentPage = ({
  deleteExperiment,
}: Props): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch('/delete-experiment/:folderId/:experimentId')
  const { t } = useTranslation()
  const experimentId = match?.params.experimentId as string
  const folderId = match?.params.folderId as string

  const onCancel = (): void => {
    navigate('/')
  }

  const onAccept = (): void => {
    deleteExperiment(experimentId, folderId)
    toggleMenuItem(folderId, true)
    navigate('/')
  }

  return (
    <ConfirmDialog
      open
      title={t('deleteExperimentDialog.title')}
      message={t('deleteExperimentDialog.message')}
      onCancel={onCancel}
      onAccept={onAccept}
    />
  )
}

const mapDispatchToProps = { toggleMenuItem, deleteExperiment }

export default connect(null, mapDispatchToProps)(DeleteExperimentPage)
