import React from 'react'
import { useAsync } from 'react-use'
import { omit, isArray } from 'lodash'
import { getProjectStructure } from '../../../../../api/providers'
import type { FolderStructure } from '../../../../../api/providers'
import { useTranslation } from 'react-i18next'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import makeStyles from '@mui/styles/makeStyles'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { ChevronRightIcon, ChevronDownIcon } from '../../../icons'

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: theme.spacing(3) },
}))

const FolderItem = ({
  structure,
}: {
  structure: FolderStructure
}): React.JSX.Element => {
  return (
    <TreeItem itemId={structure.folder.id} label={structure.folder.name}>
      {structure.children.map((c) => (
        <FolderItem key={c.folder.id} structure={c} />
      ))}
    </TreeItem>
  )
}

interface NavigatorProps {
  structure: FolderStructure
  selected: string | undefined
  setSelected?: (selected?: string) => void
}

const FileSystemNavigator = ({
  structure,
  setSelected,
  selected,
}: NavigatorProps): React.JSX.Element => {
  const classes = useStyles()
  return (
    <SimpleTreeView
      selectedItems={selected}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onItemSelectionToggle={(_e: any, id: string) => setSelected?.(id)}
      className={classes.root}
      slots={{ collapseIcon: ChevronDownIcon, expandIcon: ChevronRightIcon }}
    >
      <FolderItem structure={structure} />
    </SimpleTreeView>
  )
}

type Props = Extend<FieldProps<string | undefined>, { parentFolderId?: string }>

const SubpopulationSelectorField = ({
  required,
  value,
  parentFolderId,
  onChange,
  error,
  onError,
  label,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const folderStructure = useAsync(
    async () => await getProjectStructure(parentFolderId),
  )
  const selectSubpopulationLabel = t('fields.selectSubpopulation')
  return (
    <FieldWrapper
      required={required}
      label={selectSubpopulationLabel}
      error={
        error !== null && label !== undefined
          ? (error as { [label: string]: string })?.[label]
          : undefined
      }
    >
      {folderStructure.value !== undefined && (
        <FileSystemNavigator
          structure={folderStructure.value}
          selected={value ?? ''}
          setSelected={(node) => {
            if (node === undefined) {
              return label !== undefined
                ? onError?.((e) => ({ ...e, [label]: 'Select one filter' }))
                : undefined
            }
            if (label !== undefined) {
              onError?.((e) => {
                if (isArray(e)) {
                  throw new Error(
                    'Unexpected list of errors in SubpopulationSelectorField',
                  )
                }
                return omit(e, label)
              })
            }
            onChange?.(node)
          }}
        />
      )}
    </FieldWrapper>
  )
}

export default SubpopulationSelectorField
