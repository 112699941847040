import { reverse, concat, orderBy } from 'lodash'
import type { Store } from './store'
import { getLayoutTree } from '../../store/namespaces/layout/lib/layout'
import type { Folder } from './folder'

export interface ExperimentItem {
  type: 'experiment'
  id: string
  folderId: string
  label: string
  isSelected: boolean
}

interface FilterItem {
  type: 'filter'
  id: string
  label: string
  items: TreeItem[]
  isOpen: boolean
  isSelected: boolean
}

interface ProjectItem {
  type: 'project'
  id: string
  label: string
  items: TreeItem[]
  isOpen: boolean
  isSelected: boolean
  isArchived: boolean
}

export type TreeItem = ExperimentItem | FilterItem | ProjectItem

const parentFolders = (folders: Folder[], folderId?: string): string[] => {
  if (folderId === undefined) {
    return []
  }
  const currentFolder = folders.find((x) => x.id === folderId)
  if (currentFolder === undefined) {
    return []
  }
  return [currentFolder?.id, ...parentFolders(folders, currentFolder?.folderId)]
}

export const getTreeItems = (store: Store): TreeItem[] => {
  let { folders, experiments } = store

  // Update the orderBy calls to use lowercase comparisons
  folders = orderBy(folders, [(f) => f.name.trim().toLowerCase()], ['desc'])
  experiments = orderBy(
    experiments,
    [(e) => e.name.trim().toLowerCase()],
    ['desc'],
  )
  const selectedNode = getLayoutTree().getSelectedNode()
  const openedFolders = parentFolders(folders, selectedNode?.config?.folderId)

  const equalName = (nodeName: string | undefined, name: string): boolean =>
    nodeName?.slice('__statistics__'.length) === name || nodeName === name

  const parseChilds = (folderId?: string): TreeItem[] => {
    return folders
      .filter((x) => x.folderId === folderId)
      .map(({ id, name, archivedAt }) => {
        const childItems = concat(
          reverse(
            experiments
              .filter((x) => x.folderId === id)
              .map(
                ({ id: experimentId, name }): ExperimentItem => ({
                  type: 'experiment',
                  id: experimentId,
                  folderId: id,
                  label: name,
                  isSelected:
                    equalName(selectedNode?.name, name) &&
                    selectedNode?.config?.experimentId === experimentId &&
                    selectedNode?.config?.folderId === id,
                }),
              ),
          ),
          reverse(parseChilds(id)),
        )
        const isProject = folderId === undefined
        if (isProject) {
          return {
            type: 'project',
            id,
            label: name,
            isOpen: openedFolders.includes(id),
            isSelected:
              equalName(selectedNode?.name, name) &&
              selectedNode?.config?.folderId === id,
            items: childItems,
            isArchived: archivedAt !== undefined,
          }
        }
        return {
          type: 'filter',
          id,
          label: name,
          isOpen: openedFolders.includes(id),
          isSelected:
            equalName(selectedNode?.name, name) &&
            selectedNode?.config?.folderId === id,
          items: childItems,
        }
      })
  }
  return parseChilds()
}
