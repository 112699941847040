import type { TreeItem } from '../../../api/providers'

import {
  MENU_ITEMS_GET,
  MENU_NEW_ITEM,
  MENU_ITEMS_GET_SUCCESS,
  MENU_TOGGLE,
  MENU_CLOSE,
  MENU_OPEN,
  MENU_TOGGLE_OPEN_ITEM,
  MENU_SELECT_ITEM,
  MENU_TOGGLE_ARCHIVED_ITEM,
} from './types'
import type {
  MenuItemsGetAction,
  MenuItemsGetSuccessAction,
  MenuToggleAction,
  MenuCloseAction,
  MenuOpenAction,
  MenuToggleOpenItemAction,
  MenuSelectItemAction,
  MenuNewItemAction,
  MenuToggleArchivedItemAction,
} from './types'

export const getMenuItems = (): MenuItemsGetAction => ({ type: MENU_ITEMS_GET })

export const newMenuItem = (
  folderId: string,
  experimentId: string,
  openItem: boolean = true,
): MenuNewItemAction => ({
  type: MENU_NEW_ITEM,
  payload: { folderId, experimentId, openItem },
})

export const getMenuItemsSuccess = (
  items: TreeItem[],
): MenuItemsGetSuccessAction => ({
  type: MENU_ITEMS_GET_SUCCESS,
  payload: { items },
})

export const toggleMenu = (): MenuToggleAction => ({ type: MENU_TOGGLE })

export const closeMenu = (): MenuCloseAction => ({ type: MENU_CLOSE })

export const openMenu = (): MenuOpenAction => ({ type: MENU_OPEN })

export const toggleMenuItem = (
  folderId: string,
  isOpen?: boolean,
): MenuToggleOpenItemAction => ({
  type: MENU_TOGGLE_OPEN_ITEM,
  payload: { folderId, isOpen },
})

export const selectMenuItem = (
  folderId: string,
  experimentId?: string,
): MenuSelectItemAction => ({
  type: MENU_SELECT_ITEM,
  payload: { experimentId, folderId },
})

export const toggleArchivedItem = (
  folderId: string,
): MenuToggleArchivedItemAction => ({
  type: MENU_TOGGLE_ARCHIVED_ITEM,
  payload: { folderId },
})
