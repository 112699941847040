export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'

export interface AuthLogoutAction {
  type: typeof AUTH_LOGOUT
}

export interface AuthLogoutSuccessAction {
  type: typeof AUTH_LOGOUT_SUCCESS
}

export type AuthAction = AuthLogoutAction | AuthLogoutSuccessAction
