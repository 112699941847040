import { memoize } from 'lodash'
import type { Experiment } from './experiment'
import {
  getState,
  updateState,
  createState,
  getStates,
  type State,
} from './state'
import type { Folder } from './folder'
import type { UserVariable } from './userVariable'
import { STORE_NAME } from '../../constants/api'
import { getAppIds } from '../lib/http'
import type { Project } from './project'
import { type Dataset, getDatasets } from './dataset/dataset'

export interface Store {
  folders: Folder[]
  experiments: Experiment[]
  userVariables: UserVariable[]
}

const storeMigration = async (
  folders: Folder[],
  datasets: Dataset[],
): Promise<[Folder[], number]> => {
  let counter = 0
  const updateIfLegacyProject = (folder: Folder): Folder => {
    if (folder.folderId !== undefined) {
      // not project
      return folder
    }
    const project = folder as Project
    const newDataset = datasets.find(
      (dataset) =>
        dataset.alias === project.datasetAlias &&
        dataset.alias !== undefined &&
        dataset.id !== project.datasetId,
    )
    if (newDataset !== undefined) {
      console.debug('migrating', project, newDataset?.id, newDataset?.alias)
      counter += 1
      project.datasetAlias = newDataset.alias
      project.datasetId = newDataset.id
    }
    return project
  }
  return [folders.map(updateIfLegacyProject), counter]
}

const migrateAppStoreIfNecessary = async (
  appId: string,
  state: State<Store>,
  datasets: Dataset[],
): Promise<State<Store>> => {
  const [newFolders, counter] = await storeMigration(
    state.state.folders,
    datasets,
  )
  state.state.folders = newFolders
  if (counter > 0) {
    await updateStore(state.state, appId)
  }
  return state
}

const storeIds: { [key: string]: string } = {}

export const getStoreId = (appId: string): string | undefined => {
  return storeIds[appId]
}

export const innerGetStore = memoize(async (appId: string): Promise<Store> => {
  const [list, datasets] = await Promise.all([
    getStates(appId),
    getDatasets(appId),
  ])
  if (list.length > 0) {
    const item = list[0]
    storeIds[appId] = item.id
    return (await migrateAppStoreIfNecessary(appId, item, datasets)).state
  }
  const stateId: string = await createStore(appId)
  const doc = await getState<Store>(stateId, appId)
  return doc.state
})

export const getStore = async (
  appId: string,
): Promise<{ store: Store; error?: string }> => {
  try {
    return { store: await innerGetStore(appId) }
  } catch (_error) {
    setTimeout(() => {
      // if the store fails, try again when 10s have passed
      innerGetStore.cache.delete?.(appId)
    }, 10000)
    return {
      store: { folders: [], experiments: [], userVariables: [] },
      error: `Could not get store for appId: ${appId}.`,
    }
  }
}

export const updateStore = async (
  body: Store,
  appId: string,
): Promise<void> => {
  // getStore.cache.delete?.(appId)
  let stateId: string | undefined = storeIds[appId]
  if (storeIds[appId] === undefined) {
    const list = await getStates(appId)
    const item = list.find(({ name }) => name === STORE_NAME)
    stateId = item !== undefined ? item.id : await createStore(appId)
    storeIds[appId] = stateId
  }
  await updateState<Store>(stateId, STORE_NAME, body, appId)
  innerGetStore.cache.delete?.(appId)
}

export async function getStoreByFolderId(
  folderId: string,
): Promise<Store | undefined>
export async function getStoreByFolderId(
  folderId: string,
  returnAppId: true,
): Promise<{ store: Store; appId: string } | undefined>
export async function getStoreByFolderId(
  folderId: string,
  returnAppId?: boolean,
): Promise<Store | { store: Store; appId: string } | undefined> {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    if (store.folders.find((folder) => folder.id === folderId) !== undefined) {
      return returnAppId === true ? { store, appId } : store
    }
  }
  return undefined
}

export const createStore = async (appId: string): Promise<string> =>
  await createState<Store>(
    STORE_NAME,
    { folders: [], experiments: [], userVariables: [] },
    appId,
  )
