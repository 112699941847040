import {
  NEW_FILTER_DOWNLOAD,
  START_FILTER_DOWNLOAD,
  END_FILTER_DOWNLOAD,
} from './types'
import type {
  StartFilterDownloadsAction,
  NewFilterDownloadsAction,
  EndFilterDownloadsAction,
} from './types'

export const startFilterDownload = (
  experimentId: string,
  folderId: string,
  downloadPerPatient: boolean,
  format?: string,
): StartFilterDownloadsAction => ({
  type: START_FILTER_DOWNLOAD,
  payload: { experimentId, folderId, downloadPerPatient, format },
})

export const newFilterDownload = (
  experimentId: string,
  newExperimentId: string,
  resultId: string,
): NewFilterDownloadsAction => ({
  type: NEW_FILTER_DOWNLOAD,
  payload: { experimentId, newExperimentId, resultId },
})

export const endFilterDownload = (
  experimentId: string,
): EndFilterDownloadsAction => ({
  type: END_FILTER_DOWNLOAD,
  payload: { experimentId },
})
