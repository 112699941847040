import React from 'react'
import List from '@mui/material/List'
import type { Item } from './lib/types'
import TreeItem from './TreeItem'
import type { TreeItem as TreeItemType } from '../../../api/providers'

interface Props {
  level: number
  items: Item[]
  onToggleItem: (id: string, isOpen?: boolean) => void
  onSelectItem: (folderId: string, experimentId?: string) => void
  parseItem: (item: TreeItemType) => Item
}

const Tree = ({
  items,
  level,
  onToggleItem,
  onSelectItem,
  parseItem,
}: Props): React.JSX.Element => (
  <List dense component="div" disablePadding>
    {items.map((item, key) => (
      <TreeItem
        key={`${level}-${key}`}
        level={level}
        item={item}
        onToggleItem={onToggleItem}
        onSelectItem={onSelectItem}
        parseItem={parseItem}
      />
    ))}
  </List>
)

export default Tree
