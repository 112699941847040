import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import type { TabNode, Action } from 'flexlayout-react'
import { Layout, Actions, Model, DockLocation } from 'flexlayout-react'
import type { AppState, LayoutState, LayoutItem } from '../../../../store/types'
import {
  openExperiment,
  closeExperiment,
  saveLayoutTree,
  selectMenuItem,
} from '../../../../store/actions'
import WidgetFactory from '../WidgetFactory'
import InstructionsWidget from '../../app/widgets/InstructionsWidget'
import TabComponent from './TabComponent'

interface Props {
  layout: LayoutState
  selectedLayoutItem?: LayoutItem
  openExperiment: typeof openExperiment
  closeExperiment: typeof closeExperiment
  saveLayoutTree: typeof saveLayoutTree
  selectMenuItem: typeof selectMenuItem
}

const WidgetManager = ({
  layout,
  // openExperiment,
  closeExperiment,
  saveLayoutTree,
  selectMenuItem,
}: Props): React.JSX.Element => {
  const model = Model.fromJson({
    ...layout.tree,
    global: { tabSetTabStripHeight: 40, splitterSize: 3 },
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (layout.tree.layout?.children !== undefined) {
      if (layout.tree.layout.children.length === 0) {
        // First time
        model.doAction(
          Actions.addNode(
            { type: 'tabset' },
            model.getRoot().getId(),
            DockLocation.CENTER,
            0,
          ),
        )
        model.doAction(
          Actions.addNode(
            {
              type: 'tab',
              name: t('widget.instructions'),
              config: { instructions: true },
            },
            model.getRoot().getChildren()[0].getId(),
            DockLocation.CENTER,
            0,
          ),
        )
      } else if (
        layout.tree.layout.children.length > 0 &&
        layout.tree.layout.children[0].children !== undefined &&
        layout.tree.layout.children[0].children.length === 0
      ) {
        // When closing all tabs
        model.doAction(
          Actions.addNode(
            {
              type: 'tab',
              name: t('widget.instructions'),
              config: { instructions: true },
            },
            model.getRoot().getChildren()[0].getId(),
            DockLocation.CENTER,
            0,
          ),
        )
      }
    }
  }, [layout.tree])

  const factory = (node: TabNode): React.JSX.Element | undefined => {
    const config = node.getConfig() as {
      instructions?: boolean
      experimentId: string
      folderId: string
    }
    if (config.instructions ?? false) {
      return <InstructionsWidget />
    }
    const { experimentId, folderId } = config
    const item = layout.items.find(
      (x) => x.experimentId === experimentId && x.folderId === folderId,
    )
    if (item === undefined) {
      return undefined
    }
    return (
      <WidgetFactory
        experimentId={item.experimentId}
        folderId={item.folderId}
      />
    )
  }

  const onAction = (action: Action): Action | undefined => {
    const { type, data } = action
    let ret: Action | undefined = action

    if (type === Actions.DELETE_TAB) {
      const node = model.getNodeById(data.node)
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const experimentId: string = _.get(
        node,
        '_attributes.config.experimentId',
      )
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const folderId: string = _.get(node, '_attributes.config.folderId')
      const item = layout.items.find(
        (x) => x.experimentId === experimentId && x.folderId === folderId,
      )
      if (item !== undefined) {
        closeExperiment(item.experimentId, item.folderId)
      }
      ret = undefined
    } else if (type === Actions.SELECT_TAB) {
      const node = model.getNodeById(data.tabNode)
      // @ts-expect-error Waiting for flexlayout-react to be updated

      const experimentId: string = _.get(
        node,
        '_attributes.config.experimentId',
      )
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const folderId: string = _.get(node, '_attributes.config.folderId')
      const item = layout.items.find(
        (x) => x.experimentId === experimentId && x.folderId === folderId,
      )

      if (item !== undefined) {
        // openExperiment(item.experimentId)
        selectMenuItem(item.folderId, item.experimentId)
      }
      // ret = undefined
    } else if (type === Actions.SET_ACTIVE_TABSET) {
      const tabsetNode = model.getNodeById(data.tabsetNode)
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const selected: number = _.get(tabsetNode, '_attributes.selected')
      const nodes = tabsetNode?.getChildren() ?? []
      const node = nodes[selected]
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const experimentId: string = _.get(
        node,
        '_attributes.config.experimentId',
      )
      // @ts-expect-error Waiting for flexlayout-react to be updated
      const folderId: string = _.get(node, '_attributes.config.folderId')
      const item = layout.items.find(
        (x) => x.experimentId === experimentId && x.folderId === folderId,
      )

      if (item !== undefined) {
        // openExperiment(item.experimentId)
        selectMenuItem(item.folderId, item.experimentId)
      }
      // ret = undefined
    }

    // The Almendruco's trick.
    // I'm sorry, I didn't make this plugin.
    if (
      [Actions.SELECT_TAB, Actions.ADJUST_SPLIT, Actions.MOVE_NODE].includes(
        type,
      )
    ) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    }

    return ret
  }

  return (
    <Layout
      model={model}
      titleFactory={TabComponent}
      factory={factory}
      onAction={onAction}
      onModelChange={(model) => saveLayoutTree(model.toJson())}
    />
  )
}

const mapStateToProps = (state: AppState): { layout: LayoutState } => ({
  layout: state.layout,
})

const mapDispatchToProps = {
  openExperiment,
  closeExperiment,
  saveLayoutTree,
  selectMenuItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetManager)
