// Gets the 'short' part of a "locale code".
//
// For example:
//   getShortLang('en-US') // returns 'en'
//   getShortLang('es')    // returns 'es'
//
export const getShortLang = (lang: string): string => {
  const matches = lang.match(/^\w+/)
  if (matches === null) {
    return ''
  }
  return matches[0]
}
