import React from 'react'
import type { TabNode } from 'flexlayout-react'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

interface Props {
  node: TabNode
}

const useStyles = makeStyles({
  root: {
    paddingTop: 3,
  },
})

const TabComponent = ({ node }: Props): React.JSX.Element => {
  const classes = useStyles()
  let name = node.getName()
  // let selected = false
  if (name[0] === '[') {
    name = name.replace('[', '').replace(']', '*')
    // selected = true
  }
  return (
    <div className={classes.root}>
      {/* <Typography color={selected ? 'textPrimary' : 'textSecondary'}> */}
      <Typography color="textPrimary">
        {name.replace('__statistics__', '')}
      </Typography>
    </div>
  )
}

const factory = (node: TabNode): React.JSX.Element => (
  <TabComponent node={node} />
)

export default factory
