import i18n from 'i18next'
import { http } from '../lib/http'
import { getShortLang } from '../lib/i18n'
import { getDataset } from './dataset/dataset'
import type { Folder } from './folder'
import { isArray, memoize } from 'lodash'

export interface DictionaryContent {
  list: { [key: string]: string }
}

export interface Dictionary {
  name: string
  language: string
  content: DictionaryContent
}

type ApiDictionary = Omit<Dictionary, 'content'> & {
  name: string
  language: string
  content: { [key: string]: string }
}

export const getDictionary = memoize(
  async (
    name: string | string[],
    language: string,
    appId: string,
  ): Promise<{
    list: { [key: string]: string }
  }> => {
    if (isArray(name)) {
      name = name[0]
    }
    const cacheName = `${name}-${language ?? 'ca'}`
    console.debug('Dictionary cache miss for', cacheName)
    const url = `/v2/dictionaries/${name}/?language=${language ?? 'ca'}`
    const doc = await (await http(appId)).get<ApiDictionary>(url)
    if (
      doc.data.content.list !== undefined
      && typeof doc.data.content.list !== 'string'
    ) {
      // @ts-expect-error New dictionaries do not contain the list element but anis still expects it.
      return doc.data.content
    }
    return { list: doc.data.content }
  },
  (name: string | string[], language: string, appId?: string) =>
    `${JSON.stringify(name)}-${language}-${appId}`,
)

export const getDatasetDictionary = async (
  folders: Folder[],
): Promise<{
  list: {
    [key: string]: string
  }
} | null> => {
  if (folders.length > 0) {
    const projectFolder = folders.find((f) => f.datasetId)
    if (projectFolder?.datasetId !== undefined) {
      const dataset = await getDataset(projectFolder.datasetId)
      if (dataset.metadata?.dictionary !== undefined) {
        try {
          const dictionary = await getDictionary(
            dataset.metadata.dictionary,
            getShortLang(i18n.language),
            dataset.app_id,
          )
          return dictionary
        } catch {
          console.warn(`Dictionary ${dataset.metadata.dictionary} not found.`)
        }
      }
    }
  }
  return null
}
