import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import type { Extend } from '../../../../lib/types'
import { RegularDeleteIcon, RegularAddIcon } from '../../../icons'
import type { FieldProps, OnFieldError, FieldError } from '../../../form/types'
import FieldFactory from '../../../form/FieldFactory'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import FieldWrapper from '../../../form/FieldWrapper'
import ArrayFieldAdvanced from '../ArrayFieldAdvanced'

type Props = Extend<
  FieldProps<{ all: unknown[] } | undefined>,
  {
    items: FieldFactoryProps
    operations: string[]
    maxItems?: number
  }
>

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  row: {
    '& > td': {
      border: 'none',
    },
    '& > td:last-child': {
      width: theme.spacing(4.5),
      paddingLeft: theme.spacing(0.75),
    },
  },
  label: {
    color: theme.palette.text.secondary,
    position: 'relative',
    cursor: 'default',
    userSelect: 'none',
  },
}))

const ArrayField = ({
  required,
  label,
  value: initValue,
  items,
  onChange,
  datasetDictionary,
  error,
  onError,
  operations,
  maxItems,
  appId,
  setAdvanced,
  advanced = false,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const value = initValue ?? { all: [] }
  const [originalValue] = useState(initValue)

  const getRows = (): unknown[] =>
    value.all.length > 0
      ? value.all
      : [
          (items.value as unknown | undefined) ??
            (items.type === 'object' ? {} : ''),
        ]
  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.(undefined)

  const onRowChange = (i: number) => (v: unknown) => {
    const val = { all: [...getRows()].map((w, j) => (i === j ? v : w)) }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !_.isEqual(value, val) && onChange?.(val)
  }

  const onAddRow = (): void => {
    // FIXME ensure that items.type does not lead to problems similar to #131
    onChange?.({
      all: [
        ...getRows(),
        (items.value as unknown | undefined) ??
          (items.type === 'object' ? {} : ''),
      ],
    })
  }

  const onDeleteRow = (i: number) => () => {
    onChange?.({ all: [...value.all].filter((_, j) => i !== j) })
  }
  const vlabel =
    datasetDictionary?.list[items.prefix ?? label ?? ''] ??
    items.prefix ??
    label
  if (label === '__root') {
    // special case for subpopulation
    // TODO refactor when there is more knowledge about where this is going
    return (
      <div>
        {advanced ? (
          <ArrayFieldAdvanced
            operations={operations}
            appId={appId}
            items={items}
            label={label}
            /* @ts-expect-error TODO fix this */
            value={initValue}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChange as (v: any) => void}
            datasetDictionary={datasetDictionary}
            error={error as FieldError}
            onError={onError as OnFieldError}
          />
        ) : (
          <FieldFactory
            advanced={advanced}
            setAdvanced={setAdvanced}
            {...items}
            appId={appId}
            value={value.all[0]}
            onChange={(v: unknown) => {
              onChange?.({ all: [v] })
            }}
            datasetDictionary={datasetDictionary}
            error={error as FieldError}
            onError={onError as OnFieldError}
          />
        )}
      </div>
    )
  }

  const deleteRowLabel = t('fields.array.deleteRow')
  const addRowLabel = t('fields.array.addRow')
  return (
    <FieldWrapper
      required={required}
      label={vlabel}
      onReset={onReset}
      onClear={onClear}
      collapsable
    >
      <Table className={classes.table} size="small" padding="none">
        <TableBody>
          {getRows().map((v, i) => (
            <TableRow className={classes.row} key={i}>
              <TableCell>
                <FieldFactory
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  {...items}
                  appId={appId}
                  value={v}
                  onChange={onRowChange(i)}
                  datasetDictionary={datasetDictionary}
                  error={
                    (error as { [label: string]: FieldError })?.[String(i)] ??
                    {}
                  }
                  onError={(errorFn) => {
                    if (onError !== undefined) {
                      const newSubError = errorFn(
                        ((error as { [label: string]: FieldError })?.[
                          String(i)
                        ] ?? {}) as FieldError,
                      )
                      if (_.keys(newSubError).length > 0) {
                        onError((oldError) => ({
                          ...oldError,
                          [String(i)]: newSubError,
                        }))
                      } else {
                        onError((oldError) => {
                          if (_.isArray(oldError)) {
                            throw new Error(
                              'Unexpected list of errors in ArrayField',
                            )
                          }
                          return _.omit(oldError, String(i))
                        })
                      }
                    }
                  }}
                />
              </TableCell>
              {maxItems !== 1 && (
                <TableCell align="right">
                  {i < value.all.length - 1 ? (
                    <IconButton
                      tabIndex={-1}
                      title={deleteRowLabel}
                      size="small"
                      onClick={onDeleteRow(i)}
                    >
                      <RegularDeleteIcon fixedWidth />
                    </IconButton>
                  ) : (
                    <IconButton
                      tabIndex={-1}
                      title={addRowLabel}
                      size="small"
                      onClick={onAddRow}
                    >
                      <RegularAddIcon fixedWidth />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FieldWrapper>
  )
}

export default ArrayField
