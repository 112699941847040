import React from 'react'
import type { ReactElement } from 'react'
import SearchComponent from './SearchComponent'
import { Slider, TextField } from '@mui/material'
import SelectField from '../../../../components/form/fields/SelectField'
import CheckboxesGroup from './CheckboxesGroup'
import type { CheckboxState } from './CheckboxesGroup'
import { Trans } from 'react-i18next'

export const SearchFilter = (
  currentValue: string,
  setCurrentValue: (newValue: string) => void,
): React.JSX.Element => (
  <SearchComponent
    textValue={currentValue}
    onChange={(text: string) => {
      setCurrentValue(text)
    }}
  />
)

export const NormalizedFilter = (
  currentValue: string,
  setCurrentValue: (newValue: string) => void,
  _currentFilterError: string | undefined,
  _setCurrentFilterError: (error: string | ReactElement | undefined) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _dictionary: any,
  { attrs }: { attrs: string[] },
): React.JSX.Element => (
  <>
    <SelectField
      value={currentValue}
      onChange={(newValue: string) => {
        setCurrentValue(newValue)
      }}
      // @ts-expect-error don't know why we don't use t() here
      options={attrs.map((a) => ({
        value: a,
        label: <Trans i18nKey={`associationsGraph.attrs.${a}`}>{a}</Trans>,
      }))}
    />
  </>
)

export const MaxDistanceFilter = (
  currentValue: number,
  setCurrentValue: (newValue: number) => void,
): React.JSX.Element => (
  <>
    <Trans i18nKey="filters.name.MaxDistanceFilter">
      Current Max. Distance
    </Trans>
    :{' '}
    {currentValue < 40 ? (
      currentValue
    ) : (
      <Trans i18nKey="associationsGraph.selectField">Everything</Trans>
    )}
    <SelectField
      value={currentValue.toString()}
      onChange={(newValue: string) => {
        setCurrentValue(parseInt(newValue))
      }}
      options={[
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        {
          value: 40,
          // @ts-expect-error don't know why we don't use t() here
          label: (
            <Trans i18nKey="associationsGraph.selectField">Everything</Trans>
          ),
        },
      ]}
      style={{
        width: 100,
      }}
    />
  </>
)

export const SliderFilter = (
  currentValue: number,
  setCurrentValue: (newValue: number) => void,
): React.JSX.Element => (
  <>
    Max edges:{currentValue}
    <Slider
      value={currentValue}
      step={10}
      marks
      min={10}
      max={500}
      aria-labelledby="vertical-slider"
      valueLabelDisplay="auto"
      onChange={(_e, val) => {
        setCurrentValue(val as number)
      }}
    />
  </>
)

export const CheckboxesFilter = (
  currentValue: CheckboxState[],
  setCurrentValue: (newValue: CheckboxState[]) => void,
  currentError: boolean,
  setCurrentError: (newError: boolean) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dictionary: any,
): React.JSX.Element => {
  const handleChangeCheckbox = (checkboxId: string): void => {
    const newCheckboxesState = currentValue.map((checkboxState) => {
      const newState =
        checkboxId === checkboxState.checkboxId
          ? !checkboxState.state
          : checkboxState.state
      return { ...checkboxState, state: newState }
    })
    const error =
      newCheckboxesState.filter((checkboxState) => checkboxState.state).length <
      1
    setCurrentError(error)
    setCurrentValue(newCheckboxesState)
  }
  return (
    <CheckboxesGroup
      checkboxesState={currentValue}
      onChange={(checkboxId) => {
        handleChangeCheckbox(checkboxId)
      }}
      error={currentError}
      dictionary={dictionary}
    />
  )
}

export const CreateVariableFilter = (
  currentValue: string,
  setCurrentValue: (newValue: string) => void,
): React.JSX.Element => (
  <Trans i18nKey="clustering.createClusteringVariable">
    <p>You can create a clustering variable to be used later on as filter.</p>
    <TextField
      autoFocus
      margin="dense"
      id="variable name"
      fullWidth
      value={currentValue}
      required
      onChange={(e) => {
        setCurrentValue(e.target.value)
      }}
    />
    <p>
      To use it as filter, select the &ldquo;User Variable&rdquo; filter type at
      the filter creation.
    </p>
  </Trans>
)

export const NumberFilter = (
  currentValue: number,
  setCurrentValue: (newValue: number) => void,
  _currentFilterError: string | undefined,
  setCurrentFilterError: (error: string | ReactElement | undefined) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _dictionary: any,
  { min, max }: { min: number; max: number },
): React.JSX.Element => {
  const error = (
    <Trans i18nKey="fieldErrors.rangeError">
      Value must be between {{ min }} and {{ max }}.
    </Trans>
  )
  return (
    <TextField
      autoFocus
      margin="dense"
      type="number"
      fullWidth
      value={currentValue}
      required
      onChange={(e) => {
        const val = Number(e.target.value)
        if (val <= max && val >= min) {
          setCurrentValue(val)
          setCurrentFilterError(undefined)
        } else {
          setCurrentValue(val)
          setCurrentFilterError(error)
        }
      }}
    />
  )
}
