import { PROJECT_CREATE } from './types'
import type { ProjectCreateAction } from './types'

export const createProject = (body: {
  name: string
  datasetId: string
}): ProjectCreateAction => ({
  type: PROJECT_CREATE,
  payload: body,
})
