/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import ArrayRule from './ArrayRule'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import type { FieldError, OnFieldError } from '../../../form/types'

interface Props {
  rules: any[]
  onChange: (val: any) => void
  onDelete?: () => void
  lastChild: boolean
  firstChild: boolean
  items: FieldFactoryProps
  error?: FieldError
  onError?: OnFieldError
  datasetDictionary?: DictionaryContent | null
  appId: string
}

const ArrayRules = ({
  firstChild,
  lastChild,
  rules,
  onChange,
  items,
  datasetDictionary,
  error,
  onError,
  appId,
}: Props): React.JSX.Element => {
  const onDeleteRule = (i: number) => () => {
    onChange(rules.filter((_r, j: number) => i !== j))
    onError?.((oldError) =>
      (oldError as FieldError[]).filter((_e: any, j: number) => j !== i),
    )
  }
  const onRowChange = (i: number) => (val: any) => {
    onChange(rules.map((r: any, j: number) => (j === i ? val : r)))
  }
  const onRowError = (i: number) => (errorFn: any) => {
    const newSubError = errorFn((error as { [label: string]: FieldError })?.[i])
    return onError?.((oldError) => {
      return oldError !== undefined
        ? ((oldError as FieldError[]).map((e: any, j: number) =>
            j === i ? newSubError : e,
          ) as FieldError)
        : undefined
    })
  }

  return (
    <>
      {rules.map((rule, i) => (
        <div key={i} style={{ position: 'relative' }}>
          <ArrayRule
            firstChild={firstChild && i === 0}
            lastChild={lastChild && rules.length - 1 === i}
            onDelete={onDeleteRule(i)}
            onChange={onRowChange(i)}
            value={rule}
            items={items}
            datasetDictionary={datasetDictionary}
            error={(error as { [label: string]: FieldError[] })?.[i]}
            onError={onRowError(i)}
            appId={appId}
          />
        </div>
      ))}
    </>
  )
}

export default ArrayRules
