import { useQuery } from '@tanstack/react-query'
import Menu from '@amalfi-analytics/ui/components/Menu'
import LoadingSpinner from '@amalfi-analytics/ui/components/LoadingSpinner'

import { getUserApplications, http } from '@/api/lib/http'
import { getStore, getStoreId } from '@/api/providers/store'
import { useEffect, useState } from 'react'
import { RegularClockRotateLeftIcon } from '@/ui/components/icons'
import { Project } from '@/api/providers/project'
import {
  isToday,
  isYesterday,
  differenceInDays,
  format,
  isThisYear,
} from 'date-fns'
import { useSelector } from 'react-redux'
import { AppState } from '@/store/types'
import { getStoreAndAppByProjectId } from './analysisUtils'

export interface GraphState {
  id: string
  user_question: string
  remaining_steps: number
  project_id: string
  dataset_alias: string
  next_node: string
  title: string
  created_at: string
}

function getRelativeDate(date: Date): string {
  if (isToday(date)) return 'Today'
  if (isYesterday(date)) return 'Yesterday'

  const daysDiff = differenceInDays(new Date(), date)

  if (daysDiff <= 7) return 'Last 7 days'
  if (daysDiff <= 30) return 'Last 30 days'

  if (isThisYear(date)) {
    return format(date, 'MMMM') // Returns month name
  }

  return format(date, 'yyyy') // Returns year
}

const getProjects = async (): Promise<
  {
    appId: string
    appName: string
    storeId: string
    projects: Project[]
  }[]
> => {
  const apps = await getUserApplications()
  const projects: {
    appId: string
    appName: string
    storeId: string
    projects: Project[]
  }[] = []

  for (const app of apps) {
    const { store } = await getStore(app.id)
    const storeId = getStoreId(app.id)
    const projectsForApp = store.folders.filter(
      (folder) => folder.datasetId,
    ) as Project[]
    if (projectsForApp.length > 0) {
      projects.push({
        appId: app.id,
        appName: app.name,
        storeId: storeId ?? '',
        projects: projectsForApp,
      })
    }
  }
  return projects
}

const getHistoryByProjectId = async (
  appId: string,
  storeId: string,
  projectId: string,
): Promise<GraphState[]> => {
  const url = `/v3/case-mix-store/${storeId}/project/${projectId}/graph-states`
  const response = await (await http(appId)).get(url)
  return response.data
}

const getHistory = async (): Promise<Record<string, GraphState[]>> => {
  const projects = await getProjects()
  const historyByDate: Record<string, GraphState[]> = {}

  for (const { appId, storeId, projects: projectsForApp } of projects) {
    const settledResults = await Promise.allSettled(
      projectsForApp.map((project) =>
        getHistoryByProjectId(appId, storeId, project.id),
      ),
    )

    const appHistory = settledResults
      .filter(
        (result): result is PromiseFulfilledResult<GraphState[]> =>
          result.status === 'fulfilled',
      )
      .flatMap((result) => result.value)

    // Group by date
    appHistory.forEach((item) => {
      const dateGroup = getRelativeDate(new Date(item.created_at))
      if (!historyByDate[dateGroup]) {
        historyByDate[dateGroup] = []
      }
      historyByDate[dateGroup].push(item)
    })
  }

  // Sort items within each group by created_at
  Object.keys(historyByDate).forEach((group) => {
    historyByDate[group].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )
  })

  // Define group priority and sorting
  const fixedGroupOrder = ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days']
  const orderedGroups: Record<string, GraphState[]> = {}

  // Helper function to get group priority for sorting
  const getGroupPriority = (group: string): number => {
    const fixedIndex = fixedGroupOrder.indexOf(group)
    if (fixedIndex !== -1) return fixedIndex

    // For months and years, use their date value for sorting
    const now = new Date()
    if (group.match(/^\d{4}$/)) {
      // Year format (e.g., "2024")
      return 1000 + (now.getFullYear() - parseInt(group))
    }
    // Month format (e.g., "March")
    const monthIndex = new Date(`${group} 1`).getMonth()
    return 100 + ((12 + now.getMonth() - monthIndex) % 12)
  }

  // Sort all groups
  const sortedGroups = Object.keys(historyByDate).sort(
    (a, b) => getGroupPriority(a) - getGroupPriority(b),
  )

  // Create final ordered object
  sortedGroups.forEach((group) => {
    orderedGroups[group] = historyByDate[group]
  })

  return orderedGroups
}

export interface GraphLogs {
  id: string
  created_at: string
  updated_at: string
  node: string
  log: string
  graph_state_id: string
}

const getLogs = async (
  projectId: string,
  graphStateId?: string,
): Promise<GraphLogs[]> => {
  const result = await getStoreAndAppByProjectId(projectId)
  if (!result) {
    return []
  }
  const { storeId, appId } = result
  const url = `/v3/case-mix-store/${storeId}/graph-state/${graphStateId}/log`
  const response = await (await http(appId)).get(url)
  return response.data
}

export interface HistoryMenuProps {
  projectId?: string
  onGraphStateSelect: (graphState: GraphState, logs: GraphLogs[]) => void
}

const HistoryMenu = ({ onGraphStateSelect }: HistoryMenuProps) => {
  const menu = useSelector((state: AppState) => state.menu)
  const projectIds = menu.items
    .filter((item) => item.type === 'project')
    .map((item) => item.id)
    .sort()

  const { data: history, isLoading: historyLoading } = useQuery({
    queryKey: ['history', ...projectIds],
    queryFn: () => getHistory(),
  })

  const [selectedGraphState, setSelectedGraphState] = useState<
    GraphState | undefined
  >()
  const { data: logs } = useQuery({
    queryKey: ['logs', selectedGraphState],
    queryFn: () =>
      getLogs(selectedGraphState?.project_id ?? '', selectedGraphState?.id),
    enabled: !!selectedGraphState,
  })

  useEffect(() => {
    if (selectedGraphState && logs) {
      onGraphStateSelect(selectedGraphState, logs)
    }
  }, [selectedGraphState, logs])
  const sections = Object.entries(history ?? {}).map(
    ([appName, appHistory]) => ({
      name: appName,
      items: appHistory.map((h) => ({
        id: h.id,
        label: h.title,
      })),
    }),
  )

  return (
    <Menu
      placeholder="History"
      sections={sections}
      onSelect={({ id }) => {
        const selectedGraphState = Object.values(history ?? {})
          .flat()
          .find((h: GraphState) => h.id === id)
        setSelectedGraphState(selectedGraphState)
      }}
      icon={
        historyLoading ? <LoadingSpinner /> : <RegularClockRotateLeftIcon />
      }
      className="inline-flex"
    />
  )
}

export default HistoryMenu
