import React from 'react'
import { useAsync } from 'react-use'
import { useTranslation } from 'react-i18next'
import { getDataset } from '../../../../api/providers'
import type { Project } from '../../../../api/providers/project'
import Row from './Row'
import { getStoreByFolderId } from '../../../../api/providers/store'
import _ from 'lodash'
import { getCreatedAtFromStoreFolder } from './utils'

interface Props {
  projectId: string
  expanded?: boolean
  onSelect?: (id?: string) => void
}

const ProjectRow = ({
  projectId,
  expanded,
  onSelect,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()

  // loads initial data
  const state = useAsync(async () => {
    const store = await getStoreByFolderId(projectId)
    if (store === undefined) {
      throw new Error('Project not found')
    }
    const project = store.folders.find(
      (x) => !_.has(x, 'folderId') && x.id === projectId,
    ) as Project | undefined
    if (project === undefined) {
      throw new Error('Project not found')
    }
    const [dataset, createdAt] = await Promise.all([
      getDataset(project.datasetId),
      getCreatedAtFromStoreFolder(projectId, store),
    ])
    return { project, dataset, createdAt }
  }, [projectId])
  if (state.value === undefined) {
    return <></>
  }
  const { project, dataset, createdAt } = state.value

  return (
    <Row
      title={project.name}
      expanded={expanded}
      onChange={(expanded) => {
        onSelect?.(expanded ? project.id : undefined)
      }}
      values={[{ label: t('experimentDetails.dataset'), value: dataset?.name }]}
      createdAt={createdAt}
    />
  )
}

export default ProjectRow
