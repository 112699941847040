export const FILTER_CREATE = 'FILTER_CREATE'

export interface FilterCreateAction {
  type: typeof FILTER_CREATE
  payload: {
    name: string
    folderId: string
    pipelineId: string
    values: {
      [moduleId: string]: {
        [fieldName: string]: unknown
      }
    }
  }
}
