import { getDataset, getProject, getRootFolder } from '../providers'
import type { Field } from '../providers'

import { getStore, updateStore } from './store'

export interface UserVariable {
  name: string
  model_id: string
  type: Field
  project_id: string
}

export const createUserVariable = async (
  folderId: string,
  modelId: string,
  name: string,
  type: Field,
): Promise<void> => {
  const root = await getRootFolder(folderId)
  const dataset = await getDataset(root.datasetId)
  const { store } = await getStore(dataset.app_id)
  const userVariables = store.userVariables
  const projectId = root.id
  await updateStore(
    {
      ...store,
      // The primary key for the user variables is the modelId.
      userVariables: [
        ...userVariables.filter((u) => u.model_id !== modelId),
        { model_id: modelId, name, type, project_id: projectId },
      ],
    },
    dataset.app_id,
  )
}

export const getUserVariables = async (
  projectId: string,
): Promise<UserVariable[]> => {
  const project = await getProject(projectId)
  const dataset = await getDataset(project.datasetId)
  const { store } = await getStore(dataset.app_id)
  const userVariables = store.userVariables.map((userVariable) => ({
    // @ts-expect-error legacy. Some userVariables could be like this when migrating from mongo.
    model_id: userVariable.modelId,
    // @ts-expect-error legacy. Some userVariables could be like this when migrating from mong.
    project_id: userVariable.projectId,
    ...userVariable,
  }))
  return userVariables.filter(
    (userVariable) => userVariable.project_id === projectId,
  )
}
