import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import { getRootFolder } from '../../../../api/providers/folder'
import { getParentFilters } from '../../../../api/providers/filter'
import ProjectRow from '../row/ProjectRow'
import FilterRow from '../row/FilterRow'

interface Props {
  filterId: string
}

const FilterDetails = ({ filterId }: Props): React.JSX.Element => {
  const [selectedId, setSelectedId] = useState<string | undefined>()

  // loads initial data
  const state = useAsync(async () => {
    const project = await getRootFolder(filterId)
    const filters = await getParentFilters(filterId)

    return { project, filters }
  }, [filterId])
  const project = state.value?.project
  const filters = state.value?.filters ?? []

  useEffect(() => {
    setSelectedId(filterId)
  }, [filterId])

  return (
    <>
      <>
        {project !== undefined && (
          <ProjectRow
            projectId={project.id}
            expanded={project.id === selectedId}
            onSelect={setSelectedId}
          />
        )}
        {filters.map((filter, i) => (
          <FilterRow
            key={i}
            filterId={filter.id}
            expanded={filter.id === selectedId}
            onSelect={setSelectedId}
          />
        ))}
      </>
    </>
  )
}

export default FilterDetails
