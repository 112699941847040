import { map, mapValues, keyBy, some } from 'lodash'
import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import {
  getExperiment,
  getRootFolder,
  getFolderExperiments,
  getExperimentPipelines,
  getPipelineFields,
  getParentFolders,
  getDataset,
} from '../../../../../api/providers'
import { getDatasetDictionary } from '../../../../../api/providers/dictionary'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import { createExperiment, closeMenu } from '../../../../../store/actions'
import FieldFactory from '../../../form/FieldFactory'
import TextField from '../../../form/fields/TextField'
import { newAvailName, isEmpty } from '../../../../lib/util'
import ModalDialog from '../../../dialogs/ModalDialog'
import type { FieldError } from '../../../form/types'

interface Props {
  createExperiment: typeof createExperiment
}

const CopyExperimentPage = ({ createExperiment }: Props): React.JSX.Element => {
  const match = useMatch('/copy-experiment/:folderId/:experimentId')
  const navigate = useNavigate()
  const experimentId = match?.params.experimentId as string
  const folderId = match?.params.folderId as string
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [fieldError, setFieldError] = useState<FieldError>({})
  const [name, setName] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [pipelineId, setPipelineId] = useState('')
  const [values, setValues] = useState<{
    [moduleId: string]: { [fieldName: string]: unknown }
  }>({})
  const [datasetDictionary, setDatasetDictionary]
    = useState<DictionaryContent | null>(null)

  // loads initial data
  const state = useAsync(async () => {
    const [experiment, project, experimentNames, path] = await Promise.all([
      getExperiment(experimentId, folderId),
      getRootFolder(folderId),
      getFolderExperiments(folderId).then((experiments) =>
        map(experiments, 'name'),
      ),
      getParentFolders(folderId).then((folders) => map(folders, 'name')),
    ])

    const dictionary = await getDatasetDictionary([project])
    const pipelines = await getExperimentPipelines(project.datasetId)
    const dataset = await getDataset(project.datasetId)

    setDatasetDictionary(dictionary)
    setPlaceholder(newAvailName(t('common.experiment'), experimentNames))
    setPipelineId(experiment.pipelineId)

    return {
      experiment,
      datasetId: project.datasetId,
      appId: dataset.app_id,
      pipelines,
      experimentNames,
      path,
    }
  }, [experimentId, folderId])
  if (state.error !== undefined) {
    console.error('copy experiment state error', state.error)
  }
  const stateError
    = state.error !== undefined ? t('messages.networkError') : undefined
  const experiment = state.value?.experiment
  const datasetId = state.value?.datasetId ?? ''
  const appId = state.value?.appId
  const path = state.value?.path ?? []

  // loads pipeline fields
  const fieldsState = useAsync(async () => {
    const fieldsGroup
      = datasetId.length > 0 && pipelineId.length > 0
        ? await getPipelineFields(datasetId, pipelineId)
        : {}
    const defValues = mapValues(fieldsGroup, (fields) => {
      return mapValues(
        keyBy(fields, (x) => x.name),
        (x) => x.default,
      )
    })
    const values
      = experiment !== undefined && experiment.pipelineId === pipelineId
        ? experiment.values
        : defValues

    setValues(values)
    return fieldsGroup
  }, [datasetId, pipelineId, experiment])
  const fieldsGroup = fieldsState.value ?? {}

  const onFieldChange
    = (moduleId: string, fieldName: string) => (value?: unknown) => {
      const newFieldValues = { ...values }
      newFieldValues[moduleId][fieldName] = value

      setValues(newFieldValues)
    }

  const onCancel = (): void => {
    navigate('/')
  }

  const onSubmit = (): void => {
    const requiredFields = some(fieldsGroup, (fields, moduleId) =>
      fields.some(
        ({ required, name }) => required && isEmpty(values[moduleId][name]),
      ),
    )
    if (requiredFields || pipelineId.length === 0) {
      const formRequiredFields = t('form.requiredFields')
      setError(formRequiredFields)
      return
    }
    for (const mod in values) {
      for (const f in values[mod]) {
        if (f === '$subpopulation') {
          const v = values[mod].$subpopulation
          values[mod]._subpopulation = v
        }
      }
      delete values[mod].$subpopulation
    }
    createExperiment({
      name: name.length > 0 ? name : placeholder,
      folderId,
      pipelineId,
      values,
    })
    closeMenu()
    navigate('/')
  }
  const copyExperimentDialogName = t('copyExperimentDialog.name')
  return (
    <ModalDialog
      title={t('copyExperimentDialog.title')}
      path={path}
      error={stateError ?? error}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.continue'),
          disabled: state.loading || Object.keys(fieldError ?? {}).length > 0,
          type: 'submit',
          onClick: onSubmit,
        },
      ]}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
          onSubmit()
        }
      }}
    >
      <FormGroup>
        <TextField
          label={copyExperimentDialogName}
          placeholder={placeholder}
          value={name}
          onChange={setName}
        />
        {!state.loading && appId !== undefined && (
          <>
            {map(fieldsGroup, (fields, moduleId) =>
              fields.map(({ name, ...rest }, i) => {
                const modValues = values[moduleId] ?? {}
                const value = modValues[name]

                return (
                  <FieldFactory
                    key={`${moduleId}:${i}`}
                    prefix=""
                    label={name}
                    value={value}
                    error={fieldError}
                    onError={setFieldError}
                    onChange={onFieldChange(moduleId, name)}
                    datasetDictionary={datasetDictionary}
                    parentFolderId={folderId}
                    appId={appId}
                    {...rest}
                  />
                )
              }),
            )}
          </>
        )}
      </FormGroup>
    </ModalDialog>
  )
}

const mapDispatchToProps = { createExperiment }

export default connect(null, mapDispatchToProps)(CopyExperimentPage)
