import { API_PATH_SERVICES, LLM_URL } from '@/constants/env'
import { getSoil } from '../lib/http'
import { getStoreByFolderId, getStoreId } from './store'
import i18n from 'i18next'

// Helper type for the generator function
export type StreamGenerator = AsyncGenerator<string, void, unknown>

export interface AnalysisStreamParams {
  question: string
  projectId: string
}

// Define pipeline types for the runAction function
export type PipelineType =
  | 'Dataset'
  | 'Assessment Filter'
  | 'Episodes Filter'
  | 'Patients Filter'
  | 'User Variable'
  | 'Associations Graph'
  | 'Clustering'
  | 'Compare Graph'

export interface Pipeline {
  id: string // experiment id
  name: string
  type: PipelineType
}

export interface ActionStreamParams {
  graph_state_id: string
  parent_subset_id: string | null
  subset_id: string | null
  pipeline: Pipeline
}

export interface StepStreamParams {
  graph_state_id: string
  action: {
    parent_subset_id: string | null
    subset_id: string | null
    pipeline: Pipeline
  } | null
  projectId: string
}

export interface ReportStreamParams {
  graph_state_id: string
  projectId: string
}

/**
 * Creates a streaming generator with common streaming logic
 */
async function createStreamGenerator(
  appId: string,
  endpoint: string,
  requestBody: Record<string, unknown>,
  externalController: AbortController,
): Promise<StreamGenerator> {
  // Get the base URL
  let baseURL = LLM_URL
  if (!baseURL) {
    const soilUrl = await getSoil(appId)
    baseURL = `${soilUrl}${API_PATH_SERVICES}`
  }

  const controller = externalController //|| new AbortController()

  // Create a custom AsyncGenerator that processes chunks immediately
  async function* streamGenerator(): StreamGenerator {
    try {
      // Create headers with appropriate streaming directives
      const headers = {
        'Application-Id': appId,
        'Content-Type': 'application/json',
        Accept: 'text/event-stream', // Indicate we want streaming events
      }

      // Make the fetch request with the proper streaming options
      const response = await fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
        credentials: 'include',
        signal: controller.signal,
      })

      // Check if the response is valid
      if (!response.ok) {
        throw new Error(
          `Response error: ${response.status} ${response.statusText}`,
        )
      }

      // Process the stream chunks as they arrive
      const responseBody = response.body
      if (responseBody === null) {
        throw new Error('Response body is null')
      }

      const reader = responseBody.getReader()
      const decoder = new TextDecoder()

      try {
        while (true) {
          const { done, value } = await reader.read()

          // If the stream is done, exit the loop
          if (done) break

          // Decode the chunk and yield it immediately
          const chunk = decoder.decode(value, { stream: true })
          if (chunk !== '') {
            yield chunk
          }
        }

        // Final decode to handle any remaining bytes
        const finalChunk = decoder.decode()
        if (finalChunk !== '') {
          yield finalChunk
        }
      } finally {
        reader.releaseLock()
      }
    } catch (error) {
      // If it's an abort error, just propagate it with a clearer format
      if (
        error instanceof Error &&
        (error.name === 'AbortError' || error.message.includes('abort'))
      ) {
        console.debug('Stream generator aborted:', error.message)
        // Rethrow as a standard AbortError for consistent handling
        const abortError = new DOMException('Stream was aborted', 'AbortError')
        throw abortError
      }
      // For other errors, log and propagate normally
      console.error('Error in stream generator:', error)
      throw error
    }
  }

  // Create the actual generator instance
  const generator = streamGenerator()

  // Attach the abort controller to the generator for cleanup
  Object.defineProperty(generator, 'abort', {
    value: () => {
      controller.abort()
    },
    enumerable: true,
  })

  return generator
}

/**
 * Creates a stream generator for the new analysis endpoint
 */
export async function createAnalysisStream(
  { question, projectId }: AnalysisStreamParams,
  controller: AbortController,
): Promise<StreamGenerator> {
  // Validate inputs
  if (question === '' || projectId === '') {
    throw new Error('Question and projectId are required')
  }

  // Get the necessary store information
  const ret = await getStoreByFolderId(projectId, true)
  if (ret === undefined) {
    throw new Error('Store not found')
  }
  const { appId } = ret

  return createStreamGenerator(
    appId,
    '/agent/analysis',
    {
      question,
      language: i18n.language,
      project_id: projectId,
      case_mix_store_id: getStoreId(appId),
    },
    controller,
  )
}

/**
 * Creates a stream generator for the agent/step endpoint
 */
export async function createStepStream(
  { graph_state_id, action, projectId }: StepStreamParams,
  controller: AbortController,
): Promise<StreamGenerator> {
  // Validate inputs
  if (graph_state_id === '') {
    throw new Error('graph_state_id is required')
  }

  // Get the necessary store information
  const ret = await getStoreByFolderId(projectId, true)
  if (ret === undefined) {
    throw new Error('Store not found')
  }
  const { appId } = ret

  return createStreamGenerator(
    appId,
    '/agent/step',
    {
      case_mix_store_id: getStoreId(appId),
      graph_state_id,
      action,
      language: i18n.language,
    },
    controller,
  )
}

/**
 * Creates a stream generator for the agent/report endpoint
 */
export async function createReportStream(
  { graph_state_id, projectId }: ReportStreamParams,
  controller: AbortController,
): Promise<StreamGenerator> {
  // Validate inputs
  if (graph_state_id === '') {
    throw new Error('graph_state_id is required')
  }

  // Get the necessary store information
  const ret = await getStoreByFolderId(projectId, true)
  if (ret === undefined) {
    throw new Error('Store not found')
  }
  const { appId } = ret

  return createStreamGenerator(
    appId,
    '/agent/report',
    {
      case_mix_store_id: getStoreId(appId),
      graph_state_id,
      language: i18n.language,
    },
    controller,
  )
}
