import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import LinearProgress from '@mui/material/LinearProgress'

interface Props {
  hidden: boolean
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: -theme.spacing(1) / 2,
    },
    hidden: {
      visibility: 'hidden',
    },
  }
})

const Loading: React.FC<Props> = ({ hidden }: Props) => {
  const classes = useStyles()

  return (
    <LinearProgress
      className={clsx(classes.root, { [classes.hidden]: hidden })}
      color="secondary"
    />
  )
}

export default Loading
