import React from 'react'
import clsx from 'clsx'
import { omit } from 'lodash'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import type { Theme } from '@mui/material/styles'
import type { StyleRules, WithStyles } from '@mui/styles'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { AutoSizer, Column, Table, InfiniteLoader } from 'react-virtualized'
import type { TableCellRenderer, TableHeaderProps } from 'react-virtualized'
declare module '@mui/material/styles/withStyles' {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean
  }
}

const styles = (
  theme: Theme,
): StyleRules<
  object,
  | 'flexContainer'
  | 'table'
  | 'tableRow'
  | 'tableRowHover'
  | 'tableCell'
  | 'noClick'
  | 'tableHeader'
> =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
        flip: false,
        paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      },
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    noClick: {
      cursor: 'initial',
    },
    tableHeader: {
      fontWeight: 'bold',
    },
  })

interface ColumnData {
  dataKey: string
  label: string
  numeric?: boolean
  width: number
}

interface Row {
  index: number
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[]
  headerHeight?: number
  onRowClick?: () => void
  rowCount: number
  rowGetter: (row: Row) => { [id: string]: string | number }
  rowHeight?: number
  loadMoreRows: ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number
    stopIndex: number
  }) => Promise<void>
  isRowLoaded: ({ index }: { index: number }) => boolean
  highlight: string
  sortBy: number
  sortDirection: 'asc' | 'desc'
  setSort: (index: number) => void
}

function getHighlightedText(
  text: string | number,
  highlight: string,
): string | number | React.JSX.Element {
  // Split on highlight term and include term into parts, ignore case
  if (typeof text !== 'string' || text.length === 0 || highlight.length === 0) {
    return text
  }
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  )
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  }

  getRowClassName = ({ index }: Row): string => {
    const { classes, onRowClick } = this.props

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    })
  }

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick, highlight } = this.props
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          ((columnIndex !== null && columns[columnIndex].numeric) ?? false)
            ? 'right'
            : 'left'
        }
      >
        {getHighlightedText(cellData, highlight)}
      </TableCell>
    )
  }

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }): React.JSX.Element => {
    const { headerHeight, columns, classes, sortDirection, sortBy, setSort } =
      this.props
    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.tableHeader,
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={(columns[columnIndex].numeric ?? false) ? 'right' : 'left'}
      >
        <TableSortLabel
          active={sortBy === columnIndex}
          direction={sortDirection}
          onClick={() => {
            setSort(columnIndex)
          }}
          hideSortIcon
        >
          <>{label}</>
        </TableSortLabel>
      </TableCell>
    )
  }

  render(): React.JSX.Element {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      loadMoreRows,
      isRowLoaded,
      ...tableProps
    } = this.props
    const tProps = omit(tableProps, ['sortBy', 'sortDirection'])
    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={tableProps.rowCount}
        minimumBatchSize={50}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <Table
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                height={height}
                width={width}
                rowHeight={rowHeight ?? 0}
                gridStyle={{
                  direction: 'inherit',
                }}
                headerHeight={headerHeight ?? 0}
                className={classes.table}
                {...tProps}
                rowClassName={this.getRowClassName}
              >
                {columns.map(({ dataKey, ...other }, index) => {
                  return (
                    <Column
                      key={dataKey}
                      headerRenderer={(headerProps) =>
                        this.headerRenderer({
                          ...headerProps,
                          columnIndex: index,
                        })
                      }
                      className={classes.flexContainer}
                      cellRenderer={this.cellRenderer}
                      dataKey={dataKey}
                      flexGrow={1}
                      {...other}
                    />
                  )
                })}
              </Table>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

interface Props {
  rowCount: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowGetter: ({ index }: { index: number }) => any
  loadMoreRows: ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number
    stopIndex: number
  }) => Promise<void>
  isRowLoaded: ({ index }: { index: number }) => boolean
  columns: Array<{
    width: number
    label: string
    dataKey: string
    numeric: boolean
  }>
  highlight: string
  sortBy: number
  sortDirection: 'asc' | 'desc'
  setSort: (index: number) => void
}

function ReactVirtualizedTable({
  rowCount,
  rowGetter,
  columns,
  isRowLoaded,
  loadMoreRows,
  highlight,
  sortDirection,
  sortBy,
  setSort,
}: Props): React.JSX.Element {
  return (
    <Paper style={{ flexGrow: 1 }}>
      <VirtualizedTable
        rowCount={rowCount}
        rowGetter={rowGetter}
        columns={columns}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        highlight={highlight}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
      />
    </Paper>
  )
}

export default ReactVirtualizedTable
