import React from 'react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import type { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SubmitButton from '../../buttons/SubmitButton'
import type { Extend } from '../../../lib/types'

type Props = Extend<
  DialogProps,
  {
    title: ReactNode
    message: ReactNode
    loading?: boolean
    acceptLabel?: ReactNode
    cancelLabel?: ReactNode
    onCancel: () => void
    onAccept: () => void
  }
>

const ConfirmDialog = ({
  open,
  title,
  message,
  loading,
  cancelLabel,
  acceptLabel,
  onCancel,
  onAccept,
  ...rest
}: Props): React.JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} {...rest} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel ?? t('button.cancel')}</Button>
        <SubmitButton disabled={loading} color="primary" onClick={onAccept}>
          {acceptLabel ?? t('button.accept')}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
