import React from 'react'
import { useMount, useMap } from 'react-use'

import { getUserApplications } from '@/api/lib/http'
import { Dataset, getDatasets } from '@/api/providers/dataset'
import Menu from '@amalfi-analytics/ui/components/Menu'
import { APPLICATION_ID } from '@/constants/env'

export const useDatasetSelector = (): {
  datasets: { [appName: string]: Dataset[] }
} => {
  const [datasets, { set: setDatasets }] = useMap<{
    [appName: string]: Dataset[]
  }>()
  useMount(() => {
    getUserApplications()
      .then((userApplications) => {
        userApplications.forEach((userApplication) => {
          if (userApplication.id === APPLICATION_ID) {
            return
          }
          getDatasets(userApplication.id)
            .then((appDatasets) => {
              const name = userApplication.name.replace('-production', '')
              if (appDatasets && appDatasets.length > 0) {
                setDatasets(name, appDatasets)
              }
            })
            .catch((error) => {
              console.error('getDatasets error', error)
            })
        })
      })
      .catch((error) => {
        console.error('Error loading project page', error)
      })
  })
  return { datasets }
}

export const DatasetSelector = ({
  value,
  onChange,
}: {
  value: { id: string; label: string } | undefined
  onChange: (value: { id: string; label: string }) => void
}): React.JSX.Element => {
  const { datasets } = useDatasetSelector()
  return (
    <Menu
      value={value}
      sections={Object.entries(datasets).map(([appName, appDatasets]) => ({
        name: appName,
        items: appDatasets
          .filter((dataset) => dataset.alias)
          .map((dataset) => ({
            id: dataset.alias ?? dataset.id,
            label: dataset.name,
          })),
      }))}
      onSelect={(selected) => {
        onChange(selected)
      }}
      placeholder="Select a dataset"
      className="inline-flex"
    />
  )
}
