import React, { useState } from 'react'
import _, { sortBy } from 'lodash'
import { useMount, useAsync } from 'react-use'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { getDictionary } from '../../../../../api/providers'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import { getShortLang } from '../../../../lib/i18n'
import FieldWrapper from '../../../form/FieldWrapper'
import SelectField from '../../../form/fields/SelectField'
import MultiSelectField from '../../../form/fields/MultiSelectField'
import LargeDictionaryStringField from './LargeDictionaryStringField'

type Value =
  | { eql: string }
  | { regexp: string }
  | { in: string[] }
  | { gte: string; lte: string }
  | string
  | undefined

type Props = Extend<
  FieldProps<Value>,
  { dictionaryId: string; operations: string[] }
>

const DictionaryStringField = ({
  required,
  label,
  value: initValue,
  dictionaryId,
  operations,
  onChange,
  datasetDictionary,
  error,
  onError,
  advanced,
  fullName,
  appId,
}: Props): React.JSX.Element => {
  const { i18n } = useTranslation()
  const [originalValue] = useState(initValue)
  const numOps = operations.length

  // loads the dictionary
  const state = useAsync(
    async () =>
      await getDictionary(dictionaryId, getShortLang(i18n.language), appId),
    [dictionaryId, i18n.language],
  )
  const dictionary = state.value ?? { list: {} }

  useMount(() => {
    getDictionary(dictionaryId, getShortLang(i18n.language), appId).then(
      () => {},
      () => {},
    )
  })

  // parse dictionary
  const keys = _.keys(dictionary?.list)
  const values = _.values(dictionary?.list)
  const options = keys.map((key, i) => ({ value: key, label: values[i] }))

  // If there are more than 10 keys use unbounded string with extra validation
  if (keys.length > 200) {
    return (
      <LargeDictionaryStringField
        appId={appId}
        required={required}
        label={label}
        fullName={fullName}
        value={initValue}
        dictionaryKeys={keys}
        operations={operations}
        error={error}
        onError={onError}
        onChange={onChange}
        datasetDictionary={datasetDictionary}
        advanced={advanced}
      />
    )
  }

  // parse initValue
  const defaultValue = _.includes(operations, 'eql') ? { eql: '' } : { in: [] }
  const value = initValue ?? defaultValue

  // parse value
  const operation = _.keys(value)[0]

  const getTexts = (
    value:
      | string
      | { eql: string }
      | { regexp: string }
      | { in: string[] }
      | { gte: string; lte: string },
  ): string[] => {
    if (_.isString(value)) {
      return [value]
    }
    if (operation === 'eql') {
      if ('eql' in value) {
        return [value.eql]
      }
    }
    if ('in' in value) {
      return value.in
    }
    throw new Error(`Error in getText value=${JSON.stringify(value)}`)
  }

  const texts = getTexts(value)

  const onReset = (): void => {
    onChange?.(originalValue)
  }
  const onClear = (): void => {
    onChange?.(undefined)
  }

  const vlabel
    = datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
  return (
    <FieldWrapper
      advanced={advanced}
      required={required}
      label={vlabel}
      disabled={state.loading}
      onReset={onReset}
      onClear={onClear}
    >
      <Grid container spacing={1}>
        {numOps > 1 && (
          <Grid item xs={3}>
            <SelectField
              appId={appId}
              value={operation}
              options={operations.map((item) => ({ value: item, label: item }))}
              onChange={(v) =>
                onChange?.(
                  v === 'eql' ? { eql: texts[0] ?? '' } : { in: texts },
                )
              }
            />
          </Grid>
        )}
        <Grid item xs={numOps > 1 ? 9 : 12}>
          {operation === 'eql' ? (
            <SelectField
              appId={appId}
              value={texts[0] ?? ''}
              options={sortBy(options, 'label')}
              onChange={(v) => {
                onChange?.({ eql: v })
              }}
            />
          ) : (
            <MultiSelectField
              appId={appId}
              fullWidth
              value={texts}
              options={sortBy(options, 'label')}
              onChange={(v) => onChange?.({ in: v })}
            />
          )}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default DictionaryStringField
