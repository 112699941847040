import React, { useContext, useEffect, useState } from 'react'
import {
  reverse,
  round,
  filter,
  orderBy,
  max,
  groupBy,
  clone,
  mapValues,
  keys,
} from 'lodash'
import { useDictionaries } from '../hooks/useDictionary'
import ClusteringHeatmap from './ClusteringHeatmap'
import type { HeatmapData } from './ClusteringHeatmap'
import { clusteringResultSchema, type ClusteringResult } from './types'
import { PlusCircleIcon, ListOlIcon } from '../../../icons'
import SpeedFilterDial from '../../../buttons/SpeedFilterDialog'
import type { FilterType } from '../../../buttons/SpeedFilterDialog'
import {
  CreateVariableFilter,
  NumberFilter,
} from '../AssociationsGraphWidget/Filters'
import type { CheckboxState } from '../AssociationsGraphWidget/CheckboxesGroup'
import { useCreateUserVariable } from '../hooks/useUserVariable'
import { useTranslation } from 'react-i18next'
import type { WidgetProps } from '../types'
import Loading from '../shared/Loading'
import MessageComponent from '../AssociationsGraphWidget/MessageComponent'
import { useValidatedData } from '../hooks/useData'
import { ExportDataContext } from '../../../../components/widget/WidgetFactory/ExportDataContext'
import Grid from '@mui/material/Grid'
import LegacyClusteringTable from './LegacyClusteringTable'
import ClusteringTable from './ClusteringTable'
import { type StatisticsValue } from '../StatisticsWidget/types'
import { DEPRECATED_FEATURES } from '../../../../../constants/env'

const CLUSTERS_BREAK_POINT = 1300
const CLUSTERS_MIN_WIDTH = 600
const ROW_MIN_PROBABILITY = 0
const NUM_ROWS = 15
const BREAK_CLUSTERS = 6

interface DictionaryT2 {
  list: { [key: string]: string }
}

interface DictionariesT {
  [key: string]: DictionaryT2
}

interface ClusteringMetadata {
  encoding_dict: { [variable: string]: string[] }
  count_variables: string[]
  supplementary_variables?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statistics_operations?: any[]
}

const getDictVal = (
  dicts: DictionariesT,
  label: string,
  encodingDict: { [key: string]: string[] },
): string => {
  const [rest, code] = label.split('=')
  if (!(rest in encodingDict)) {
    return code
  }
  const dict = encodingDict[rest][0]
  if (dicts?.[dict]?.list[code] === undefined) {
    return code
  }
  return `${code ?? rest} ${dicts[dict]?.list[code] ?? ''}`
}

const getItemType = (label: string): string => {
  const parts = label.split('=')
  if (parts.length > 0) {
    return parts[0]
  } else {
    return ''
  }
}

const parseResults = (
  results: ClusteringResult,
  dicts: DictionariesT,
  encodingDict: { [key: string]: string[] },
): {
  z: number[][]
  y: string[]
  x: string[]
  text: string[][]
  types: string[]
  R: number[][]
} => {
  const { M, disease_index: diseaseIndex, R } = results
  return {
    z: reverse(clone(M)),
    y: reverse(diseaseIndex.map((d) => getDictVal(dicts, d, encodingDict))),
    x:
      M.length > 0
        ? Array.from(Array(M[0].length)).map((_i, i) => String(i + 1))
        : [],
    text: reverse(M.map((r) => r.map((v) => `${round(v * 100, 2)}%`))),
    types: reverse(diseaseIndex.map((d) => getItemType(d))),
    R,
  }
}

const buildHeatmapData = (
  x: string[],
  y: string[],
  z: number[][],
  text: string[][],
  types: string[],
): HeatmapData[] => {
  const result: HeatmapData[] = []
  z.forEach((row: number[], rowIndex: number) => {
    row.forEach((value: number, columnIndex: number) => {
      result.push({
        x: x[columnIndex],
        y_title: y[rowIndex].slice(y[rowIndex].indexOf(' ') + 1),
        y: y[rowIndex].slice(0, 30),
        value,
        text: text[rowIndex][columnIndex],
        type: types[rowIndex],
      })
    })
  })
  return result
}

interface StatisticsArgs {
  [id: string]: never
}

const ClusteringWidget = ({
  experimentId,
  folderId,
  dictionary,
  outputs,
  dimensions,
  appId,
}: WidgetProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [data, setData] = useState<{ [group: string]: HeatmapData[] }>({})
  const [initData, setInitData] = useState<ClusteringResult>()
  const [dictionaries, setDictionaries] = useDictionaries({}, appId)
  const [checkboxesState, setCheckboxesState] = useState<CheckboxState[]>([])
  const [filteredHeatmapData, setFilteredHeatmapData] =
    useState<HeatmapData | null>(null)
  const [shouldExecuteFilters, setShouldExecuteFilters] =
    useState<boolean>(false)
  const createUserVariable = useCreateUserVariable()
  const [variableName, setVariableName] = useState('')
  const [numRows, setNumRows] = useState(NUM_ROWS)

  if (outputs.clustering === undefined) {
    throw new Error('Clustering statistics not found.')
  }
  const [{ loading, value, error }] = useValidatedData<
    ClusteringResult,
    ClusteringMetadata,
    StatisticsArgs
  >(outputs.clustering, {}, { data: clusteringResultSchema })
  if (!loading && error !== undefined) throw error
  const numClusters = value?.results.R[0]?.length
  const { onResultIdChange } = useContext(ExportDataContext)
  useEffect(() => {
    onResultIdChange(outputs.clustering)
  }, [outputs.clustering])

  const isChecked = (key: string): boolean => {
    return (
      checkboxesState.find(
        (checkboxState) =>
          checkboxState.checkboxId === key && checkboxState.state,
      ) !== undefined
    )
  }

  const getHeatmapGroups = (
    heatmapData: HeatmapData[],
    numRows: number,
    numClusters: number,
  ): { [x: string]: HeatmapData[] } => {
    const groups = groupBy(heatmapData, (d) => d.type)
    const filteredGroups = mapValues(groups, (g) =>
      g.slice(g.length - numRows * numClusters, g.length),
    )
    return filteredGroups
  }

  const sortData = (
    initData: ClusteringResult,
    sortingCluster: number | null = null,
  ): {
    z: number[][]
    y: string[]
    x: string[]
    text: string[][]
    types: string[]
    R: number[][]
  } => {
    if (sortingCluster === null) {
      sortingCluster = 0
    }
    const MFiltered: number[][] = []
    const diseaseIndexFiltered: string[] = []
    const MM = [...initData.M, ...(initData.M_sup ?? [])]
    const largeDiseaseIndex = [
      ...initData.disease_index,
      ...(initData.disease_index_sup ?? []),
    ]
    for (let i = 0; i < MM.length; ++i) {
      const MValue = MM[i]
      const diseaseIndexValue = largeDiseaseIndex[i]

      const diseaseKey = diseaseIndexValue.substring(
        0,
        diseaseIndexValue.indexOf('='),
      )
      if (
        (checkboxesState.length === 0 || isChecked(diseaseKey)) &&
        MValue.filter((val) => val >= ROW_MIN_PROBABILITY).length > 0
      ) {
        MFiltered.push(MValue)
        diseaseIndexFiltered.push(diseaseIndexValue)
      }
    }

    const relevance = MFiltered.map((r, i) => [
      sortingCluster !== null ? r[sortingCluster] : max(r),
      i,
    ])
    const sortedRelevance = orderBy(relevance, (e) => e[0], 'desc').map(
      (r) => r[1],
    ) as number[]

    return parseResults(
      {
        ...initData,
        M: sortedRelevance.map((r) => MFiltered[r]),
        disease_index: sortedRelevance.map((r) => diseaseIndexFiltered[r]),
      },
      dictionaries,
      value?.metadata.encoding_dict ?? {},
    )
  }

  useEffect(() => {
    if (value !== undefined && !loading) {
      setDictionaries(value.metadata.encoding_dict)
    }
  }, [loading])
  useEffect(() => {
    if (
      value !== undefined &&
      (filter(dictionaries, (d) => d).length > 0 ||
        keys(dictionaries).length === 0)
    ) {
      const { x, y, z, text, types } = sortData(value.results)
      const heatmapData = buildHeatmapData(x, y, z, text, types)
      const heatmapGroups = getHeatmapGroups(heatmapData, numRows, z[0].length)

      if (checkboxesState.length === 0) {
        setCheckboxesState(
          Object.keys(heatmapGroups).map((checkboxId: string) => ({
            checkboxId,
            state: true,
          })),
        )
      }

      setData(heatmapGroups)
      setInitData(value.results)
    }
  }, [value, keys(dictionaries).join(''), numRows, dictionaries.dischargeType])

  useEffect(() => {
    if (
      shouldExecuteFilters &&
      checkboxesState.filter((c) => c.state).length > 0
    ) {
      sortAndSetData?.()
    }
  }, [filteredHeatmapData, checkboxesState])

  if (value === undefined || loading || initData === undefined) {
    return <Loading />
  }
  if (numClusters === undefined) throw new Error('No clusters found.')
  const createClusteringVariable = (
    modelId: string,
    name: string,
    min: number,
    max: number,
  ): void => {
    createUserVariable(folderId, modelId, name, {
      name,
      min,
      max,
      type: 'number',
      required: true,
      operations: ['in'],
    })
  }

  const filterList: FilterType[] = [
    {
      name: t('filters.name.CreateVariableFilter'),
      onConfirm: (newValue: string) => {
        setVariableName(newValue)
        createClusteringVariable(outputs.clustering, newValue, 1, numClusters)
      },
      component: CreateVariableFilter,
      icon: PlusCircleIcon,
      currentValue: variableName,
    },
    {
      name: t('filters.name.NumRows'),
      onConfirm: setNumRows,
      component: NumberFilter,
      icon: ListOlIcon,
      currentValue: numRows,
      filterProps: { min: 1, max: 50 },
    },
  ]

  const sortAndSetData = (): void => {
    const { x, y, z, text, types } = sortData(
      initData,
      filteredHeatmapData !== null ? Number(filteredHeatmapData.x) - 1 : null,
    )
    const heatmapData = buildHeatmapData(x, y, z, text, types)
    const heatmapGroups = getHeatmapGroups(heatmapData, numRows, z[0].length)
    setData(heatmapGroups)
  }

  const onCellClick = (d: HeatmapData): void => {
    setShouldExecuteFilters(true)
    setFilteredHeatmapData(d)
  }
  const activeVariables = value?.metadata.count_variables ?? []
  const supplementaryVariables = (
    value?.metadata.supplementary_variables ?? []
  ).filter((v: string) => !activeVariables.includes(v))
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={
          dimensions !== undefined &&
          (dimensions.width < CLUSTERS_BREAK_POINT ||
            numClusters > BREAK_CLUSTERS)
            ? 12
            : 6
        }
        style={{ minWidth: CLUSTERS_MIN_WIDTH, marginBottom: '50px' }}
      >
        <>
          {DEPRECATED_FEATURES && <SpeedFilterDial filters={filterList} />}
          {keys(data).length > 0 ? (
            <>
              {activeVariables.map((v: string) => (
                <div key={v}>
                  <ClusteringHeatmap
                    data={data[v] ?? []}
                    title={`${dictionary?.list[v] ?? v} (${t(
                      'clustering.active',
                    )})`}
                    numClust={value.results.M[0].length}
                    onCellClick={onCellClick}
                    experimentId={experimentId}
                    folderId={folderId}
                  />
                </div>
              ))}
              {supplementaryVariables.map((v: string) => (
                <div key={v}>
                  <ClusteringHeatmap
                    data={data[v] ?? []}
                    title={`${dictionary?.list[v] ?? v} (${t(
                      'clustering.supplementary',
                    )})`}
                    numClust={value.results.M[0].length}
                    onCellClick={onCellClick}
                    experimentId={experimentId}
                    folderId={folderId}
                  />
                </div>
              ))}
            </>
          ) : (
            <MessageComponent
              message={t('associationsGraph.messageComponent')}
            />
          )}
        </>
      </Grid>
      <Grid
        item
        xs={
          dimensions !== undefined &&
          (dimensions.width < CLUSTERS_BREAK_POINT ||
            numClusters > BREAK_CLUSTERS)
            ? 12
            : 6
        }
      >
        {value?.metadata.statistics_operations === undefined ? (
          <LegacyClusteringTable
            value={value}
            dictionaries={dictionaries}
            appId={appId}
            numClusters={numClusters}
            dictionary={dictionary}
          />
        ) : (
          <ClusteringTable
            encodingDict={value.metadata.encoding_dict}
            datasetDict={dictionary}
            appId={appId}
            statistics={
              value.results.stats as Array<{ result: StatisticsValue[] }>
            }
          />
        )}
      </Grid>
    </Grid>
  )
}
export default ClusteringWidget
