 
import React, { useState } from 'react'
import {
  Popover,
  MenuItem,
  TableHead,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import ArrowButton from '../../../buttons/ArrowButton'
import type { StatisticsTopCounts } from './types'
import type { VarDict } from './StatisticsTable'
import { round } from 'lodash'
import { MoreVerticalIcon, FilterIcon } from '../../../icons'
import { makeStyles } from '@mui/styles'
import type { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  filterIcon: {
    transition: 'color 0.3s ease, background-color 0.3s ease',
    borderRadius: '50%',
    padding: '4px',
    '&.enabled': {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      animation: '$pulsate 1.5s ease-in-out infinite',
    },
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1)',
      backgroundColor: theme.palette.primary.light,
    },
    '50%': {
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.primary.light,
    },
    '100%': {
      transform: 'scale(1)',
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

interface IconButtonProps {
  icon?: React.ReactNode
  label: string
  onClick: (code: string[]) => Promise<boolean>
}

interface FilterPopoverProps {
  open: boolean
  anchorEl: HTMLElement | null
  activeRows: string[]
  mousePosition: { x: number; y: number } | null
  onClose: () => void
  iconButtons?: IconButtonProps[]
}

const OFFSET_X = 1 // Horizontal offset in pixels
const OFFSET_Y = 1 // Vertical offset in pixels

interface TopCountsTableHook {
  open: boolean
  setOpen: (open: boolean) => void
  anchorEl: HTMLElement | null
  selectedRows: string[]
  activeRows: string[]
  mousePosition: { x: number; y: number } | null
  handleRowClick: (row: string) => void
  handleIconRowClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    row: string,
  ) => void
  handleFilterClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleClose: () => void
}

const useTopCountsTable = (
  initialCollapsed: boolean = false,
): TopCountsTableHook => {
  const [open, setOpen] = useState(!initialCollapsed)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [activeRows, setActiveRows] = useState<string[]>([])
  const [mousePosition, setMousePosition] = useState<{
    x: number
    y: number
  } | null>(null)
  // Add this new state to track if the button was previously disabled

  const handleRowClick = (row: string): void => {
    setSelectedRows((prev) => {
      const newSelectedRows = prev.includes(row)
        ? prev.filter((r) => r !== row)
        : [...prev, row]
      return newSelectedRows
    })
  }

  const handleIconRowClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: string,
  ): void => {
    event.stopPropagation() // Prevent row click event
    setAnchorEl(event.currentTarget)
    setMousePosition({
      x: event.clientX + OFFSET_X,
      y: event.clientY + OFFSET_Y,
    })
    setActiveRows([row])
  }

  const handleFilterClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
    setMousePosition({
      x: event.clientX + OFFSET_X,
      y: event.clientY + OFFSET_Y,
    })
    setActiveRows(selectedRows)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    setMousePosition(null)
  }

  return {
    open,
    setOpen,
    anchorEl,
    selectedRows,
    activeRows,
    mousePosition,
    handleRowClick,
    handleIconRowClick,
    handleFilterClick,
    handleClose,
  }
}

const FilterPopover = ({
  anchorEl,
  activeRows,
  mousePosition,
  onClose,
  iconButtons,
  open,
}: FilterPopoverProps): React.ReactNode => {
  const { t } = useTranslation()

  if (
    activeRows.length === 0
    || mousePosition === null
    || iconButtons === undefined
    || iconButtons.length === 0
  ) {
    return <></>
  }
  const anchorPosition = {
    top: mousePosition.y + OFFSET_Y,
    left: mousePosition.x + OFFSET_X,
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorPosition={anchorPosition}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorReference="anchorPosition"
    >
      {iconButtons.map((button, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            onClose()
            button
              .onClick(activeRows)
              .then((success) => {
                if (success) {
                  toast.success(t('toast.filterCreatedSuccessfully'))
                } else {
                  toast.error(t('toast.errorCreatingFilter'))
                }
              })
              .catch((error) => {
                console.error(error)
                toast.error(t('toast.errorCreatingFilter'))
              })
          }}
        >
          {button.icon !== undefined && <>{button.icon} &nbsp;</>}
          {button.label}
        </MenuItem>
      ))}
    </Popover>
  )
}

export const TopCountsTable = ({
  varDicts,
  collapsed = false,
  iconButtons,
  ...topCounts
}: StatisticsTopCounts & {
  varDicts: VarDict
  collapsed?: boolean
  iconButtons?: IconButtonProps[]
}): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    open,
    setOpen,
    anchorEl,
    selectedRows,
    activeRows,
    mousePosition,
    handleRowClick,
    handleIconRowClick,
    handleFilterClick,
    handleClose,
  } = useTopCountsTable(collapsed)

  const opName
    = varDicts.datasetDict?.list[topCounts.operation_name]
    ?? topCounts.operation_name
  const rawVarName = topCounts.args.by
  const hideKey = topCounts.args.hide_key === true

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: open ? 'none' : '1px solid black',
        }}
      >
        <h3 className="text-lg font-bold my-4">{opName}</h3>
        <ArrowButton onClick={() => setOpen(!open)} isOpen={open} />
      </div>
      <TableContainer component={Paper}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size="small">
            <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
              <TableRow>
                {!hideKey && (
                  <TableCell style={{ width: '20%' }}>
                    <b>{varDicts.datasetDict?.list.code ?? 'Code'}</b>
                  </TableCell>
                )}
                <TableCell style={{ width: '50%' }}>
                  <b>
                    {t('createExperimentDialog.description') ?? 'Description'}
                  </b>
                </TableCell>
                <TableCell key="BasicTopCounts" style={{ width: '25%' }}>
                  <b>
                    {varDicts.datasetDict?.list[
                      topCounts.args.percent_denominator ?? ''
                    ]
                      ?? topCounts.args.percent_denominator
                      ?? 'Episodes'}
                  </b>
                </TableCell>
                {iconButtons !== undefined && iconButtons.length > 0 && (
                  <TableCell style={{ width: '5%', textAlign: 'center' }}>
                    <IconButton
                      onClick={handleFilterClick}
                      disabled={selectedRows.length === 0}
                    >
                      <FilterIcon
                        className={`${classes.filterIcon} ${
                          selectedRows.length > 0 ? 'enabled' : ''
                        }`}
                        fixedWidth
                        fontSize="small"
                      />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {topCounts.result
                .filter((row) => row.key !== null)
                .map((row) => {
                  const value = row.value
                  const percent
                    = row.percent !== undefined
                      ? ` (${round(row.percent * 100, 2)}%)`
                      : ''
                  const rowKey = row.key ?? ''
                  const isSelected = selectedRows.includes(rowKey)
                  return (
                    <TableRow
                      key={rowKey}
                      selected={isSelected}
                      onClick={() => handleRowClick(rowKey)}
                      hover
                      style={{ cursor: 'pointer' }}
                    >
                      {!hideKey && <TableCell>{row.key}</TableCell>}
                      <TableCell style={{ textOverflow: 'ellipsis' }}>
                        {varDicts[rawVarName]?.list[rowKey] ?? rowKey}
                      </TableCell>
                      <TableCell>
                        {value}
                        {percent}
                      </TableCell>
                      {iconButtons !== undefined && iconButtons.length > 0 && (
                        <TableCell style={{ width: '5%', textAlign: 'center' }}>
                          <IconButton
                            size="small"
                            onClick={(event) =>
                              handleIconRowClick(event, rowKey)
                            }
                          >
                            <MoreVerticalIcon fixedWidth fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Collapse>
      </TableContainer>

      <FilterPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        activeRows={activeRows}
        mousePosition={mousePosition}
        onClose={handleClose}
        iconButtons={iconButtons}
      />
    </>
  )
}
