import React, { useState, useRef, useEffect } from 'react'
import { Chip, TextField, Box, InputAdornment, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import type { TextFieldProps, Theme } from '@mui/material'
import type { Extend } from '../../../../lib/types'
import FieldWrapper from '../../FieldWrapper'
import { RegularAddIcon } from '../../../icons'

type Props = Extend<
  Omit<TextFieldProps, 'value' | 'onChange'>,
  {
    value: string[]
    onChange: (value: string[]) => void
    label?: string
    valueErrors?: { [key: string]: string }
  }
>

const useStyles = makeStyles((theme: Theme) => ({
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(4),
  },
  placeholder: {
    width: '100%',
    height: theme.spacing(4),
  },
}))

const MultiTextField = ({
  required,
  label,
  value,
  onChange,
  valueErrors = {},
  ...rest
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (inputValue.trim().length > 0) {
        event.stopPropagation()
        addValue()
      }
    }
  }

  const addValue = (): void => {
    const existingValuesLowercased = value.map((val) => val.toLowerCase())
    const newValues = Array.from(
      new Set(
        inputValue
          .split(/[,\s]+/)
          .map((v) => v.trim())
          .filter(
            (v) =>
              v.length > 0
              && !existingValuesLowercased.includes(v.toLowerCase()),
          ),
      ),
    ) // Compare in lowercase and remove duplicates

    if (newValues.length > 0) {
      onChange([...value, ...newValues])
      setInputValue('')
    }
  }

  const removeValue = (valueToRemove: string): void => {
    onChange(value.filter((item) => item !== valueToRemove))
  }

  return (
    <FieldWrapper required={required} label={label}>
      <TextField
        {...rest}
        inputRef={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={addValue}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={addValue} edge="end">
                <RegularAddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box className={classes.chipContainer}>
        {value.length > 0 ? (
          value.map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => {
                removeValue(item)
              }}
              color={valueErrors[item] !== undefined ? 'error' : 'default'}
              title={valueErrors[item] ?? ''}
            />
          ))
        ) : (
          <Box className={classes.placeholder} />
        )}
      </Box>
    </FieldWrapper>
  )
}

export default MultiTextField
