import _ from 'lodash'
import type { Store } from './store'
import { getStore, updateStore } from './store'
import type { Project } from './project'
import type { Filter } from './filter'
import type { Experiment } from './experiment'
import { getAppIds } from '../lib/http'

export interface Folder {
  id: string
  name: string
  folderId?: string
  datasetId?: string
  archivedAt?: Date
}

export function getFolder(folderId: string): Promise<Folder>
export function getFolder(
  folderId: string,
  returnAppId: true,
): Promise<{ folder: Folder; appId: string }>
export async function getFolder(
  folderId: string,
  returnAppId?: boolean,
): Promise<Folder | { folder: Folder; appId: string }> {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    const folder = store.folders.find((folder) => folder.id === folderId)
    if (folder !== undefined) {
      return returnAppId === true ? { folder, appId } : folder
    }
  }
  throw new Error('Folder not found')
}

async function getAppIdAndStoreByFolderId(
  folderId: string,
): Promise<{ appId: string; store: Store }> {
  let applicationId: string | undefined
  let store: Store | undefined
  const appIds = await getAppIds()
  for (const appId of appIds) {
    store = (await getStore(appId)).store
    const folder = store.folders.find((folder) => folder.id === folderId)
    if (folder !== undefined) {
      applicationId = appId
      break
    }
  }
  if (applicationId === undefined || store === undefined) {
    throw new Error('updateFolder: Folder not found')
  }
  return { appId: applicationId, store }
}

export const deleteFolder = async (folderId: string): Promise<void> => {
  const { store, appId } = await getAppIdAndStoreByFolderId(folderId)
  const folderIds = [folderId, ..._.map(getChildFolders(store, folderId), 'id')]
  const experimentIds = _.map(
    _.flatten(folderIds.map((folder) => getChildExperiments(store, folder))),
    'id',
  )

  await updateStore(
    {
      ...store,
      experiments: store.experiments.filter(
        (x) =>
          !(experimentIds.includes(x.id) && folderIds.includes(x.folderId)),
      ),
      folders: store.folders.filter((x) => !folderIds.includes(x.id)),
    },
    appId,
  )
}

export const updateFolder = async (
  filterId: string,
  body: Pick<Folder, 'name'>,
): Promise<void> => {
  const { appId, store } = await getAppIdAndStoreByFolderId(filterId)
  await updateStore(
    {
      ...store,
      folders: store.folders.map((x) =>
        x.id === filterId ? { ...x, ...body } : x,
      ),
      experiments: store.experiments.map((x) =>
        x.folderId === filterId && x.name.startsWith('__statistics__')
          ? { ...x, name: '__statistics__' + body.name }
          : x,
      ),
    },
    appId,
  )
}

export const getRootFolder = async (folderId: string): Promise<Project> => {
  const item = await getFolder(folderId)

  return item.folderId !== undefined
    ? await getRootFolder(item.folderId)
    : (item as Project)
}

export const getFolderExperiments = async (
  folderId: string,
): Promise<Experiment[]> => {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    const experiments = store.experiments.filter((x) => x.folderId === folderId)
    if (experiments.length > 0) {
      return experiments
    }
  }
  return []
}

export const getFolderFilters = async (folderId: string): Promise<Filter[]> => {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    const filters = store.folders.filter(
      (x) => x.folderId === folderId,
    ) as Filter[]
    if (filters.length > 0) {
      return filters
    }
  }
  return []
}

export const getParentFolders = async (folderId: string): Promise<Folder[]> => {
  const folder = await getFolder(folderId)
  if (folder.folderId === undefined) {
    return [folder]
  }
  const parentFolders = await getParentFolders(folder.folderId)
  return [...parentFolders, folder]
}

export const getChildFolders = (store: Store, folderId: string): Folder[] => {
  const subfolders = store.folders.filter((x) => x.folderId === folderId)

  return subfolders.concat(
    ...subfolders.map((x) => getChildFolders(store, x.id)),
  )
}

export const getChildExperiments = (
  store: Store,
  folderId: string,
): Experiment[] => {
  const subexperiments = store.experiments.filter(
    (x) => x.folderId === folderId,
  )

  return subexperiments.concat(
    ...subexperiments.map((x) => getChildExperiments(store, x.id)),
  )
}
