import React from 'react'

export const ExportDataContext = React.createContext({
  data: null,
  args: null,
  resultId: '',
  downloadablePatients: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange: (_data: any) => {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onArgsChange: (_args: any) => {},
  onResultIdChange: (_resultId: string) => {},
})
