import { useState, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useResizeObserver = (ref: any): DOMRectReadOnly | undefined => {
  const [dimensions, setdimensions] = useState<DOMRectReadOnly | undefined>(
    undefined,
  )
  useEffect(() => {
    if (ref.current === undefined || ref.current === null) {
      return
    }
    const observeTarget = ref.current
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries !== null) {
        entries.forEach((entry) => {
          setdimensions(entry.contentRect)
        })
      }
    })
    resizeObserver.observe(observeTarget)
    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [ref])
  return dimensions
}
