import _ from 'lodash'
import type { Dataset } from './dataset'
import type { Field } from './module'
import { getModuleFields } from './module'

export interface Pipeline {
  id: string
  name: string
  type: string
  metadata: {
    pipe: Array<{
      module: string
      inputs: string[]
      outputs: string[]
      default?: {
        [fieldName: string]: unknown
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      args?: any
    }>
  }
}

export interface Pipe {
  moduleId: string
  inputs: string[]
  outputs: string[]
  default?: {
    [fieldName: string]: unknown
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args?: any
}

export const getPipeline = (ds: Dataset, pipelineId: string): Pipeline => {
  const pipelines = getPipelines(ds)
  const pipeline = pipelines.find((x) => x.id === pipelineId)

  if (pipeline === undefined) {
    throw new Error('Pipeline not found')
  }

  return pipeline
}

export const getPipelines = (
  ds: Dataset,
  type?: 'filter' | 'experiment',
): Pipeline[] => {
  const { pipelines } = ds.metadata

  return pipelines
    .map(({ type, name, pipe }) => ({
      id: name,
      name,
      type: type === 'functionality' ? 'experiment' : 'filter',
      metadata: {
        pipe,
      },
    }))
    .filter((x) => type === undefined || x.type === type)
}

export const getExperimentPipelines = (ds: Dataset): Pipeline[] =>
  getPipelines(ds, 'experiment')

export const getFilterPipelines = (ds: Dataset): Pipeline[] =>
  getPipelines(ds, 'filter')

export const getPipelinePipes = (ds: Dataset, pipelineId: string): Pipe[] => {
  const pipeline = getPipeline(ds, pipelineId)
  const { pipe } = pipeline.metadata

  return pipe.map((x) => ({
    moduleId: x.module,
    default: x.default,
    inputs: x.inputs,
    outputs: x.outputs,
    args: x.args,
  }))
}

export const getPipelineFields = (
  ds: Dataset,
  pipelineId: string,
): { [moduleId: string]: Field[] } => {
  const pipes = getPipelinePipes(ds, pipelineId)
  const obj: { [moduleId: string]: Field[] } = {}
  for (const pipe of pipes) {
    const { moduleId, default: def, args, inputs } = pipe
    const fields = getModuleFields(ds, moduleId, pipe?.args)
    // if the pipe has args they become default
    if (
      typeof args !== 'object' ||
      args === null ||
      Object.keys(args).length === 0
    ) {
      obj[moduleId] = fields.map((field) => {
        // the default pipe's value overwrites the default field's value
        const defValue = def !== undefined ? def[field.name] : field.default
        return {
          ...field,
          ...(defValue !== undefined && { default: defValue }),
        }
      })
    } else {
      obj[moduleId] = _.map(args, (val, name) => ({
        name,
        ...val,
        default: def?.[name],
      }))
    }
    if (inputs.includes('$userVariable')) {
      obj[moduleId] = [
        {
          name: '$userVariable',
          type: 'userVariableSelection',
          required: true,
          operations: ['eql'],
        },
        ...obj[moduleId],
      ]
    }
    if (inputs.includes('$subpopulation')) {
      obj[moduleId] = [
        {
          name: '$subpopulation',
          type: 'subpopulationSelection',
          required: true,
          operations: ['eql'],
        },
        ...obj[moduleId],
      ]
    }
  }

  return obj
}
