import React from 'react'
import { groupBy, map } from 'lodash'
import type { WidgetProps } from '../types'
import LegacyStatistics from './LegacyStatistics'
import { useValidatedData } from '../hooks/useData'
import {
  type StatisticsData,
  type StatisticsCount,
  type EncodingDict,
  statisticsDataSchema,
} from './types'
import Loading from '../shared/Loading'
import Grid from '@mui/material/Grid'
import StatisticsPyramid, { type PyramidData } from './StatisticsPyramid'
import MessageComponent from '../AssociationsGraphWidget/MessageComponent'
import { useTranslation } from 'react-i18next'
import StatisticsTable from './StatisticsTable'

const STATISTICS_BREAK_POINT = 1200
const STATISTICS_MIN_WIDTH = 650
interface StatisticsArgs {
  [id: string]: never
}

function buildPyramid(
  statisticsData: StatisticsCount,
): PyramidData[] | undefined {
  if (statisticsData === undefined) {
    return undefined
  }
  const data = statisticsData.result
  const ageIndex = statisticsData.args.partition_variables?.indexOf('age')
  const genderIndex = statisticsData.args.partition_variables?.indexOf('gender')
  if (ageIndex === undefined || ageIndex === -1) {
    throw new Error('Invalid population pyramid')
  }
  if (genderIndex === undefined || genderIndex === -1) {
    throw new Error('Invalid population pyramid')
  }
  const fdata = data.filter((item) => item.partition !== undefined)
  const ageGroups = groupBy(
    fdata,
    (item) => (item.partition as Array<string | null>)[ageIndex],
  )
  const mappedGroups = map(ageGroups, (agePartition, age) => ({
    trace0: (agePartition.find(
      (item) =>
        item.partition?.[genderIndex] === 'Male'
        || item.partition?.[genderIndex] === 'male',
    )?.value ?? 0) as number,
    trace1: (agePartition.find(
      (item) =>
        item.partition?.[genderIndex] === 'Female'
        || item.partition?.[genderIndex] === 'female',
    )?.value ?? 0) as number,
    group: age,
  }))
  return mappedGroups
}

const StatisticsWidget = ({
  outputs,
  experimentId,
  folderId,
  dictionary,
  dimensions,
  appId,
}: WidgetProps): React.JSX.Element => {
  const { t } = useTranslation()

  if (
    outputs.statistics !== undefined
    || outputs.custom_statistics !== undefined
  ) {
    return (
      <LegacyStatistics
        outputs={outputs}
        experimentId={experimentId}
        folderId={folderId}
        dictionary={dictionary}
        dimensions={dimensions}
        appId={appId}
      />
    )
  }
  if (outputs.statistics_2 === undefined) {
    throw new Error('Statistics not recognized.')
  }
  const [{ loading, value, error }] = useValidatedData<
    StatisticsData,
    { encoding_dict?: EncodingDict },
    StatisticsArgs
  >(outputs.statistics_2, {}, { data: statisticsDataSchema })
  if (error !== undefined) {
    throw error
  }
  if (loading) {
    return <Loading />
  }
  const remainingStatistics = value?.results.result.filter(
    (op) => op.operation_name !== 'population_pyramid',
  )
  const pyramidData = value?.results.result.find(
    (o) => o.operation_name === 'population_pyramid',
  )
  const pyramid = buildPyramid(pyramidData as StatisticsCount)
  if (pyramid?.length === 0) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <MessageComponent message={t('statistics.noResultsWarning')} />
        </Grid>
      </Grid>
    )
  }
  if (value?.results.result.length === 0 || remainingStatistics === undefined) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <MessageComponent message={t('statistics.noResultsWarning')} />
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container alignItems="start" spacing={1}>
      <Grid
        item
        xs={(dimensions?.width ?? 0) < STATISTICS_BREAK_POINT ? 12 : 6}
        style={{ minWidth: STATISTICS_MIN_WIDTH, marginBottom: '50px' }}
      >
        {pyramid !== undefined && pyramid?.length > 0 && (
          <Grid
            key="statisticsPyramid"
            item
            xs={(dimensions?.width ?? 0) < STATISTICS_BREAK_POINT ? 12 : 6}
            style={{ minWidth: STATISTICS_MIN_WIDTH }}
          >
            <StatisticsPyramid
              data={pyramid}
              experimentId={experimentId}
              folderId={folderId}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <StatisticsTable
          statistics={remainingStatistics}
          encodingDict={value?.metadata.encoding_dict ?? {}}
          datasetDict={dictionary}
          appId={appId}
          folderId={folderId}
        />
      </Grid>
    </Grid>
  )
}

export default StatisticsWidget
