import React, { useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getFolder } from '../../../../../api/providers'
import { http } from '../../../../../api/lib/http'
import type { ArchiveProjectError, ArchiveProjectResponse } from './types'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getStoreId } from '../../../../../api/providers/store'
import { useDispatch } from 'react-redux'
import ConfirmDialog from '../../../dialogs/ConfirmDialog'
import { archiveFolder } from '../../../../../store/namespaces/folder/actions'
import type { FolderArchiveAction } from '../../../../../store/namespaces/folder/types'
import type { Dispatch } from 'redux'

type ArchiveAction = 'ARCHIVE' | 'UNARCHIVE'

const innerArchiveFolder = async (
  appId: string,
  folderId: string,
  action: ArchiveAction,
): Promise<ArchiveProjectResponse> => {
  const url = '/v3/case-mix-store/archive-folder'
  const storeId = getStoreId(appId)
  if (storeId === undefined) {
    throw new Error('Store not found')
  }
  const res = await (
    await http(appId)
  ).post<ArchiveProjectResponse>(url, {
    store_id: storeId,
    folder_id: folderId,
    action,
  })
  return res.data
}

export const ArchiveProjectPage = ({
  action,
}: {
  action: ArchiveAction
}): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch(`/${action.toLowerCase()}-folder/:folderId`)
  const { t } = useTranslation()
  const [isArchiving, setIsArchiving] = useState(false)
  const dispatch = useDispatch<Dispatch<FolderArchiveAction>>()
  const folderId = match?.params.folderId as string
  const dialog =
    action === 'ARCHIVE' ? 'archiveProjectDialog' : 'unarchiveProjectDialog'

  const onCancel = (): void => {
    navigate('/')
  }
  const onSubmit = async (): Promise<void> => {
    try {
      setIsArchiving(true)
      // Fetch the latest folder data before archiving/unarchiving
      const { appId } = await getFolder(folderId, true)
      const { status, message } = await innerArchiveFolder(
        appId,
        folderId,
        action,
      )
      if (status === 'SUCCESS') {
        dispatch(archiveFolder(folderId, appId))
        toast.success(t(`${dialog}.successMessage`))
      } else {
        console.error('archive folder error', message)
        toast.error(t(`${dialog}.internalServerError`))
      }
    } catch (error) {
      // eslint-disable-next-line  no-unsafe-optional-chaining
      if (axios.isAxiosError(error) && 'detail' in error.response?.data) {
        const { error: detailError } = error.response?.data
          .detail as ArchiveProjectError
        switch (detailError) {
          case 'FOLDER_NOT_FOUND':
            toast.error(`${t(`${dialog}.projectNotFound`)}`)
            break
          default:
            toast.error(t(`${dialog}.internalServerError`))
            break
        }
      } else {
        console.error('share folder error', error)
        toast.error(t(`${dialog}.internalServerError`))
      }
    } finally {
      setIsArchiving(false)
      navigate('/')
    }
  }

  return (
    <ConfirmDialog
      open
      title={t(`${dialog}.title`)}
      message={t(`${dialog}.message`)}
      onCancel={onCancel}
      onAccept={onSubmit}
      loading={isArchiving}
    />
  )
}
