import { type MemoizedFunction, memoize } from 'lodash'

export const uniqId = (): string => Math.random().toString(16).slice(2)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const memoizeWithTTL = <T extends (...args: any[]) => any>(
  fn: T,
  ttl = 60000,
): T & MemoizedFunction => {
  const memoized = memoize(fn)
  const cache = memoized.cache
  setInterval(() => cache.clear?.(), ttl)
  return memoized
}
