import type { Experiment } from '../../../api/providers'

export const EXPERIMENT_CREATE = 'EXPERIMENT_CREATE'
export const EXPERIMENT_UPDATE = 'EXPERIMENT_UPDATE'
export const EXPERIMENT_DELETE = 'EXPERIMENT_DELETE'

export interface ExperimentCreateAction {
  type: typeof EXPERIMENT_CREATE
  payload: {
    name: string
    folderId: string
    pipelineId: string
    values: { [moduleId: string]: { [fieldName: string]: unknown } }
  }
}

export interface ExperimentUpdateAction {
  type: typeof EXPERIMENT_UPDATE
  payload: { id: string; name: string; folderId: string; description?: string }
}

export interface ExperimentDeleteAction {
  type: typeof EXPERIMENT_DELETE
  payload: { id: string; folderId: string }
}

export type ExperimentAction =
  | ExperimentCreateAction
  | ExperimentUpdateAction
  | ExperimentDeleteAction

export interface ExperimentState {
  items: Experiment[]
}
