import React from 'react'
import { round, mapValues, sortBy } from 'lodash'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import type {
  StatisticsValue,
  StatisticsMean,
  StatisticsCount,
  EncodingDict,
  StatisticsTopCounts,
  StatisticsCountFilter,
  StatisticsMinMaxValue,
  StatisticsAvgTime,
  StatisticsReadmissions,
} from './types'
import { useDictionaries } from '../hooks/useDictionary'
import type { Dictionary } from '../types'
import moment from 'moment'
import { Badge } from '@mui/material'
import { FilterIcon } from '../../../icons'
import { TopCountsTable } from './TopCountsTable'
import { createExperiment } from '../../../../components/form/fields/AISearchField/createExperiment'
import { useDispatch } from 'react-redux'
import { newMenuItem } from '../../../../../store/actions'
import type { MenuNewItemAction } from '../../../../../store/namespaces/menu/types'
import type { Dispatch } from 'redux'
interface Props {
  statistics: StatisticsValue[]
  encodingDict: EncodingDict
  datasetDict: Dictionary
  appId: string
  folderId: string
}

export interface VarDict {
  [dict_var: string]: { list: { [key: string]: string } } | undefined
}

export function setupVarDict(
  encodingDict: EncodingDict,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dicts: { [dict_name: string]: any },
): VarDict {
  return mapValues(encodingDict, (v) => dicts[v[0]])
}

function NotImplementedStatistic({
  varDicts,
  ...statisticsValue
}: StatisticsValue & { varDicts: VarDict }): React.JSX.Element {
  const opName =
    varDicts.datasetDict?.list[statisticsValue.operation_name] ??
    statisticsValue.operation_name
  return (
    <TableRow>
      <TableCell>
        <b>{opName}</b>
      </TableCell>
      <TableCell>{JSON.stringify(statisticsValue.result)}</TableCell>
    </TableRow>
  )
}

function MeanCountStatistic({
  varDicts,
  ...statistics
}: (StatisticsCount | StatisticsMean) & {
  varDicts: VarDict
}): React.JSX.Element {
  if ((statistics.args.partition_variables?.length ?? 0) > 1) {
    return (
      <NotImplementedStatistic
        key={statistics.operation_name}
        varDicts={varDicts}
        {...statistics}
      />
    )
  }
  let name = `${
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  }`
  const total = statistics.result.find((stat) => stat.partition === undefined)
  let value = total?.value ?? ''
  if (statistics.args.partition_variables !== undefined) {
    const partitionStats = sortBy(
      statistics.result.filter((stat) => stat.partition !== undefined),
      (stat) => stat.partition?.[0],
    )
    const dict = varDicts[statistics.args.partition_variables[0]]?.list
    name += ` (${partitionStats
      .map((item) => {
        const part = String(item.partition?.[0])
        return dict?.[part] ?? part ?? ''
      })
      .join(', ')})`
    const stats = partitionStats.map((stat) =>
      'percent' in stat
        ? `${stat.value} ${total?.value === undefined ? '(' : ''}${round(
            Number(stat.percent) * 100,
            2,
          )}%${total?.value === undefined ? ')' : ''}`
        : stat.value,
    )
    value = `${value} ${total?.value !== undefined ? '(' : ''}${stats.join(
      ', ',
    )}${total?.value !== undefined ? ')' : ''}`
  }

  return (
    <TableRow>
      <TableCell>
        <b>{name}</b>
      </TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  )
}

function CountFilterStatistic({
  varDicts,
  ...statistics
}: (StatisticsCountFilter | StatisticsReadmissions) & {
  varDicts: VarDict
}): React.JSX.Element {
  const name = `${
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  }`
  let value = '0'
  if (statistics.result.length > 0) {
    value = `${statistics.result[0].value} (${round(
      statistics.result[0].percent * 100,
      2,
    )}%)`
  }

  return (
    <TableRow>
      <TableCell>
        <b>{name}</b>
      </TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  )
}

function AvgTimeStatistic({
  varDicts,
  ...statistics
}: StatisticsAvgTime & { varDicts: VarDict }): React.JSX.Element {
  const name = `${
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  }`
  let value = '0'
  if (statistics.result.length > 0) {
    const unit = `${
      varDicts.datasetDict?.list[statistics.args.time_unit] ??
      statistics.args.time_unit
    }`
    value = `${statistics.result[0].value} ${unit}`
  }

  return (
    <TableRow>
      <TableCell>
        <b>{name}</b>
      </TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  )
}

function MinMaxValueStatistic({
  varDicts,
  ...statistics
}: StatisticsMinMaxValue & { varDicts: VarDict }): React.JSX.Element {
  const name = `${
    varDicts.datasetDict?.list[statistics.operation_name] ??
    statistics.operation_name
  }`
  let value = '-'

  if (statistics.result.length > 0) {
    const minDate = moment(statistics.result[0].min)
    const maxDate = moment(statistics.result[0].max)
    if (minDate.isValid() && maxDate.isValid()) {
      value = `${minDate.format('DD/MM/YYYY')}, ${maxDate.format('DD/MM/YYYY')}`
    } else {
      value = `${statistics.result[0].min},  ${statistics.result[0].max}`
    }
  }

  return (
    <TableRow>
      <TableCell>
        <b>{name}</b>
      </TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  )
}

function StatisticsComponent({
  statistics,
  varDicts,
}: {
  statistics: StatisticsValue
  varDicts: VarDict
}): React.JSX.Element {
  switch (statistics.fn) {
    case 'count':
    case 'mean':
      return (
        <MeanCountStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'count_filter':
    case 'readmissions':
      return (
        <CountFilterStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'avg_time':
      return (
        <AvgTimeStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    case 'min_max_value':
      return (
        <MinMaxValueStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
    default:
      return (
        <NotImplementedStatistic
          key={statistics.operation_name}
          varDicts={varDicts}
          {...statistics}
        />
      )
  }
}

const StatisticsTable = ({
  statistics,
  encodingDict,
  datasetDict,
  appId,
  folderId,
}: Props): React.JSX.Element => {
  const [dicts] = useDictionaries(encodingDict, appId)
  const dispatch = useDispatch<Dispatch<MenuNewItemAction>>()
  const varDicts = setupVarDict(encodingDict, dicts)
  if (datasetDict !== null && datasetDict !== undefined) {
    varDicts.datasetDict = { list: datasetDict.list }
  }
  const tableComponents = statistics
    .filter((stats) => stats.fn !== 'top_counts')
    .map((stats) => (
      <StatisticsComponent
        statistics={stats}
        varDicts={varDicts}
        key={stats.operation_name}
      />
    ))

  const innerCreateExperiment = async (
    codes: string[],
    filter: 'Patients Filter' | 'Episodes Filter',
    stats: StatisticsTopCounts,
  ): Promise<boolean> => {
    const innerFilter = stats.args.by.includes('history.')
      ? {
          history: {
            all: [
              { [`${stats.args.by.slice('history.'.length)}`]: { in: codes } },
            ],
          },
        }
      : { [`${stats.args.by}`]: { in: codes } }

    const codesTranslated = codes
      .map((code) => varDicts[stats.args.by]?.list[code] ?? code)
      .join(', ')
    const filterName = `${datasetDict?.list[filter] ?? filter} - ${varDicts.datasetDict?.list[stats.args.by] ?? stats.args.by} - ${codesTranslated}`
    return await createExperiment(
      folderId,
      filter,
      filter === 'Episodes Filter'
        ? [innerFilter]
        : [{ all_query: [{ all: [innerFilter] }] }],
      filterName,
    )
      .then(({ experimentId, folderId: newFolderId }) => {
        dispatch(newMenuItem(newFolderId, experimentId, true))
        return true
      })
      .catch((err) => {
        console.error(err)
        return false
      })
  }

  const topCountsComponents = statistics
    .filter((stats) => stats.fn === 'top_counts')
    .map((stats, index) => (
      <TopCountsTable
        {...stats}
        varDicts={varDicts}
        key={stats.operation_name}
        collapsed={index > 1}
        iconButtons={[
          {
            icon: (
              <Badge badgeContent="P">
                <FilterIcon fixedWidth />
              </Badge>
            ),
            label: datasetDict?.list['Patients Filter'] ?? 'Patients Filter',
            onClick: async (codes: string[]) => {
              return await innerCreateExperiment(
                codes,
                'Patients Filter',
                stats,
              )
            },
          },
          {
            icon: (
              <Badge badgeContent="E">
                <FilterIcon fixedWidth />
              </Badge>
            ),
            label: datasetDict?.list['Episodes Filter'] ?? 'Episodes Filter',
            onClick: async (codes: string[]) => {
              return await innerCreateExperiment(
                codes,
                'Episodes Filter',
                stats,
              )
            },
          },
        ]}
      />
    ))

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead style={{ backgroundColor: 'lightgray', opacity: '90%' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <b>{varDicts.datasetDict?.list.value ?? 'Value'}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableComponents}</TableBody>
        </Table>
      </TableContainer>
      {topCountsComponents}
    </>
  )
}

export default StatisticsTable
