import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import { getExperiment } from '../../../../../api/providers'
import { updateExperiment } from '../../../../../store/actions'
import ModalDialog from '../../../dialogs/ModalDialog'
import TextField from '../../../form/fields/TextField'

interface Props {
  updateExperiment: typeof updateExperiment
}

const EditExperimentPage = ({ updateExperiment }: Props): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch('/edit-experiment/:folderId/:experimentId')
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const experimentId = match?.params.experimentId as string
  const folderId = match?.params.folderId as string

  const state = useAsync(async () => {
    const { name } = await getExperiment(experimentId, folderId)

    setName(name)
  }, [experimentId, folderId])
  if (state.error !== undefined) {
    console.error('edit experiment state error', state.error)
  }
  const stateError
    = state.error !== undefined ? t('messages.networkError') : undefined

  const onCancel = (): void => {
    navigate('/')
  }

  const onSubmit = (): void => {
    if (name.length === 0) {
      const formRequiredFields = t('form.requiredFields')
      setError(formRequiredFields)
      return
    }

    updateExperiment(experimentId, name, folderId)
    navigate('/')
  }
  const editFolderDialogName = t('editFolderDialog.name')
  return (
    <ModalDialog
      title={t('editFolderDialog.title')}
      error={stateError ?? error}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.accept'),
          disabled: state.loading,
          type: 'submit',
          onClick: onSubmit,
        },
      ]}
    >
      <FormGroup>
        <TextField
          autoFocus
          required
          label={editFolderDialogName}
          value={name}
          onChange={setName}
        />
      </FormGroup>
    </ModalDialog>
  )
}

const mapDispatchToProps = { updateExperiment }

export default connect(null, mapDispatchToProps)(EditExperimentPage)
