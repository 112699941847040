import React, { useState } from 'react'
import { isEqual, omit, isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import type { Extend } from '../../../../lib/types'
import type { FieldProps, FieldError } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'

type Props = Extend<
  FieldProps<{ eql?: number; in?: number[] } | number | undefined>,
  {
    min: number
    max: number
    operations: string[]
  }
>

const setInitialValues = (
  initValue: number | { eql?: number; in?: number[] } | undefined,
  operations: string[],
  min: number,
):
  | number
  | {
      eql?: number | undefined
      in?: number[] | undefined
    } => {
  if (initValue !== undefined) {
    if (typeof initValue !== 'number' && initValue.in !== undefined) {
      return initValue
    }
    return Number(initValue)
  }
  const numOps = operations.length
  if (numOps === 0) {
    return min
  }
  if (operations[0] === 'in') {
    return { in: [] }
  }
  return { eql: min }
}

const SingleNumericField = ({
  required,
  label,
  value: initValue,
  min,
  max,
  onChange,
  operations = [],
  datasetDictionary,
  onError,
  fullName,
  error,
}: Props): React.JSX.Element => {
  const numOps = operations.length
  const value = setInitialValues(initValue, operations, min)
  const [originalValue] = useState(initValue)
  const { t } = useTranslation()
  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.(undefined)
  const [currValue, setCurrValue] = useState('')
  const val
    = numOps > 0 && typeof value !== 'number' && value.eql !== undefined
      ? value.eql
      : value
  const vlabel
    = datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
  if (operations.length > 0 && operations[0] === 'in') {
    const comaSeparated = t('fields.unboundedString.comaSeparated')
    return (
      <FieldWrapper
        required={required}
        label={vlabel !== undefined ? `${vlabel} (${min}-${max})` : ''}
        onReset={onReset}
        onClear={onClear}
        error={
          error !== null && label !== undefined
            ? (error as { [label: string]: string })?.[label]
            : undefined
        }
      >
        <TextField
          fullWidth
          placeholder={comaSeparated}
          type="text"
          variant="standard"
          value={currValue}
          onChange={(e) => {
            const input = e.target.value.replace(/\s/g, '')
            const parts = input.split(',')
            const numbers = new Set<number>()
            parts.forEach((part) => {
              if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number)
                for (let i = start; i <= end; i++) {
                  if (i >= min && i <= max) numbers.add(i)
                }
              } else {
                const num = Number(part)
                if (!isNaN(num) && num >= min && num <= max) numbers.add(num)
              }
            })
            setCurrValue(input)
            onChange?.({ in: Array.from(numbers).sort((a, b) => a - b) })
          }}
        />
      </FieldWrapper>
    )
  }
  return (
    <FieldWrapper
      required={required}
      label={
        label !== undefined ? `${vlabel ?? ''} (${val as number})` : undefined
      }
      onReset={onReset}
      onClear={onClear}
      error={
        error !== null && label !== undefined
          ? (error as { [label: string]: string })?.[label]
          : undefined
      }
    >
      <TextField
        value={val}
        type="number"
        variant="standard"
        onChange={(e) => {
          const v = Number(e.target.value)
          if (isNaN(v)) {
            return
          }
          if (v < min || v > max) {
            if (label !== undefined) {
              onError?.((e: FieldError) => ({
                ...e,
                [label]: `${t('fieldErrors.rangeError', { min, max })}`,
              }))
            }
            return !isEqual(v, val) && onChange?.(numOps === 0 ? v : { eql: v })
          }
          if (label !== undefined) {
            onError?.((e) => {
              if (isArray(e)) {
                throw new Error(
                  'Unexpected list of errors in SingleNumericField',
                )
              }
              return omit(e, label)
            })
          }
          return !isEqual(v, val) && onChange?.(numOps === 0 ? v : { eql: v })
        }}
      />
    </FieldWrapper>
  )
}

export default SingleNumericField
