import type { AxiosError } from 'axios'
import type { CallEffect, PutEffect } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'
import {
  createExperiment as createExperimentRequest,
  updateExperiment as updateExperimentRequest,
  deleteExperiment as deleteExperimentRequest,
} from '../../../api/providers'
import { requestStart, requestStop, requestFail } from '../request/actions'
import { getMenuItems, newMenuItem } from '../menu/actions'
import { closeExperiment, updateLayoutItem } from '../layout/actions'
import type {
  ExperimentUpdateAction,
  ExperimentDeleteAction,
  ExperimentCreateAction,
} from './types'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'
import type {
  LayoutCloseExperimentAction,
  LayoutUpdateItemAction,
} from '../layout/types'
import type { MenuItemsGetAction, MenuNewItemAction } from '../menu/types'

export function* updateExperiment(
  action: ExperimentUpdateAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<void>
  | PutEffect<LayoutUpdateItemAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>
  | PutEffect<MenuItemsGetAction>,
  void,
  unknown
> {
  const { id, name, folderId } = action.payload

  try {
    yield put(requestStart())
    yield call(updateExperimentRequest, id, folderId, { name })
    yield put(updateLayoutItem(id, name, folderId))
    yield put(getMenuItems())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

export function* deleteExperiment(
  action: ExperimentDeleteAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<void>
  | PutEffect<RequestFailAction>
  | PutEffect<LayoutCloseExperimentAction>
  | PutEffect<MenuItemsGetAction>
  | PutEffect<RequestStopAction>,
  void,
  unknown
> {
  const { id, folderId } = action.payload

  try {
    yield put(requestStart())
    yield put(closeExperiment(id, folderId))
    yield call(deleteExperimentRequest, id, folderId)
    yield put(getMenuItems())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

export function* createExperiment(
  action: ExperimentCreateAction,
): Generator<
  | PutEffect<RequestStartAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>
  | PutEffect<MenuItemsGetAction>
  | PutEffect<MenuNewItemAction>
  | CallEffect<string>,
  void,
  string
> {
  try {
    yield put(requestStart())
    const id: string = yield call(createExperimentRequest, action.payload)
    yield put(newMenuItem(action.payload.folderId, id, true))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
