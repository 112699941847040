import type { AllEffect, CallEffect, PutEffect } from 'redux-saga/effects'
import { call, put, all } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import { requestStart, requestStop, requestFail } from '../request/actions'
import {
  updateFolder as updateFolderRequest,
  deleteFolder as deleteFolderRequest,
  innerGetStore,
} from '../../../api/providers'
import type { Experiment, Filter, Store } from '../../../api/providers'
import {
  getFolderExperiments,
  getFolder,
  type Folder,
} from '../../../api/providers/folder'
import { closeExperiment } from '../layout/actions'
import {
  getMenuItems,
  selectMenuItem,
  toggleArchivedItem,
  toggleMenuItem,
} from '../menu/actions'
import type {
  FolderUpdateAction,
  FolderDeleteAction,
  FolderArchiveAction,
} from './types'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'
import type {
  MenuItemsGetAction,
  MenuSelectItemAction,
  MenuToggleArchivedItemAction,
  MenuToggleOpenItemAction,
} from '../menu/types'
import type {
  LayoutCloseExperimentAction,
  LayoutOpenExperimentAction,
} from '../layout/types'
import {
  getInnerFolderStructure,
  type InnerFolderStructure,
} from '../../../api/providers/project'

export function* updateFolder(
  action: FolderUpdateAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<void>
  | PutEffect<MenuItemsGetAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  unknown
> {
  const { id, name } = action.payload

  try {
    yield put(requestStart())
    yield call(updateFolderRequest, id, { name })
    yield put(getMenuItems())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

function getFilters(folderStructure: InnerFolderStructure): Filter[] {
  if (folderStructure.children.length === 0) {
    return [folderStructure.folder]
  }
  return [
    folderStructure.folder,
    ...folderStructure.children.flatMap(getFilters),
  ]
}

async function getExperiments(folderId: string): Promise<Experiment[]> {
  const innerFolderStructure = await getInnerFolderStructure(folderId)
  const filters = innerFolderStructure.flatMap(getFilters)
  const experiments = await Promise.all(
    filters.map(async (x) => await getExperiments(x.id)),
  )
  const folderExperiments = await getFolderExperiments(folderId)
  return folderExperiments.concat(...experiments)
}

export function* deleteFolder(
  action: FolderDeleteAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<Experiment[]>
  | CallEffect<void>
  | CallEffect<Experiment>
  | PutEffect<MenuSelectItemAction>
  | PutEffect<MenuToggleOpenItemAction>
  | PutEffect<MenuItemsGetAction>
  | PutEffect<RequestStopAction>
  | PutEffect<RequestFailAction>
  | PutEffect<LayoutOpenExperimentAction>
  | AllEffect<PutEffect<LayoutCloseExperimentAction>>
  | CallEffect<Experiment[]>,
  void,
  Experiment[]
> {
  const { folderId } = action.payload

  // gets the experiments inside a folder, including subfolders

  try {
    yield put(requestStart())

    // Removes the experiments from the 'widget manager'.
    // Remember that deleting a folder deletes its experiments as well,
    // and we don't want to show deleted experiments.
    const experiments: Experiment[] = yield call(getExperiments, folderId)
    // @ts-expect-error sagas with overloads are not supported
    const { folderId: parentFolderId } = (yield call(
      // @ts-expect-error sagas with overloads are not supported
      getFolder,
      folderId,
    )) as Folder

    yield all(experiments.map((x) => put(closeExperiment(x.id, x.folderId))))

    yield call(deleteFolderRequest, folderId)
    if (parentFolderId !== undefined) {
      yield put(selectMenuItem(parentFolderId))
      yield put(toggleMenuItem(parentFolderId, true))
    }
    yield put(getMenuItems())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

export function* archiveFolder(
  action: FolderArchiveAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<void>
  | CallEffect<Store>
  | PutEffect<MenuToggleArchivedItemAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  unknown
> {
  try {
    yield put(requestStart())
    const { folderId, appId } = action.payload
    innerGetStore.cache.delete(appId) // clear cache for specific app
    yield put(toggleArchivedItem(folderId))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
