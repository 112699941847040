import {
  createAnalysisStream,
  createReportStream,
  createStepStream,
} from '@/api/providers/graph'
import type { AnalysisState, InternalNodeType, Action } from './useAnalysis'
import type { PipelineType, StreamGenerator } from '@/api/providers/graph'
import type { GraphLogs, GraphState } from './HistoryMenu'
import { processLastNode, parseNodesFromString } from './analysisUtils'

export type AnalysisAction =
  | {
      type: 'SET_GRAPH'
      payload: { graphState: GraphState; logs: GraphLogs[] }
    }
  | { type: 'RESET_ANALYSIS' }
  | { type: 'CONTINUE_ANALYSIS' }
  | { type: 'REPORT_ANALYSIS' }
  | {
      type: 'NEW_ANALYSIS'
      payload: {
        question: string
        projectId: string
      }
    }
  | { type: 'RETRY_NODE' }
  | { type: 'NEW_CHUNK'; payload: { chunk: string } }
  | { type: 'END_STREAM'; payload: { autoMode: boolean } }
  | {
      type: 'SET_RUNNING_EXPERIMENT'
      payload: {
        parentSubsetId: string
        folderId: string
        experimentId: string
        type: PipelineType
        title: string
        description: string
      }
    }
  | {
      type: 'UPDATE_EXPERIMENT_NODE'
      payload: { status: string }
    }
  | { type: 'END_EXPERIMENT_AND_CONTINUE' }
  | {
      type: 'SET_STREAM'
      payload: { stream: StreamGenerator }
    }
  | {
      type: 'ERROR_RUNNING_EXPERIMENT'
      payload: { error: string }
    }
  | {
      type: 'CURRENT_ACTION'
      payload: {
        title: string
        description: string
        parentSubsetId: string
        index: number
        nodeId: number
      }
    }

function resetAbortController(
  abortController?: AbortController,
): AbortController {
  if (abortController !== undefined) {
    abortController.abort()
  }
  return new AbortController()
}

function updateWaitingNodes(nodes: InternalNodeType[]): InternalNodeType[] {
  return nodes.map((node) => {
    const status =
      node.status.type === 'waiting'
        ? { type: 'success' as const }
        : node.status
    if (node.type === 'plain') {
      return {
        ...node,
        status,
      }
    }
    return {
      ...node,
      status,
      actions: node.actions.map((action) => ({
        ...action,
        disabled: !action.selected,
      })),
    }
  })
}

function continueAnalysis(state: AnalysisState): AnalysisState {
  const abortController = resetAbortController(state.abortController)
  const currentNodes: InternalNodeType[] = [
    {
      type: 'plain',
      status: { type: 'initializing' },
    } as InternalNodeType,
  ]

  const streamPromise = createStepStream(
    {
      action: state.currentExperiment
        ? {
            parent_subset_id: state.currentExperiment.parentSubsetId ?? '',
            subset_id: state.currentExperiment.folderId ?? '',
            pipeline: {
              id: state.currentExperiment.experimentId ?? '',
              name: state.currentExperiment.title ?? '',
              type: state.currentExperiment.type ?? '',
            },
          }
        : null,
      projectId: state.projectId,
      graph_state_id: state.graphStateId ?? '',
    },
    abortController,
  )
  return {
    ...state,
    nodes: updateWaitingNodes(state.nodes),
    currentExperiment: undefined,
    running: true,
    stream: undefined,
    abortController,
    pendingAction: undefined,
    currentNodes,
    accumulator: '',
    streamPromise,
    lastAction: 'continue',
  }
}

function reportAnalysis(state: AnalysisState): AnalysisState {
  const abortController = resetAbortController(state.abortController)
  const currentNodes: InternalNodeType[] = [
    {
      type: 'plain',
      status: { type: 'initializing' },
    } as InternalNodeType,
  ]

  const streamPromise = createReportStream(
    {
      projectId: state.projectId,
      graph_state_id: state.graphStateId ?? '',
    },
    abortController,
  )
  return {
    ...state,
    currentExperiment: undefined,
    running: true,
    abortController,
    nodes: updateWaitingNodes(state.nodes),
    currentNodes,
    accumulator: '',
    streamPromise,
    pendingAction: undefined,
    stream: undefined,
    lastAction: 'report',
  }
}

function createAnalysis(
  state: AnalysisState,
  question: string,
  projectId: string,
): AnalysisState {
  const abortController = resetAbortController(state.abortController)
  const initialNodes: InternalNodeType[] = [
    {
      type: 'plain',
      status: { type: 'initializing' },
    } as InternalNodeType,
  ]

  const streamPromise = createAnalysisStream(
    { question, projectId },
    abortController,
  )
  return {
    nodes: [],
    currentNodes: initialNodes,
    question,
    abortController,
    projectId,
    running: true,
    streamPromise,
    stream: undefined,
    accumulator: '',
    questionAnswered: false,
    graphStateId: undefined,
    lastAction: 'analysis',
    pendingAction: undefined,
  }
}

function processChunk(state: AnalysisState, chunk: string): AnalysisState {
  let accumulator = state.accumulator + chunk
  accumulator = accumulator.replace('```markdown', '')
  let {
    nodes: newNodes,
    graphStateId: newGraphStateId,
    questionAnswered: newQuestionAnswered,
  } = parseNodesFromString(accumulator)

  newNodes = processLastNode(newNodes as InternalNodeType[], {
    type: 'loading',
    executionText: 'Executing...',
  })
  return {
    ...state,
    currentNodes: newNodes,
    graphStateId: newGraphStateId || state.graphStateId,
    questionAnswered: newQuestionAnswered,
    accumulator,
  }
}

function retryNode(state: AnalysisState): AnalysisState {
  const nodes = state.nodes.filter(
    ({ status }) => status.type !== 'loading' && status.type !== 'initializing',
  )
  switch (state.lastAction) {
    case 'continue':
      return continueAnalysis({ ...state, nodes })
    case 'report':
      return reportAnalysis({ ...state, nodes })
    case 'analysis':
      return createAnalysis(
        { ...state, nodes },
        state.question,
        state.projectId,
      )
    default:
      return state
  }
}

function endStream(state: AnalysisState, autoMode: boolean): AnalysisState {
  const resetState = {
    stream: undefined,
    streamPromise: undefined,
    abortController: undefined,
    running: false,
    nodes: [...state.nodes, ...state.currentNodes],
    currentNodes: [],
  }
  const nodes = resetState.nodes
  if (nodes.length === 0) {
    return {
      ...state,
      ...resetState,
    }
  }
  const lastNode = nodes[nodes.length - 1]
  if (lastNode.type === 'action') {
    return {
      ...state,
      ...resetState,
      nodes: [
        ...nodes.slice(0, -1),
        {
          ...lastNode,
          status: { type: 'waiting', waitingText: 'Waiting...' },
          actions: lastNode.actions.map((action) => ({
            ...action,
            disabled: false,
          })),
        },
      ],
    }
  }

  let pendingAction = state.pendingAction
  if (autoMode) {
    pendingAction = state.questionAnswered ? 'report' : 'continue'
  }

  return {
    ...state,
    ...resetState,
    pendingAction,
    nodes: [
      ...nodes.slice(0, -1),
      {
        ...lastNode,
        status: { type: 'waiting', waitingText: 'Waiting...' },
      },
    ],
  }
}

function setGraph(
  state: AnalysisState,
  graphState: GraphState,
  logs: GraphLogs[],
): AnalysisState {
  const { nodes: newNodes, questionAnswered } = parseNodesFromString(
    logs.map(({ log }) => log).join('\n'),
  )
  const nodes = processLastNode(newNodes, {
    type: 'waiting',
    waitingText: 'Waiting...',
  })

  const abortController = resetAbortController(state.abortController)
  return {
    currentNodes: [],
    nodes,
    pendingAction: undefined,
    projectId: graphState.project_id,
    question: graphState.user_question,
    graphStateId: graphState.id,
    questionAnswered,
    running: false,
    accumulator: '',
    abortController,
    stream: undefined,
    streamPromise: undefined,
    lastAction: undefined,
    currentExperiment: undefined,
    currentAction: undefined,
  }
}

export function reducer(
  state: AnalysisState,
  action: AnalysisAction,
): AnalysisState {
  if (action.type !== 'NEW_CHUNK') {
    console.debug('action', action)
  }
  switch (action.type) {
    case 'NEW_ANALYSIS':
      return createAnalysis(
        state,
        action.payload.question,
        action.payload.projectId,
      )
    case 'CONTINUE_ANALYSIS':
      return continueAnalysis(state)
    case 'REPORT_ANALYSIS':
      return reportAnalysis(state)
    case 'NEW_CHUNK':
      return processChunk(state, action.payload.chunk)
    case 'SET_GRAPH':
      return setGraph(state, action.payload.graphState, action.payload.logs)
    case 'SET_RUNNING_EXPERIMENT':
      return {
        ...state,
        currentAction: undefined,
        currentExperiment: { ...action.payload, running: true },
      }
    case 'SET_STREAM':
      return {
        ...state,
        streamPromise: undefined,
        stream: action.payload.stream,
      }
    case 'UPDATE_EXPERIMENT_NODE':
      return {
        ...state,
        currentNodes: [
          {
            type: 'plain',
            title: state.currentExperiment?.title,
            description: state.currentExperiment?.description,
            status: {
              type: 'loading',
              executionText: action.payload.status,
            },
          },
        ],
      }
    case 'END_EXPERIMENT_AND_CONTINUE':
      return {
        ...state,
        currentNodes: [],
        pendingAction: 'continue',
        nodes: [
          ...state.nodes,
          {
            type: 'plain',
            status: { type: 'success' },
            title: state.currentExperiment?.title,
            description: state.currentExperiment?.description,
          },
        ],
      }
    case 'ERROR_RUNNING_EXPERIMENT':
      return {
        ...state,
        nodes: [
          ...state.nodes,
          {
            type: 'plain',
            title: state.currentExperiment?.title ?? '',
            description: state.currentExperiment?.description ?? '',
            status: {
              type: 'error',
              errorText: action.payload.error,
            },
          },
        ],
        currentNodes: [],
      }
    case 'RESET_ANALYSIS':
      if (state.abortController !== undefined) {
        state.abortController.abort()
      }
      return {
        nodes: [],
        currentNodes: [],
        accumulator: '',
        questionAnswered: false,
        currentExperiment: undefined,
        graphStateId: undefined,
        stream: undefined,
        streamPromise: undefined,
        abortController: undefined,
        running: false,
        projectId: '',
        question: '',
        pendingAction: undefined,
      }
    case 'RETRY_NODE':
      return retryNode(state)
    case 'END_STREAM':
      return endStream(state, action.payload.autoMode)
    case 'CURRENT_ACTION':
      return {
        ...state,
        running: true,
        nodes: setSelectedAction(state.nodes, action.payload),
        currentNodes: [
          {
            type: 'plain',
            status: { type: 'initializing' },
          },
        ],
        currentAction: {
          title: action.payload.title,
          description: action.payload.description,
          parentSubsetId: action.payload.parentSubsetId,
        },
      }
    default:
      return state
  }
}

function setSelectedAction(
  nodes: InternalNodeType[],
  selectedAction: Action,
): InternalNodeType[] {
  return nodes.map((node, nodeIndex) => {
    if (nodeIndex === nodes.length - 1) {
      if (node.type === 'plain') {
        return {
          ...node,
          status: { type: 'success' },
        }
      }
      return {
        ...node,
        status: { type: 'success' },
        actions: node.actions.map((action) => ({
          ...action,
          disabled: !(
            nodeIndex === selectedAction.nodeId &&
            action.index === selectedAction.index
          ),
          selected:
            nodeIndex === selectedAction.nodeId &&
            action.index === selectedAction.index,
        })),
      }
    }
    return node
  })
}
