import React, { useEffect, useState } from 'react'
import {
  type ClusteringStatisticsData,
  type ClusteringStatisticsValue,
} from './types'
import { cloneDeep, keys } from 'lodash'
import LegacyStatisticsTable from '../StatisticsWidget/LegacyStatisticsTable'

interface LegacyClusteringTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dictionaries: any
  appId: string
  numClusters: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dictionary: any
}

const LegacyClusteringTable = ({
  value,
  dictionaries,
  numClusters,
  dictionary,
  appId,
}: LegacyClusteringTableProps): React.JSX.Element => {
  const [table, setTable] = useState<ClusteringStatisticsData>([])
  function parseTableStatistics(
    tableData: ClusteringStatisticsData[],
    numClusters: number,
  ): ClusteringStatisticsData {
    const modifiedStatistics = tableData.slice(0, numClusters)
    tableData.slice(numClusters, tableData.length).forEach((value, index) => {
      modifiedStatistics[index % numClusters] =
        modifiedStatistics[index % numClusters].concat(value)
    })
    const modifiedClusterStatistics: {
      [key: string]: ClusteringStatisticsValue
    } = {}
    modifiedStatistics.forEach((clusterStatistics, cluster) => {
      clusterStatistics.forEach((statistic) => {
        const keyOperation = `${statistic.fn}${statistic.operation_name}`
        if (keyOperation in modifiedClusterStatistics) {
          const keyData = modifiedClusterStatistics[keyOperation].result as {
            [cluster: number]: ClusteringStatisticsValue['result']
          }
          keyData[cluster] = statistic.result
        } else {
          const modifiedStatistic = cloneDeep(statistic)
          modifiedStatistic.partition_variables.unshift({ name: 'clusters' })
          modifiedStatistic.result = { [cluster]: modifiedStatistic.result }
          modifiedClusterStatistics[keyOperation] = modifiedStatistic
        }
      })
    })

    return Object.values(modifiedClusterStatistics)
  }
  useEffect(() => {
    const table = parseTableStatistics(value.results.stats, numClusters ?? 0)
    setTable(table)
  }, [value, keys(dictionaries).join('')])
  return (
    <LegacyStatisticsTable
      statistics={table}
      encodingDict={value.metadata?.encoding_dict ?? {}}
      datasetDict={dictionary}
      appId={appId}
      clustering={numClusters}
    />
  )
}

export default LegacyClusteringTable
