import React, { useState } from 'react'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import SelectField from '../../../form/fields/SelectField'
import MultiSelectField from '../../../form/fields/MultiSelectField'

type Props = Extend<
  FieldProps<
    { eql: string } | { in: string[] } | string[] | undefined | undefined
  >,
  {
    operations: string[]
    options: string[]
  }
>

const getTexts = (
  numOps: number,
  operation: string,
  value: string[] | { eql: string } | { in: string[] },
): string[] => {
  if (numOps === 0) {
    return value as string[]
  }
  if (_.isArray(value)) {
    throw new Error('Error in get text value should not be string[]')
  }
  if (operation === 'eql') {
    if ('eql' in value) {
      return [value.eql]
    }
  }
  if ('in' in value) {
    return value.in
  }
  throw new Error(
    `Error in getText numOps=${numOps} operation=${operation} value=${JSON.stringify(
      value,
    )}`,
  )
}

const EnumField = ({
  required,
  label,
  value: initValue,
  onChange,
  operations = [],
  options,
  datasetDictionary,
  advanced,
  appId,
}: Props): React.JSX.Element => {
  const [originalValue] = useState(initValue)
  const numOps = operations.length
  const defaultValue
    = numOps === 0 ? [] : _.includes(operations, 'eql') ? { eql: '' } : { in: [] }
  const value = initValue ?? defaultValue

  const operation = _.keys(value)[0]
  const texts: string[] = getTexts(numOps, operation, value)

  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.(undefined)
  return (
    <FieldWrapper
      advanced={advanced}
      required={required}
      label={datasetDictionary?.list[label ?? ''] ?? label}
      onReset={onReset}
      onClear={onClear}
    >
      <Grid container spacing={1}>
        {numOps > 1 && (
          <Grid item xs={3}>
            <SelectField
              appId={appId}
              value={operation}
              options={operations.map((item) => ({ value: item, label: item }))}
              onChange={(v) =>
                onChange?.(
                  v === 'eql' ? { eql: texts[0] ?? '' } : { in: texts },
                )
              }
            />
          </Grid>
        )}
        <Grid item xs={numOps > 1 ? 9 : 12}>
          {operation === 'eql' ? (
            <SelectField
              appId={appId}
              value={texts[0] ?? ''}
              options={options.map((option) => ({
                value: option,
                label: datasetDictionary?.list[option] ?? option,
              }))}
              onChange={(v) => onChange?.({ eql: v })}
            />
          ) : (
            <MultiSelectField
              appId={appId}
              fullWidth
              value={texts}
              options={options.map((option) => ({
                value: option,
                label: datasetDictionary?.list[option] ?? option,
              }))}
              onChange={(v) =>
                numOps === 0 ? onChange?.(v) : onChange?.({ in: v })
              }
            />
          )}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default EnumField
