import React, { useState } from 'react'
import type { FieldProps, FieldError } from '../../../form/types'
import type { Extend } from '../../../../lib/types'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import ArrayGroup from './ArrayGroup'
import { cleanPipelineValues } from '../../../../lib/util'

type Props = Extend<
  FieldProps<{ [sth: string]: never } | undefined>,
  {
    items: FieldFactoryProps
    operations: string[]
  }
>

const ArrayFieldAdvanced = ({
  value: initValue,
  items,
  onChange,
  datasetDictionary,
  onError,
  appId,
}: Props): React.JSX.Element => {
  const value = initValue ?? { all_query: [{ all: [{}] }] }
  const [e, setE] = useState<FieldError>([[{}]])
  return (
    <div style={{ position: 'relative' }}>
      <ArrayGroup
        value={value}
        noBars
        appId={appId}
        items={items}
        onChange={onChange ?? (() => {})}
        datasetDictionary={datasetDictionary}
        error={e}
        onError={(errorFn) => {
          const newSubError = errorFn(e)
          const errs = cleanPipelineValues(newSubError)?.length
          if (errs === 0) {
            onError?.(() => ({}))
          } else {
            onError?.(() => ({ error: 'true' }))
          }
          setE(newSubError)
        }}
      />
    </div>
  )
}

export default ArrayFieldAdvanced
