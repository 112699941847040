import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import type { Theme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'

interface Props {
  message: string
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 30,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
)

const MessageComponent = ({ message }: Props): React.JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Alert severity="warning">{message}</Alert>
    </div>
  )
}

export default MessageComponent
