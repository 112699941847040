import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup'
import { getExperiment } from '../../../../../api/providers'
import ModalDialog from '../../../dialogs/ModalDialog'
import { FormControlLabel, Checkbox, FormLabel } from '@mui/material'
import { getExportData } from '../../../../../api/providers/experiment'
import { startFilterDownload } from '../../../../../store/actions'
import type { DownloadsState } from '../../../../../store/types'
import type { Dispatch } from 'redux'
import type { StartFilterDownloadsAction } from '../../../../../store/namespaces/downloads/types'

const ExportExperimentPage = (): React.JSX.Element => {
  const navigate = useNavigate() // as History<{ downloadablePatients: boolean, data: any[][], args: { [id: string]: any }, resultId: string }>,
  const match = useMatch('/export-experiment/:folderId/:experimentId')
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch<StartFilterDownloadsAction>>()
  const downloads = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.download,
  ) as DownloadsState
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [downloadPerPatient] = useState('true')
  const location = useLocation()
  const experimentId = match?.params.experimentId as string
  const folderId = match?.params.folderId as string
  const { downloadablePatients } = location.state
  const [stateCheck, setState] = React.useState({
    dataExport: !Boolean(downloadablePatients),
    downloadPatients: Boolean(downloadablePatients),
  })
  const alreadyDownloadingPatients = Boolean(
    downloads.currentFilterDownloads.find(
      (e) => e.experimentId === experimentId,
    ),
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any): void => {
    setState({ ...stateCheck, [event.target.name]: event.target.checked })
  }

  const { dataExport, downloadPatients } = stateCheck

  const state = useAsync(async () => {
    const folder = await getExperiment(experimentId, folderId)
    setName(folder.name)
  }, [experimentId, folderId])
  if (state.error !== undefined) {
    console.error('export experiment state error', state.error)
  }
  const stateError =
    state.error !== undefined ? t('messages.networkError') : undefined

  const onCancel = (): void => {
    navigate('/')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function generateBlob(fileName: string, blob: any): void {
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    link.download = fileName
    link.href = url
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const onSubmit = async (): Promise<void> => {
    if (name === '') {
      const requiredFields = t('form.requiredFields')
      setError(requiredFields)
      return
    }

    if (dataExport) {
      const { args, resultId } = location.state
      const exportData = await getExportData(resultId, args)
      generateBlob(`${name}-data.zip`, exportData)
    }
    if (downloadPatients) {
      dispatch(
        startFilterDownload(
          experimentId,
          folderId,
          downloadPerPatient === 'true',
          location.state.args.format,
        ),
      )
    }
    navigate('/')
  }

  return (
    <ModalDialog
      title={t('exportFolderDialog.title')}
      error={stateError ?? error}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.accept'),
          disabled: state.loading,
          type: 'submit',
          onClick: () => {
            onSubmit().then(
              () => {},
              () => {},
            )
          },
        },
      ]}
    >
      <FormGroup>
        <FormLabel component="legend">
          {t('commonText.selectMultipleOption')}
        </FormLabel>
        {downloadablePatients === true ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={downloadPatients}
                onChange={handleChange}
                name="downloadPatients"
              />
            }
            label={
              alreadyDownloadingPatients
                ? t('commonText.alreadyDownloadPatients')
                : t('commonText.downloadPatients')
            }
            disabled={alreadyDownloadingPatients}
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={dataExport}
                onChange={handleChange}
                name="dataExport"
              />
            }
            label={t('commonText.data')}
          />
        )}
      </FormGroup>
    </ModalDialog>
  )
}

export default ExportExperimentPage
