import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { useMount, useWindowSize } from 'react-use'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import type { AppState } from '../../../../../store/types'
import { getMenuItems } from '../../../../../store/actions'
import ErrorHandler from '../../../ErrorHandler'
import Header, { HeaderGap } from '../../../Header'
import WidgetManager from '../../../widget/WidgetManager'
import NotFoundPage from '../NotFoundPage'
import CreateProjectPage from '../CreateProjectPage'
import EditFolderPage from '../EditFolderPage'
import CreateFilterPage from '../CreateFilterPage'
import DeleteFolderPage from '../DeleteFolderPage'
import CreateExperimentPage from '../CreateExperimentPage'
import ExportExperimentPage from '../ExportExperimentPage'
import EditExperimentPage from '../EditExperimentPage'
import CopyExperimentPage from '../CopyExperimentPage'
import DeleteExperimentPage from '../DeleteExperimentPage'
import MainMenu from './MainMenu'
import useTheme from '@mui/styles/useTheme'
import ShareFolderPage from '../ShareProjectPage'
import ArchiveProjectPage from '../ArchiveProjectPage'
import { getAppIds } from '../../../../../api/lib/http'
import { getStore } from '../../../../../api/providers/store'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import ProgressStepperPanel from '../../progressStepper/ProgressStepperPanel'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const useStyles = makeStyles((theme) => ({
  drawerPaper: { width: theme.spacing(55) },
  menu: { whiteSpace: 'nowrap', overflowX: 'hidden' },
  addProjectBtn: { width: '100%' },
}))

export const HomePage = (): React.JSX.Element => {
  const menu = useSelector((state: AppState) => state.menu)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const windowSize = useWindowSize()

  useMount(() => {
    dispatch(getMenuItems() as { type: string })
    const fetchData = async (): Promise<void> => {
      const appIds = await getAppIds()
      const promises = await Promise.all(appIds.map(getStore))
      const res = promises.filter(({ error }) => error !== undefined)
      if (res.length > 0) {
        console.error(
          t('messages.storeFetchFailed'),
          res.map(({ error }) => error),
        )
        toast.warning(t('messages.storeFetchFailed'))
      }
    }

    fetchData().catch(console.error)
  })
  const anisSaasEnabled = useFeatureFlagEnabled('anis-saas')

  return (
    <>
      <Header />
      <Box display="flex">
        <Box width={menu.open ? theme.spacing(55) : '0%'}>
          <Drawer
            open={menu.open}
            variant="persistent"
            ModalProps={{ keepMounted: true }}
            classes={{ paper: classes.drawerPaper }}
          >
            <HeaderGap />
            <MainMenu />
          </Drawer>
        </Box>
        <Box
          display="flex"
          width={
            menu.open ? windowSize.width - parseInt(theme.spacing(55)) : '100%'
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={0}
            height="100vh"
            width="100%"
            overflow="hidden"
          >
            <HeaderGap />
            <Box position="relative" height="100%" overflow="auto">
              {anisSaasEnabled ? (
                <ProgressStepperPanel>
                  <WidgetManager />
                </ProgressStepperPanel>
              ) : (
                <WidgetManager />
              )}
              <ErrorHandler />
            </Box>
          </Box>
        </Box>
        <Routes>
          <Route path={'/edit-folder/:folderId'} element={<EditFolderPage />} />
          <Route
            path={'/delete-folder/:folderId'}
            element={<DeleteFolderPage />}
          />
          <Route
            path={'/share-folder/:folderId'}
            element={<ShareFolderPage />}
          />
          <Route
            path={'/archive-folder/:folderId'}
            element={<ArchiveProjectPage action="ARCHIVE" />}
          />
          <Route
            path={'/unarchive-folder/:folderId'}
            element={<ArchiveProjectPage action="UNARCHIVE" />}
          />
          <Route path={'/create-project'} element={<CreateProjectPage />} />
          <Route
            path={'/create-filter/:folderId'}
            element={<CreateFilterPage />}
          />
          <Route
            path={'/create-experiment/:folderId'}
            element={<CreateExperimentPage />}
          />
          <Route
            path={'/export-experiment/:folderId/:experimentId'}
            element={<ExportExperimentPage />}
          />
          <Route
            path={'/edit-experiment/:folderId/:experimentId'}
            element={<EditExperimentPage />}
          />
          <Route
            path={'/copy-experiment/:folderId/:experimentId'}
            element={<CopyExperimentPage />}
          />
          <Route
            path={'/delete-experiment/:folderId/:experimentId'}
            element={<DeleteExperimentPage />}
          />
          <Route element={<NotFoundPage />} />
        </Routes>
      </Box>
    </>
  )
}

export default HomePage
