import { call, put } from 'redux-saga/effects'
import type { CallEffect, PutEffect } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import type { UserVariableCreateAction } from './types'
import { requestStart, requestStop, requestFail } from '../request/actions'

import { createUserVariable as createUserVariableRequest } from '../../../api/providers'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'

export function* createUserVariable(
  action: UserVariableCreateAction,
): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<void>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  unknown
> {
  const { modelId, name, type, folderId } = action.payload

  try {
    yield put(requestStart())
    yield call(createUserVariableRequest, folderId, modelId, name, type)
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
