import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import type { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
    InputLabel: {
      fontSize: '70px',
    },
  }),
)

interface SearchComponentProps {
  textValue: string
  onChange: (Text: string) => void
}

export default function SearchComponent({
  textValue,
  onChange,
}: SearchComponentProps): React.JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.margin}>
        <InputLabel>{t('associationsGraph.textField')}</InputLabel>
        <Input
          value={textValue}
          onChange={(e) => {
            onChange(e.target.value)
          }}
        />
      </FormControl>
    </div>
  )
}
