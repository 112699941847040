 
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _, { orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import {
  selectedMenuItem,
  selectedLayoutItem,
} from '../../../../../store/selectors'
import {
  closeMenu,
  toggleMenuItem,
  selectMenuItem,
  openExperiment,
} from '../../../../../store/actions'
import type {
  AppState,
  MenuState,
  LayoutItem,
} from '../../../../../store/types'
import {
  FilterIcon,
  ExperimentIcon,
  RegularFolderIcon,
  RegularOpenFolderIcon,
  RegularEditIcon,
  RegularCopyIcon,
  RegularDeleteIcon,
  RegularShareIcon,
  PlusIcon,
  ArchiveIcon,
} from '../../../icons'
import Tree from '../../../Tree'
import type { TreeItem } from '../../../../../api/providers'
import ExperimentDetails from '../../../details/ExperimentDetails'
import FilterDetails from '../../../details/FilterDetails/FilterDetails'
import ProjectDetails from '../../../details/ProjectDetails'
import type {
  MenuSelectItemAction,
  MenuToggleOpenItemAction,
} from '../../../../../store/namespaces/menu/types'
import type { Item } from '../../../Tree/lib/types'
import { type Theme, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

interface Props {
  menu: MenuState
  selectedMenuItem?: TreeItem
  selectedLayoutItem?: LayoutItem
  closeMenu: typeof closeMenu
  toggleMenuItem: (
    folderId: string,
    isOpen?: boolean,
  ) => MenuToggleOpenItemAction
  selectMenuItem: (
    folderId: string,
    experimentId?: string,
  ) => MenuSelectItemAction
  openExperiment: typeof openExperiment
}

const ArchiveToggle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
}))

const ToggleButton = styled('div')<{ active: boolean }>(
  ({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: active ? theme.palette.primary.main : 'transparent',
    color: active
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: !active ? theme.palette.grey[200] : undefined,
    },
    cursor: 'pointer',
  }),
)

const ArchiveTooltip = ({
  showArchived,
  setShowArchived,
  translation,
  classes,
}: {
  showArchived: boolean
  setShowArchived: (showArchived: boolean) => void
  translation: (key: string) => string
  classes: { [key: string]: string }
}): React.JSX.Element => (
  <ArchiveToggle className={classes.archivedToggle}>
    <Tooltip title={translation('button.hideArchived')} placement="top" arrow>
      <ToggleButton
        active={!showArchived}
        onClick={() => showArchived && setShowArchived(false)}
      >
        <RegularFolderIcon />
      </ToggleButton>
    </Tooltip>
    <Tooltip title={translation('button.showArchived')} placement="top" arrow>
      <ToggleButton
        active={showArchived}
        onClick={() => !showArchived && setShowArchived(true)}
      >
        <ArchiveIcon />
      </ToggleButton>
    </Tooltip>
  </ArchiveToggle>
)

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: theme.spacing(55),
    maxWidth: theme.spacing(55),
    [theme.breakpoints.up('xl')]: { flexShrink: 0 },
    [theme.breakpoints.down('xl')]: { width: 0 },
  },
  drawerPaper: {
    width: theme.spacing(55),
    [theme.breakpoints.down('sm')]: { width: theme.spacing(36) },
  },
  menu: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    backgroundColor: '#f9f9f9',
    height: '100%',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '10%',
  },
  innerSpan: { marginTop: 5, fontSize: 12 },
  explorerContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 5,
    height: '90%',
  },
  explorerHeaderContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid grey',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  addProjectBtnContainer: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    overflow: 'hidden',
  },
  addProjectBtn: {
    borderRadius: '10%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    justifyContent: 'flex-start',
  },
  archivedToggle: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  explorerTypo: { padding: theme.spacing(1) },
  archivedIcon: {
    // marginRight: theme.spacing(1),
    color: ({ showArchived }: { showArchived: boolean }) =>
      showArchived ? theme.palette.primary.main : 'inherit',
  },
}))

const MainMenu = ({
  menu,
  selectedMenuItem,
  selectedLayoutItem,
  closeMenu,
  toggleMenuItem,
  selectMenuItem,
  openExperiment,
}: Props): React.JSX.Element => {
  const [showArchived, setShowArchived] = useState(false)
  const classes = useStyles({ showArchived })
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const selectedLayoutItemId = _.get(selectedLayoutItem, 'experimentId')
  const itemDetails = (item: TreeItem): React.JSX.Element => {
    if (item.type === 'project') {
      return <ProjectDetails projectId={item.id} />
    }
    if (item.type === 'filter') {
      return <FilterDetails filterId={item.id} />
    }

    return <ExperimentDetails experimentId={item.id} folderId={item.folderId} />
  }
  const treeItems = orderBy(
    menu.items.filter(
      (item) => item.type !== 'project' || item.isArchived === showArchived,
    ),
    [(f) => f.label.trim().toLowerCase()],
    ['asc'],
  )
  const onOpenExperiment = (id: string, folderId: string): void => {
    if (
      !(
        selectedLayoutItemId === id && selectedLayoutItem?.folderId === folderId
      )
    ) {
      openExperiment(id, folderId)
      closeMenu()
    }
  }

  const parseItems = (item: TreeItem): Item => {
    if (item.type === 'project') {
      return {
        ...item,
        icon: <RegularFolderIcon size="lg" fixedWidth />,
        openIcon: <RegularOpenFolderIcon size="lg" fixedWidth />,
        tooltip: item.label,
        actions: [
          {
            tooltip: t('button.openExperiment'),
            default: true,
            onClick: (item: Item) => {
              if (item.type === 'experiment') {
                onOpenExperiment(item.id, item.folderId)
              }
            },
          },
          {
            //   icon: <Badge badgeContent="+"><ExperimentIcon fixedWidth /></Badge>,
            //   tooltip: t('button.addExperiment'),
            //   onClick: (item: TreeItem) => history.push(`/create-experiment/${item.id}`)
            // }, {
            //   icon: <Badge badgeContent="+"><FilterIcon fixedWidth /></Badge>,
            //   tooltip: t('button.addFilter'),
            //   onClick: (item: TreeItem) => history.push(`/create-filter/${item.id}`)
            // }, {
            hidden: true,
            icon: <RegularEditIcon fixedWidth />,
            tooltip: t('button.editProject'),
            onClick: (item: TreeItem) => {
              navigate(`/edit-folder/${item.id}`)
            },
          },
          {
            hidden: true,
            icon: <RegularShareIcon fixedWidth />,
            tooltip: t('button.shareProject'),
            onClick: (item: TreeItem) => {
              navigate(`/share-folder/${item.id}`)
            },
          },
          {
            hidden: true,
            icon: <ArchiveIcon fixedWidth />,
            tooltip: item.isArchived
              ? t('button.unarchiveProject')
              : t('button.archiveProject'),
            onClick: (item: TreeItem) => {
              if (item.type === 'project') {
                navigate(
                  item.isArchived
                    ? `/unarchive-folder/${item.id}`
                    : `/archive-folder/${item.id}`,
                )
              }
            },
          },
          {
            hidden: true,
            icon: <RegularDeleteIcon fixedWidth />,
            tooltip: t('button.deleteProject'),
            onClick: (item: TreeItem) => {
              navigate(`/delete-folder/${item.id}`)
            },
          },
        ],
      }
    }
    if (item.type === 'filter') {
      return {
        ...item,
        icon: <FilterIcon />,
        tooltip: item.label,
        actions: [
          {
            tooltip: t('button.openExperiment'),
            default: true,
            onClick: (item: Item) => {
              if (item.type === 'experiment') {
                onOpenExperiment(item.id, item.folderId)
              }
            },
          },
          {
            //   icon: <Badge badgeContent="+"><ExperimentIcon fixedWidth /></Badge>,
            //   tooltip: t('button.addExperiment'),
            //   onClick: (item: TreeItem) => history.push(`/create-experiment/${item.id}`)
            // }, {
            //   icon: <Badge badgeContent="+"><FilterIcon fixedWidth /></Badge>,
            //   tooltip: t('button.addFilter'),
            //   onClick: (item: TreeItem) => history.push(`/create-filter/${item.id}`)
            // }, {
            hidden: true,
            icon: <RegularEditIcon fixedWidth />,
            tooltip: t('button.editFolder'),
            onClick: (item: TreeItem) => {
              navigate(`/edit-folder/${item.id}`)
            },
          },
          {
            hidden: true,
            icon: <RegularDeleteIcon fixedWidth />,
            tooltip: t('button.deleteFolder'),
            onClick: (item: TreeItem) => {
              navigate(`/delete-folder/${item.id}`)
            },
          },
        ],
      }
    }

    return {
      ...item,
      icon: <ExperimentIcon size="lg" fixedWidth />,
      tooltip: item.label,
      actions: [
        {
          tooltip: t('button.openExperiment'),
          default: true,
          onClick: (item: Item) => {
            if (item.type === 'experiment') {
              onOpenExperiment(item.id, item.folderId)
            }
          },
        },
        {
          hidden: true,
          icon: <RegularEditIcon fixedWidth />,
          tooltip: t('button.editExperiment'),
          onClick: (item: Item) => {
            if (item.type === 'experiment') {
              navigate(`/edit-experiment/${item.folderId}/${item.id}`)
            }
          },
        },
        {
          hidden: true,
          icon: <RegularCopyIcon fixedWidth />,
          tooltip: t('button.copyExperiment'),
          onClick: (item: Item) => {
            if (item.type === 'experiment') {
              navigate(`/copy-experiment/${item.folderId}/${item.id}`)
            }
          },
        },
        {
          hidden: true,
          icon: <RegularDeleteIcon fixedWidth />,
          tooltip: t('button.deleteExperiment'),
          onClick: (item: Item) => {
            if (item.type === 'experiment') {
              navigate(`/delete-experiment/${item.folderId}/${item.id}`)
            }
          },
        },
      ],
    }
  }

  return (
    <>
      <Tabs
        variant="scrollable"
        textColor="primary"
        indicatorColor="primary"
        value={tabIndex}
        onChange={(_, index) => {
          setTabIndex(index)
        }}
      >
        <Tab label={t('homePage.projects')} />
        <Tab label={t('homePage.details')} />
      </Tabs>
      <Divider />
      <Box hidden={tabIndex !== 0} className={classes.menu}>
        <div className={classes.explorerContainer}>
          <div className={classes.explorerHeaderContainer}>
            <div className={classes.addProjectBtnContainer}>
              <Button
                onClick={() => {
                  navigate('/create-project')
                }}
                color="primary"
                startIcon={<PlusIcon fixedWidth />}
                className={classes.addProjectBtn}
              >
                {t('button.addProject')}
              </Button>
            </div>
            <ArchiveTooltip
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              translation={t}
              classes={classes}
            />
          </div>
          {treeItems.length > 0 ? (
            <Tree
              items={treeItems}
              onSelectItem={selectMenuItem}
              onToggleItem={toggleMenuItem}
              parseItems={parseItems}
            />
          ) : showArchived ? (
            <Typography>{t('homePage.noArchivedProjects')}</Typography>
          ) : (
            <></>
          )}
        </div>
      </Box>
      <Box hidden={tabIndex !== 1} p={2}>
        {selectedMenuItem !== undefined && tabIndex === 1 ? (
          itemDetails(selectedMenuItem)
        ) : (
          <Typography>{t('homePage.selectExperiment')}</Typography>
        )}
      </Box>
    </>
  )
}

interface AppStateProps {
  menu: MenuState
  selectedMenuItem: TreeItem | undefined
  selectedLayoutItem: LayoutItem | undefined
}

const mapStateToProps = (state: AppState): AppStateProps => ({
  menu: state.menu,
  selectedMenuItem: selectedMenuItem(state),
  selectedLayoutItem: selectedLayoutItem(state),
})

const mapDispatchToProps = {
  closeMenu,
  toggleMenuItem,
  selectMenuItem,
  openExperiment,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
