import { z } from 'zod'

const basicStatisticsValueSchema = z.object({
  operation_name: z.string(),
  partition_variables: z.array(z.object({ name: z.string() })),
  aggregation_variable: z.optional(z.object({ name: z.string() })),
  fn: z.string(),
  result: z.any(),
  options: z.optional(
    z.union([
      z.object({ date_format: z.string() }),
      z.object({}),
      z.object({ per_cluster_percentage: z.boolean() }),
      z.object({ counts: z.any() }),
    ]),
  ),
})

 
function basicOrPartitioned<T extends z.ZodTypeAny>(tSchema: T) {
  return z.union([
    z.object({}),
    z.object({ value: tSchema }),
    z.record(z.object({ value: tSchema })),
    z.record(z.record(z.object({ value: tSchema }))),
  ])
}

const statisticsMeanSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('mean'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsMean = z.infer<typeof statisticsMeanSchema>

const statisticsCountSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('count'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsCount = z.infer<typeof statisticsCountSchema>

const statisticsCountPatientsFilterSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('count_patients_filter'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsCountPatientsFilterSchema = z.infer<
  typeof statisticsCountPatientsFilterSchema
>

const statisticsTopCountsSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('top_counts'),
  result: basicOrPartitioned(z.array(z.tuple([z.string(), z.number()]))),
})
export type StatisticsTopCounts = z.infer<typeof statisticsTopCountsSchema>

const statisticsReadmissionsSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('readmissions_ratio'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsReadmissions = z.infer<
  typeof statisticsReadmissionsSchema
>

const statisticsDeathRatioSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('death_ratio'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsDeathRatio = z.infer<typeof statisticsDeathRatioSchema>

const statisticsAvgStayTimeSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('avg_stay_time'),
  result: basicOrPartitioned(z.number()),
})
export type StatisticsAvgStayTime = z.infer<typeof statisticsAvgStayTimeSchema>

const statisticsFirstLastEventSchema = basicStatisticsValueSchema.extend({
  fn: z.literal('first_last_event'),
  result: basicOrPartitioned(z.tuple([z.string(), z.string()])),
})
export type StatisticsFirstLastEvent = z.infer<
  typeof statisticsFirstLastEventSchema
>

const statisticsValueSchema = z.discriminatedUnion('fn', [
  statisticsMeanSchema,
  statisticsCountSchema,
  statisticsTopCountsSchema,
  statisticsReadmissionsSchema,
  statisticsDeathRatioSchema,
  statisticsAvgStayTimeSchema,
  statisticsFirstLastEventSchema,
  statisticsCountPatientsFilterSchema,
])

export type StatisticsValue = z.infer<typeof statisticsValueSchema>

export const statisticsDataSchema = z.array(statisticsValueSchema)
export type StatisticsData = z.infer<typeof statisticsDataSchema>
