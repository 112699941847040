/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import type { FieldError } from '../components/form/types'

// Creates a new available name based on the `proposedName` and different from any the given `names`.
export const newAvailName = (proposedName: string, names: string[]): string => {
  let availName = proposedName

  for (let i = 1; ; i++) {
    availName = i > 0 ? `${proposedName} ${i}` : proposedName

    if (!names.includes(availName)) {
      break
    }
  }

  return availName
}

export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (_.isObject(value) && _.isEmpty(value))
  )
}

type PipelineValues =
  | string
  | unknown[]
  | { [id: string]: unknown }
  | { [moduleId: string]: { [fieldName: string]: unknown } }
  | undefined

// Removes empty strings and empty arrays from filter and experiment arguments.
export const cleanPipelineValues = (
  values: PipelineValues | PipelineValues[] | FieldError | FieldError[],
): PipelineValues => {
  const filterValuesFn = (val: any): boolean => {
    if (_.isArray(val) || _.isString(val)) {
      return val.length > 0
    }
    if (_.isObject(val)) {
      return _.keys(val).length > 0
    }
    return val !== undefined
  }
  if (_.isArray(values)) {
    // @ts-expect-error problems upgrading to typescript 5
    const cleaned: any[] = values.map(cleanPipelineValues)
    return cleaned.filter(filterValuesFn)
  }
  if (_.isObject(values)) {
    const root = (values as any).__root
    if (root !== undefined) {
      return cleanPipelineValues(root)
    }
    const cleaned: { [id: string]: any } = _.mapValues(
      values,
      cleanPipelineValues,
    )
    return _.pickBy(cleaned, filterValuesFn)
  }
  return values
}

export function replaceParallel(
  search: string[],
  replace: string[],
  str: string,
): string {
  let ret = ''
  let replaced = false
  let cursor = 0

  while (cursor < str.length) {
    replaced = false

    for (let t = 0; t < search.length; t++) {
      if (str.substr(cursor, search[t].length) === search[t]) {
        replaced = true
        ret += replace[t]
        cursor += search[t].length
        break
      }
    }

    if (!replaced) ret += str[cursor++]
  }

  return ret
}
