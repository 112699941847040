import React, { useEffect, useState, useContext } from 'react'
import { map, round, pickBy, sortBy, reverse, isNumber } from 'lodash'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import makeStyles from '@mui/styles/makeStyles'
import { useData } from '../hooks/useData'
import { useDictionaries } from '../hooks/useDictionary'
import type { DictionaryT } from '../hooks/useDictionary'
import Graph from './Graph'
import type { Graph as GraphT, Link, Node } from './GraphTypes'
import MessageComponent from './MessageComponent'
import { useTranslation } from 'react-i18next'
import {
  RegularAddIcon,
  RegularCopyIcon,
  FilterIcon,
  RegularFileIcon,
  RegularEyeIcon,
} from '../../../icons'
import SpeedFilterDial from '../../../buttons/SpeedFilterDialog'
import type { FilterType } from '../../../buttons/SpeedFilterDialog'
import {
  SearchFilter,
  NormalizedFilter,
  MaxDistanceFilter,
  SliderFilter,
  CheckboxesFilter,
} from './Filters'
import type { CheckboxState } from './CheckboxesGroup'
import type { WidgetProps } from '../types'
import { ExportDataContext } from '../../../../components/widget/WidgetFactory/ExportDataContext'
import Loading from '../shared/Loading'
import { icd10 } from './nodeColor'

const GRAPH_BREAK_POINT = 1000
const TYPE_COMPARE = 'TYPE_COMPARE'
const TYPE_ASSOCIATIONS = 'TYPE_ASSOCIATIONS'
const TABLE_ATTRS_ASSOC = ['cases', 'pr', 'pvalue']
const TABLE_ATTRS_COMPARE = ['w']

interface AssociationsGraphResult {
  centerNode?: string
  center_node?: string
  nodes: {
    [key: string]: {
      attrs: { p: number }
      id: string
    }
  }
  edges: {
    [key: string]: {
      attrs: { w: number }
      id: string
      nodes: [string, string]
    }
  }
}

interface DictionariesT {
  [key: string]: DictionaryT
}

interface AssociationGraphArgs {
  center_node?: string
  max_edges?: number
  normalize?: boolean
  depth?: number
  sort_variable?: string
}

interface AssociationGraphMetadata {
  encoding_dict: { [variable_name: string]: string[] }
  size: number
}

const useStyles = makeStyles((theme) => ({
  tableCell: { maxWidth: 300 },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const getDictAndCode = (
  label: string,
  encodingDict: { [key: string]: string[] },
): [string, string] => {
  const [rest, code] = label.split('=')
  return [encodingDict[rest][0], code]
}

const getDictVal = (
  dicts: DictionariesT,
  label: string,
  encodingDict: { [key: string]: string[] },
): string => {
  const [dict, code] = getDictAndCode(label, encodingDict)
  if (dicts[dict] === undefined) {
    return code
  }
  return `${code} ${dicts[dict]?.list[code] ?? ''}`
}

const getNodeColor = (
  _dicts: DictionariesT,
  id: string,
  encodingDict: { [key: string]: string[] },
): string => {
  const [_dict, code] = getDictAndCode(id, encodingDict)
  const nodeColor = icd10
  const element =
    nodeColor === undefined
      ? []
      : nodeColor.filter((e) => e.rangeIni <= code && e.rangeFin >= code)
  return element.length > 0 ? element[0].color : '#ccc'
}

const getTable = (
  { links }: GraphT,
  centerNode: string,
  dicts: DictionariesT,
  metadata: AssociationGraphMetadata,
  sortVariable: string,
  graphType: string,
): Array<Array<string | number>> => {
  const centeredLinks = links.filter(
    (l) => l.source === centerNode || l.target === centerNode,
  )
  const tableAttrs =
    graphType === TYPE_ASSOCIATIONS ? TABLE_ATTRS_ASSOC : TABLE_ATTRS_COMPARE
  const table: Array<[string, ...number[]]> = reverse(
    sortBy(
      centeredLinks.map((l) => [
        (l.source === centerNode ? l.target : l.source) as string,
        ...tableAttrs.map((a) => l.attrs[a]),
      ]),
      (e) => e[tableAttrs.indexOf(sortVariable) + 1],
    ),
  )
  return table.map(([e, ...attrs]) => [
    getDictVal(dicts, e, metadata.encoding_dict),
    ...attrs.map((a) =>
      isNumber(a) ? (a < 0.0001 ? '<0.0001' : round(a, 3)) : '-',
    ),
  ])
}

const parseResults = (
  { nodes, edges, center_node: centerNodeGraph }: AssociationsGraphResult,
  dicts: DictionariesT,
  centerNode: string,
  metadata: AssociationGraphMetadata,
  sortVariable: string,
  graphType: string,
): {
  graph: GraphT
  table: Array<Array<string | number>>
} => {
  // @ts-expect-error Missing x and y props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gnodes: Node[] = map(nodes, (n: any) => ({
    id: String(n.id),
    Strength: n.attrs.p,
    fill: getNodeColor(dicts, n.id, metadata.encoding_dict),
    Name: n.id
      .split(',')
      .map((idx: string) => getDictVal(dicts, idx, metadata.encoding_dict))
      .join(', '),
    label: n.id
      .split(',')
      .map((idx: string) => (!idx.includes('=') ? idx : idx.split('=')[1]))
      .join(', '),
    group: 1,
  }))
  if (centerNode.length === 0) {
    centerNode = centerNodeGraph ?? ''
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const links: Link[] = map(edges, (e: any) => ({
    id: e.id,
    source: e.nodes[0],
    target: e.nodes[1],
    value: e.nodes[0],
    width: e.attrs.w,
    Strength: e.attrs.w,
    logWidth: Math.log(e.attrs.w),
    strokeColor: centerNode === e.nodes[0] ? 'orange' : '#ccc',
    attrs: e.attrs,
  }))

  const graph: GraphT = { links, nodes: gnodes }
  const table = getTable(
    graph,
    centerNode,
    dicts,
    metadata,
    sortVariable,
    graphType,
  )
  return { graph, table }
}

// FIXME: This can be problematic if the codes are repeated across dicts
const findInDicts = (dicts: DictionariesT, code: string): string[] => {
  return Object.keys(pickBy(dicts, (d) => d.list[code]))
}
const formNodeId = (
  dicts: DictionariesT,
  code: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any,
): string => {
  const candidates = findInDicts(dicts, code)
  const encodingDict = metadata.encoding_dict
  if (candidates.length === 0) {
    return code
  }
  const vars = pickBy(encodingDict, (d) => d === candidates[0])
  if (Object.keys(vars).length > 0) {
    return map(vars, (_v, k) => `${k}=${code}`)[0]
  }
  return code
}

const AssociationsGraphWidget = ({
  outputs,
  experimentId,
  folderId,
  dictionary,
  dimensions,
  pipelineId,
  appId,
}: WidgetProps): React.JSX.Element => {
  const graphType =
    pipelineId === 'Compare Graph' ? TYPE_COMPARE : TYPE_ASSOCIATIONS
  const tableAttrs =
    graphType === TYPE_ASSOCIATIONS ? TABLE_ATTRS_ASSOC : TABLE_ATTRS_COMPARE
  const classes = useStyles()
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [centerNodeText, setCenterNodeText] = useState('')
  const [sortVariable, setSortVariable] = useState(tableAttrs[0])
  const [maxEdges, setMaxEdges] = useState(50)
  const [currentDepth, setCurrentDepth] = useState(1)
  const [currentMouseOver, setCurrentMouseOver] = useState('')
  const [{ loading, value }, setArgs, callId, currentArgs] = useData<
    AssociationsGraphResult,
    AssociationGraphMetadata,
    AssociationGraphArgs
  >(outputs.hypergraph, {
    depth: currentDepth,
    sort_variable: sortVariable,
    max_edges: maxEdges,
  })
  const [centerNode, setCenterNode] = useState('')
  const [dicts, setDicts] = useDictionaries({}, appId)
  const [checkboxesState, setCheckboxesState] = useState<CheckboxState[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graph, setGraph] = useState<any>()
  const [table, setTable] = useState<Array<Array<string | number>>>([])
  const { onDataChange, onArgsChange, onResultIdChange } =
    useContext(ExportDataContext)
  useEffect(() => {
    if (value !== undefined) {
      if (checkboxesState.length === 0) {
        setCheckboxesState(
          Object.keys(value.metadata.encoding_dict).map(
            (checkboxId: string) => ({ checkboxId, state: true }),
          ),
        )
      }
      setDicts(value.metadata.encoding_dict)
    }
  }, [loading])
  useEffect(() => {
    if (value !== undefined) {
      const { graph, table } = parseResults(
        value.results,
        dicts,
        centerNode,
        value.metadata,
        sortVariable,
        graphType,
      )
      setGraph(graph)
      setTable(table)
    }
  }, [value, dicts, centerNode])

  useEffect(() => {
    onDataChange([
      [
        t('associationsGraph.grid'),
        ...tableAttrs.map((a) => t(`associationsGraph.attrs.${a}`)),
      ],
      ...(table ?? []),
    ])
  }, [table])

  useEffect(() => {
    onArgsChange(currentArgs)
  }, [currentArgs])

  useEffect(() => {
    onResultIdChange(outputs.hypergraph)
  }, [outputs.hypergraph])

  if (value === undefined || loading || Object.keys(dicts).length === 0) {
    return <Loading />
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage)

  const handleSetCenterNode = (centerNode: string): void => {
    setArgs((oldArgs) => ({ ...oldArgs, center_node: centerNode }))
    setCenterNodeText(
      getDictAndCode(centerNode, value.metadata.encoding_dict)[1],
    )
    setCenterNode(centerNode)
  }

  const handleSubmitCenterNodeText = (centerNodeText: string): void => {
    const nodeId = formNodeId(dicts, centerNodeText, value.metadata)
    if (nodeId.length > 0) {
      handleSetCenterNode(nodeId)
    }
  }

  const graphHasData = (): boolean => {
    return (
      value !== undefined &&
      Object.keys(value.results.nodes).length > 0 &&
      Object.keys(value.results.edges).length > 0 &&
      value.results.centerNode !== null
    )
  }

  const filterList: FilterType[] = [
    {
      name: t('filters.name.SearchFilter'),
      onConfirm: (newValue: string) => {
        handleSubmitCenterNodeText(newValue)
      },
      icon: RegularAddIcon,
      currentValue:
        centerNodeText.length > 0
          ? centerNodeText
          : value.results.center_node !== undefined
            ? value.results.center_node.split('=')[1]
            : '',
      component: SearchFilter,
    },
    {
      name: t('filters.name.NormalizedFiler'),
      component: NormalizedFilter,
      icon: RegularCopyIcon,
      currentValue: sortVariable,
      displayValue: t(`associationsGraph.attrs.${sortVariable}`),
      onConfirm: (newValue: string) => {
        setSortVariable(newValue)
        setArgs((oldArgs) => ({ ...oldArgs, sort_by: newValue }))
      },
      filterProps: {
        attrs: tableAttrs.filter((a) => a !== 'pvalue' && a !== 'w'),
      },
    },
    {
      name: t('filters.name.MaxDistanceFilter'),
      onConfirm: (newValue: number) => {
        setCurrentDepth(newValue)
        setArgs((oldArgs) => ({ ...oldArgs, depth: newValue }))
      },
      component: MaxDistanceFilter,
      icon: RegularEyeIcon,
      currentValue: currentDepth.toString(),
    },
    {
      name: t('filters.name.SliderFilter'),
      onConfirm: (newValue: number) => {
        if (newValue > 0) {
          setMaxEdges(newValue)
          setArgs((oldArgs: AssociationGraphArgs) => ({
            ...oldArgs,
            max_edges: newValue,
          }))
        }
      },
      component: SliderFilter,
      icon: RegularFileIcon,
      currentValue: maxEdges.toString(),
    },
    {
      name: t('filters.name.CheckboxesFilter'),
      onConfirm: (newValue: CheckboxState[]) => {
        setCheckboxesState(newValue)
        const checked = newValue
          .filter((checkboxState) => checkboxState.state)
          .map(
            (checkboxState) =>
              checkboxState.checkboxId.replace(/\./g, '\\.') + '\\.*',
          )
        if (checked.length > 0) {
          setArgs((oldArgs) => ({ ...oldArgs, filter: checked }))
        }
      },
      component: CheckboxesFilter,
      icon: FilterIcon,
      currentValue: checkboxesState,
      displayValue: checkboxesState
        .filter((checkboxState) => checkboxState.state)
        .map(
          (checkboxState) =>
            dictionary?.list[checkboxState.checkboxId] ??
            checkboxState.checkboxId,
        )
        .join(', '),
      dictionary,
    },
  ].filter(
    (f) =>
      graphType === TYPE_ASSOCIATIONS ||
      f.name !== t('filters.name.NormalizedFiler'),
  )

  let centerNodeP = 1
  if (value.results.nodes[centerNode] !== undefined) {
    centerNodeP = value.results.nodes[centerNode].attrs.p
  }
  return (
    <>
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid
          item
          xs={
            dimensions !== undefined && dimensions.width < GRAPH_BREAK_POINT
              ? 12
              : 6
          }
        >
          <SpeedFilterDial filters={filterList} />
          {graphHasData() && (
            <Graph
              metadata={value.metadata}
              graphType={graphType}
              graph={graph}
              setCurrentMouseOver={(_name: string, code: string) => {
                setCurrentMouseOver(code)
              }}
              setCenterNode={handleSetCenterNode}
              loading={loading}
              callId={callId}
              experimentId={experimentId}
              folderId={folderId}
              renderDependencies={[graph]}
            />
          )}
          {!graphHasData() && (
            <MessageComponent
              message={t('associationsGraph.messageComponent')}
            />
          )}
        </Grid>
        <Grid item xs>
          <Paper key={`table-${experimentId}-${folderId}`}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'center' }}
                  >
                    {t('associationsGraph.grid')}
                  </TableCell>
                  {tableAttrs.map((a) => (
                    <TableCell key={a}>
                      {t(`associationsGraph.attrs.${a}`)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {table
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((r, x: string | number | undefined) => (
                    <TableRow
                      key={x}
                      className={
                        String(r[0]).split(' ')?.[0] ===
                        currentMouseOver.split('=')?.[1]
                          ? classes.selected
                          : ''
                      }
                    >
                      {r.map((c, y) => (
                        <TableCell
                          key={y}
                          className={classes.tableCell}
                          style={{ textAlign: y === 0 ? 'left' : 'right' }}
                        >
                          {y !== 1
                            ? c
                            : `${c} (${round(
                                ((c as number) /
                                  (centerNodeP * value.metadata.size)) *
                                  100,
                                2,
                              )}%)`}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 15, 25]}
              count={table.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_a, b) => {
                setPage(b)
              }}
              onRowsPerPageChange={(a) => {
                setRowsPerPage(parseInt(a.target.value))
                setPage(0)
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default AssociationsGraphWidget
