import React, { useState } from 'react'
import type { ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AngleRightIcon, AngleDownIcon } from '../../icons'
import Collapse from '@mui/material/Collapse'

interface Props {
  required?: boolean
  label?: string
  disabled?: boolean
  children?: ReactNode
  onReset?: () => void
  onClear?: () => void
  error?: string
  collapsable?: boolean
  collapsed?: boolean
  advanced?: boolean
  important?: boolean
}

const useStyles = makeStyles((theme) => ({
  label: {
    position: 'relative',
    cursor: 'default',
    userSelect: 'none',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  smallIconButton: {
    fontSize: '0.8rem',
  },
  collapsables: {
    padding: 0,
    minWidth: 0,
    textTransform: 'inherit',
    position: 'relative',
    cursor: 'default',
    userSelect: 'none',
    color: 'inherit',
  },
}))

const FieldWrapper = ({
  advanced = false,
  required = false,
  label,
  disabled = false,
  children,
  collapsable = false,
  collapsed = false,
  error,
  important,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  // const { t } = useTranslation()
  const [focus, setFocus] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  const collapsing = collapsable ? (
    <span>
      <Button
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
        className={classes.collapsables}
      >
        {!isCollapsed ? <AngleDownIcon /> : <AngleRightIcon />}
        &nbsp;
        {[label, required ? '*' : ''].join('')}
      </Button>
      &nbsp;
    </span>
  ) : (
    [label, required ? '*' : ''].join('')
  )

  const collapsedChildren = collapsable ? (
    <Collapse in={!isCollapsed}>{children}</Collapse>
  ) : (
    children
  )

  return (
    <FormControl
      margin="dense"
      fullWidth={true}
      onFocus={() => {
        setFocus(true)
      }}
      onBlur={() => {
        setFocus(false)
      }}
    >
      {!advanced && label !== undefined && (
        <Typography
          className={classes.label}
          style={{
            fontWeight: important !== undefined && important ? 900 : 'inherit',
          }}
          variant="caption"
          color={focus ? 'primary' : 'textSecondary'}
        >
          {collapsing}
        </Typography>
      )}
      {!disabled && collapsedChildren}
      {error !== undefined && error !== '.' && (
        <Typography color="error">{error}</Typography>
      )}
    </FormControl>
  )
}

export default FieldWrapper
