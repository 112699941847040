import { STORE_NAME } from '../../constants/api'
import { http } from '../lib/http'
import { uniqId } from '../lib/utils'
import type { Store } from './store'

export interface State<T> {
  name: string
  state: T
  id: string
}

export const createState = async <T>(
  name: string,
  state: T,
  appId: string,
): Promise<string> => {
  const url = '/v2/states/'
  const doc = await (
    await http(appId)
  ).post<{ _id: string }>(url, { name, state })

  return doc.data._id
}

export const getState = async <T>(
  stateId: string,
  appId: string,
): Promise<State<T>> => {
  const url = [`/v2/states/${stateId}/`, `rnd=${uniqId()}`].join('?')
  const res = await (
    await http(appId)
  ).get<{ name: string; id: string; state: T }>(url)
  return res.data
}

export const updateState = async <T>(
  stateId: string,
  name: string,
  state: T,
  appId: string,
): Promise<void> => {
  const url = `/v2/states/${stateId}/`

  await (await http(appId)).patch(url, { name, state })
}

export const getStates = async (
  appId: string,
): Promise<Array<State<Store>>> => {
  // the uniqid is used to bypass something that avoids parallel requests
  const url = `/v2/states/?show-states&name=${STORE_NAME}&id=${uniqId()}`
  const doc = await (
    await http(appId)
  ).get<Array<{ _id: string; name: string; state: Store }>>(url, {
    timeout: 5000,
  })

  return doc.data.map(({ _id: id, name, state }) => ({ id, name, state }))
}
