import React from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { useAsync } from 'react-use'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getFolder } from '../../../../../api/providers'
import {
  deleteFolder,
  selectMenuItem,
  toggleMenuItem,
} from '../../../../../store/actions'
import ConfirmDialog from '../../../dialogs/ConfirmDialog'

interface Props {
  toggleMenuItem: typeof toggleMenuItem
  deleteFolder: typeof deleteFolder
  selectMenuItem: typeof selectMenuItem
}

const DeleteFolderPage = ({
  toggleMenuItem,
  deleteFolder,
  selectMenuItem,
}: Props): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch('/delete-folder/:folderId')
  const { t } = useTranslation()
  const folderId = match?.params.folderId as string

  // loads initial data
  const state = useAsync(async () => {
    const folder = await getFolder(folderId)

    return folder.folderId
  }, [folderId])
  const parentFolderId = state.value

  const onCancel = (): void => {
    navigate('/')
  }

  const onAccept = (): void => {
    deleteFolder(folderId)
    if (parentFolderId !== undefined) {
      toggleMenuItem(parentFolderId, true)
      selectMenuItem(parentFolderId)
    }
    navigate('/')
  }

  return (
    <ConfirmDialog
      open
      title={t('deleteFolderDialog.title')}
      message={t('deleteFolderDialog.message')}
      loading={state.loading}
      onCancel={onCancel}
      onAccept={onAccept}
    />
  )
}

const mapDispatchToProps = { toggleMenuItem, deleteFolder, selectMenuItem }

export default connect(null, mapDispatchToProps)(DeleteFolderPage)
