import type { AxiosError } from 'axios'
import {
  REQUEST_START,
  REQUEST_STOP,
  REQUEST_FAIL,
  REQUEST_DISMISS_ERRORS,
} from './types'
import type {
  RequestStartAction,
  RequestStopAction,
  RequestFailAction,
  RequestDismissErrors,
} from './types'

export const requestStart = (): RequestStartAction => ({
  type: REQUEST_START,
})

export const requestStop = (): RequestStopAction => ({
  type: REQUEST_STOP,
})

export const requestFail = (error: AxiosError): RequestFailAction => ({
  type: REQUEST_FAIL,
  error,
})

export const dismissErrors = (): RequestDismissErrors => ({
  type: REQUEST_DISMISS_ERRORS,
})
