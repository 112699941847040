import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import TextField from '../../../form/fields/TextField'
import SelectField from '../../../form/fields/SelectField'
import { replaceParallel } from '../../../../lib/util'

type Operation = 'eql' | 'regexp' | 'in' | 'interval'
type Value =
  | { eql: string }
  | { regexp: string }
  | { in: string[] }
  | { gte: string; lte: string }
  | string
  | undefined

const decode = (value: Value): { operation: string; items: string[] } => {
  const keys = _.keys(value)

  if (_.includes(keys, 'in')) {
    return {
      operation: 'in',
      items: _.get(value, 'in', []),
    }
  } else if (_.includes(keys, 'gte') && _.includes(keys, 'lte')) {
    const gte = _.get(value, 'gte', '')
    const lte = _.get(value, 'lte', '')

    return {
      operation: 'interval',
      items: [gte, lte],
    }
  }
  const operation = keys.length > 0 ? keys[0] : 'eql'
  if (value === undefined) {
    value = ''
  }
  return {
    operation,
    items: [_.isObject(value) ? _.get(value, operation, '') : value],
  }
}

const encode = (operation: Operation, items: string[]): Value => {
  if (operation === 'in') {
    return { in: items.filter((item) => !_.isEmpty(item)) }
  }
  if (operation === 'interval') {
    return {
      gte: items[0] ?? '',
      lte: items[1] ?? '',
    }
  }
  if (operation === 'eql') {
    return { eql: items.length > 0 ? items[0] : '' }
  }
  if (operation === 'regexp') {
    return { regexp: items.length > 0 ? items[0] : '' }
  }
  throw new Error('UnboundStringField: Unrecognised operation')
}

const parseOperations = (ops: string[]): string[] => {
  const res = [...ops]

  if (res.includes('lte') && res.includes('gte')) {
    res.push('interval')
  }
  return res.length > 0
    ? _.uniq(res.map((item) => (['lte', 'gte'].includes(item) ? 'eql' : item)))
    : ['eql']
}

type Props = Extend<
  FieldProps<Value>,
  {
    operations: string[]
  }
>

const UnboundStringField = ({
  required,
  label,
  value: initValue,
  onChange,
  operations,
  datasetDictionary,
  fullName,
  appId,
}: Props): React.JSX.Element => {
  const value = decode(initValue)
  const [originalValue] = useState(initValue)
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState('')
  const { operation, items } = value
  const ops = parseOperations(operations)
  const onReset = (): void => {
    onChange?.(originalValue)
  }
  const onClear = (): void => {
    onChange?.(undefined)
  }

  const vlabel =
    datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
  return (
    <FieldWrapper
      required={required}
      label={vlabel}
      onReset={onReset}
      onClear={onClear}
    >
      <Grid container spacing={1}>
        {ops.length > 1 && (
          <Grid item xs={3}>
            <SelectField
              appId={appId}
              fullWidth
              value={operation}
              options={ops.map((item) => ({
                value: item,
                label: t(`fields.string.${item}`),
              }))}
              onChange={(v) => {
                // @ts-expect-error operations should be "eql" | "regexp" | "in" | "interval"
                onChange?.(encode(v, items))
              }}
            />
          </Grid>
        )}
        <Grid item xs>
          {(() => {
            if (operation === 'in') {
              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>,
              ): void => {
                const newValue =
                  typeof event === 'string' ? event : event.target?.value
                setCurrentValue(newValue)
                const newValues = newValue.split(',').map((v) => v.trim())
                onChange?.({ in: Array.from(new Set(newValues)) })
              }

              return (
                <TextField
                  fullWidth
                  value={currentValue}
                  placeholder={t('fields.unboundedString.comaSeparated')}
                  onChange={(e) => {
                    // @ts-expect-error e should be of some types
                    handleChange(e)
                  }}
                />
              )
            } else if (operation === 'interval') {
              const gte = items[0] ?? ''
              const lte = items[1] ?? ''
              const fromLabel = t('fields.unboundedString.from')
              const toLabel = t('fields.unboundedString.to')
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      placeholder={fromLabel}
                      fullWidth
                      value={gte}
                      onChange={(v) => {
                        onChange?.({ gte: v, lte })
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      placeholder={toLabel}
                      fullWidth
                      value={lte}
                      onChange={(v) => {
                        onChange?.({ gte, lte: v })
                      }}
                    />
                  </Grid>
                </Grid>
              )
            } else if (operation === 'regexp') {
              const removeWildcard = (str: string): string =>
                str.endsWith('*') ? str.slice(0, -2) : str
              return (
                <TextField
                  fullWidth
                  value={replaceParallel(
                    ['\\.', '.'],
                    ['.', '?'],
                    removeWildcard(items[0]).replace(/\.\*/g, '*'),
                  )}
                  onChange={(v) => {
                    const withWildcard = !v.endsWith('*') ? `${v}*` : v
                    onChange?.({
                      regexp: withWildcard
                        .replace(/\./g, '\\.')
                        .replace(/\?/g, '.')
                        .replace(/\*/g, '.*'),
                    })
                  }}
                />
              )
            }

            return (
              <TextField
                fullWidth
                value={items[0] ?? ''}
                onChange={(v) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  _.isObject(initValue) || operations.length > 0
                    ? onChange?.({ eql: v })
                    : onChange?.(v)
                }}
              />
            )
          })()}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default UnboundStringField
