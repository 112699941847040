import type { TreeItem } from '../../../api/providers'
import type { AppState } from '../../../store/types'

export const selectedMenuItem = (state: AppState): TreeItem | undefined => {
  const { items } = state.menu

  // transforms a 'tree' of items into a 'list' of items
  const flatten = (items: TreeItem[]): TreeItem[] => {
    return items.concat(
      ...items.map((item) =>
        flatten(item.type === 'experiment' ? [] : item.items),
      ),
    )
  }
  return flatten(items).find((item) => item.isSelected)
}
