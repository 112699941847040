import React, { useState, useEffect, useContext } from 'react'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import makeStyles from '@mui/styles/makeStyles'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { getExperimentData } from '../../../../../api/providers'
import type { WidgetProps } from '../types'
import InfiniteTable from './InfiniteTable'
import { FilterIcon } from '../../../icons'
import { NumberFilter } from '../AssociationsGraphWidget/Filters'
import { useData } from '../hooks/useData'
import { useDictionaries, getDictVal } from '../hooks/useDictionary'
import { ExportDataContext } from '../../../../components/widget/WidgetFactory/ExportDataContext'
import SpeedFilterDial from '../../../buttons/SpeedFilterDialog'
import { DEPRECATED_FEATURES } from '../../../../../constants/env'

const DEFAULT_MIN_SUPPORT = 0.0025

const useStyles = makeStyles(() => ({
  searchContainer: { margin: 10, marginLeft: 100, display: 'flex' },
  searchDiv: { margin: 10, alignSelf: 'center' },
}))

const getColumns = (
  t: TFunction,
  size1: number,
  size2: number,
): Array<{
  label: string
  dataKey: string
  width: number
  numeric: boolean
}> => {
  const columns = [
    { label: t('compare.item'), dataKey: 'key', width: 1000, numeric: false },
    {
      label: `${t('compare.cases_subpopulation')} (${size1})`,
      dataKey: 'support_1_str',
      width: 250,
      numeric: true,
    },
    {
      label: `${t('compare.cases_base_population')} (${size2})`,
      dataKey: 'support_2_str',
      width: 250,
      numeric: true,
    },
    {
      label: t('compare.excess'),
      dataKey: 'excess_str',
      width: 200,
      numeric: true,
    },
  ]
  return columns
}

interface PaginatedListArgs {
  limit: number
  pattern_key: string
  pattern: string
  sort_key: string
  reversed: boolean
  filters: Array<Array<string | number>>
  skip?: number
}

interface PaginatedListMetadata {
  encoding_dict: { [variableName: string]: string[] }
  alpha: number
  max_itemset_size_1: number
  max_itemset_size_2: number
  min_support_1: number
  min_support_2: number
  size: number
  size_input_1: number
  size_input_2: number
}

interface PaginatedDataPoint {
  excess: number
  id: string
  support_1: number
  support_2: number
  excess_str?: string | number
  support_1_str?: string | number
  support_2_str?: string | number
}
interface PaginatedListData {
  data: PaginatedDataPoint[]
  size: number
}

const PopulationCompareWidget = ({
  outputs,
  appId,
}: WidgetProps): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [cache, setCahe] = useState<{
    [id: number]: PaginatedDataPoint & { key: string }
  }>({})
  const [search, setSearch] = useState('')
  const [hits, setHits] = useState(0)
  const [dictionaries, setDictionaries] = useDictionaries({}, appId)
  const [metadata, setMetadata] = useState<PaginatedListMetadata | undefined>(
    undefined,
  )
  const [sortBy, setSortBy] = useState(1)
  const [minSubpop, setMinSubpop] = useState(10)
  const [minExcess, setMinExcess] = useState(1)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [loading, setLoading] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined)
  const { onResultIdChange } = useContext(ExportDataContext)
  const [res, setArgs] = useData<
    PaginatedListData,
    PaginatedListMetadata,
    PaginatedListArgs
  >(outputs.fisc, {
    limit: 0,
    pattern_key: 'id',
    pattern: `.*${search}.*`,
    sort_key: 'excess',
    reversed: true,
    filters: [
      ['support_1', 'gte', minSubpop],
      ['excess', 'gte', minExcess],
    ],
  })

  useEffect(() => {
    onResultIdChange(outputs.fisc)
  }, [outputs.fisc])

  useEffect(() => {
    if (res.value === undefined) {
      return
    }
    setHits(res.value.results.size)
    if (!res.loading && res.value !== undefined && metadata === undefined) {
      setMetadata(res.value.metadata)
      setDictionaries(res.value.metadata.encoding_dict)
    }
  }, [res.loading])

  const filterList = [
    {
      name: t('filters.name.MinSubpop'),
      onConfirm: (newValue: number) => {
        setMinSubpop(newValue)
        setCahe({})
        setArgs((oldArgs) => ({
          limit: 0,
          sort_key: oldArgs.sort_key.replace('_str', ''),
          reversed: sortDirection === 'desc',
          pattern_key: 'id',
          pattern: `.*${search}.*`,
          filters: [
            ['support_1', 'gte', newValue],
            ['excess', 'gte', minExcess],
          ],
        }))
      },
      component: NumberFilter,
      filterProps: { min: 0, max: 5000000 },
      icon: FilterIcon,
      currentValue: minSubpop,
    },
    {
      name: t('filters.name.MinExcess'),
      onConfirm: (newValue: number) => {
        setMinExcess(newValue)
        setCahe({})
        setArgs((oldArgs) => ({
          limit: 0,
          sort_key: oldArgs.sort_key.replace('_str', ''),
          reversed: sortDirection === 'desc',
          pattern_key: 'id',
          pattern: `.*${search}.*`,
          filters: [
            ['support_1', 'gte', minSubpop],
            ['excess', 'gte', newValue],
          ],
        }))
      },
      component: NumberFilter,
      filterProps: { min: 0, max: 5000000 },
      icon: FilterIcon,
      currentValue: minExcess,
    },
  ]

  const minSupport = res.value?.metadata.min_support_2 ?? DEFAULT_MIN_SUPPORT

  return (
    <Box flexGrow={1} display="flex" flexDirection="column">
      {DEPRECATED_FEATURES && <SpeedFilterDial filters={filterList} />}
      <div className={classes.searchContainer}>
        <Box className={classes.searchDiv}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => {
              const s = e.target.value
              setSearch(s)
              setLoading(true)
              if (searchTimeout !== undefined) {
                clearTimeout(searchTimeout)
              }
              const timeout = setTimeout(() => {
                setCahe({})
                setArgs(() => ({
                  limit: 0,
                  pattern_key: 'id',
                  pattern: `.*${s}.*`,
                  sort_key: 'excess',
                  reversed: true,
                  filters: [
                    ['support_1', 'gte', minSubpop],
                    ['excess', 'gte', minExcess],
                  ],
                }))
              }, 500)
              setSearchTimeout(timeout)
            }}
          />
        </Box>
        <Box className={classes.searchDiv}>
          <Typography>
            {t('compare.hits')}: <strong>{hits}</strong>
          </Typography>
        </Box>
        <Box>{loading && <CircularProgress />}</Box>
      </div>
      {metadata !== undefined && !res.loading && (
        <InfiniteTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={(index) => {
            setLoading(true)
            if (index === sortBy) {
              setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
            }
            setSortBy(index)
            setCahe({})
            const columns = getColumns(
              t,
              metadata.size_input_1,
              metadata.size_input_2,
            )
            setArgs(() => ({
              limit: 0,
              sort_key:
                columns[index].dataKey === 'key'
                  ? 'id'
                  : columns[index].dataKey.replace('_str', ''),
              reversed: sortDirection === 'desc',
              pattern_key: 'id',
              pattern: `.*${search}.*`,
              filters: [
                ['support_1', 'gte', minSubpop],
                ['excess', 'gte', minExcess],
              ],
            }))
          }}
          rowCount={hits}
          highlight={search}
          rowGetter={({ index }) => {
            const val = cache[index]
            if (val === undefined) {
              return {}
            }
            const vals = val.key
              .split(',')
              .map((k: string) =>
                getDictVal(dictionaries, k, metadata.encoding_dict),
              )
              .join(', ')
            return { ...val, key: vals }
          }}
          columns={getColumns(t, metadata.size_input_1, metadata.size_input_2)}
          isRowLoaded={({ index }) => index in cache}
          loadMoreRows={async ({ startIndex, stopIndex }) => {
            const columns = getColumns(
              t,
              metadata.size_input_1,
              metadata.size_input_2,
            )
            setLoading(true)
            const data = await getExperimentData<
              PaginatedListData,
              PaginatedListMetadata,
              PaginatedListArgs
            >(outputs.fisc, {
              skip: startIndex,
              limit: stopIndex - startIndex + 1,
              sort_key:
                columns[sortBy].dataKey === 'key'
                  ? 'id'
                  : columns[sortBy].dataKey.replace('_str', ''),
              reversed: sortDirection === 'desc',
              pattern_key: 'id',
              pattern: `.*${search}.*`,
              filters: [
                ['support_1', 'gte', minSubpop],
                ['excess', 'gte', minExcess],
              ],
            })
            const d = data.results
            d.data.forEach((e, i) => {
              if (e.support_2 === 0) {
                e.excess_str = `> ${round(
                  e.support_1 / metadata.size_input_1 / minSupport,
                  2,
                )}`
              } else {
                e.excess_str
                  = e.excess < 0.01
                    ? e.excess.toExponential(2)
                    : round(e.excess, 2)
              }
              try {
                e.support_1_str = `${e.support_1} (${round(
                  (e.support_1 / metadata.size_input_1) * 100,
                  2,
                )}%)`
                e.support_2_str
                  = e.support_2 === 0
                    ? `< ${round(metadata.size_input_2 * minSupport)} (${round(
                        minSupport * 100,
                        2,
                      )}%)`
                    : `${e.support_2} (${round(
                        (e.support_2 / metadata.size_input_2) * 100,
                        2,
                      )}%)`
              } catch (error) {
                console.error(error)
              }
              cache[startIndex + i] = { ...e, key: e.id }
            })
            setTimeout(() => {
              setLoading(false)
            }, 2000)
          }}
        />
      )}
    </Box>
  )
}
export default PopulationCompareWidget
