import type { CallEffect, PutEffect } from 'redux-saga/effects'
import { call, put, all } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import {
  getExperiment as getExperimentRequest,
  getFolder as getFolderRequest,
} from '../../../api/providers'
import type { Experiment, Folder } from '../../../api/providers'
import { requestStart, requestStop, requestFail } from '../request/actions'
import {
  getLayoutItems,
  setLayoutItems,
  setLayoutTree,
  getLayoutTree,
  buildLayout,
} from './lib/layout'
import type {
  LayoutItem,
  LayoutOpenExperimentAction,
  LayoutCloseExperimentAction,
  LayoutUpdateItemAction,
  LayoutSaveTree,
  LayoutOpenExperimentSuccessAction,
  LayoutCloseExperimentSuccessAction,
  LayoutUpdateItemSuccessAction,
} from './types'
import {
  openExperimentSuccess,
  closeExperimentSuccess,
  updateLayoutItemSuccess,
} from './actions'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'
import type {
  MenuSelectItemAction,
  MenuToggleOpenItemAction,
} from '../menu/types'
import { selectMenuItem, toggleMenuItem } from '../menu/actions'

export function* openExperiment(
  action: LayoutOpenExperimentAction,
): Generator<
  | CallEffect<[Experiment, Folder]>
  | PutEffect<RequestStartAction>
  | PutEffect<LayoutOpenExperimentSuccessAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  [Experiment, Folder]
> {
  const { experimentId, folderId } = action.payload
  const cachedItems = getLayoutItems()
  const cachedTree = getLayoutTree()

  try {
    yield put(requestStart())
    const [
      { name: experimentName },
      { name: folderName, folderId: parentFolder },
      // @ts-expect-error Sagas do not understand overloads
    ]: [Experiment, Folder] = yield all([
      call(getExperimentRequest, experimentId, folderId),
      call(getFolderRequest as (folderId: string) => Promise<Folder>, folderId),
    ])
    const item: LayoutItem = {
      experimentId,
      name: parentFolder === undefined ? folderName : experimentName,
      folderId,
    }
    const items = [
      item,
      ...cachedItems.filter(
        (x) => !(x.experimentId === experimentId && x.folderId === folderId),
      ),
    ]
    const tree = buildLayout(cachedTree, items)

    setLayoutItems(items)
    setLayoutTree(tree)
    yield put(openExperimentSuccess())
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

export function* closeExperiment(
  action: LayoutCloseExperimentAction,
): Generator<
  | PutEffect<LayoutCloseExperimentSuccessAction>
  | PutEffect<MenuSelectItemAction>
  | PutEffect<MenuToggleOpenItemAction>,
  void,
  unknown
> {
  const { experimentId, folderId } = action.payload
  const cachedItems = getLayoutItems()
  const cachedTree = getLayoutTree()

  const items = [
    ...cachedItems.filter(
      (x) => !(x.experimentId === experimentId && x.folderId === folderId),
    ),
  ]
  const tree = buildLayout(cachedTree, items)

  setLayoutItems(items)
  setLayoutTree(tree)
  yield put(closeExperimentSuccess())
  if (items.length > 0) {
    const openedItem = items[0] // first item is the opened one
    yield put(selectMenuItem(openedItem.folderId))
    yield put(toggleMenuItem(openedItem.folderId, true))
  }
}

export function* updateLayoutItem(
  action: LayoutUpdateItemAction,
): Generator<PutEffect<LayoutUpdateItemSuccessAction>, void, unknown> {
  const { experimentId, name, folderId } = action.payload
  const cachedItems = getLayoutItems()
  const cachedTree = getLayoutTree()
  const items = cachedItems.map((x) =>
    x.experimentId === experimentId && x.folderId === folderId
      ? { ...x, name }
      : x,
  )
  const tree = buildLayout(cachedTree, items)
  setLayoutItems(items)
  setLayoutTree(tree)
  yield put(updateLayoutItemSuccess())
}
// eslint-disable-next-line require-yield
export function* saveLayoutTree(
  action: LayoutSaveTree,
): Generator<never, void, unknown> {
  // Store the serialized tree directly
  const serializedTree = action.payload
  setLayoutTree(serializedTree)
}
