import {
  MENU_ITEMS_GET_SUCCESS,
  MENU_TOGGLE,
  MENU_OPEN,
  MENU_TOGGLE_OPEN_ITEM,
  MENU_SELECT_ITEM,
  MENU_TOGGLE_ARCHIVED_ITEM,
} from './types'
import type { MenuAction, MenuState } from './types'
import type { TreeItem } from '../../../api/providers'
import { storage } from '@/store/lib/storage'

const initState = {
  open: (storage().getItem('menuOpen') ?? 'true') === 'true',
  items: [],
}

export default (
  state: MenuState = initState,
  action: MenuAction,
): MenuState => {
  if (action.type === MENU_ITEMS_GET_SUCCESS) {
    const { items } = action.payload
    return { ...state, items }
  } else if (action.type === MENU_TOGGLE) {
    storage().setItem('menuOpen', (!state.open).toString())
    return { ...state, open: !state.open }
    // } else if (action.type === MENU_CLOSE) {
    //   return { ...state, open: false }
  } else if (action.type === MENU_OPEN) {
    return { ...state, open: true }
  } else if (action.type === MENU_TOGGLE_OPEN_ITEM) {
    const { folderId, isOpen } = action.payload
    const { items } = state
    const toggleOpenItem = (
      item: TreeItem,
      isFolderIdChildren?: boolean,
    ): TreeItem => {
      if (item.type === 'experiment') {
        return item
      }
      const open = isOpen ?? !item.isOpen
      if (item.id === folderId) {
        return {
          ...item,
          isOpen: open,
          items: item.items.map((i) => toggleOpenItem(i, true)),
        }
      }
      if (isFolderIdChildren === true) {
        return {
          ...item,
          isOpen: !open ? false : item.isOpen,
          items: item.items.map((i) => toggleOpenItem(i, true)),
        }
      }

      return { ...item, items: item.items.map((i) => toggleOpenItem(i)) }
    }

    return { ...state, items: items.map((i) => toggleOpenItem(i)) }
  } else if (action.type === MENU_SELECT_ITEM) {
    const { folderId, experimentId } = action.payload
    const { items } = state
    let selectedItem: TreeItem | undefined
    const setSelectedItem = (item: TreeItem): TreeItem => {
      if (item.type === 'experiment') {
        const isSelected =
          experimentId !== undefined &&
          item.id === experimentId &&
          item.folderId === folderId
        if (isSelected) {
          selectedItem = item
        }
        return { ...item, isSelected }
      }

      const isSelected =
        item.id === folderId &&
        (experimentId === undefined ||
          !item.items
            .filter(
              ({ type, label }) =>
                type === 'experiment' && !label.startsWith('__statistics__'), // !needs to be improved :S
            )
            .map(({ id }) => id)
            .includes(experimentId))
      if (isSelected) {
        selectedItem = item
      }
      return { ...item, isSelected, items: item.items.map(setSelectedItem) }
    }
    const newItems = items.map(setSelectedItem)
    if (selectedItem === undefined) {
      console.error(
        'MENU_SELECT_ITEM: item not found',
        folderId,
        experimentId ?? '',
      )
    }
    return { ...state, items: newItems }
  } else if (action.type === MENU_TOGGLE_ARCHIVED_ITEM) {
    const { folderId } = action.payload
    const { items } = state
    const toggleArchivedItem = (item: TreeItem): TreeItem => {
      if (item.id === folderId && item.type === 'project') {
        return { ...item, isArchived: !item.isArchived }
      }
      return item
    }
    return { ...state, items: items.map(toggleArchivedItem) }
  }

  return state
}
