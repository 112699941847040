import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import Card from '@mui/material/Card'
import TopBar from './TopBar'
import ArrayRules from './ArrayRules'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import type { FieldError, OnFieldError } from '../../../form/types'

interface ValueQuery {
  not_query?: ValueQuery
  all_query?: ValueQuery[]
  any_query?: ValueQuery[]
  any?: Condition[]
  all?: Condition[]
  not?: Condition
}

interface Condition {
  [variable: string]:
    | { eql: number | string }
    | { lte: number | string; gte: number | string }
    | { regexp: number | string }
    | { in: number[] | string[] }
    | ValueQuery
}
interface Props {
  value: ValueQuery
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (val: any) => void
  onDelete?: () => void
  lastChild?: boolean
  firstChild?: boolean
  noBars: boolean
  items: FieldFactoryProps
  error?: FieldError
  onError?: OnFieldError
  datasetDictionary?: DictionaryContent | null
  appId: string
}

const useStyles = makeStyles({
  card: { paddingLeft: 25, marginLeft: 0, marginBottom: 10, marginRight: 0 },
  leftBarFirst: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      top: -35,
      height: 'calc(58% + 35px)',
      borderWidth: '2px 0 2px 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
    },
  },
  leftBarTop: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      height: 'calc(58%)',
      borderWidth: '0 0 2px 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
    },
  },
  leftBarBottom: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      height: 'calc(100%)',
      borderWidth: '0 0 0 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      top: '57%',
    },
  },
})

const ArrayGroup = ({
  value,
  onChange,
  onDelete,
  noBars,
  lastChild,
  firstChild,
  items,
  datasetDictionary,
  error,
  onError,
  appId,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  // TODO refactor this code which is very repeated and error prone.
  const getNotValue = (): 'not' | null =>
    value.not_query !== undefined ? 'not' : null
  const getAllAnyValue = (): 'all' | 'any' => {
    if (getNotValue() !== null) {
      return value?.not_query?.all_query !== undefined ? 'all' : 'any'
    } else {
      return value.all_query !== undefined ? 'all' : 'any'
    }
  }
  const getChildren = (): ValueQuery[] | undefined => {
    if (getNotValue() !== null) {
      return value?.not_query?.all_query ?? value?.not_query?.any_query
    } else {
      return value.all_query ?? value.any_query
    }
  }
  const getRules = (): ValueQuery | undefined => {
    return getChildren()?.[0]
  }
  const setNot = (val: string | null): void => {
    if (val !== null && val.length > 0) {
      onChange({ not_query: value })
    } else {
      onChange({ ...value.not_query })
    }
  }
  const setAllAny = (val: string): void => {
    if (getNotValue() !== null) {
      if (val === 'all') {
        const newVal = [
          { all: value?.not_query?.any_query?.[0]?.any },
          ...(value?.not_query?.any_query?.slice(1) ?? []),
        ]
        onChange({ not_query: { all_query: newVal } })
      } else {
        const newVal = [
          { any: value?.not_query?.all_query?.[0].all },
          ...(value?.not_query?.all_query?.slice(1) ?? []),
        ]
        onChange({ not_query: { any_query: newVal } })
      }
    } else {
      if (val === 'all') {
        const newVal = [
          { all: value?.any_query?.[0].any },
          ...(value?.any_query?.slice(1) ?? []),
        ]
        onChange({ all_query: newVal })
      } else {
        const newVal = [
          { any: value?.all_query?.[0].all },
          ...(value?.all_query?.slice(1) ?? []),
        ]
        onChange({ any_query: newVal })
      }
    }
  }
  const addGroup = (): void => {
    if (getNotValue() !== null) {
      if (value?.not_query?.all_query !== undefined) {
        onChange({
          not_query: {
            all_query: [
              ...value.not_query.all_query,
              { all_query: [{ all: [{}] }] },
            ],
          },
        })
      } else {
        onChange({
          not_query: {
            any_query: [
              ...(value.not_query?.any_query ?? []),
              { all_query: [{ all: [{}] }] },
            ],
          },
        })
      }
    } else {
      if (value.all_query !== undefined) {
        onChange({
          all_query: [...value.all_query, { all_query: [{ all: [{}] }] }],
        })
      } else {
        onChange({
          any_query: [
            ...(value?.any_query ?? []),
            { all_query: [{ all: [{}] }] },
          ],
        })
      }
    }
    onError?.((oldError) => [...(oldError as FieldError[]), [[{}]]])
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChildrenChange = (i: number) => (val: any) => {
    if (getNotValue() !== null) {
      if (value?.not_query?.all_query !== undefined) {
        const newVal = value.not_query.all_query.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (child: any, j: number) => (i === j ? val : child),
        )
        onChange({ not_query: { all_query: newVal } })
      } else {
        const newVal = (value?.not_query?.any_query ?? []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (child: any, j: number) => (i === j ? val : child),
        )
        onChange({ not_query: { any_query: newVal } })
      }
    } else {
      if (value.all_query !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newVal = value.all_query.map((child: any, j: number) =>
          i === j ? val : child,
        )
        onChange({ all_query: newVal })
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newVal = (value?.any_query ?? []).map((child: any, j: number) =>
          i === j ? val : child,
        )
        onChange({ any_query: newVal })
      }
    }
  }

  const onChildrenDelete = (i: number) => () => {
    if (getNotValue() !== null) {
      if (value?.not_query?.all_query !== undefined) {
        const newVal = value.not_query.all_query.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_child: any, j: number) => i !== j,
        )
        onChange({ not_query: { all_query: newVal } })
      } else {
        const newVal = (value?.not_query?.any_query ?? []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_child: any, j: number) => i !== j,
        )
        onChange({ not_query: { any_query: newVal } })
      }
    } else {
      if (value.all_query !== undefined) {
        const newVal = value.all_query.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_child: any, j: number) => i !== j,
        )
        onChange({ all_query: newVal })
      } else {
        const newVal = (value?.any_query ?? []).filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_child: any, j: number) => i !== j,
        )
        onChange({ any_query: newVal })
      }
    }
    onError?.((oldError) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (oldError as FieldError[]).filter((_e: any, j: number) => j !== i),
    )
  }

  const addRule = (): void => {
    if (getNotValue() !== null) {
      if (value?.not_query?.all_query !== undefined) {
        const newVal = [
          { all: [...(getRules()?.all ?? []), {}] },
          ...value.not_query.all_query.slice(1),
        ]
        onChange({ not_query: { all_query: newVal } })
      } else {
        const newVal = [
          { any: [...(getRules()?.any ?? []), {}] },
          ...(value?.not_query?.any_query?.slice(1) ?? []),
        ]
        onChange({ not_query: { any_query: newVal } })
        onError?.(() => ({}))
      }
    } else {
      if (value.all_query !== undefined) {
        const newVal = [
          { all: [...(getRules()?.all ?? []), {}] },
          ...value.all_query.slice(1),
        ]
        onChange({ all_query: newVal })
      } else {
        const newVal = [
          { any: [...(getRules()?.any ?? []), {}] },
          ...(value?.any_query?.slice(1) ?? []),
        ]
        onChange({ any_query: newVal })
      }
    }
    onError?.((oldError) => {
      return [
        [...(oldError as FieldError[][])[0], {}],
        ...(oldError as FieldError[]).slice(1),
      ]
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRulesChange = (val: any): void => {
    if (getNotValue() !== null) {
      if (value?.not_query?.all_query !== undefined) {
        const newVal = [{ all: val }, ...value.not_query.all_query.slice(1)]
        onChange({ not_query: { all_query: newVal } })
      } else {
        const newVal = [
          { any: val },
          ...(value?.not_query?.any_query?.slice(1) ?? []),
        ]
        onChange({ not_query: { any_query: newVal } })
      }
    } else {
      if (value.all_query !== undefined) {
        const newVal = [{ all: val }, ...value.all_query.slice(1)]
        onChange({ all_query: newVal })
      } else {
        const newVal = [{ any: val }, ...(value?.any_query?.slice(1) ?? [])]
        onChange({ any_query: newVal })
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRowError = (i: number) => (errorFn: any) => {
    const newSubError = errorFn(
      (error as { [label: string]: FieldError })?.[i] as FieldError[],
    )
    return onError?.((oldError) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (oldError as FieldError[])?.map((e: any, j: number) =>
        j === i ? newSubError : e,
      )
    })
  }

  const childs = getChildren()
  const rules = getRules()
  return (
    <Card variant="outlined" className={classes.card}>
      {!noBars &&
        (firstChild !== undefined ? (
          <div className={classes.leftBarFirst} />
        ) : (
          <div className={classes.leftBarTop} />
        ))}
      {!noBars && lastChild !== undefined && !lastChild && (
        <div className={classes.leftBarBottom} />
      )}
      <TopBar
        notValue={getNotValue()}
        conditionValue={getAllAnyValue()}
        onPressAllAny={setAllAny}
        onPressNot={setNot}
        onNewGroup={addGroup}
        onNewRule={addRule}
        onDelete={onDelete}
      />
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (childs ?? []).map((child: any, i: number) => (
          <div key={i} style={{ position: 'relative' }}>
            {i > 0 ? (
              <ArrayGroup
                noBars={false}
                value={child}
                onChange={onChildrenChange(i)}
                onDelete={onChildrenDelete(i)}
                lastChild={(childs ?? []).length - 1 === i}
                firstChild={i === 0}
                items={items}
                appId={appId}
                datasetDictionary={datasetDictionary}
                error={(error as { [label: string]: FieldError })?.[i]}
                onError={onRowError(i)}
              />
            ) : (
              <ArrayRules
                firstChild
                lastChild={(childs ?? []).length - 1 === i}
                rules={rules?.any ?? rules?.all ?? []}
                onChange={onRulesChange}
                items={items}
                appId={appId}
                datasetDictionary={datasetDictionary}
                error={(error as { [label: string]: FieldError })?.[i]}
                onError={onRowError(i)}
              />
            )}
          </div>
        ))
      }
    </Card>
  )
}

export default ArrayGroup
