import { FILTER_CREATE } from './types'
import type { FilterCreateAction } from './types'

export const createFilter = (payload: {
  name: string
  folderId: string
  pipelineId: string
  values: {
    [moduleId: string]: {
      [fieldName: string]: unknown
    }
  }
}): FilterCreateAction => ({
  type: FILTER_CREATE,
  payload,
})
