import React from 'react'
import { Button, InputText, Toggle } from '@amalfi-analytics/ui/components'
import { RegularMaximizeIcon, RegularMinimizeIcon } from '../../icons'
import { RegularEyeSlashIcon } from '../../icons'
import { DatasetSelector } from './DatasetSelector'
import { useEffect, useRef, useState } from 'react'
import HistoryMenu, { GraphLogs, GraphState } from './HistoryMenu'
import { Action } from './ProgressStepperPanel'
interface HeaderState {
  mode: boolean
  seeProgress: boolean
  fullScreen: boolean
  input: { text: string; submitted: boolean }
  dataset: { id: string; label: string } | undefined
}

export interface HeaderProps {
  suggestedActions?: { label: string; text: string }[]
  state: HeaderState
  dispatch: React.Dispatch<Action>
  setGraph: (graphState: GraphState, logs: GraphLogs[]) => void
}

const ActionButtons = ({ state, dispatch, setGraph }: HeaderProps) => {
  return (
    <div className="flex items-center gap-2">
      {!state.fullScreen && (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => dispatch({ type: 'TOGGLE_PROGRESS' })}
          icon={<RegularEyeSlashIcon />}
        />
      )}
      <Button
        color="primary"
        variant="outlined"
        onClick={() => dispatch({ type: 'TOGGLE_FULLSCREEN' })}
        icon={
          state.fullScreen ? <RegularMinimizeIcon /> : <RegularMaximizeIcon />
        }
      />
      <HistoryMenu
        onGraphStateSelect={(graphState, logs) => {
          dispatch({
            type: 'SET_INPUT',
            payload: { text: graphState.user_question, submitted: true },
          })
          setGraph(graphState, logs)
        }}
      />
      <DatasetSelector
        value={state.dataset}
        onChange={(value) => {
          dispatch({ type: 'SET_DATASET', payload: value })
        }}
      />
    </div>
  )
}

export const Header = ({
  suggestedActions,
  state,
  dispatch,
  setGraph,
}: HeaderProps) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const [isFocused, setIsFocused] = useState(true)

  useEffect(() => {
    if (inputRef.current && !state.input.submitted) {
      setTimeout(() => {
        inputRef.current?.click()
      }, 0)
      setTimeout(() => {
        inputRef.current?.focus()
      }, 10)
    }
  }, [state.input.submitted])

  if (!isFocused) {
    return (
      <div
        className="flex flex-col gap-2 p-4 mb-4 rounded-lg sticky top-0 z-10 backdrop-blur-sm shadow-md cursor-pointer transition-all duration-300"
        onMouseEnter={() => setIsFocused(true)}
      >
        <div className="flex justify-between items-center">
          <ActionButtons
            state={state}
            dispatch={dispatch}
            setGraph={setGraph}
          />
          <Toggle
            checked={state.mode}
            label="Automatic Mode"
            disabled={true}
            color="primary"
          />
        </div>
        <InputText
          inputRef={inputRef}
          value={state.input.text}
          color="primary"
          multiline={true}
        />
      </div>
    )
  }

  return (
    <div
      className="flex flex-col gap-2 p-4 mb-4 bg-surface-background dark:bg-surface-background-dark rounded-lg sticky top-0 z-10 shadow-md"
      onMouseLeave={() => setIsFocused(false)}
    >
      <div className="flex justify-between items-center">
        <ActionButtons state={state} dispatch={dispatch} setGraph={setGraph} />
        <Toggle
          checked={state.mode}
          onClick={() => dispatch({ type: 'TOGGLE_MODE' })}
          label="Automatic Mode"
          color="primary"
        />
      </div>
      <div className="flex gap-2 items-end">
        <InputText
          inputRef={inputRef}
          placeholder="Enter text..."
          value={state.input.text}
          color="primary"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              dispatch({
                type: 'SET_INPUT',
                payload: { text: state.input.text, submitted: true },
              })
            }
          }}
          onChange={(e) =>
            dispatch({
              type: 'SET_INPUT',
              payload: { text: e.target.value, submitted: false },
            })
          }
          multiline={true}
        />
        <Button
          onClick={() =>
            dispatch({
              type: 'SET_INPUT',
              payload: { text: state.input.text, submitted: true },
            })
          }
          color="primary"
        >
          Send
        </Button>
      </div>
      {suggestedActions && suggestedActions.length > 0 && (
        <div className="flex flex-wrap justify-end gap-4 mt-2">
          {suggestedActions.map((action, index) => (
            <Button
              key={index}
              color="gray"
              variant="outlined"
              className="rounded-full"
              onClick={() => {
                dispatch({
                  type: 'SET_INPUT',
                  payload: { text: action.text, submitted: false },
                })
              }}
            >
              {action.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
