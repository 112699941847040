import React from 'react'
import { useData } from '../hooks/useData'
import type { ResultsData } from '../hooks/useData'
import { merge, map, keys, uniq, flatten, round } from 'lodash'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

interface Props {
  outputs: { [key: string]: string }
}

interface Statistic {
  operation_name: string
  result: { value: number }
}

interface ClusterStatistics {
  group: number
  statistics: Statistic[]
}

interface YearStatistics {
  year: number
  statistics: ClusterStatistics[]
}
type TimeStatisticsData = YearStatistics[]

const parseStatistic = ({
  operation_name: operationName,
  result,
}: Statistic): {
  [x: string]: number
} => {
  return { [operationName]: result.value }
}

const parseCluster = ({
  group,
  statistics,
}: ClusterStatistics): {
  [x: number]: {
    [x: string]: number
  }
} => {
  return {
    [group]: merge({}, ...statistics.map(parseStatistic)) as {
      [x: string]: number
    },
  }
}

const parseYear = ({
  year,
  statistics,
}: YearStatistics): {
  [x: number]: {
    [x: number]: {
      [x: string]: number
    }
  }
} => {
  return {
    [year]: merge({}, ...statistics.map(parseCluster)) as {
      [x: number]: { [x: string]: number }
    },
  }
}

const parseResults = (
  data: TimeStatisticsData,
): {
  [x: number]: {
    [x: number]: {
      [x: string]: number
    }
  }
} => {
  return merge({}, ...data.map(parseYear)) as {
    [x: number]: { [x: number]: { [x: string]: number } }
  }
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: { margin: 20 },
})

const roundIt = (
  attribute: string,
  row: { [key: string]: number },
): string | number => {
  if (row[attribute] === undefined) {
    return '-'
  }
  if (attribute.includes('ratio')) {
    // FIXME too hacky
    return `${round(row[attribute] * 100, 2)}%`
  }
  return round(row[attribute], 2)
}

const ClusterTable = ({
  year,
  table,
}: {
  year: string
  table: { [x: number]: { [x: string]: number } }
}): React.JSX.Element => {
  const classes = useStyles()
  const clusters = keys(table)
  const rows = uniq(flatten(map(table, (t) => keys(t))))
  return (
    <div className={classes.container}>
      <Typography variant="h5">{year}</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cluster</TableCell>
              {clusters.map((c) => (
                <TableCell key={c}>
                  <b>{parseInt(c)}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row}>
                <TableCell component="th" scope="row">
                  <b>{row}</b>
                </TableCell>
                {clusters.map((c) => (
                  <TableCell key={c}>
                    {roundIt(row, table[parseInt(c)])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const TimeStatisticsWidget = ({ outputs }: Props): React.JSX.Element => {
  const [{ loading, value }]: ResultsData<
    TimeStatisticsData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    { [id: string]: never }
  > = useData(outputs.time_statistics, {})

  if (loading) {
    return <div>Loading...</div>
  }
  if (value === undefined) {
    return <></>
  }
  const tables = parseResults(value.results)

  return (
    <div>
      {map(tables, (table, year) => (
        <ClusterTable key={year} table={table} year={year} />
      ))}
    </div>
  )
}

export default TimeStatisticsWidget
