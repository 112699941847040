import type { TreeItem } from '../../../api/providers'

export const MENU_ITEMS_GET = 'MENU_ITEMS_GET'
export const MENU_ITEMS_GET_SUCCESS = 'MENU_ITEMS_GET_SUCCESS'
export const MENU_TOGGLE = 'MENU_TOGGLE'
export const MENU_CLOSE = 'MENU_CLOSE'
export const MENU_OPEN = 'MENU_OPEN'
export const MENU_TOGGLE_OPEN_ITEM = 'MENU_TOGGLE_OPEN_ITEM'
export const MENU_SELECT_ITEM = 'MENU_SELECT_ITEM'
export const MENU_NEW_ITEM = 'MENU_NEW_ITEM'
export const MENU_TOGGLE_ARCHIVED_ITEM = 'MENU_TOGGLE_ARCHIVED_ITEM'

export interface MenuItemsGetAction {
  type: typeof MENU_ITEMS_GET
}

export interface MenuToggleArchivedItemAction {
  type: typeof MENU_TOGGLE_ARCHIVED_ITEM
  payload: { folderId: string }
}

export interface MenuNewItemAction {
  type: typeof MENU_NEW_ITEM
  payload: { folderId: string; experimentId: string; openItem: boolean }
}

export interface MenuItemsGetSuccessAction {
  type: typeof MENU_ITEMS_GET_SUCCESS
  payload: { items: TreeItem[] }
}

export interface MenuToggleAction {
  type: typeof MENU_TOGGLE
}

export interface MenuCloseAction {
  type: typeof MENU_CLOSE
}

export interface MenuOpenAction {
  type: typeof MENU_OPEN
}

export interface MenuToggleOpenItemAction {
  type: typeof MENU_TOGGLE_OPEN_ITEM
  payload: { folderId: string; isOpen?: boolean }
}

export interface MenuSelectItemAction {
  type: typeof MENU_SELECT_ITEM
  payload: { experimentId?: string; folderId: string }
}

export type MenuAction =
  | MenuItemsGetAction
  | MenuItemsGetSuccessAction
  | MenuToggleAction
  | MenuCloseAction
  | MenuOpenAction
  | MenuToggleOpenItemAction
  | MenuSelectItemAction
  | MenuToggleArchivedItemAction

export interface MenuState {
  open: boolean
  items: TreeItem[]
}
