import _ from 'lodash'
import type { AppState } from '../../types'
import type { LayoutItem } from './types'

// The selected item is always the first item.
export const selectedLayoutItem = ({
  layout,
}: AppState): LayoutItem | undefined => {
  return _.head(layout.items)
}
