import React from 'react'

export interface LogoProps {
  width?: number
  height?: number
}

export const AmalfiLogo: React.FunctionComponent<LogoProps> = (
  props: LogoProps,
): React.JSX.Element => {
  const baseWidth = 47
  const baseHeight = 24
  const { width, height } = props
  const measures = getActualMeasurments(width, height, baseWidth, baseHeight)

  return (
    <svg
      width={measures.width}
      height={measures.height}
      viewBox="0 0 47 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.0711 2.64844L44.0721 3.17451C43.3462 3.17549 42.73 3.42726 42.2235 3.92787C41.717 4.43043 41.4652 5.06133 41.4662 5.82644L41.4721 9.62945L44.1005 9.62553L44.1015 10.2545L41.4731 10.2584L41.4789 14.5218L40.8774 14.5228L40.8647 5.82839C40.8637 4.90164 41.1753 4.14045 41.8003 3.54482C42.4253 2.94821 43.1816 2.6504 44.0711 2.64844Z"
        fill="black"
      />
      <path
        d="M46.1391 3.61438C45.7756 3.61536 45.5934 3.44686 45.5934 3.10888C45.5925 2.7709 45.7747 2.60142 46.1371 2.60044C46.4996 2.59946 46.6818 2.76894 46.6818 3.10692C46.6828 3.44588 46.5016 3.61438 46.1391 3.61438ZM46.4418 5.68047L46.4546 14.1124L45.853 14.1133L45.8403 5.68145L46.4418 5.68047Z"
        fill="black"
      />
      <path
        d="M5.54134 18.465L5.54721 22.3239H5.27291L5.27193 21.3638C5.11225 21.69 4.87223 21.9477 4.55287 22.1368C4.23252 22.3258 3.88964 22.4209 3.52423 22.4209C2.96387 22.4218 2.48384 22.2239 2.08316 21.8272C1.68248 21.4304 1.48165 20.9553 1.48067 20.4008C1.47969 19.8463 1.67954 19.3702 2.07924 18.9725C2.47894 18.5747 2.95897 18.3749 3.51933 18.3749C3.88474 18.3739 4.23546 18.4709 4.5705 18.6649C4.90554 18.8588 5.1387 19.1155 5.27095 19.4359L5.26997 18.467L5.54134 18.465ZM1.75399 20.4086C1.75497 20.8887 1.93131 21.3041 2.28301 21.6557C2.6347 22.0065 3.05302 22.1818 3.53598 22.1808C4.01895 22.1799 4.43139 22.0035 4.77427 21.6518C5.11715 21.3001 5.28761 20.8828 5.28663 20.3989C5.28565 19.9159 5.11225 19.4976 4.76643 19.1429C4.41963 18.7893 4.0072 18.612 3.52619 18.6129C3.04616 18.6139 2.63078 18.7932 2.27909 19.1508C1.92837 19.5093 1.75301 19.9286 1.75399 20.4086Z"
        fill="black"
      />
      <path
        d="M8.56451 22.3199H8.29021L8.28433 18.4611H8.55864L8.55962 19.2419C8.85057 18.658 9.34726 18.3661 10.0506 18.3651C10.5307 18.3641 10.9343 18.5297 11.2605 18.8608C11.5867 19.1919 11.7503 19.5946 11.7513 20.0687L11.7543 22.315H11.48L11.477 20.0687C11.476 19.6739 11.3369 19.331 11.0597 19.0401C10.7815 18.7491 10.4464 18.6031 10.0516 18.6041C9.65683 18.6051 9.31003 18.7511 9.01026 19.043C8.71048 19.3349 8.56059 19.6778 8.56157 20.0726L8.56451 22.3199Z"
        fill="black"
      />
      <path
        d="M18.3425 18.4464L18.3484 22.3052H18.0741L18.0731 21.3452C17.9134 21.6714 17.6734 21.9291 17.354 22.1181C17.0337 22.3072 16.6908 22.4022 16.3254 22.4022C15.765 22.4032 15.285 22.2053 14.8843 21.8086C14.4836 21.4118 14.2828 20.9367 14.2818 20.3822C14.2808 19.8277 14.4807 19.3516 14.8804 18.9539C15.2801 18.5561 15.7601 18.3563 16.3205 18.3563C16.6859 18.3563 17.0366 18.4523 17.3717 18.6462C17.7067 18.8402 17.9399 19.0969 18.0721 19.4172L18.0711 18.4483L18.3425 18.4464ZM14.5552 20.39C14.5561 20.8701 14.7325 21.2854 15.0842 21.6371C15.4359 21.9878 15.8542 22.1632 16.3371 22.1622C16.8201 22.1612 17.2325 21.9849 17.5754 21.6332C17.9183 21.2815 18.0888 20.8642 18.0878 20.3802C18.0868 19.8973 17.9134 19.4789 17.5676 19.1243C17.2208 18.7707 16.8084 18.5933 16.3273 18.5943C15.8473 18.5953 15.4319 18.7746 15.0802 19.1321C14.7305 19.4907 14.5552 19.91 14.5552 20.39Z"
        fill="black"
      />
      <path
        d="M22.5579 22.1357V22.3758C22.1523 22.3767 21.8056 22.2415 21.5195 21.9702C21.2334 21.6988 21.0894 21.352 21.0894 20.9288L21.0816 15.8699H21.3559L21.3637 20.9288C21.3637 21.2776 21.4803 21.5666 21.7125 21.7938C21.9447 22.0231 22.2268 22.1367 22.5579 22.1357Z"
        fill="black"
      />
      <path
        d="M28.0577 18.4327H28.332L28.3379 22.1711C28.3388 22.6795 28.1615 23.1115 27.8079 23.4662C27.4542 23.8208 27.0202 23.9991 26.5049 24.0001C25.8877 24.0011 25.3989 23.764 25.0374 23.2908L25.0541 22.8617C25.2147 23.136 25.4253 23.3545 25.685 23.5171C25.9455 23.6797 26.2179 23.761 26.5039 23.7601C26.9272 23.7591 27.2926 23.6063 27.6012 23.2996C27.9097 22.993 28.0636 22.6256 28.0626 22.1975L28.0616 21.546C27.7824 22.1064 27.2935 22.3876 26.596 22.3886C26.1219 22.3895 25.7153 22.223 25.3773 21.8889C25.0394 21.5549 24.8699 21.1542 24.8699 20.6849L24.8669 18.4386H25.1412L25.1442 20.6849C25.1452 21.0797 25.2892 21.4216 25.5792 21.7136C25.8682 22.0045 26.21 22.1505 26.6049 22.1495C26.9997 22.1485 27.3406 22.0026 27.6286 21.7106C27.9166 21.4187 28.0606 21.0758 28.0596 20.681L28.0577 18.4327Z"
        fill="black"
      />
      <path
        d="M32.376 22.0956V22.3356C31.9704 22.3366 31.6236 22.2014 31.3375 21.93C31.0515 21.6587 30.9084 21.3119 30.9075 20.8887L30.9016 16.876H31.1759L31.1778 18.4366L32.3613 18.4346V18.6747L31.1778 18.6766L31.1808 20.8887C31.1818 21.2374 31.2974 21.5254 31.5295 21.7537C31.7627 21.9829 32.0448 22.0966 32.376 22.0956Z"
        fill="black"
      />
      <path
        d="M35.0191 17.4873C34.8536 17.4873 34.7703 17.4109 34.7703 17.2561C34.7703 17.1013 34.8526 17.0249 35.0191 17.024C35.1847 17.024 35.268 17.1004 35.268 17.2552C35.268 17.4099 35.1847 17.4873 35.0191 17.4873ZM35.1573 18.4307L35.1631 22.2808H34.8888L34.883 18.4307H35.1573Z"
        fill="black"
      />
      <path
        d="M39.888 22.3856H39.8106C39.262 22.3866 38.7898 22.1847 38.395 21.7792C38.0002 21.3736 37.8023 20.8985 37.8013 20.3528C37.8004 19.8071 38.0002 19.3349 38.3999 18.9372C38.7996 18.5395 39.2904 18.3396 39.8743 18.3396C40.4572 18.3386 40.9549 18.5581 41.3673 18.9979L41.3683 19.4349C41.191 19.1723 40.9656 18.9636 40.6943 18.8098C40.4229 18.656 40.1378 18.5796 39.841 18.5796C39.3668 18.5806 38.9534 18.7569 38.6027 19.1086C38.252 19.4603 38.0766 19.8796 38.0776 20.3655C38.0786 20.8514 38.2549 21.2697 38.6066 21.6205C38.9583 21.9712 39.3717 22.1465 39.8469 22.1465C40.1437 22.1465 40.4278 22.0682 40.6992 21.9134C40.9705 21.7586 41.1949 21.547 41.3712 21.2776L41.3722 21.7233C40.9705 22.1642 40.4768 22.3846 39.888 22.3856Z"
        fill="black"
      />
      <path
        d="M44.6501 18.5453C44.5012 18.5453 44.3699 18.5943 44.2562 18.6913C44.1416 18.7893 44.0848 18.9088 44.0848 19.0518C44.0848 19.1949 44.1495 19.3261 44.2788 19.4456C44.4071 19.5652 44.5619 19.6749 44.7421 19.7748C44.9224 19.8747 45.1036 19.9815 45.2868 20.0952C45.47 20.2098 45.6258 20.3548 45.7551 20.5321C45.8844 20.7094 45.9491 20.9122 45.9491 21.1404C45.9491 21.4774 45.8227 21.7655 45.569 22.0025C45.3152 22.2406 45.0076 22.3591 44.6481 22.3601C44.4198 22.3601 44.2063 22.3111 44.0094 22.2112C43.8125 22.1113 43.6675 21.979 43.5754 21.8135L43.5744 21.402C43.7919 21.882 44.1416 22.1211 44.6217 22.1211C44.9185 22.1211 45.1683 22.029 45.3711 21.8458C45.5739 21.6626 45.6748 21.4579 45.6748 21.2325C45.6748 21.0072 45.6101 20.8172 45.4808 20.6624C45.3515 20.5086 45.1957 20.3773 45.0125 20.2686C44.8293 20.1598 44.6481 20.053 44.4678 19.9482C44.2876 19.8424 44.1328 19.7141 44.0045 19.5632C43.8761 19.4123 43.8115 19.235 43.8105 19.0322C43.8095 18.8294 43.8918 18.658 44.0544 18.5169C44.2171 18.3768 44.4267 18.3063 44.6843 18.3063C44.942 18.3063 45.1614 18.4053 45.3456 18.6051V18.9568C45.1614 18.6815 44.9302 18.5453 44.6501 18.5453Z"
        fill="black"
      />
      <path
        d="M46.9255 15.2684L6.86646e-05 15.3369L0.000783689 15.8268L46.9262 15.7583L46.9255 15.2684Z"
        fill="#4BBD5B"
      />
      <path
        d="M44.1223 9.40068L40.8699 9.40576L40.8714 10.3854L44.1238 10.3803L44.1223 9.40068Z"
        fill="#4BBD5B"
      />
      <path
        d="M2.06059 7.21658C2.82864 6.43286 3.73874 6.04198 4.79088 6.041C5.84303 6.03904 6.74823 6.42404 7.50648 7.19895C8.14326 7.84846 8.51161 8.56459 8.61545 9.44627L9.20129 9.4453L9.19541 5.72261L8.60762 5.72359L8.61055 7.83964C8.31568 7.13919 7.80822 6.57687 7.07544 6.15072C6.34266 5.72653 5.57363 5.51492 4.77227 5.5159C3.54574 5.51786 2.4936 5.95087 1.61876 6.82276C0.86443 7.57514 0.435342 8.38041 0.333458 9.45901L0.941823 9.45803C1.04567 8.57536 1.41794 7.87099 2.06059 7.21658Z"
        fill="black"
      />
      <path
        d="M7.52314 12.6919C6.77272 13.4629 5.8685 13.8518 4.81145 13.8528C3.75245 13.8547 2.83941 13.4678 2.06842 12.6987C1.41499 12.0473 1.03881 11.2204 0.937901 10.4367L0.334435 10.4377C0.439257 11.4173 0.870305 12.3265 1.62758 13.0759C2.50535 13.9449 3.5575 14.3808 4.78402 14.3788C5.58538 14.3779 6.33873 14.1712 7.03919 13.7568C7.73964 13.3424 8.22653 12.78 8.61741 12.0659L8.62035 14.1496L9.20814 14.1486L9.20226 10.4259L8.61937 10.4269C8.5214 11.2097 8.15795 12.0404 7.52314 12.6919Z"
        fill="black"
      />
      <path
        d="M12.0403 8.52832C12.0394 7.8651 12.299 7.28318 12.8006 6.78062C13.3002 6.27904 13.8929 6.02824 14.5698 6.02726C15.2467 6.02628 15.7846 6.27316 16.2607 6.7669C16.7368 7.26065 16.9376 7.8455 16.9386 8.52146L16.9396 9.43352L17.6253 9.43254L17.6244 8.52048C17.6234 7.85726 17.8801 7.27534 18.3993 6.77278C18.9185 6.2712 19.5112 6.02041 20.1813 6.01943C20.8504 6.01845 21.4264 6.25944 21.9025 6.74045C22.3786 7.22244 22.6196 7.82591 22.6206 8.55085L22.6216 9.42568L23.2094 9.4247L23.2084 8.54987C23.2074 7.61136 22.9223 6.86683 22.3453 6.31626C21.7683 5.76668 21.1002 5.49139 20.337 5.49237C19.5729 5.49335 18.9263 5.67655 18.3954 6.04C17.8634 6.40443 17.5049 6.8874 17.3177 7.48793C17.0914 6.90014 16.7339 6.42206 16.246 6.05274C15.7571 5.68439 15.2467 5.50021 14.7148 5.50119C14.1819 5.50217 13.6587 5.6403 13.165 5.91656C12.6703 6.19381 12.2333 6.52003 12.0384 6.89524L12.0364 5.71867L11.4486 5.71965L11.4545 9.44234L12.0423 9.44136L12.0403 8.52832Z"
        fill="black"
      />
      <path
        d="M23.2106 10.4049L22.6228 10.4058L22.6282 14.1285L23.216 14.1276L23.2106 10.4049Z"
        fill="black"
      />
      <path
        d="M12.0425 10.4206L11.4547 10.4215L11.4601 14.1442L12.0479 14.1433L12.0425 10.4206Z"
        fill="black"
      />
      <path
        d="M17.6265 10.4127L16.9408 10.4137L16.9462 14.1363L17.6319 14.1353L17.6265 10.4127Z"
        fill="black"
      />
      <path
        d="M26.7547 7.18033C27.5238 6.3966 28.4339 6.00572 29.485 6.00474C30.5362 6.00278 31.4414 6.38779 32.2006 7.16269C32.8374 7.8122 33.2058 8.52833 33.3096 9.41002L33.8876 9.40904L33.8817 5.68636L33.2939 5.68733L33.2969 7.80339C33.002 7.10293 32.4985 6.54061 31.7657 6.11446C31.0319 5.69027 30.2658 5.47867 29.4645 5.47965C28.237 5.48161 27.1868 5.91461 26.3119 6.78651C25.5566 7.53888 25.1295 8.34416 25.0276 9.42276L25.635 9.42178C25.7398 8.53911 26.1121 7.83474 26.7547 7.18033Z"
        fill="black"
      />
      <path
        d="M32.2183 12.6556C31.4679 13.4266 30.5636 13.8155 29.5056 13.8165C28.4476 13.8184 27.5336 13.4315 26.7626 12.6624C26.1091 12.011 25.733 11.1841 25.6321 10.4004L25.0296 10.4014C25.1344 11.3811 25.5645 12.2902 26.3217 13.0396C27.1995 13.9086 28.2517 14.3445 29.4782 14.3426C30.2795 14.3416 31.029 14.1349 31.7294 13.7205C32.4299 13.3061 32.9128 12.7438 33.3037 12.0296L33.3067 14.1133L33.8945 14.1123L33.8886 10.3896L33.3135 10.3906C33.2165 11.1734 32.8521 12.0041 32.2183 12.6556Z"
        fill="black"
      />
      <path
        d="M36.7149 -4.45834e-06L36.1271 0.000854492L36.1408 9.40553L36.7286 9.40467L36.7149 -4.45834e-06Z"
        fill="black"
      />
      <path
        d="M36.1428 10.3857L36.1437 11.1234C36.1447 12.0492 36.47 12.8104 37.0969 13.4031C37.7239 13.9977 38.4018 14.2936 39.3815 14.2926L39.3805 13.7665C38.5968 13.7675 38.0198 13.5177 37.5123 13.0181C37.0039 12.5175 36.7325 11.8856 36.7315 11.1224L36.7306 10.3848L36.1428 10.3857Z"
        fill="black"
      />
    </svg>
  )
}

export const ByAmalfi: React.FunctionComponent<LogoProps> = (
  props: LogoProps,
) => {
  const baseWidth = 50
  const baseHeight = 18
  const { width, height } = props
  const measures = getActualMeasurments(width, height, baseWidth, baseHeight)

  return (
    <svg
      width={measures.width}
      height={measures.height}
      viewBox="0 0 50 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.3031 2.06964L47.3038 2.48075C46.7365 2.48152 46.255 2.67827 45.8592 3.06947C45.4634 3.46221 45.2667 3.95523 45.2674 4.55314L45.272 7.52506L47.326 7.522L47.3268 8.01349L45.2728 8.01656L45.2774 11.3483L44.8073 11.3491L44.7974 4.55467C44.7966 3.83044 45.04 3.2356 45.5285 2.77013C46.0169 2.3039 46.6079 2.07117 47.3031 2.06964Z"
        fill="white"
      />
      <path
        d="M48.9192 2.82453C48.6351 2.8253 48.4927 2.69362 48.4927 2.4295C48.492 2.16538 48.6344 2.03293 48.9176 2.03217C49.2009 2.0314 49.3433 2.16385 49.3433 2.42797C49.3441 2.69285 49.2024 2.82453 48.9192 2.82453ZM49.1557 4.43911L49.1657 11.0283L48.6956 11.0291L48.6857 4.43987L49.1557 4.43911Z"
        fill="white"
      />
      <path
        d="M17.1934 14.4298L17.1979 17.4453H16.9836L16.9828 16.6951C16.858 16.95 16.6705 17.1513 16.4209 17.2991C16.1706 17.4468 15.9026 17.5211 15.6171 17.5211C15.1792 17.5219 14.804 17.3672 14.4909 17.0572C14.1778 16.7471 14.0208 16.3758 14.0201 15.9425C14.0193 15.5092 14.1755 15.1371 14.4878 14.8263C14.8002 14.5155 15.1753 14.3593 15.6132 14.3593C15.8988 14.3586 16.1729 14.4343 16.4347 14.5859C16.6965 14.7375 16.8787 14.9381 16.9821 15.1884L16.9813 14.4313L17.1934 14.4298ZM14.2337 15.9486C14.2344 16.3238 14.3722 16.6484 14.6471 16.9232C14.9219 17.1973 15.2488 17.3343 15.6262 17.3335C16.0037 17.3328 16.326 17.195 16.5939 16.9201C16.8619 16.6453 16.9951 16.3192 16.9943 15.941C16.9935 15.5636 16.858 15.2367 16.5878 14.9595C16.3168 14.6832 15.9945 14.5446 15.6186 14.5454C15.2435 14.5461 14.9189 14.6862 14.644 14.9656C14.3699 15.2458 14.2329 15.5735 14.2337 15.9486Z"
        fill="white"
      />
      <path
        d="M19.5559 17.4422H19.3415L19.3369 14.4267H19.5513L19.552 15.0368C19.7794 14.5805 20.1676 14.3524 20.7172 14.3516C21.0924 14.3509 21.4078 14.4802 21.6627 14.739C21.9176 14.9978 22.0455 15.3124 22.0463 15.6829L22.0485 17.4384H21.8342L21.8319 15.6829C21.8311 15.3744 21.7224 15.1065 21.5058 14.8791C21.2883 14.6517 21.0265 14.5377 20.718 14.5384C20.4095 14.5392 20.1385 14.6533 19.9042 14.8814C19.6699 15.1095 19.5528 15.3775 19.5536 15.686L19.5559 17.4422Z"
        fill="white"
      />
      <path
        d="M27.197 14.4152L27.2016 17.4308H26.9873L26.9865 16.6805C26.8617 16.9355 26.6741 17.1368 26.4246 17.2846C26.1742 17.4323 25.9063 17.5066 25.6207 17.5066C25.1828 17.5073 24.8077 17.3527 24.4946 17.0426C24.1815 16.7326 24.0245 16.3613 24.0237 15.928C24.023 15.4947 24.1792 15.1226 24.4915 14.8118C24.8039 14.501 25.179 14.3448 25.6169 14.3448C25.9024 14.3448 26.1765 14.4198 26.4383 14.5714C26.7002 14.723 26.8824 14.9236 26.9857 15.1739L26.985 14.4168L27.197 14.4152ZM24.2373 15.9341C24.2381 16.3092 24.3759 16.6338 24.6507 16.9087C24.9256 17.1827 25.2525 17.3198 25.6299 17.319C26.0073 17.3182 26.3296 17.1804 26.5976 16.9056C26.8655 16.6308 26.9987 16.3046 26.998 15.9264C26.9972 15.549 26.8617 15.2221 26.5915 14.945C26.3204 14.6686 25.9981 14.5301 25.6222 14.5308C25.2471 14.5316 24.9225 14.6717 24.6477 14.9511C24.3744 15.2313 24.2373 15.559 24.2373 15.9341Z"
        fill="white"
      />
      <path
        d="M30.4913 17.2983V17.4859C30.1743 17.4866 29.9033 17.381 29.6798 17.1689C29.4562 16.9568 29.3437 16.6858 29.3437 16.3551L29.3376 12.4017H29.5519L29.5581 16.3551C29.5581 16.6277 29.6492 16.8535 29.8306 17.0311C30.012 17.2103 30.2325 17.2991 30.4913 17.2983Z"
        fill="white"
      />
      <path
        d="M34.7892 14.4045H35.0035L35.0081 17.3259C35.0089 17.7232 34.8703 18.0608 34.5939 18.338C34.3176 18.6151 33.9784 18.7544 33.5757 18.7552C33.0934 18.756 32.7114 18.5707 32.4289 18.2009L32.4419 17.8656C32.5675 18.08 32.7321 18.2507 32.935 18.3778C33.1386 18.5049 33.3514 18.5684 33.575 18.5676C33.9057 18.5669 34.1913 18.4474 34.4324 18.2078C34.6736 17.9682 34.7938 17.6811 34.793 17.3465L34.7922 16.8374C34.574 17.2754 34.192 17.4951 33.6469 17.4958C33.2764 17.4966 32.9587 17.3665 32.6946 17.1054C32.4305 16.8443 32.298 16.5312 32.298 16.1645L32.2957 14.4091H32.5101L32.5124 16.1645C32.5131 16.473 32.6257 16.7402 32.8523 16.9684C33.0781 17.1957 33.3453 17.3098 33.6538 17.309C33.9624 17.3083 34.2288 17.1942 34.4538 16.9661C34.6789 16.7379 34.7915 16.47 34.7907 16.1615L34.7892 14.4045Z"
        fill="white"
      />
      <path
        d="M38.1637 17.2669V17.4545C37.8468 17.4553 37.5758 17.3496 37.3522 17.1375C37.1287 16.9255 37.0169 16.6545 37.0161 16.3237L37.0115 13.188H37.2259L37.2274 14.4075L38.1522 14.406V14.5936L37.2274 14.5951L37.2297 16.3237C37.2305 16.5963 37.3208 16.8214 37.5023 16.9997C37.6845 17.1789 37.905 17.2677 38.1637 17.2669Z"
        fill="white"
      />
      <path
        d="M40.2292 13.6657C40.0999 13.6657 40.0348 13.606 40.0348 13.485C40.0348 13.3641 40.0991 13.3044 40.2292 13.3036C40.3586 13.3036 40.4237 13.3633 40.4237 13.4843C40.4237 13.6052 40.3586 13.6657 40.2292 13.6657ZM40.3372 14.4029L40.3418 17.4116H40.1274L40.1228 14.4029H40.3372Z"
        fill="white"
      />
      <path
        d="M44.0341 17.4935H43.9736C43.5449 17.4943 43.1759 17.3366 42.8674 17.0196C42.5589 16.7027 42.4042 16.3314 42.4034 15.905C42.4027 15.4785 42.5589 15.1095 42.8712 14.7987C43.1836 14.4879 43.5671 14.3317 44.0234 14.3317C44.4789 14.331 44.8678 14.5024 45.1901 14.8462L45.1909 15.1876C45.0523 14.9825 44.8762 14.8194 44.6642 14.6992C44.4521 14.579 44.2293 14.5193 43.9974 14.5193C43.6268 14.5201 43.3038 14.6579 43.0297 14.9327C42.7556 15.2075 42.6186 15.5352 42.6193 15.9149C42.6201 16.2946 42.7579 16.6215 43.0327 16.8956C43.3076 17.1697 43.6306 17.3067 44.0019 17.3067C44.2339 17.3067 44.4559 17.2455 44.668 17.1245C44.8801 17.0036 45.0554 16.8382 45.1932 16.6277L45.1939 16.976C44.8801 17.3205 44.4942 17.4928 44.0341 17.4935Z"
        fill="white"
      />
      <path
        d="M47.7555 14.4925C47.6391 14.4925 47.5366 14.5308 47.4478 14.6066C47.3582 14.6831 47.3138 14.7765 47.3138 14.8883C47.3138 15.0001 47.3643 15.1027 47.4654 15.1961C47.5656 15.2895 47.6866 15.3752 47.8275 15.4533C47.9683 15.5314 48.11 15.6148 48.2531 15.7036C48.3963 15.7932 48.518 15.9065 48.6191 16.0451C48.7201 16.1837 48.7706 16.3421 48.7706 16.5205C48.7706 16.7839 48.6719 17.0089 48.4736 17.1942C48.2753 17.3802 48.0349 17.4729 47.754 17.4736C47.5756 17.4736 47.4087 17.4354 47.2548 17.3573C47.1009 17.2792 46.9876 17.1758 46.9157 17.0465L46.9149 16.7249C47.0849 17.1 47.3582 17.2868 47.7333 17.2868C47.9653 17.2868 48.1605 17.2149 48.319 17.0717C48.4774 16.9286 48.5563 16.7685 48.5563 16.5925C48.5563 16.4164 48.5058 16.2679 48.4047 16.1469C48.3037 16.0267 48.1819 15.9241 48.0388 15.8392C47.8956 15.7542 47.754 15.6707 47.6131 15.5888C47.4722 15.5061 47.3513 15.4058 47.251 15.2879C47.1507 15.17 47.1002 15.0315 47.0994 14.873C47.0987 14.7145 47.163 14.5806 47.29 14.4703C47.4171 14.3608 47.581 14.3057 47.7823 14.3057C47.9836 14.3057 48.1551 14.383 48.2991 14.5392V14.8141C48.1551 14.5989 47.9745 14.4925 47.7555 14.4925Z"
        fill="white"
      />
      <path
        d="M49.5336 11.9318L12.863 11.9853L12.8636 12.3681L49.5342 12.3145L49.5336 11.9318Z"
        fill="white"
      />
      <path
        d="M47.3431 7.34631L44.8014 7.35028L44.8026 8.11585L47.3443 8.11188L47.3431 7.34631Z"
        fill="white"
      />
      <path
        d="M14.4733 5.63951C15.0735 5.02706 15.7847 4.7216 16.6069 4.72083C17.4291 4.7193 18.1365 5.02017 18.7291 5.62573C19.2267 6.1333 19.5145 6.69293 19.5957 7.38194L20.0535 7.38117L20.0489 4.47202L19.5896 4.47279L19.5919 6.12641C19.3614 5.57903 18.9649 5.1396 18.3922 4.80658C17.8196 4.47509 17.2186 4.30972 16.5924 4.31049C15.6339 4.31202 14.8117 4.6504 14.128 5.33175C13.5385 5.91971 13.2032 6.549 13.1236 7.39189L13.599 7.39113C13.6802 6.70135 13.9711 6.15091 14.4733 5.63951Z"
        fill="white"
      />
      <path
        d="M18.7421 9.91828C18.1557 10.5208 17.4491 10.8247 16.623 10.8255C15.7954 10.827 15.0819 10.5246 14.4794 9.92363C13.9688 9.41453 13.6748 8.7684 13.596 8.15594L13.1244 8.15671C13.2063 8.92227 13.5432 9.63272 14.1349 10.2184C14.8209 10.8974 15.6431 11.2381 16.6016 11.2366C17.2278 11.2358 17.8165 11.0743 18.3639 10.7504C18.9113 10.4266 19.2918 9.98718 19.5972 9.42908L19.5995 11.0574L20.0589 11.0567L20.0543 8.14752L19.5988 8.14829C19.5222 8.75997 19.2382 9.40918 18.7421 9.91828Z"
        fill="white"
      />
      <path
        d="M22.2722 6.66461C22.2714 6.14632 22.4743 5.69157 22.8662 5.29884C23.2567 4.90687 23.7198 4.71088 24.2488 4.71012C24.7779 4.70935 25.1981 4.90227 25.5702 5.28812C25.9423 5.67396 26.0992 6.13101 26.1 6.65925L26.1007 7.37199L26.6366 7.37122L26.6359 6.65848C26.6351 6.14019 26.8357 5.68545 27.2414 5.29271C27.6472 4.90074 28.1104 4.70476 28.634 4.70399C29.1569 4.70322 29.607 4.89155 29.9791 5.26745C30.3512 5.64411 30.5395 6.11569 30.5403 6.68221L30.541 7.36586L31.0004 7.3651L30.9996 6.68145C30.9988 5.94803 30.7761 5.36621 30.3251 4.93596C29.8742 4.50647 29.3521 4.29135 28.7557 4.29212C28.1586 4.29288 27.6533 4.43604 27.2384 4.72007C26.8227 5.00486 26.5425 5.38228 26.3963 5.85157C26.2194 5.39223 25.94 5.01864 25.5587 4.73002C25.1767 4.44217 24.7779 4.29824 24.3621 4.29901C23.9457 4.29977 23.5369 4.40772 23.151 4.62361C22.7644 4.84026 22.423 5.09519 22.2706 5.38841L22.2691 4.46896L21.8098 4.46973L21.8143 7.37888L22.2737 7.37811L22.2722 6.66461Z"
        fill="white"
      />
      <path
        d="M31.0013 8.1311L30.542 8.13177L30.5462 11.0409L31.0056 11.0403L31.0013 8.1311Z"
        fill="white"
      />
      <path
        d="M22.2739 8.14337L21.8145 8.14404L21.8188 11.0532L22.2781 11.0525L22.2739 8.14337Z"
        fill="white"
      />
      <path
        d="M26.6376 8.13716L26.1017 8.13794L26.1059 11.0471L26.6418 11.0463L26.6376 8.13716Z"
        fill="white"
      />
      <path
        d="M33.771 5.61119C34.3719 4.99874 35.0831 4.69328 35.9046 4.69251C36.726 4.69098 37.4334 4.99185 38.0267 5.59741C38.5244 6.10498 38.8122 6.66461 38.8934 7.35362L39.345 7.35285L39.3404 4.4437L38.8811 4.44447L38.8834 6.09809C38.653 5.55071 38.2595 5.11128 37.6868 4.77825C37.1134 4.44676 36.5147 4.2814 35.8885 4.28217C34.9293 4.2837 34.1086 4.62208 33.4249 5.30343C32.8347 5.89139 32.5009 6.52068 32.4213 7.36357L32.8959 7.3628C32.9778 6.67303 33.2687 6.12259 33.771 5.61119Z"
        fill="white"
      />
      <path
        d="M38.0405 9.88989C37.4541 10.4924 36.7475 10.7963 35.9207 10.7971C35.0938 10.7986 34.3796 10.4962 33.7771 9.89525C33.2664 9.38615 32.9725 8.74001 32.8936 8.12756L32.4228 8.12833C32.5047 8.89389 32.8408 9.60434 33.4326 10.19C34.1185 10.8691 34.9407 11.2097 35.8992 11.2082C36.5255 11.2074 37.1111 11.0459 37.6585 10.7221C38.2059 10.3982 38.5833 9.9588 38.8888 9.4007L38.8911 11.0291L39.3504 11.0283L39.3458 8.11914L38.8964 8.11991C38.8206 8.73159 38.5358 9.38079 38.0405 9.88989Z"
        fill="white"
      />
      <path
        d="M41.5544 1.46551e-07L41.0951 0.000671387L41.1058 7.3501L41.5652 7.34943L41.5544 1.46551e-07Z"
        fill="white"
      />
      <path
        d="M41.1074 8.11612L41.1081 8.69259C41.1089 9.41605 41.3631 10.0109 41.853 10.4741C42.343 10.9388 42.8728 11.17 43.6383 11.1692L43.6376 10.7581C43.0251 10.7589 42.5742 10.5636 42.1776 10.1732C41.7803 9.78199 41.5682 9.2882 41.5675 8.69183L41.5667 8.11536L41.1074 8.11612Z"
        fill="white"
      />
      <path
        d="M0 11.6681V4.30219H0.89511V7.33256C1.10514 7.08919 1.34516 6.89417 1.6152 6.74748C1.88856 6.59747 2.20027 6.52246 2.55031 6.52246C2.84368 6.52246 3.10871 6.57746 3.34541 6.68748C3.58211 6.79749 3.7838 6.96251 3.95048 7.18254C4.11717 7.39923 4.24552 7.66926 4.33553 7.99264C4.42554 8.31268 4.47055 8.68272 4.47055 9.10277C4.47055 9.47615 4.42054 9.82453 4.32053 10.1479C4.22052 10.4679 4.0755 10.7463 3.88548 10.983C3.69879 11.2164 3.46876 11.4014 3.19539 11.5381C2.92536 11.6714 2.62032 11.7381 2.28028 11.7381C1.95357 11.7381 1.67521 11.6748 1.44518 11.5481C1.21848 11.4214 1.02013 11.2447 0.850104 11.018L0.805099 11.4781C0.778429 11.6047 0.701753 11.6681 0.575071 11.6681H0ZM2.26028 7.23254C1.97024 7.23254 1.71521 7.29922 1.49518 7.43257C1.27849 7.56592 1.07847 7.75428 0.89511 7.99764V10.4479C1.05513 10.668 1.23182 10.823 1.42517 10.913C1.62187 11.003 1.84023 11.048 2.08026 11.048C2.55365 11.048 2.91702 10.8797 3.17039 10.543C3.42375 10.2062 3.55044 9.72618 3.55044 9.10277C3.55044 8.77273 3.52043 8.48937 3.46042 8.25267C3.40375 8.01597 3.32041 7.82262 3.21039 7.6726C3.10038 7.51925 2.96536 7.40757 2.80534 7.33756C2.64532 7.26755 2.46364 7.23254 2.26028 7.23254Z"
        fill="#EBEBEB"
      />
      <path
        d="M6.91483 13.1633C6.88483 13.2299 6.84649 13.2833 6.79982 13.3233C6.75648 13.3633 6.68814 13.3833 6.59479 13.3833H5.93471L6.85983 11.3731L4.76957 6.60247H5.53966C5.61634 6.60247 5.67635 6.62247 5.71969 6.66247C5.76302 6.69915 5.79469 6.74082 5.8147 6.78749L7.16986 9.97788C7.19987 10.0512 7.22487 10.1246 7.24487 10.1979C7.26821 10.2712 7.28821 10.3463 7.30488 10.4229C7.32822 10.3463 7.35155 10.2712 7.37489 10.1979C7.39822 10.1246 7.42489 10.0496 7.4549 9.97288L8.77006 6.78749C8.79006 6.73415 8.8234 6.69081 8.87007 6.65747C8.92008 6.6208 8.97342 6.60247 9.03009 6.60247H9.74018L6.91483 13.1633Z"
        fill="#EBEBEB"
      />
    </svg>
  )
}

const getActualMeasurments = (
  width: number | undefined,
  height: number | undefined,
  baseWidth: number,
  baseHeight: number,
): {
  height: number | undefined
  width: number
} => {
  let actualHeight
  let actualWidth
  if (width !== undefined) {
    actualHeight = (width * baseHeight) / baseWidth
    actualWidth = width
  } else if (height !== undefined) {
    actualWidth = (height * baseWidth) / baseHeight
    actualHeight = width
  } else {
    throw Error('Height or Width should be defined')
  }
  return { height: actualHeight, width: actualWidth }
}

export const AAmalfi: React.FunctionComponent<LogoProps> = (
  props: LogoProps,
) => {
  const baseWidth = 43
  const baseHeight = 38
  const { width, height } = props
  const measures = getActualMeasurments(width, height, baseWidth, baseHeight)

  return (
    <svg
      width={measures.width}
      height={measures.height}
      viewBox="0 0 38 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M37.9999 40.0309H18.9279V43H37.9999V40.0309Z" fill="#EBEBEB" />
      <path
        d="M30.5962 29.4265C27.3966 32.5791 23.5506 34.16 19.0488 34.16C14.547 34.16 10.6591 32.5791 7.3851 29.4265C4.7017 26.8425 3.11585 23.8645 2.63219 20.5013H0.0650635C0.567328 24.487 2.38106 27.9755 5.50626 30.967C9.23603 34.5316 13.7053 36.314 18.9279 36.314C22.3368 36.314 25.5317 35.4676 28.5174 33.7748C31.5031 32.0821 33.7354 29.7758 35.2329 26.8515V35.4676H37.786V20.5013H35.2329C34.7585 23.8735 33.2145 26.847 30.5962 29.4265Z"
        fill="#EBEBEB"
      />
      <path
        d="M7.38979 6.96365C10.6638 3.75724 14.5378 2.15403 19.0116 2.15403C23.4855 2.15403 27.3362 3.7438 30.5591 6.92783C33.3215 9.65059 34.8981 12.8033 35.2934 16.3814H37.7907V0.846386H35.2375V9.54312C34.0098 6.66809 31.8426 4.3618 28.7267 2.61529C25.6062 0.873256 22.3415 0 18.9326 0C13.71 0 9.23608 1.78234 5.51095 5.34701C2.23229 8.48177 0.3953 12.1584 0 16.3814H2.58573C2.99498 12.8391 4.59014 9.69985 7.38979 6.96365Z"
        fill="#EBEBEB"
      />
    </svg>
  )
}
