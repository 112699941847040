import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _, { omit, isArray } from 'lodash'
import Grid from '@mui/material/Grid'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import TextField from '../../../form/fields/TextField'
import SelectField from '../../../form/fields/SelectField'
import { replaceParallel } from '../../../../lib/util'

// WARNING: The code is very similar to Unbounded string. I decided to keep it separated because this one may evolve to Autocomplete.

type Operation = 'eql' | 'regexp' | 'in' | 'interval'
type Value =
  | { eql: string }
  | { regexp: string }
  | { in: string[] }
  | { gte: string; lte: string }
  | string
  | undefined

const decode = (value: Value): { operation: string; items: string[] } => {
  if (_.isString(value) || value === undefined) {
    return {
      operation: 'eql',
      items: value !== undefined ? [value] : [],
    }
  }

  if ('in' in value) {
    return {
      operation: 'in',
      items: value.in,
    }
  }
  if ('gte' in value && 'lte' in value) {
    return {
      operation: 'interval',
      items: [value.gte, value.lte],
    }
  }
  if ('eql' in value) {
    return {
      operation: 'eql',
      items: [value.eql],
    }
  }
  if ('regexp' in value) {
    return {
      operation: 'regexp',
      items: [value.regexp],
    }
  }
  throw new Error('Unexpected operation in LargeDictionaryStringField decode.')
}

const encode = (operation: Operation, items: string[]): Value => {
  if (operation === 'in') {
    return { in: items.filter((item) => !_.isEmpty(item)) }
  } else if (operation === 'interval') {
    return {
      gte: items[0] ?? '',
      lte: items[1] ?? '',
    }
  }
  if (operation === 'eql') {
    return { eql: items[0] ?? '' }
  }
  if (operation === 'regexp') {
    return { regexp: items[0] ?? '' }
  }
  throw new Error('Unexpected operation in LargeDictionaryStringField encode.')
}

const parseOperations = (ops: string[]): string[] => {
  const res = [...ops]

  if (res.includes('lte') && res.includes('gte')) {
    res.push('interval')
  }
  return res.length > 0
    ? _.uniq(res.map((item) => (['lte', 'gte'].includes(item) ? 'eql' : item)))
    : ['eql']
}

type Props = Extend<
  FieldProps<Value>,
  {
    operations: string[]
    dictionaryKeys: string[]
  }
>

const LargeDictionaryStringField = ({
  required,
  label,
  value: initValue,
  onChange,
  operations,
  datasetDictionary,
  dictionaryKeys,
  onError,
  error,
  advanced,
  fullName,
  appId,
}: Props): React.JSX.Element => {
  const value = decode(initValue)
  const [originalValue] = useState(initValue)
  const { t } = useTranslation()
  const { operation, items } = value
  const ops = parseOperations(operations)
  const [currentValue, setCurrentValue] = useState('')
  const onReset = (): void => onChange?.(originalValue)
  const onClear = (): void => onChange?.(undefined)
  const vlabel =
    datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
  return (
    <FieldWrapper
      required={required}
      label={vlabel}
      onReset={onReset}
      onClear={onClear}
      error={
        error !== null && label !== undefined
          ? (error as { [label: string]: string })?.[label]
          : undefined
      }
      advanced={advanced}
    >
      <Grid container spacing={1}>
        {ops.length > 1 && (
          <Grid item xs={3}>
            <SelectField
              appId={appId}
              fullWidth
              value={operation}
              options={ops.map((item) => ({
                value: item,
                label: t(`fields.string.${item}`),
              }))}
              onChange={(v) => {
                if (label !== undefined) {
                  onError?.((e) => {
                    if (isArray(e)) {
                      throw new Error(
                        'Unexpected list of errors in LargeDictionaryStringField',
                      )
                    }
                    return omit(e, label)
                  })
                }
                // @ts-expect-error v should be of some types
                onChange?.(encode(v, []))
              }}
            />
          </Grid>
        )}
        <Grid item xs>
          {(() => {
            if (operation === 'in') {
              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>,
              ): void => {
                const newValue =
                  typeof event === 'string' ? event : event.target?.value
                setCurrentValue(newValue)
                const newValues = newValue.split(',').map((v) => v.trim())
                const filtered = newValues.filter((value) =>
                  dictionaryKeys.includes(value),
                )

                if (filtered.length < newValues.length) {
                  newValues.forEach((value) => {
                    if (onError !== undefined && label !== undefined) {
                      if (!dictionaryKeys.includes(value)) {
                        onError((e) => ({
                          ...e,
                          [label]: t('fieldErrors.fieldCodeError', {
                            code: value,
                          }),
                        }))
                      } else {
                        onError((e) => {
                          if (isArray(e)) {
                            throw new Error(
                              'Unexpected list of errors in LargeDictionaryStringField',
                            )
                          }
                          return omit(e, label)
                        })
                      }
                    }
                  })
                } else {
                  onError?.(() => ({}))
                }
                onChange?.({ in: Array.from(new Set(filtered)) })
              }

              return (
                <TextField
                  fullWidth
                  value={currentValue}
                  placeholder={t('fields.unboundedString.comaSeparated')}
                  onChange={(e) => {
                    // @ts-expect-error e should be of some types
                    handleChange(e)
                  }}
                />
              )
            } else if (operation === 'interval') {
              const gte = items[0] ?? ''
              const lte = items[1] ?? ''
              const unboundedStringFromLabel = t('fields.unboundedString.from')
              const unboundedStringToLabel = t('fields.unboundedString.to')
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      placeholder={unboundedStringFromLabel}
                      fullWidth
                      value={gte}
                      onChange={(v) => {
                        if (onError !== undefined && label !== undefined) {
                          if (
                            dictionaryKeys.find((k) => k >= v) === undefined
                          ) {
                            onError((e) => ({
                              ...e,
                              [label]: t('fieldErrors.fieldCodeErrorSmaller', {
                                code: v,
                              }),
                            }))
                          } else if (lte.length === 0) {
                            onError((e) => ({ ...e, [label]: '.' }))
                          } else if (v > lte) {
                            onError((e) => ({
                              ...e,
                              [label]: t('fieldErrors.rangeErrorVals', {
                                gte: v,
                                lte,
                              }),
                            }))
                          } else {
                            onError((e) => {
                              if (isArray(e)) {
                                throw new Error(
                                  'Unexpected list of errors in LargeDictionaryStringField',
                                )
                              }
                              return omit(e, label)
                            })
                          }
                        }
                        onChange?.({ gte: v, lte })
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      placeholder={unboundedStringToLabel}
                      fullWidth
                      value={lte}
                      onChange={(v) => {
                        if (onError !== undefined && label !== undefined) {
                          if (
                            dictionaryKeys.find((k) => k <= v) === undefined
                          ) {
                            onError((e) => ({
                              ...e,
                              [label]: t('fieldErrors.fieldCodeErrorBigger', {
                                code: v,
                              }),
                            }))
                          } else if (v < gte) {
                            onError((e) => ({
                              ...e,
                              [label]: t('fieldErrors.rangeErrorVals', {
                                gte,
                                lte: v,
                              }),
                            }))
                          } else {
                            onError((e) => {
                              if (isArray(e)) {
                                throw new Error(
                                  'Unexpected list of errors in LargeDictionaryStringField',
                                )
                              }
                              return omit(e, label)
                            })
                          }
                        }
                        onChange?.({ gte, lte: v })
                      }}
                    />
                  </Grid>
                </Grid>
              )
            } else if (operation === 'regexp') {
              const removeWildcard = (str: string): string =>
                str.endsWith('*') ? str.slice(0, -2) : str
              return (
                <TextField
                  fullWidth
                  value={
                    replaceParallel(
                      ['\\.', '.'],
                      ['.', '?'],
                      removeWildcard(items[0]).replace(/\.\*/g, '*'),
                    ) ?? ''
                  }
                  onChange={(v) => {
                    const withWildcard = !v.endsWith('*') ? `${v}*` : v
                    const newVal = withWildcard
                      .replace(/\./g, '\\.')
                      .replace(/\?/g, '.')
                      .replace(/\*/g, '.*')
                    if (onError !== undefined && label !== undefined) {
                      if (
                        dictionaryKeys.find((k) => RegExp(newVal).test(k)) ===
                        undefined
                      ) {
                        onError((e) => ({
                          ...e,
                          [label]: t('fieldErrors.fieldCodeErrorRegexp', {
                            code: v,
                          }),
                        }))
                      } else {
                        onError((e) => {
                          if (isArray(e)) {
                            throw new Error(
                              'Unexpected list of errors in LargeDictionaryStringField',
                            )
                          }
                          return omit(e, label)
                        })
                      }
                    }
                    onChange?.({ regexp: newVal })
                  }}
                />
              )
            }

            return (
              <TextField
                fullWidth
                value={items[0] ?? ''}
                onChange={(v) => {
                  if (onError !== undefined && label !== undefined) {
                    if (!dictionaryKeys.includes(v)) {
                      onError((e) => ({
                        ...e,
                        [label]: t('fieldErrors.fieldCodeError', { code: v }),
                      }))
                    } else {
                      onError((e) => {
                        if (isArray(e)) {
                          throw new Error(
                            'Unexpected list of errors in LargeDictionaryStringField',
                          )
                        }
                        return omit(e, label)
                      })
                    }
                  }
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  _.isObject(initValue) || operations.length > 0
                    ? onChange?.({ eql: v })
                    : onChange?.(v)
                }}
              />
            )
          })()}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default LargeDictionaryStringField
