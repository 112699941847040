import { takeLatest, takeEvery } from 'redux-saga/effects'

import { AUTH_LOGOUT } from './namespaces/auth/types'
import { logout } from './namespaces/auth/sagas'

import { MENU_ITEMS_GET, MENU_NEW_ITEM } from './namespaces/menu/types'
import { getMenuItems, newMenuItem } from './namespaces/menu/sagas'

import {
  FOLDER_UPDATE,
  FOLDER_DELETE,
  FOLDER_ARCHIVE,
} from './namespaces/folder/types'
import {
  updateFolder,
  deleteFolder,
  archiveFolder,
} from './namespaces/folder/sagas'

import { PROJECT_CREATE } from './namespaces/project/types'
import { createProject } from './namespaces/project/sagas'

import { FILTER_CREATE } from './namespaces/filter/types'
import { createFilter } from './namespaces/filter/sagas'

import {
  LAYOUT_OPEN_EXPERIMENT,
  LAYOUT_CLOSE_EXPERIMENT,
  LAYOUT_UPDATE_ITEM,
  LAYOUT_SAVE_TREE,
} from './namespaces/layout/types'
import {
  openExperiment,
  closeExperiment,
  updateLayoutItem,
  saveLayoutTree,
} from './namespaces/layout/sagas'

import {
  EXPERIMENT_CREATE,
  EXPERIMENT_UPDATE,
  EXPERIMENT_DELETE,
} from './namespaces/experiment/types'
import {
  updateExperiment,
  deleteExperiment,
  createExperiment,
} from './namespaces/experiment/sagas'

import { USER_VARIABLE_CREATE } from './namespaces/userVariable/types'

import { createUserVariable } from './namespaces/userVariable/sagas'

import { START_FILTER_DOWNLOAD } from './namespaces/downloads/types'

import { startFilterDownload } from './namespaces/downloads/sagas'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* (): any {
  // auth
  yield takeLatest(AUTH_LOGOUT, logout)

  // menu
  yield takeLatest(MENU_ITEMS_GET, getMenuItems)
  yield takeLatest(MENU_NEW_ITEM, newMenuItem)

  // folder
  yield takeLatest(FOLDER_UPDATE, updateFolder)
  yield takeLatest(FOLDER_DELETE, deleteFolder)
  yield takeLatest(FOLDER_ARCHIVE, archiveFolder)

  // project
  yield takeLatest(PROJECT_CREATE, createProject)

  // filter
  yield takeLatest(FILTER_CREATE, createFilter)

  // layout
  yield takeEvery(LAYOUT_OPEN_EXPERIMENT, openExperiment)
  yield takeEvery(LAYOUT_CLOSE_EXPERIMENT, closeExperiment)
  yield takeEvery(LAYOUT_UPDATE_ITEM, updateLayoutItem)
  yield takeEvery(LAYOUT_SAVE_TREE, saveLayoutTree)

  // experiment
  yield takeLatest(EXPERIMENT_UPDATE, updateExperiment)
  yield takeLatest(EXPERIMENT_DELETE, deleteExperiment)
  yield takeLatest(EXPERIMENT_CREATE, createExperiment)

  // user variables
  yield takeEvery(USER_VARIABLE_CREATE, createUserVariable)

  // downloads
  yield takeEvery(START_FILTER_DOWNLOAD, startFilterDownload)
}
