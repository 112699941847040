import { z } from 'zod'
import {
  statisticsDataSchema as legacyStatsticsDataSchema,
  type StatisticsData,
  type StatisticsValue,
} from '../StatisticsWidget/legacyTypes'

import { statisticsDataSchema } from '../StatisticsWidget/types'

export const clusteringResultSchema = z.object({
  stats: z.union([
    z.array(statisticsDataSchema),
    z.array(legacyStatsticsDataSchema),
  ]),
  M: z.array(z.array(z.number())),
  M_sup: z.optional(z.array(z.array(z.number()))),
  disease_index: z.array(z.string()),
  disease_index_sup: z.optional(z.array(z.string())),
  R: z.array(z.array(z.number())),
})
export type ClusteringResult = z.infer<typeof clusteringResultSchema>
export type ClusteringStatisticsData = StatisticsData
export type ClusteringStatisticsValue = StatisticsValue
