import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import type { Item } from './lib/types'
import Tree from './Tree'
import { type TreeItem } from '../../../api/providers'

interface Props {
  items: Item[]
  onToggleItem: (id: string, isOpen?: boolean) => void
  onSelectItem: (folderId: string, experimentId?: string) => void
  parseItems?: (item: TreeItem) => Item
}

const useStyles = makeStyles({ root: {} })

const TreeContainer = ({
  items,
  onSelectItem,
  onToggleItem,
  parseItems = (item) => item,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Tree
        items={items}
        level={0}
        onToggleItem={onToggleItem}
        onSelectItem={onSelectItem}
        parseItem={parseItems}
      />
    </div>
  )
}

export default TreeContainer
