import type { CallEffect, PutEffect } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'
import type { AxiosError } from 'axios'
import { getAppIds } from '../../../api/lib/http'
import { requestStart, requestStop, requestFail } from '../request/actions'
import { getTreeItems as getTreeItemsRequest } from '../../../api/providers'
import type { TreeItem } from '../../../api/providers'
import { getMenuItemsSuccess, selectMenuItem, toggleMenuItem } from './actions'
import type {
  RequestFailAction,
  RequestStartAction,
  RequestStopAction,
} from '../request/types'
import type {
  MenuItemsGetSuccessAction,
  MenuNewItemAction,
  MenuSelectItemAction,
  MenuToggleOpenItemAction,
} from './types'
import { openExperiment } from '../layout/actions'
import type { LayoutOpenExperimentAction } from '../layout/types'

const getAllTreeItemsRequest = async (
  appIds: string[],
): Promise<TreeItem[]> => {
  const promises = appIds.map(async (appId) => getTreeItemsRequest(appId))
  const result = await Promise.allSettled(promises)
  result.forEach((res) => {
    if (res.status === 'rejected') {
      console.error(res.reason)
    }
  })
  return result
    .map((res) => (res.status === 'fulfilled' ? res.value : []))
    .flat()
}

function* getItems(): Generator<
  CallEffect<string[]> | CallEffect<TreeItem[]>,
  TreeItem[],
  TreeItem[]
> {
  // @ts-expect-error don't know why this is not working
  const appIds: string[] = yield call(getAppIds)
  const folders: TreeItem[] = yield call(getAllTreeItemsRequest, appIds)
  return folders
}
export function* getMenuItems(): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<string[]>
  | CallEffect<TreeItem[]>
  | PutEffect<MenuItemsGetSuccessAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  TreeItem[]
> {
  try {
    yield put(requestStart())
    const folders = yield call(getItems)
    yield put(getMenuItemsSuccess(folders))
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}

export function* newMenuItem({
  payload: { folderId, experimentId, openItem },
}: MenuNewItemAction): Generator<
  | PutEffect<RequestStartAction>
  | CallEffect<string[]>
  | CallEffect<TreeItem[]>
  | PutEffect<MenuSelectItemAction>
  | PutEffect<MenuToggleOpenItemAction>
  | PutEffect<LayoutOpenExperimentAction>
  | PutEffect<MenuItemsGetSuccessAction>
  | PutEffect<RequestFailAction>
  | PutEffect<RequestStopAction>,
  void,
  TreeItem[]
> {
  try {
    const folders = yield call(getItems)
    yield put(getMenuItemsSuccess(folders))
    if (openItem) {
      yield put(selectMenuItem(folderId, experimentId))
      yield put(toggleMenuItem(folderId, true))
      yield put(openExperiment(experimentId, folderId))
    }
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(error as AxiosError<unknown, any>))
  } finally {
    yield put(requestStop())
  }
}
