import React, { useState } from 'react'
import { useList, useMount } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import type { Dataset } from '../../../../../api/providers'
import { getDatasets } from '../../../../../api/providers'
import { createProject } from '../../../../../store/actions'
import ModalDialog from '../../../dialogs/ModalDialog'
import TextField from '../../../form/fields/TextField'
import SelectField from '../../../form/fields/SelectField'
import { getAppIds } from '../../../../../api/lib/http'

const CreateProjectPage = (): React.JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [datasetId, setDatasetId] = useState('')
  const [datasets, { push: pushDataset }] = useList<Dataset>([])

  useMount(() => {
    getAppIds()
      .then((appIds) => {
        appIds.forEach((appId) => {
          getDatasets(appId)
            .then((appDatasets) => {
              appDatasets.forEach((dataset) => {
                pushDataset(dataset)
              })
            })
            .catch((error) => {
              console.error('getDatasets error', error)
            })
        })
      })
      .catch((error) => {
        console.error('Error loading project page', error)
      })
  })

  const onSubmit = (): void => {
    if (name.length === 0 || datasetId.length === 0) {
      const formRequiredFields = t('form.requiredFields')
      setError(formRequiredFields)
      return
    }
    const trimmedName = name.trim()
    dispatch(
      createProject({ name: trimmedName, datasetId }) as { type: string },
    )
    navigate('/')
  }
  const createProjectDialogName = t('createProjectDialog.name')
  const createProjectDialogDataset = t('createProjectDialog.dataset')
  return (
    <ModalDialog
      title={t('createProjectDialog.title')}
      error={error}
      actions={[
        {
          label: t('button.cancel'),
          onClick: (): void => {
            navigate('/')
          },
          type: 'close',
        },
        {
          label: t('button.accept'),
          disabled: datasets.length === 0,
          type: 'submit',
          onClick: onSubmit,
        },
      ]}
    >
      <FormGroup>
        <TextField
          autoFocus
          required
          label={createProjectDialogName}
          value={name}
          onChange={setName}
        />
        {datasets.length > 0 && (
          <SelectField
            appId="" // TODO improve typing for Selectfield
            required
            label={createProjectDialogDataset}
            value={datasetId}
            options={datasets.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={setDatasetId}
          />
        )}
      </FormGroup>
    </ModalDialog>
  )
}

export default CreateProjectPage
