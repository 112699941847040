import React from 'react'
import { keys, omit, isArray, isEqual } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import type { Extend } from '../../../../lib/types'
import type { FieldProps, FieldError } from '../../../form/types'
import FieldFactory from '../../../form/FieldFactory'
import type { FieldFactoryProps } from '../../../form/FieldFactory'

type Props = Extend<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FieldProps<{ [k: string]: any } | undefined>,
  {
    properties: FieldFactoryProps
    noDash?: boolean
    parentIsObject?: boolean
    prefix?: string
  }
>

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(0.3),
    alignItems: 'center',
  },
}))

function getFlattenedProperties(
  properties: {
    [key: string]: FieldFactoryProps
  },
  prefix: string = '',
): { [key: string]: FieldFactoryProps } {
  const flattenedProperties: { [key: string]: FieldFactoryProps } = {}
  for (const key of Object.keys(properties)) {
    if (properties[key].type === 'object') {
      const subProperties = getFlattenedProperties(
        properties[key].properties,
        prefix + '.' + key,
      )
      for (const subkey of Object.keys(subProperties)) {
        flattenedProperties[key + '.' + subkey] = subProperties[subkey]
      }
    } else {
      flattenedProperties[key] = properties[key]
    }
  }
  return flattenedProperties
}

const ObjectFieldAdvanced = ({
  properties,
  prefix = '',
  label,
  value,
  onChange,
  datasetDictionary,
  appId,
  error,
  onError,
  parentIsObject = false,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const currentValue = keys(value).length > 0 ? keys(value)[0] : ''
  const onRowChange = (k: string, childIsObject: boolean) => (v: unknown) => {
    if (value === undefined) {
      return
    }
    let val
    if (childIsObject) {
      val = value[k] !== v ? { ...value, ...(v as object) } : value
    } else {
      val = value[k] !== v ? { ...value, [k]: v } : value
    }

    if (!isEqual(value, val)) {
      onChange?.(val)
    }
  }
  const getFullName = (label: string): string => {
    let newPrefix = prefix
    if (label !== undefined && label !== '__root') {
      if (newPrefix.length > 0) {
        newPrefix += '.'
      }
      newPrefix += label
    }
    return newPrefix
  }
  const flattenedProperties = getFlattenedProperties(properties)
  return (
    <Box display="flex" p={1} flexGrow={1} className={classes.box}>
      <Box p={1} className={classes.box}>
        <Select
          value={currentValue}
          onChange={(e) => {
            onError?.(() => ({}))
            onChange?.({ [e.target.value]: undefined })
          }}
          variant="standard"
        >
          {keys(flattenedProperties).map((property) => (
            <MenuItem key={property} value={property}>
              {datasetDictionary?.list[getFullName(property)] ??
                getFullName(property)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box flexGrow={1} p={1} className={classes.box}>
        <FieldFactory
          {...flattenedProperties[currentValue]}
          label={currentValue}
          value={
            properties[currentValue]?.type !== 'object'
              ? value?.[
                  parentIsObject ? `${label}.${currentValue}` : currentValue
                ]
              : value
          }
          // value={value !== undefined ? value[currentValue] : undefined}
          // onChange={(v: any) => {
          //   onChange?.({ [currentValue]: v })
          // }}
          onChange={onRowChange(
            parentIsObject ? `${label}.${currentValue}` : currentValue,
            properties[currentValue]?.type === 'object',
          )}
          appId={appId}
          advanced
          datasetDictionary={datasetDictionary}
          parentIsObject
          error={
            error !== null &&
            error !== undefined &&
            currentValue !== undefined &&
            currentValue.length > 0
              ? (error as { [label: string]: FieldError })[currentValue]
              : {}
          }
          onError={(errorFn) => {
            if (onError !== undefined) {
              const newSubError = errorFn(
                ((error !== null &&
                  (error as { [label: string]: FieldError })[currentValue]) ??
                  {}) as FieldError,
              )
              if (keys(newSubError).length > 0) {
                onError((oldError) => ({
                  ...oldError,
                  [currentValue]: newSubError,
                }))
              } else {
                onError((oldError) => {
                  if (isArray(oldError)) {
                    throw new Error(
                      'Unexpected list of errors in ObjectFieldAdvanced',
                    )
                  }
                  return omit(oldError, currentValue)
                })
              }
            }
          }}
        />
      </Box>
    </Box>
  )
}

export default ObjectFieldAdvanced
