import { getLayoutItems, getSerializedLayoutTree } from './lib/layout'
import {
  LAYOUT_OPEN_EXPERIMENT_SUCCESS,
  LAYOUT_CLOSE_EXPERIMENT_SUCCESS,
  LAYOUT_UPDATE_ITEM_SUCCESS,
} from './types'
import type { LayoutState, LayoutAction } from './types'

const initState = {
  items: getLayoutItems(),
  tree: getSerializedLayoutTree(),
}

export default (
  state: LayoutState = initState,
  action: LayoutAction,
): LayoutState => {
  const ret = state

  if (
    action.type === LAYOUT_OPEN_EXPERIMENT_SUCCESS
    || action.type === LAYOUT_CLOSE_EXPERIMENT_SUCCESS
    || action.type === LAYOUT_UPDATE_ITEM_SUCCESS
  ) {
    // Make sure we're storing a serializable representation of the tree
    return {
      ...state,
      items: getLayoutItems(),
      tree: getSerializedLayoutTree(),
    }
  }

  return ret
}
