import { useState } from 'react'
import type { DependencyList } from 'react'
import useAsync from 'react-use/lib/useAsync'
import type { AsyncState } from 'react-use/lib/useAsync'
import { getExperimentData } from '../../../../../api/providers'
import { type z } from 'zod'
type Result<T, V> = { results: T; metadata: V } | undefined

type SA<U> = (oldArgs: U) => U
type SB<U> = (args: U) => U
 
type SetArgs<U> = (fn: SA<U>) => void | SB<U>

export type ResultsData<T, V, U> = [
  AsyncState<{ results: T; metadata: V }>,
  SetArgs<U>,
  string,
  U,
]

export function useData<T, V, U>(
  id: string,
  args: U,
  deps: DependencyList = [],
): ResultsData<T, V, U> {
  const [currentArgs, setCurrentArgs] = useState<U>(args)
  const [currentId, setCurrentId] = useState('')
  const [result, setCurrentResult] = useState<Result<T, V>>(undefined)
  const state = useAsync(async () => {
    const data = await getExperimentData<T, V, U>(id, currentArgs)
    setCurrentId(String(Math.random()).slice(2))
    return data
  }, [...deps, currentArgs])
  if (state.value !== undefined && state.value !== result) {
    setCurrentResult(state.value)
  } else {
    state.value = result
  }
  return [state, setCurrentArgs, currentId, currentArgs]
}

export function useValidatedData<T, V, U>(
  id: string,
  args: U,
  schemas: { data: z.ZodTypeAny; metadata?: z.ZodTypeAny },
  deps: DependencyList = [],
): ResultsData<T, V, U> {
  const [state, setCurrentArgs, currentId, currentArgs] = useData<T, V, U>(
    id,
    args,
    deps,
  )
  if (state.value?.results !== undefined) {
    const parsed = schemas.data?.safeParse(state.value?.results)
    if (!parsed.success) {
      state.error = parsed.error
    }
  }
  if (schemas.metadata !== undefined && state.value?.metadata !== undefined) {
    const parsed = schemas.metadata?.safeParse(state.value?.metadata)
    if (!parsed.success) {
      state.error = parsed.error
    }
  }
  return [state, setCurrentArgs, currentId, currentArgs]
}
