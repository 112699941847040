import {
  LAYOUT_OPEN_EXPERIMENT,
  LAYOUT_OPEN_EXPERIMENT_SUCCESS,
  LAYOUT_CLOSE_EXPERIMENT,
  LAYOUT_CLOSE_EXPERIMENT_SUCCESS,
  LAYOUT_UPDATE_ITEM,
  LAYOUT_UPDATE_ITEM_SUCCESS,
  LAYOUT_SAVE_TREE,
} from './types'
import type {
  LayoutOpenExperimentAction,
  LayoutOpenExperimentSuccessAction,
  LayoutCloseExperimentAction,
  LayoutCloseExperimentSuccessAction,
  LayoutUpdateItemAction,
  LayoutUpdateItemSuccessAction,
  LayoutSaveTree,
} from './types'

export const openExperiment = (
  experimentId: string,
  folderId: string,
): LayoutOpenExperimentAction => ({
  type: LAYOUT_OPEN_EXPERIMENT,
  payload: {
    experimentId,
    folderId,
  },
})

export const openExperimentSuccess = (): LayoutOpenExperimentSuccessAction => ({
  type: LAYOUT_OPEN_EXPERIMENT_SUCCESS,
})

export const closeExperiment = (
  experimentId: string,
  folderId: string,
): LayoutCloseExperimentAction => ({
  type: LAYOUT_CLOSE_EXPERIMENT,
  payload: {
    experimentId,
    folderId,
  },
})

export const closeExperimentSuccess =
  (): LayoutCloseExperimentSuccessAction => ({
    type: LAYOUT_CLOSE_EXPERIMENT_SUCCESS,
  })

export const updateLayoutItem = (
  experimentId: string,
  name: string,
  folderId: string,
): LayoutUpdateItemAction => ({
  type: LAYOUT_UPDATE_ITEM,
  payload: {
    experimentId,
    name,
    folderId,
  },
})

export const updateLayoutItemSuccess = (): LayoutUpdateItemSuccessAction => ({
  type: LAYOUT_UPDATE_ITEM_SUCCESS,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveLayoutTree = (tree: any): LayoutSaveTree => ({
  type: LAYOUT_SAVE_TREE,
  payload: tree,
})
