import React, { useEffect, useReducer, useState } from 'react'
import { Button } from '@amalfi-analytics/ui'
import { RegularEyeIcon } from '@/ui/components/icons'

import { storage } from '@/store/lib/storage'
import { Header } from './Header'
import AutoScroller from './AutoScroller'
import NodeGraph from '@amalfi-analytics/ui/components/NodeGraph'
import type { Node } from '@amalfi-analytics/ui/components/NodeGraph'
import {
  createProject as createProjectRequest,
  getFolderExperiments,
  updateFolder,
} from '../../../../api/providers'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { getMenuItems, selectMenuItem } from '@/store/namespaces/menu/actions'
import { LayoutOpenExperimentAction } from '@/store/namespaces/layout/types'
import { createStatisticsFromProject } from './createProject'
import { getDatasetByAlias } from '@/api/providers/dataset/dataset'
import { MenuSelectItemAction } from '@/store/namespaces/menu/types'
import type { Dispatch } from 'redux'
import { AppState } from '@/store/types'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { openExperiment } from '@/store/namespaces/layout/actions'
import { useAnalysis } from './useAnalysis'

const SUGGESTED_ACTIONS = [
  {
    label: 'Mean Stay for COPD',
    text: 'Which clusters of patients with COPD including all its subtypes have the highest mean stay?',
  },
  {
    label: 'Death Rate for COPD',
    text: 'Which clusters of patients with COPD including all its subtypes have the highest death rate?',
  },
]

interface NewProject {
  name: string
  datasetAlias: string
}

// Create a custom hook for folder updates
function useFolderUpdateName(projectId: string | undefined, nodes: Node[]) {
  const dispatch = useDispatch()
  // Track previous nodes length
  const prevNodesLengthRef = React.useRef(0)
  // Track previous projectId to detect changes
  const prevProjectIdRef = React.useRef<string | undefined>(undefined)

  // Create mutation for folder update
  const updateFolderMutation = useMutation({
    mutationFn: async (params: { projectId: string; title: string }) => {
      await updateFolder(params.projectId, { name: params.title })
    },
    onSuccess: () => {
      dispatch(getMenuItems() as { type: string })
    },
  })

  // Effect to detect 0 to 1+ transitions and update folder
  useEffect(() => {
    // Reset prevNodesLengthRef when projectId changes
    if (projectId !== prevProjectIdRef.current) {
      prevNodesLengthRef.current = 0
      prevProjectIdRef.current = projectId
    }

    const currentLength = nodes.length
    const prevLength = prevNodesLengthRef.current

    if (projectId && prevLength === 0 && currentLength > 0 && nodes[0].title) {
      updateFolderMutation.mutate({
        projectId,
        title: nodes[0].title,
      })
    }

    // Only update length if nodes[0] exists and has title
    if (currentLength > 0 && nodes[0]?.title) {
      prevNodesLengthRef.current = currentLength
    }
  }, [projectId, nodes, updateFolderMutation])

  return { updateFolderMutation } // Return anything needed from the hook
}

function useCreateProject(
  project: NewProject | undefined,
  submitted: boolean = false,
) {
  const dispatch = useDispatch()
  const [projectId, setProjectId] = useState<string | undefined>(undefined)
  const { mutate, data: newProjectId } = useMutation({
    mutationFn: async (newProject: NewProject) => {
      if (!newProject) throw new Error('Project is required')
      const dataset = await getDatasetByAlias(newProject.datasetAlias)
      return createProjectRequest({
        name: 'New Project',
        datasetId: dataset.id,
      })
    },
    onSuccess: async (newId) => {
      setProjectId(newId)
      // Add delay to ensure DB consistency
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await createStatisticsFromProject(newId, project?.name || '', dispatch)
      dispatch(getMenuItems() as { type: string })
    },
  })

  // Replace the single name ref with a ref that tracks both name and datasetId
  const lastProjectRef = React.useRef<{ name?: string; datasetAlias?: string }>(
    {},
  )
  useEffect(() => {
    if (
      project &&
      submitted &&
      (project.name !== lastProjectRef.current.name ||
        project.datasetAlias !== lastProjectRef.current.datasetAlias)
    ) {
      lastProjectRef.current = {
        name: project.name,
        datasetAlias: project.datasetAlias,
      }
      mutate({
        name: project.name,
        datasetAlias: project.datasetAlias,
      })
    }
  }, [project?.name, project?.datasetAlias, submitted, projectId, mutate])

  return { projectId: newProjectId || projectId }
}

function useSelectedProjectId(): string | undefined {
  const reUseProjectsEnabled = useFeatureFlagEnabled('re-use-projects')
  const menu = useSelector((state: AppState) => state.menu)
  return reUseProjectsEnabled
    ? menu.items.find((item) => item.type === 'project' && item.isOpen)?.id
    : undefined
}

type State = {
  mode: boolean
  seeProgress: boolean
  fullScreen: boolean
  input: { text: string; submitted: boolean }
  dataset: { id: string; label: string } | undefined
}

export type Action =
  | { type: 'TOGGLE_PROGRESS' }
  | { type: 'TOGGLE_FULLSCREEN' }
  | { type: 'TOGGLE_MODE' }
  | { type: 'SET_INPUT'; payload: { text: string; submitted: boolean } }
  | { type: 'SET_DATASET'; payload: { id: string; label: string } }
  | { type: 'RESET_INPUT' }

function reducer(state: State, action: Action): State {
  const newState = (() => {
    switch (action.type) {
      case 'TOGGLE_PROGRESS':
        return { ...state, seeProgress: !state.seeProgress }
      case 'TOGGLE_FULLSCREEN':
        return { ...state, fullScreen: !state.fullScreen }
      case 'TOGGLE_MODE':
        return { ...state, mode: !state.mode }
      case 'SET_INPUT':
        return { ...state, input: action.payload }
      case 'SET_DATASET':
        return { ...state, dataset: action.payload }
      case 'RESET_INPUT':
        return { ...state, input: { text: '', submitted: false } }
      default:
        return state
    }
  })()

  // Persist state to storage
  storage().setItem('saas-state', JSON.stringify(newState))
  return newState
}

function ProgressStepperPanel({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  const selectedProjectId = useSelectedProjectId()
  const dispatch =
    useDispatch<Dispatch<MenuSelectItemAction | LayoutOpenExperimentAction>>()
  const [loadedProjectId, setLoadedProjectId] = useState<string | undefined>()

  const defaultState = {
    mode: true,
    seeProgress: true,
    fullScreen: false,
    input: { text: '', submitted: false },
    dataset: undefined,
  }
  const initialState =
    JSON.parse(storage().getItem('saas-state') ?? 'null') ?? defaultState
  // do not start with a submitted input or it will cause the project to be created
  initialState.input.submitted = false

  const [state, dispatchReducer] = useReducer(reducer, initialState)

  const shouldCreateProject =
    state.input.submitted &&
    state.input.text.length > 0 &&
    state.dataset &&
    !loadedProjectId &&
    !selectedProjectId

  const newProject = {
    name: state.input.text,
    datasetAlias: state.dataset?.id ?? '',
  }

  let { projectId: newProjectId } = useCreateProject(
    newProject,
    shouldCreateProject,
  )

  let projectId = newProjectId
  if (loadedProjectId) {
    projectId = loadedProjectId
  } else if (selectedProjectId) {
    projectId = selectedProjectId
  }
  const { nodes, setGraph } = useAnalysis({
    projectId,
    question: state.input.text,
    questionIsSubmitted:
      !!projectId && !loadedProjectId && state.input.submitted,
    setQuestionIsSubmitted: (questionIsSubmitted: boolean) => {
      setLoadedProjectId(undefined)
      dispatchReducer({
        type: 'SET_INPUT',
        payload: { ...state.input, submitted: questionIsSubmitted },
      })
    },
    resetQuestion: () => {
      setLoadedProjectId(undefined)
      dispatchReducer({ type: 'RESET_INPUT' })
    },
    autoMode: state.mode,
  })

  useFolderUpdateName(newProjectId, nodes)

  return (
    <div className="flex h-full">
      {!state.fullScreen && (
        <div className={`${state.seeProgress ? 'w-3/5' : 'w-full'} relative`}>
          {children}
        </div>
      )}
      <div
        className={`${state.seeProgress ? `${state.fullScreen ? 'w-full' : 'w-2/5'} relative border-l-8 border-primary-500` : 'absolute right-0 top-0'} flex flex-col`}
      >
        {!state.seeProgress && (
          <Button
            color="primary"
            onClick={() => dispatchReducer({ type: 'TOGGLE_PROGRESS' })}
            icon={<RegularEyeIcon />}
          />
        )}
        {state.seeProgress && (
          <AutoScroller items={nodes}>
            <Header
              state={state}
              dispatch={dispatchReducer}
              suggestedActions={SUGGESTED_ACTIONS}
              setGraph={async (graphState, logs) => {
                const experiments = await getFolderExperiments(
                  graphState.project_id,
                )
                const stats = experiments.find((experiment) =>
                  experiment.name.startsWith('__statistics__'),
                )
                if (stats) {
                  dispatch(selectMenuItem(graphState.project_id, stats.id))
                  dispatch(openExperiment(stats.id, graphState.project_id))
                  setLoadedProjectId(graphState.project_id)
                  setGraph(graphState, logs)
                }
              }}
            />
            {nodes.length > 0 && (
              <div className="px-1">
                <NodeGraph nodes={nodes} />
              </div>
            )}
          </AutoScroller>
        )}
      </div>
    </div>
  )
}

export default ProgressStepperPanel
