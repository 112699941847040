import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup'
import { getFolder } from '../../../../../api/providers'
import ModalDialog from '../../../dialogs/ModalDialog'
import MultiTextField from '../../../form/fields/MultiTextField'
import { http } from '../../../../../api/lib/http'
import type { ShareProjectError, ShareProjectResponse } from './types'
import Loading from '../../../Loading'
import axios from 'axios'
import { camelCase } from 'lodash'
import { toast } from 'react-toastify'

const shareFolder = async (
  appId: string,
  folderId: string,
  emails: string[],
): Promise<ShareProjectResponse> => {
  const url = '/v3/case-mix-store/share-project'
  const res = await (
    await http(appId)
  ).post<ShareProjectResponse>(url, { emails, folder_id: folderId })
  return res.data
}

export const ShareFolderPage = (): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch('/share-folder/:folderId')
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [emailErrors, setEmailErrors] = useState<{ [key: string]: string }>({})
  const [isSharing, setIsSharing] = useState(false)
  const folderId = match?.params.folderId as string

  const state = useAsync(async () => {
    const res = await getFolder(folderId, true)
    return res
  }, [folderId])

  useEffect(() => {
    // Create a new object that only includes errors for emails currently in the `emails` array
    const filteredEmailErrors = Object.keys(emailErrors).reduce(
      (acc: { [key: string]: string }, key: string) => {
        if (emails.includes(key)) {
          acc[key] = emailErrors[key]
        }
        return acc
      },
      {},
    )

    // Update the state if there are changes in the filtered errors
    if (
      // eslint-disable-next-line no-constant-binary-expression
      new Set(Object.keys(filteredEmailErrors)) !==
      new Set(Object.keys(emailErrors))
    ) {
      setEmailErrors(filteredEmailErrors)
    }
    if (Object.keys(filteredEmailErrors).length === 0) {
      setError('')
    }
  }, [emails]) // Depend on `emails` to trigger this effect

  const messagestNetworkError = 'messages.networkError'
  if (state.error !== undefined) {
    console.error('edit folder state error', state.error)
  }
  const stateError =
    state.error !== undefined ? messagestNetworkError : undefined

  const onCancel = (): void => {
    navigate('/')
  }
  const formRequiredFields = t('form.requiredFields')
  const onSubmit = async (): Promise<void> => {
    if (emails.length === 0) {
      setError(formRequiredFields)
      return
    }

    setIsSharing(true)
    try {
      const response = await shareFolder(
        state.value?.appId ?? '',
        folderId,
        emails,
      )
      if (response.status === 'SUCCESS') {
        toast.success(t('shareProjectDialog.successMessage'))
        navigate('/')
      } else {
        setError(response.message)
      }
    } catch (error) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      if (axios.isAxiosError(error) && 'detail' in error.response?.data) {
        const detail = error.response?.data.detail as ShareProjectError
        switch (detail.error) {
          case 'USER_NOT_FOUND':
          case 'NOT_ENOUGH_PERMISSIONS':
            {
              const message = t(`shareProjectDialog.${camelCase(detail.error)}`)
              setError(message)
              setEmailErrors({ ...emailErrors, [detail.email]: message })
            }
            break
          case 'FOLDER_NOT_FOUND':
            setError(
              `${t('shareProjectDialog.projectNotFound')}: ${state.value?.folder.name}`,
            )
            break
          default:
            setError(t('shareProjectDialog.internalServerError'))
            break
        }
      } else {
        console.error('share folder error', error)
      }
    } finally {
      setIsSharing(false)
    }
  }
  const shareProjectDialogEmail = t('shareProjectDialog.email')
  if (state.loading || state.value === undefined) {
    return <Loading hidden={false} />
  }
  return (
    <ModalDialog
      title={`${t('shareProjectDialog.title')}: ${state.value.folder.name}`}
      error={stateError ?? error}
      loading={isSharing}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.accept'),
          disabled:
            state.loading || isSharing || Object.keys(emailErrors).length > 0,
          type: 'submit',
          onClick: () => {
            if (Object.keys(emailErrors).length === 0) {
              void onSubmit()
            }
          },
        },
      ]}
    >
      <FormGroup>
        <MultiTextField
          required
          label={shareProjectDialogEmail}
          value={emails}
          onChange={setEmails}
          placeholder="email@example.com"
          valueErrors={emailErrors}
        />
      </FormGroup>
    </ModalDialog>
  )
}
