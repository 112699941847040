import { type Dataset } from '../../../../../api/providers'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem'
import styled from '@emotion/styled'
import { useDictionary } from '../../../app/widgets/hooks/useDictionary'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import useAsync from 'react-use/lib/useAsync'
import CircularProgress from '@mui/material/CircularProgress'
import i18n from 'i18next'
import { getDictionary } from '../../../../../api/providers'
import { getDatasetFromFolder } from './createExperiment'

interface Item {
  id: string
  label: string
  type: string
  items?: Item[]
}

interface BaseField {
  type: string
  order?: number
}

interface ObjectField extends BaseField {
  type: 'object'
  properties: { [name: string]: Field }
  operations: ['has']
}

interface ArrayField extends BaseField {
  type: 'array'
  items: ObjectField
}

type DateOps = 'gte' | 'lte'
type NumberOps = DateOps | 'eql' | 'in'
type StringOps = NumberOps | 'regexp'
interface NumberField extends BaseField {
  type: 'number'
  operations: NumberOps[]
  min?: number
  max?: number
}

interface StringField extends BaseField {
  type: 'string'
  operations: StringOps[]
  encoding: string[]
}
interface DateField extends BaseField {
  type: 'date'
  operations: DateOps[]
  format: string
  time_zone: string
}

type Field = ObjectField | ArrayField | NumberField | StringField | DateField

function parseArray(
  fieldName: string,
  array: ArrayField,
  dictionary?: DictionaryContent['list'],
): Item {
  return parseObject(fieldName, array.items, dictionary)
}

function parseObject(
  fieldName: string,
  object: ObjectField,
  dictionary?: DictionaryContent['list'],
): Item {
  const items = Object.entries(object.properties).map(([key, value]) =>
    parseField(
      fieldName === '' ? key : `${fieldName}.${key}`,
      value,
      dictionary,
    ),
  )
  return {
    id: fieldName,
    label: dictionary?.[fieldName] ?? fieldName,
    type: object.type,
    items,
  }
}

function parseNumber(
  fieldName: string,
  field: NumberField,
  dictionary?: DictionaryContent['list'],
): Item {
  return {
    id: fieldName,
    label: dictionary?.[fieldName] ?? fieldName,
    type: field.type,
  }
}

function parseString(
  fieldName: string,
  field: StringField,
  dictionary?: DictionaryContent['list'],
): Item {
  return {
    id: fieldName,
    label: dictionary?.[fieldName] ?? fieldName,
    type: field.type,
  }
}

function parseDate(
  fieldName: string,
  field: DateField,
  dictionary?: DictionaryContent['list'],
): Item {
  return {
    id: fieldName,
    label: dictionary?.[fieldName] ?? fieldName,
    type: field.type,
  }
}

function parseField(
  fieldName: string,
  field: Field,
  dictionary?: DictionaryContent['list'],
): Item {
  switch (field.type) {
    case 'object':
      return parseObject(fieldName, field, dictionary)
    case 'array':
      return parseArray(fieldName, field, dictionary)
    case 'number':
      return parseNumber(fieldName, field, dictionary)
    case 'string':
      return parseString(fieldName, field, dictionary)
    case 'date':
      return parseDate(fieldName, field, dictionary)
    default:
      throw new Error(`Unrecognised type ${JSON.stringify(field)}`)
  }
}

export function getFieldsFromDataset(
  dataset: Dataset,
  dictionary?: DictionaryContent['list'],
): Item | undefined {
  const filterPieline = dataset.metadata.pipelines.find(
    (pipe) => pipe.name === 'Patients Filter',
  )
  if (filterPieline === undefined) {
    return undefined
  }
  const fields = filterPieline.pipe[0].args?.__root as Field
  return parseField('', fields, dictionary)
}

const StyledSimpleTreeView = styled(SimpleTreeView)({
  borderLeft: '1px solid gray',
})

const StyledTreeItem = styled(TreeItem)<{ rootNode?: boolean }>(({
  rootNode = false,
}) => {
  const borderColor = 'gray'

  return {
    position: 'relative',
    '&:before': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 28,
      left: 2,
      top: 14,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `1px solid ${borderColor}` : 'none',
    },
    [`& .${treeItemClasses.content}`]: {
      marginLeft: 9,
      paddingLeft: 2,
      borderRadius: 0,
    },

    [`& .${treeItemClasses.root}`]: {
      marginLeft: 6,
      paddingLeft: 2,
      borderLeft: `1px solid ${borderColor}`,
      borderRadius: 0,
    },
  }
})

function ShowDict({
  dictId,
  appId,
  complete,
}: {
  dictId: string
  appId: string
  complete?: boolean
}): React.JSX.Element {
  const [dict] = useDictionary(dictId, appId)

  let values
    = complete === true
      ? Object.entries(dict?.list ?? {}).map(
          ([key, value]) => `${key}: ${value}`,
        )
      : Object.values(dict?.list ?? {})
  if (values.length > 1000) {
    values = [...values.slice(0, 1000), `and ${values.length - 1000} more...`]
  }
  return (
    <ul>
      {values.map((value) => (
        <li key={value}>{value}</li>
      ))}
    </ul>
  )
}

function DictionaryDialog({
  dictId,
  appId,
}: {
  dictId: string
  appId: string
}): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const complete = dictId.includes('ICD') || dictId.includes('drg')
  const { t } = useTranslation()
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {t('fields.ai.openDictionary')}
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <DialogContent>
          <ShowDict dictId={dictId} appId={appId} complete={complete} />
        </DialogContent>
      </Dialog>
    </>
  )
}

function FieldsNode({
  item,
  encodingDict,
  appId,
}: {
  item: Item
  encodingDict?: { [key: string]: [string] }
  appId: string
}): React.JSX.Element {
  const content = (
    <div>
      {item.label}
      {encodingDict?.[item.id] !== undefined && (
        <DictionaryDialog dictId={encodingDict?.[item.id][0]} appId={appId} />
      )}
    </div>
  )
  return (
    <StyledTreeItem rootNode={'items' in item} itemId={item.id} label={content}>
      {item.items?.map((child) => (
        <FieldsNode
          key={child.id}
          item={child}
          encodingDict={encodingDict}
          appId={appId}
        />
      ))}
    </StyledTreeItem>
  )
}

function getIds(fields: Item): string[] {
  return fields.items?.flatMap((child) => [...getIds(child), child.id]) ?? []
}

export function FieldsTree({
  folderId,
}: {
  folderId: string
}): React.JSX.Element {
  const state = useAsync(async (): Promise<{
    fields?: Item
    appId: string
    encodingDict?: { [key: string]: [string] }
  }> => {
    const dataset = await getDatasetFromFolder(folderId)
    let datasetDictionary = { list: {} }
    if (dataset.metadata.dictionary !== undefined) {
      datasetDictionary = await getDictionary(
        dataset.metadata.dictionary,
        i18n.language.slice(0, 2),
        dataset.app_id,
      )
    }

    const fields = getFieldsFromDataset(dataset, datasetDictionary?.list)
    return {
      fields,
      appId: dataset.app_id,
      encodingDict: dataset.metadata.encoding_dict,
    }
  }, [folderId])
  if (state.loading || state.value === undefined) {
    return <CircularProgress size={24} />
  }
  if (state.error !== undefined || state.value.fields === undefined) {
    return <div>No fields</div>
  }
  return (
    <StyledSimpleTreeView defaultExpandedItems={getIds(state.value.fields)}>
      {state.value.fields.items?.map((child) => (
        <FieldsNode
          key={child.id}
          item={child}
          encodingDict={state.value.encodingDict}
          appId={state.value.appId}
        />
      ))}
    </StyledSimpleTreeView>
  )
}
