import { FOLDER_ARCHIVE, FOLDER_DELETE, FOLDER_UPDATE } from './types'
import type {
  FolderArchiveAction,
  FolderDeleteAction,
  FolderUpdateAction,
} from './types'

export const updateFolder = (id: string, name: string): FolderUpdateAction => ({
  type: FOLDER_UPDATE,
  payload: {
    id,
    name,
  },
})

export const deleteFolder = (folderId: string): FolderDeleteAction => ({
  type: FOLDER_DELETE,
  payload: {
    folderId,
  },
})

export const archiveFolder = (
  folderId: string,
  appId: string,
): FolderArchiveAction => ({
  type: FOLDER_ARCHIVE,
  payload: {
    folderId,
    appId,
  },
})
