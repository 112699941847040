import { library } from '@fortawesome/fontawesome-svg-core'

// Look for icons here
// https://fontawesome.com/icons?d=gallery

// Import this way to reduce bundle size
// https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking

import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import { faAtom } from '@fortawesome/free-solid-svg-icons/faAtom'
import { faAdjust } from '@fortawesome/free-solid-svg-icons/faAdjust'
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask'
import { faDna } from '@fortawesome/free-solid-svg-icons/faDna'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'

library.add(faLayerGroup, faAtom, faAdjust, faFlask, faDna, faQuestionCircle, faUsersCog, faCalendarAlt, faHistory)
