/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import _ from 'lodash'
import type { DictionaryContent } from '../../../../api/providers/dictionary'
import RangeNumericField from '../../app/fields/RangeNumericField'
import SingleNumericField from '../../app/fields/SingleNumericField'
import UnboundStringField from '../../app/fields/UnboundStringField'
import EnumStringField from '../../app/fields/EnumStringField'
import DictionaryStringField from '../../app/fields/DictionaryStringField'
import ArrayField from '../../app/fields/ArrayField'
import BooleanField from '../../app/fields/BooleanField'
import ArrayFieldAdvanced from '../../app/fields/ArrayFieldAdvanced'
import ObjectField from '../../app/fields/ObjectField'
import ObjectFieldAdvanced from '../../app/fields/ObjectFieldAdvanced'
import DateField from '../../app/fields/DateField'
import SubpopulationSelectorField from '../../app/fields/SubpopulationSelectorField'
import type { FieldError, OnFieldError } from '../types'

export interface FieldFactoryProps {
  type: string
  required?: boolean
  label: string
  prefix: string
  operations: string[]
  onChange: (value: any) => void
  error: FieldError
  onError: OnFieldError
  [key: string]: any
  datasetDictionary: DictionaryContent | null | undefined
  parentFolderId?: string
  advanced?: boolean
  setAdvanced?: (advanced: boolean) => void
  value: any
  appId: string
  userVariables?: Array<{ name: string; type: any }>
  onChangeSelectedUserVariable?: (
    value: string[] | { eql: string } | { in: string[] } | undefined,
  ) => void
  selectedUserVariable?: string[] | { eql: string } | { in: string[] }
  hidden?: boolean
}

const FieldFactory = ({
  type,
  required,
  label,
  value,
  operations,
  onChange,
  userVariables,
  onChangeSelectedUserVariable,
  selectedUserVariable,
  datasetDictionary,
  error,
  onError,
  parentFolderId,
  appId,
  hidden = false,
  advanced = false,
  prefix = '',
  ...rest
}: FieldFactoryProps): React.JSX.Element | null => {
  userVariables = userVariables ?? []
  let newPrefix = prefix
  if (label !== undefined && label !== '__root') {
    if (newPrefix.length > 0) {
      newPrefix += '.'
    }
    newPrefix += label
  }
  if (hidden) {
    return null
  }

  if (
    type === 'number' &&
    // _.has(rest, 'min') &&
    // _.has(rest, 'max') &&
    _.intersection(operations, ['lte', 'gte']).length > 0
  ) {
    const min: number = _.get(rest, 'min')
    const max: number = _.get(rest, 'max')
    const rangedDefined = min !== undefined && max !== undefined
    return (
      <RangeNumericField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        min={min ?? 0}
        max={max ?? 0}
        rangedDefined={rangedDefined}
        onChange={onChange}
        datasetDictionary={datasetDictionary}
        error={error}
        onError={onError}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'boolean') {
    return (
      <BooleanField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        operations={operations}
        onChange={onChange}
        options={rest.enum}
        datasetDictionary={datasetDictionary}
        error={error}
        onError={onError}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'number' && _.has(rest, 'min') && _.has(rest, 'max')) {
    const min: number = _.get(rest, 'min', 0)
    const max: number = _.get(rest, 'max', min)

    return (
      <SingleNumericField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
        operations={operations}
        datasetDictionary={datasetDictionary}
        error={error}
        onError={onError}
        advanced={rest.advanced}
      />
    )
  } else if (_.has(rest, 'enum')) {
    const options = _.get(rest, 'enum', ['eql'])
    return (
      <EnumStringField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        operations={operations}
        options={options}
        onChange={onChange}
        datasetDictionary={datasetDictionary}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'string' && _.has(rest, 'encoding')) {
    const dictionaryId = _.get(rest, 'encoding[0]', '')
    return (
      <DictionaryStringField
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        dictionaryId={dictionaryId}
        operations={operations}
        onChange={onChange}
        datasetDictionary={datasetDictionary}
        error={error}
        onError={onError}
        advanced={rest.advanced}
        appId={appId}
      />
    )
  } else if (type === 'string') {
    return (
      <UnboundStringField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        operations={operations}
        onChange={onChange}
        datasetDictionary={datasetDictionary}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'array') {
    if (advanced) {
      return (
        <ArrayFieldAdvanced
          appId={appId}
          required={required}
          label={label}
          value={value}
          operations={operations}
          onChange={onChange}
          items={{ ...rest.items, prefix: newPrefix }}
          datasetDictionary={datasetDictionary}
          error={error}
          onError={onError}
          advanced={rest.advanced}
        />
      )
    }
    return (
      <ArrayField
        appId={appId}
        required={required}
        label={label}
        value={value}
        operations={operations}
        onChange={onChange}
        items={{ ...rest.items, prefix: newPrefix }}
        datasetDictionary={datasetDictionary}
        error={error}
        onError={onError}
        advanced={rest.advanced}
        maxItems={rest.maxItems}
      />
    )
  } else if (type === 'object') {
    if (advanced) {
      return (
        <ObjectFieldAdvanced
          appId={appId}
          required={required}
          label={label}
          value={value}
          onChange={onChange}
          properties={rest.properties}
          prefix={newPrefix}
          datasetDictionary={datasetDictionary}
          error={error}
          onError={onError}
          advanced={rest.advanced}
          parentIsObject={rest.parentIsObject}
        />
      )
    }
    return (
      <ObjectField
        appId={appId}
        required={required}
        label={label}
        value={value}
        onChange={onChange}
        properties={{ ...rest.properties, prefix: newPrefix }}
        datasetDictionary={datasetDictionary}
        error={error}
        parentIsObject={rest.parentIsObject}
        onError={onError}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'date') {
    return (
      <DateField
        appId={appId}
        required={required}
        fullName={newPrefix}
        label={label}
        value={value}
        onChange={onChange}
        format={rest.format}
        operations={operations}
        datasetDictionary={datasetDictionary}
        advanced={rest.advanced}
      />
    )
  } else if (type === 'userVariableSelection') {
    return (
      <EnumStringField
        appId={appId}
        required={required}
        label={label.replace('$', '')}
        value={selectedUserVariable}
        operations={operations}
        options={userVariables.map((u: { name: string }) => u.name)}
        onChange={onChangeSelectedUserVariable}
        datasetDictionary={datasetDictionary}
      />
    )
  } else if (type === 'subpopulationSelection') {
    return (
      <SubpopulationSelectorField
        appId={appId}
        label={label.replace('$', '')}
        required={required}
        onChange={onChange}
        value={value}
        parentFolderId={parentFolderId}
      />
    )
  } else if (type === 'userVariableType') {
    if (
      selectedUserVariable === undefined ||
      !('eql' in selectedUserVariable)
    ) {
      return null
    }
    const vlabel = datasetDictionary?.list[label] ?? label
    const userVariable = userVariables.find(
      (v: any) => v.name === selectedUserVariable.eql,
    )
    return (
      <FieldFactory
        label={vlabel}
        fullName={newPrefix}
        onChange={onChange}
        value={value}
        datasetDictionary={datasetDictionary}
        {...userVariable?.type}
        appId={appId}
      />
    )
  }
  return null
}

export default FieldFactory
