import React, { useEffect, useState, useContext } from 'react'
import { round } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import ToggleButton from '@mui/material/ToggleButton'
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import { Typography } from '@mui/material'
import { Trajectories } from '@amalfi-analytics/visualizations'
import { SearchMinusIcon, SearchPlusIcon, ArrowsAlt } from '../../../icons'
import type { WidgetProps } from '../types'
import { useData } from '../hooks/useData'
import type { ResultsData } from '../hooks/useData'
import { useDictionaries } from '../hooks/useDictionary'
import TrajectoriesTable from './TrajectoriesTable'
import type { Trajectory } from './TrajectoriesTable'
import Loading from '../shared/Loading'
import { useTranslation } from 'react-i18next'
import { ExportDataContext } from '../../../../components/widget/WidgetFactory/ExportDataContext'

interface TrajectoriesArgs {
  [id: string]: unknown
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  gridtItemControls: {
    height: 50,
  },
}))

const mergeDictionaries = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dictionaries: { [id: string]: any },
  encodingDict: { [key: string]: string[] },
): {
  content: {
    list: {
      [key: string]: string
    }
  }
} => {
  const mergedDict: { content: { list: { [key: string]: string } } } = {
    content: {
      list: {},
    },
  }
  for (const variable in encodingDict) {
    encodingDict[variable].forEach((dictionaryName) => {
      for (const key in dictionaries[dictionaryName].list) {
        mergedDict.content.list[`${variable}=${key}`] =
          dictionaries[dictionaryName].list[key]
      }
    })
  }
  return mergedDict
}

const TrajectoriesWidget = ({
  outputs,
  experimentId,
  folderId,
  appId,
}: WidgetProps): React.JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ loading, value }]: ResultsData<Trajectory, any, TrajectoriesArgs> =
    useData(outputs.trajectories, {})
  const { t } = useTranslation()
  const classes = useStyles()
  const [scale, setScale] = useState(1)
  const [relEmissionThreshold, setRelEmissionThreshold] = useState(0.25)
  const [relTransitionThreshold] = useState(0.01)
  const [absTransitionThreshold, setAbsTransitionThreshold] = useState(50)

  const [refreshCounter, setRefreshCounter] = useState(0)
  const [showFinalStates, setShowFinalStates] = useState(true)
  const [moving, setMoving] = useState(false)
  const [isPadding, setIsPadding] = useState(false)
  const [padding, setPadding] = useState<
    [[number, number], [number, number], [number, number]]
  >([
    [0, 0],
    [0, 0],
    [0, 0],
  ])
  const [decrossingAlgorithm, setDecrossingAlgorithm] = useState('Fast 2')
  const [selectedState, setSelectedState] = useState<string>()
  const [mergedDict, setMergedDict] = useState<{
    content: { list: { [key: string]: string } }
  }>()
  const [dictionaries, setDictionaries] = useDictionaries({}, appId)
  const { onResultIdChange } = useContext(ExportDataContext)

  useEffect(() => {
    onResultIdChange(outputs.trajectories)
  }, [outputs.trajectories])

  useEffect(() => {
    setDictionaries(value?.metadata.encoding_dict)
  }, [loading])
  useEffect(() => {
    setMergedDict(
      mergeDictionaries(dictionaries, value?.metadata.encoding_dict),
    )
  }, [dictionaries])

  if (loading || value === undefined || mergedDict === undefined) {
    return <Loading />
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Grid item xs={6} lg={6} className={classes.gridtItemControls}>
          <FormControl className={classes.formControl}>
            <FormLabel>
              {t('trajectories.options.emissions')}{' '}
              {round(relEmissionThreshold * 100)}%
            </FormLabel>
            <Slider
              value={relEmissionThreshold}
              onChange={(_e, v) => {
                setRelEmissionThreshold(v as number)
              }}
              onChangeCommitted={(_e, _v) => {}}
              min={0.05}
              max={1}
              step={0.05}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6} className={classes.gridtItemControls}>
          <FormControl className={classes.formControl}>
            <FormLabel>
              {' '}
              {t('trajectories.options.min_patients_transitions')}{' '}
              {round(
                (absTransitionThreshold /
                  ((value.metadata.num_sequences as number) ?? 1000)) *
                  100,
                1,
              )}
              %
            </FormLabel>
            <Slider
              value={
                (absTransitionThreshold /
                  ((value.metadata.num_sequences as number) ?? 1000)) *
                100
              }
              onChange={(_e, v) => {
                setAbsTransitionThreshold(
                  ((v as number) / 100) *
                    ((value.metadata.num_sequences as number) ?? 1000),
                )
              }}
              onChangeCommitted={() => {
                setRefreshCounter(refreshCounter + 1)
              }}
              min={0.2}
              max={25}
              step={0.2}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <FormLabel>
              {t('trajectories.options.decrossing_algorithm')}
            </FormLabel>
            <RadioGroup
              row
              value={decrossingAlgorithm}
              onChange={(_e, v) => {
                setDecrossingAlgorithm(v)
              }}
            >
              <FormControlLabel
                value="Fast 2"
                control={<Radio />}
                label={t('trajectories.options.fast')}
              />
              <FormControlLabel
                value="Optimal"
                control={<Radio />}
                label={t('trajectories.options.optimal')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormLabel> {t('trajectories.options.show_final_states')}</FormLabel>
          <Checkbox
            checked={showFinalStates}
            onChange={(_e, v) => {
              setShowFinalStates(v)
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <IconButton
            onClick={() => {
              setScale(Math.min(scale + 0.1, 10))
            }}
            size="large"
          >
            <SearchPlusIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setScale(Math.max(scale - 0.1, 0.1))
            }}
            size="large"
          >
            <SearchMinusIcon />
          </IconButton>
          <ToggleButton
            value="check"
            selected={moving}
            onChange={() => {
              setMoving(!moving)
            }}
            style={{ border: 'none', fontSize: 20 }}
          >
            <ArrowsAlt />
          </ToggleButton>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 800, position: 'relative' }}>
            <div style={{ position: 'absolute' }}>
              {selectedState !== undefined && (
                <Typography variant="h5">
                  <span>{t('trajectories.tableHeaders.state')}</span>
                  <span>
                    <b> {selectedState}</b>
                  </span>
                </Typography>
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: moving ? 'block' : 'none',
                zIndex: 5,
                cursor: 'move',
              }}
              onMouseDown={(e) => {
                setPadding([padding[0], [e.clientX, e.clientY], padding[0]])
                setIsPadding(true)
              }}
              onMouseUp={() => {
                setPadding([padding[0], [0, 0], padding[0]])
                setIsPadding(false)
              }}
              onMouseLeave={() => {
                setPadding([padding[0], [0, 0], padding[0]])
                setIsPadding(false)
              }}
              onMouseMove={(e) => {
                if (isPadding) {
                  setPadding([
                    [
                      e.clientX - padding[1][0] + padding[2][0],
                      e.clientY - padding[1][1] + padding[2][1],
                    ],
                    padding[1],
                    padding[2],
                  ])
                }
              }}
            />
            <Trajectories
              svgClassName={`svg-${experimentId}-${folderId}`}
              data={value.results}
              props={{
                renderOptions: {
                  relEmissionThreshold,
                  relTransitionThreshold,
                  absTransitionThreshold,
                  decrossingAlgorithm,
                  hideAbsorbingStates: !showFinalStates,
                },
                dictionary: mergedDict,
                onClickState: setSelectedState,
                selectedState,
                zoom: scale,
                padding: padding[0],
              }}
              renderDependencies={[
                relEmissionThreshold,
                relTransitionThreshold,
                absTransitionThreshold,
                decrossingAlgorithm,
                selectedState,
                mergedDict,
                scale,
                padding,
                showFinalStates,
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TrajectoriesTable
            experimentId={experimentId}
            folderId={folderId}
            trajectory={value.results}
            dictionary={mergedDict}
            selectedState={selectedState}
            metadata={value.metadata}
            options={{
              relEmissionThreshold,
              relTransitionThreshold,
              absTransitionThreshold,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TrajectoriesWidget
