import { http } from '../../../../../api/lib/http'
import { uniqId } from '../../../../../api/lib/utils'
import {
  getDataset,
  getRootFolder,
  type Dataset,
  getParentFolders,
  type Folder,
  type Filter,
  getStore,
  updateStore,
  type Experiment,
} from '../../../../../api/providers'
import { type Pipeline } from '../../../../../api/providers/dataset/dataset'
import { STATISTICS_PIPELINE_ID } from '../../../../../constants/api'
import { omit, zip } from 'lodash'

export interface PipePlan {
  module: string
  inputs: string[]
  outputs: string[]
  args: object
}

interface Pipe extends Folder {
  id: string
  name: string
  folderId: string
  pipelineId: string
  plan: PipePlan[]
}

function translatePipe(obj: Filter | Pipe, pipelines: Pipeline[]): Pipe {
  if ('plan' in obj && obj.plan.length > 0) {
    return obj
  }
  const pipeline = pipelines.find((pipe) => pipe.name === obj.pipelineId)
  if (pipeline === undefined) {
    throw new Error(`Pipeline ${obj.pipelineId} not found`)
  }
  const plan = pipeline.pipe.map((pipe) => ({
    module: pipe.module,
    inputs: pipe.inputs,
    outputs: pipe.outputs,
    args: {
      ...(pipe?.default ?? {}),
      ...((obj as Filter).values[pipe.module] ?? {}),
    },
  }))
  return {
    id: obj.id,
    name: obj.name,
    folderId: obj.folderId,
    pipelineId: obj.pipelineId,
    plan,
  }
}

export async function getDatasetFromFolder(folderId: string): Promise<Dataset> {
  const project = await getRootFolder(folderId)
  const dataset = await getDataset(project.datasetId)
  return dataset
}
export const getParentPipes = async (
  filterId: string,
  pipelines: Pipeline[],
): Promise<Pipe[]> => {
  const folders = await getParentFolders(filterId)
  // the first element is a project, so we have to exclude it
  return folders
    .slice(1)
    .map((folder) => translatePipe(folder as Filter, pipelines))
}

function replaceTerms(
  pipes: Pipe[],
  datasetId: string,
  prefix: string,
  modelId?: string,
  subpopulationSymbol?: string,
): Pipe[] {
  let lastOutput = datasetId
  return pipes.map((pipe, i) => {
    const plan = pipe.plan.map((p) => {
      const inputs = p.inputs.map((input) => {
        if (input === '$input' || input === 'predictions') {
          return lastOutput
        }
        if (input === '$userVariable') {
          if (modelId === undefined) {
            throw new Error('Model is undefined')
          }
          return modelId
        }
        if (input === '$subpopulation') {
          if (subpopulationSymbol === undefined) {
            throw new Error('Subpopulation symbol is undefined')
          }
          return subpopulationSymbol
        }
        return input
      })
      const outputs = p.outputs.map((output) => {
        if (output === '$output') {
          lastOutput = `${prefix}-pipe_filter-${i}`
          return lastOutput
        }
        if (output === 'predictions') {
          lastOutput = `predictions-${i}`
          return lastOutput
        }
        return output
      })
      return { ...p, inputs, outputs }
    })
    return { ...pipe, plan }
  })
}

async function getFilters(folderId: string): Promise<Pipe[]> {
  const dataset = await getDatasetFromFolder(folderId)
  // const project = await getRootFolder(folderId)
  const pipes = await getParentPipes(folderId, dataset.metadata.pipelines)
  return pipes
}

async function createFolder(
  folderId: string,
  pipelineId: string,
  appId: string,
  experimentName: string,
  plan: PipePlan[],
): Promise<Pipe> {
  const id = uniqId()
  const newFolder: Pipe = {
    id,
    name: experimentName,
    folderId,
    pipelineId,
    plan,
  }
  const { store } = await getStore(appId)
  await updateStore({ ...store, folders: [newFolder, ...store.folders] }, appId)
  return newFolder
}

function addArgsToPipeline(
  pipeline: Pipeline,
  args: object[],
  modelId?: string,
): PipePlan[] {
  const argsWithDefaults = zip(pipeline.pipe, args).map(([pipe, args]) => {
    if (pipe === undefined) {
      throw new Error('Pipe is undefined')
    }
    if (modelId !== undefined) {
      pipe = {
        ...pipe,
        inputs: pipe.inputs.map((input) => {
          if (input === '$userVariable') {
            return modelId
          }
          return input
        }),
      }
    }
    return {
      ...omit(pipe, 'default'),
      args: { ...(pipe?.default ?? {}), ...(args ?? {}) },
    }
  })
  return argsWithDefaults
}

async function innerCreateExperiment(
  folderId: string,
  pipelineId: string,
  experimentName: string,
  plan: PipePlan[],
  appId: string,
): Promise<string> {
  const url = '/v2/experiments/'
  const payload = {
    experiment: { description: pipelineId, name: experimentName, plan },
  }
  const res = await (
    await http(appId)
  ).post<{ experiment: { _id: string; outputs: { [key: string]: string } } }>(
    url,
    payload,
  )
  const { _id, outputs } = res.data.experiment
  // run experiment
  const experiment: Experiment = {
    id: _id,
    folderId,
    outputs,
    name: experimentName,
    pipelineId,
    values: {},
  }
  const { store } = await getStore(appId)
  await updateStore(
    { ...store, experiments: [experiment, ...store.experiments] },
    appId,
  )
  return _id
}

export async function createExperiment(
  folderId: string,
  pipelineId: string,
  pipelineArgs: object[],
  experimentName: string,
  modelId?: string,
): Promise<{ experimentId: string; folderId: string }> {
  const dataset = await getDatasetFromFolder(folderId)

  const pipeline = dataset.metadata.pipelines.find(
    (pipeline) => pipeline.name === pipelineId,
  )

  if (pipeline === undefined) {
    throw new Error(`Pipeline ${pipelineId} not found`)
  }
  const filterPipes = await getFilters(folderId)
  let functionality = pipeline

  if (pipeline.type === 'preprocessing') {
    // add new filter
    const argsWithDefaults = addArgsToPipeline(pipeline, pipelineArgs, modelId)
    const newFolder = await createFolder(
      folderId,
      pipelineId,
      dataset.app_id,
      experimentName,
      argsWithDefaults,
    )
    filterPipes.push(newFolder)
    const statistics = dataset.metadata.pipelines.find(
      (f: Pipeline) => f.name === STATISTICS_PIPELINE_ID,
    )
    if (statistics === undefined) {
      throw new Error(`Pipeline ${STATISTICS_PIPELINE_ID} not found`)
    }
    folderId = newFolder.id
    functionality = statistics
    experimentName = '__statistics__' + experimentName
    pipelineArgs = [{}]
    pipelineId = STATISTICS_PIPELINE_ID
  }
  // add functionality
  const argsWithDefaults = addArgsToPipeline(functionality, pipelineArgs)
  const functionalityPipe: Pipe = {
    id: '',
    name: experimentName,
    folderId,
    pipelineId: functionality.name,
    plan: argsWithDefaults,
  }
  const finalPipeline = replaceTerms(
    [...filterPipes, functionalityPipe],
    dataset.id,
    '',
    modelId,
    undefined,
  )
  const plan = finalPipeline.flatMap((pipe) => pipe.plan)
  const id = await innerCreateExperiment(
    folderId,
    pipelineId,
    experimentName,
    plan,
    dataset.app_id,
  )
  return { experimentId: id, folderId }
}

export async function createExperimentCompare(
  folderId: string,
  pipelineId: string,
  pipelineArgs: object[],
  experimentName: string,
  subpopulationFolderId: string,
): Promise<{ experimentId: string; folderId: string }> {
  const dataset = await getDatasetFromFolder(folderId)
  const pipeline = dataset.metadata.pipelines.find(
    (pipeline) => pipeline.name === pipelineId,
  )

  if (pipeline === undefined) {
    throw new Error(`Pipeline ${pipelineId} not found`)
  }
  const filterPipes = await getFilters(folderId)
  const filterPipesSubpopulation = await getFilters(subpopulationFolderId)
  const functionality = pipeline
  const argsWithDefaults = addArgsToPipeline(functionality, pipelineArgs)
  const functionalityPipe: Pipe = {
    id: '',
    name: experimentName,
    folderId,
    pipelineId: functionality.name,
    plan: argsWithDefaults,
  }
  const pipeline1Plan = replaceTerms(
    filterPipes,
    dataset.id,
    'f1',
    undefined,
    undefined,
  ).flatMap((pipe) => pipe.plan)
  const pipeline2Plan = replaceTerms(
    filterPipesSubpopulation,
    dataset.id,
    'f2',
    undefined,
    undefined,
  ).flatMap((pipe) => pipe.plan)

  const filterSymbol = pipeline1Plan[pipeline1Plan.length - 1].outputs[0]
  const filterSymbolSubpopulation =
    pipeline2Plan[pipeline2Plan.length - 1].outputs[0]

  const functionalityPlan = replaceTerms(
    [functionalityPipe],
    filterSymbol,
    '',
    undefined,
    filterSymbolSubpopulation,
  ).flatMap((pipe) => pipe.plan)
  // const plan = finalPipeline.flatMap((pipe) => pipe.plan)
  const plan = [...pipeline1Plan, ...pipeline2Plan, ...functionalityPlan]
  const id = await innerCreateExperiment(
    folderId,
    pipelineId,
    experimentName,
    plan,
    dataset.app_id,
  )
  return { experimentId: id, folderId }
}
