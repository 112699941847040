import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../../dialogs/AlertDialog'

const NotFoundPage = (): React.JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <AlertDialog
      open
      title={t('pageNotFound.title')}
      message={t('pageNotFound.message')}
      onAccept={() => {
        navigate('/')
      }}
    />
  )
}

export default NotFoundPage
