import type { PutEffect } from 'redux-saga/effects'
import { getDataset, innerGetStore } from '../../../api/providers'
import type { AuthLogoutSuccessAction } from './types'
import { getDatasetInner } from '../../../api/providers/dataset/dataset'
import { APPLICATION_ID, LOGIN_URL, LOGOUT_PATH } from '../../../constants/env'
import { clearStorage } from '../../lib/storage'
// eslint-disable-next-line require-yield
export function* logout(): Generator<
  PutEffect<AuthLogoutSuccessAction>,
  void,
  unknown
> {
  clearStorage()
  // clean memoized caches as well (https://gitlab.com/amalfianalytics/soil/anis-interface/-/issues/116)
  innerGetStore.cache.clear?.()
  getDataset.cache.clear?.()
  getDatasetInner.cache.clear?.()
  // yield put(logoutSuccess())
  location.href = `${LOGIN_URL}${LOGOUT_PATH}?client_id=${APPLICATION_ID}&post_logout_redirect_uri=${encodeURIComponent(
    `${location.protocol}//${location.host}/`,
  )}`
}
