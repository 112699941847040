import { useEffect, useReducer } from 'react'
import type { GraphLogs, GraphState } from './HistoryMenu'

import { PipelineType, type StreamGenerator } from '@/api/providers/graph'
import type {
  Node,
  PlainNode,
  ActionNode as ActionNodeType,
} from '@amalfi-analytics/ui/components/NodeGraph'
import { reducer } from './analysisReducer'
import { useAIExperiment } from './useAIExperiment'
import { ButtonProps } from '@amalfi-analytics/ui/components/Button'
import { downloadPdf } from './markdown_to_pdf'

interface useAnalysisReturn {
  nodes: Node[]
  setGraph: (graphState: GraphState, logs: GraphLogs[]) => void
}

interface NodeGroups {
  onEdit?: () => void
  onReport?: () => void
  onCancel?: () => void
  onContinue?: () => void
  onRetry?: () => void
  onDownloadReport?: () => Promise<void>
}

export function mapNodeGroupsToOperations(
  groups?: NodeGroups,
  questionAnswered?: boolean,
  isReport: boolean = false,
): ButtonProps[] | undefined {
  if (groups === undefined) {
    return undefined
  }

  const operations: ButtonProps[] = []

  if (questionAnswered) {
    if (groups.onReport !== undefined) {
      operations.push({
        variant: 'filled',
        color: 'primary',
        children: 'Generate Report',
        onClick: groups.onReport,
      })
    }
    if (groups.onContinue !== undefined) {
      operations.push({
        variant: 'outlined',
        color: 'primary',
        children: 'Continue',
        onClick: groups.onContinue,
      })
    }
  } else {
    if (groups.onContinue !== undefined) {
      operations.push({
        variant: isReport ? 'outlined' : 'filled',
        color: 'primary',
        children: 'Continue',
        onClick: groups.onContinue,
      })
    }
    if (isReport) {
      if (groups.onDownloadReport !== undefined) {
        operations.push({
          variant: 'filled',
          color: 'primary',
          children: 'Download Report',
          onClick: groups.onDownloadReport,
        })
      }
    } else if (groups.onReport !== undefined) {
      operations.push({
        variant: 'outlined',
        color: 'primary',
        children: 'Generate Report',
        onClick: groups.onReport,
      })
    }
  }

  if (groups.onRetry !== undefined) {
    operations.push({
      variant: (groups.onReport ?? groups.onContinue) ? 'outlined' : 'filled',
      color: 'secondary',
      children: 'Retry',
      onClick: groups.onRetry,
    })
  }

  if (groups.onEdit !== undefined) {
    operations.push({
      variant: 'outlined',
      color: 'secondary',
      children: 'Edit',
      onClick: groups.onEdit,
    })
  }

  if (groups.onCancel !== undefined) {
    operations.push({
      variant: 'outlined',
      color: 'gray',
      children: 'Cancel',
      onClick: groups.onCancel,
    })
  }

  return operations.length > 0 ? operations : undefined
}

export interface AnalysisState {
  nodes: InternalNodeType[]
  currentNodes: InternalNodeType[]
  question: string
  abortController?: AbortController
  projectId: string
  running: boolean
  streamPromise?: Promise<StreamGenerator>
  stream?: StreamGenerator
  accumulator: string
  graphStateId?: string
  questionAnswered: boolean
  currentExperiment?: {
    parentSubsetId: string
    folderId: string
    experimentId: string
    title: string
    description: string
    type: PipelineType
    running: boolean
  }
  lastAction?: 'continue' | 'report' | 'analysis'
  pendingAction?: 'continue' | 'report'
  currentAction?: {
    title: string
    description: string
    parentSubsetId: string
  }
}

export type Action = {
  title: string
  description: string
  parentSubsetId?: string
  index: number
  nodeId: number
  selected?: boolean
  disabled?: boolean
}

export interface ActionNode extends ActionNodeType {
  actions: Action[]
}

export type InternalNodeType = PlainNode | ActionNode

interface useAnalysisProps {
  projectId?: string
  autoMode: boolean
  question: string
  questionIsSubmitted: boolean
  setQuestionIsSubmitted: (questionIsSubmitted: boolean) => void
  resetQuestion: () => void
}

export const useAnalysis = ({
  projectId,
  autoMode,
  question,
  questionIsSubmitted,
  setQuestionIsSubmitted,
  resetQuestion,
}: useAnalysisProps): useAnalysisReturn => {
  const defaultState: AnalysisState = {
    nodes: [],
    currentNodes: [],
    question: '',
    projectId: '',
    running: false,
    accumulator: '',
    questionAnswered: false,
  }
  const [analysis, dispatchReducer] = useReducer(reducer, defaultState)
  useAIExperiment(analysis, dispatchReducer)

  useEffect(() => {
    if (questionIsSubmitted && projectId) {
      dispatchReducer({
        type: 'NEW_ANALYSIS',
        payload: {
          projectId,
          question,
        },
      })
    }
  }, [questionIsSubmitted, projectId])

  useEffect(() => {
    switch (analysis.pendingAction) {
      case 'continue':
        dispatchReducer({ type: 'CONTINUE_ANALYSIS' })
        break
      case 'report':
        dispatchReducer({ type: 'REPORT_ANALYSIS' })
        break
    }
  }, [analysis.pendingAction])

  useEffect(() => {
    if (analysis.streamPromise !== undefined) {
      const processStream = async (streamPromise: Promise<StreamGenerator>) => {
        const stream = await streamPromise
        dispatchReducer({
          type: 'SET_STREAM',
          payload: { stream },
        })
      }
      processStream(analysis.streamPromise)
    }
  }, [analysis.streamPromise])

  useEffect(() => {
    if (analysis.stream === undefined) {
      return
    }
    const processStream = async (stream: StreamGenerator) => {
      const chunk = await stream.next()
      if (chunk.value) {
        dispatchReducer({
          type: 'NEW_CHUNK',
          payload: { chunk: chunk.value },
        })
      } else {
        dispatchReducer({ type: 'END_STREAM', payload: { autoMode } })
      }
    }
    processStream(analysis.stream)
  }, [analysis.accumulator, analysis.stream])

  const setGraph = (graphState: GraphState, logs: GraphLogs[]) => {
    dispatchReducer({
      type: 'SET_GRAPH',
      payload: { graphState, logs },
    })
  }

  const nodeGroups: NodeGroups = {
    onEdit: () => {
      setQuestionIsSubmitted(false)
    },
    onCancel: () => {
      dispatchReducer({ type: 'RESET_ANALYSIS' })
      resetQuestion()
    },
    onReport: () => dispatchReducer({ type: 'REPORT_ANALYSIS' }),
    onContinue: () => dispatchReducer({ type: 'CONTINUE_ANALYSIS' }),
    onRetry: () => dispatchReducer({ type: 'RETRY_NODE' }),
    onDownloadReport: async () => {
      if (analysis.nodes.length === 0) {
        return
      }
      const lastNode = analysis.nodes[analysis.nodes.length - 1]
      await downloadPdf(lastNode.title ?? '', lastNode.description ?? '')
    },
  }

  useEffect(() => {
    if (
      !autoMode ||
      analysis.nodes.length === 0 ||
      analysis.currentAction !== undefined
    ) {
      return
    }
    const targetNode = analysis.nodes[analysis.nodes.length - 1] as
      | InternalNodeType
      | undefined
    if (
      !targetNode ||
      targetNode.type !== 'action' ||
      targetNode.actions.length === 0
    ) {
      return
    }
    const targetAction = targetNode.actions[0]
    dispatchReducer({
      type: 'CURRENT_ACTION',
      payload: {
        title: targetAction.title ?? '',
        description: targetAction.description ?? '',
        parentSubsetId: targetAction.parentSubsetId ?? '',
        index: targetAction.index,
        nodeId: analysis.nodes.length - 1,
      },
    })
  }, [analysis.nodes])

  const nodes = [...analysis.nodes, ...analysis.currentNodes].map(
    (node, index) => {
      if (node.type === 'action') {
        return {
          ...node,
          operations:
            index < analysis.nodes.length + analysis.currentNodes.length - 1
              ? undefined
              : analysis.running
                ? mapNodeGroupsToOperations(
                    {
                      ...nodeGroups,
                      onContinue: undefined,
                      onReport: undefined,
                    },
                    analysis.questionAnswered,
                  )
                : mapNodeGroupsToOperations(
                    {
                      ...nodeGroups,
                      onContinue: undefined,
                      onRetry: undefined,
                    },
                    analysis.questionAnswered,
                  ),
          actions: node.actions.map((action) => ({
            ...action,
            onSelect: () =>
              dispatchReducer({
                type: 'CURRENT_ACTION',
                payload: {
                  title: action.title,
                  description: action.description,
                  parentSubsetId: action.parentSubsetId ?? '',
                  index: action.index,
                  nodeId: index,
                },
              }),
          })),
        } as ActionNodeType
      }

      return {
        ...node,
        operations:
          index < analysis.nodes.length + analysis.currentNodes.length - 1
            ? undefined
            : analysis.running
              ? mapNodeGroupsToOperations(
                  {
                    ...nodeGroups,
                    onContinue: undefined,
                    onReport: undefined,
                  },
                  analysis.questionAnswered,
                )
              : mapNodeGroupsToOperations(
                  { ...nodeGroups, onRetry: undefined },
                  analysis.questionAnswered,
                  analysis.lastAction === 'report',
                ),
      } as PlainNode
    },
  )

  return { nodes, setGraph }
}
