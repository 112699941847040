import { getDataset, getRootFolder } from '../../../../api/providers'
import { filterToText } from '../../../../api/providers/llm'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useAsync, { type AsyncState } from 'react-use/lib/useAsync'
import { AngleDownIcon } from '../../icons'
import AccordionDetails from '@mui/material/AccordionDetails'
import { type PipePlan } from '../../form/fields/AISearchField/createExperiment'
import CircularProgress from '@mui/material/CircularProgress'
import { useWindowSize } from 'react-use'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

type FilterType = 'Patients Filter' | 'Episodes Filter' | 'Assessment Filter'

interface OldRowProps {
  title: string
  filterType: FilterType
  values: { [moduleId: string]: { [fieldName: string]: unknown } }
  folderId: string
}

interface NewRowProps {
  title: string
  filterType: FilterType
  folderId: string
  plan: PipePlan[]
  createdAt: number
}

type AIRowProps = NewRowProps | OldRowProps

function useExplanation(
  folderId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  filterType: FilterType,
): AsyncState<{ text: string; details: string }> {
  const { i18n } = useTranslation()
  return useAsync(async (): Promise<{ text: string; details: string }> => {
    const project = await getRootFolder(folderId)
    const dataset = await getDataset(project.datasetId)
    if (values === undefined || Object.keys(values).length === 0) {
      return { text: '-', details: '' }
    }

    let baseFilter = values
    if (filterType === 'Episodes Filter') {
      baseFilter = baseFilter.history
    } else if (filterType === 'Assessment Filter') {
      baseFilter = baseFilter['history.assessments']
    }
    const { result, error } = await filterToText(
      dataset.app_id,
      baseFilter,
      filterType,
      i18n.language,
      dataset.id,
    )
    if (error !== undefined) {
      throw new Error(error)
    }
    return result
  }, [JSON.stringify(values)])
}

function explanationContent(
  explanation: AsyncState<{ text: string; details: string }>,
  details: object,
): React.JSX.Element | null {
  const { t } = useTranslation()
  const { width } = useWindowSize()

  if (explanation.loading) {
    return <CircularProgress size={20} />
  }

  if (explanation.error !== undefined) {
    return (
      <Typography variant="body1" color="error" title={JSON.stringify(details)}>
        {t('messages.networkError')}
      </Typography>
    )
  }

  if (explanation.value !== undefined) {
    return (
      <>
        <Typography variant="body1" title={JSON.stringify(details)}>
          {explanation.value.text}
        </Typography>
        <Box
          sx={{
            overflowX: 'auto',
            maxWidth: `${Math.floor(width) - 100}px`,
            border: '0.5px solid',
            px: 1,
            borderRadius: 1,
            scrollbarWidth: 'thin',
            scrollBehavior: 'smooth',
          }}
        >
          <pre title={JSON.stringify(details)}>{explanation.value.details}</pre>
        </Box>
      </>
    )
  }

  return null
}

export default function AIRow({
  title,
  filterType,
  folderId,
  ...rest
}: AIRowProps): React.JSX.Element {
  let details = {}
  if ('plan' in rest && rest.plan !== undefined) {
    const det = rest.plan.reduce<{
      [moduleId: string]: { [fieldName: string]: unknown }
    }>((acc, module) => {
      acc[module.module] = module.args as { [fieldName: string]: unknown }
      return acc
    }, {})
    details = Object.values(det)[0]
  } else if ('values' in rest && rest.values !== undefined) {
    details = Object.values(rest.values)[0]
  }

  const explanation = useExplanation(folderId, details, filterType)
  const [expanded, setExpanded] = React.useState(true)

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded?.(expanded)
      }}
    >
      <AccordionSummary
        expandIcon={<AngleDownIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          },
        }}
      >
        <Tooltip title={title} arrow>
          <Typography
            variant="body1"
            sx={{
              maxWidth: '90%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        {explanationContent(explanation, details)}
        {'createdAt' in rest && (
          <Typography variant="body2" color="textSecondary">
            {new Date(rest.createdAt).toLocaleString()}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
