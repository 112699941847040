import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Grid from '@mui/material/Grid'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import LegacyDateField from './LegacyDateField'
import moment from 'moment'
import FieldWrapper from '../../../form/FieldWrapper'

interface Value {
  gte?: string
  lte?: string
}

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginBottom: theme.spacing(0.5),
  },
}))

type Props = Extend<
  FieldProps<Value>,
  {
    format: string
    operations: string[]
  }
>

const DateField = ({
  required,
  label,
  value,
  onChange,
  format,
  operations = ['lte', 'gte'],
  datasetDictionary,
  fullName,
  appId,
}: Props): React.JSX.Element => {
  if (!format.startsWith('iso')) {
    return (
      <LegacyDateField
        required={required}
        label={label}
        // @ts-expect-error legacy
        value={value}
        // @ts-expect-error legacy
        onChange={onChange}
        format={format}
        operations={operations}
        datasetDictionary={datasetDictionary}
        appId={appId}
      />
    )
  }

  const classes = useStyles()
  const { t } = useTranslation()
  const { gte, lte } = { gte: value?.gte, lte: value?.lte }
  const onReset = (): void => {}
  const onClear = (): void => {}
  return (
    <FieldWrapper
      required={required}
      label={
        datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
      }
      onReset={onReset}
      onClear={onClear}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <DatePicker
            className={classes.datePicker}
            format="DD/MM/YYYY"
            label={t('fields.date.from')}
            slotProps={{
              textField: { variant: 'standard', fullWidth: true },
            }}
            value={gte !== undefined ? moment(gte) : null}
            onChange={(v) => {
              onChange?.({ gte: v?.format('YYYY-MM-DD'), lte })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            className={classes.datePicker}
            format="DD/MM/YYYY"
            label={t('fields.date.to')}
            slotProps={{
              textField: { variant: 'standard', fullWidth: true },
            }}
            value={lte !== undefined ? moment(lte) : null}
            onChange={(v) => {
              onChange?.({ lte: v?.format('YYYY-MM-DD'), gte })
            }}
          />
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

export default DateField
