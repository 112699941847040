import React from 'react'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import Snackbar from '@mui/material/Snackbar'
import { AlertIcon, CloseIcon } from '../../icons'

const useStyles = makeStyles((theme) => ({
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  background: {
    backgroundColor: theme.palette.error.dark,
  },
}))

interface Props {
  open: boolean
  message: React.ReactNode
  onClose: () => void
}

const ErrorMessage: React.FC<Props> = ({ open, message, onClose }: Props) => {
  const classes = useStyles()

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      ContentProps={{
        classes: {
          root: classes.background,
        },
      }}
      message={
        <span className={classes.message}>
          <AlertIcon className={classes.icon} size="lg" />
          {message}
        </span>
      }
      action={
        <IconButton key="close" color="inherit" onClick={onClose} size="large">
          <CloseIcon size="xs" />
        </IconButton>
      }
    />
  )
}

export default ErrorMessage
