import { getRawExperiment } from '../../../../api/providers/experiment'
import { type Store, getStoreByFolderId } from '../../../../api/providers/store'

export async function getCreatedAtFromStoreFolder(
  folderId: string,
  store?: Store,
): Promise<number> {
  if (store === undefined) {
    store = await getStoreByFolderId(folderId)
    if (store === undefined) {
      throw new Error('Store is undefined')
    }
  }
  const experimentId = store.experiments.find(
    (experiment) => experiment.folderId === folderId,
  )
  if (experimentId === undefined) {
    console.error(`No experiment found for folder ${folderId}`)
    throw new Error(`No experiment found for folder ${folderId}`)
  }
  return (await getRawExperiment(experimentId.id, folderId)).created_at
}
