import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ErrorIcon } from '../../../icons'
import ExperimentLogsBox from '../ExperimentLogsBox'

const useStyles = makeStyles((theme) => ({
  // centers the inner content vertically and horizontally
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  text: {
    color: theme.palette.grey[500],
  },
}))

interface Props {
  experimentId: string
  folderId: string
}

const ErrorWidget = ({ experimentId, folderId }: Props): React.JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <ErrorIcon className={classes.icon} size="10x" />
        <Typography variant="h6" className={classes.text}>
          {t('widget.error')}
        </Typography>
        <ExperimentLogsBox experimentId={experimentId} folderId={folderId} />
      </Box>
    </Box>
  )
}

export default ErrorWidget
