export const storage = (): Storage => {
  return localStorage.getItem('remember') !== null
    ? localStorage
    : sessionStorage
}

export const clearStorage = (): void => {
  // somebody could change "remember"
  localStorage.clear()
  sessionStorage.clear()
}
