import _ from 'lodash'
import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useMatch, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import {
  getRootFolder,
  getFolderExperiments,
  getExperimentPipelines,
  getPipelineFields,
  getParentFolders,
  getUserVariables,
  getDataset,
} from '../../../../../api/providers'
import { createExperiment, closeMenu } from '../../../../../store/actions'
import { getDatasetDictionary } from '../../../../../api/providers/dictionary'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import FieldFactory from '../../../form/FieldFactory'
import TextField from '../../../form/fields/TextField'
import { newAvailName, isEmpty } from '../../../../lib/util'
import ModalDialog from '../../../dialogs/ModalDialog'
import type { FieldError } from '../../../form/types'

interface Props {
  closeMenu: typeof closeMenu
  createExperiment: typeof createExperiment
}

const CreateExperimentPage = ({
  closeMenu,
  createExperiment,
}: Props): React.JSX.Element => {
  const match = useMatch('/create-experiment/:folderId')
  const navigate = useNavigate()
  const location = useLocation()
  const folderId = match?.params.folderId as string
  const initialPipelineId =
    (location.state.pipelineId as string | undefined) ?? ''
  const pipelineId = initialPipelineId
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [fieldError, setFieldError] = useState<FieldError>({})
  const [name, setName] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [values, setValues] = useState<{
    [moduleId: string]: { [fieldName: string]: unknown }
  }>({})
  const [selectedUserVariable, setSelectedUserVariable] = useState<
    string[] | { eql: string } | { in: string[] } | undefined
  >(undefined)
  const [datasetDictionary, setDatasetDictionary] =
    useState<DictionaryContent | null>(null)
  // loads initial data
  const state = useAsync(async () => {
    const project = await getRootFolder(folderId)
    const pipelines = await getExperimentPipelines(project.datasetId)
    const dataset = await getDataset(project.datasetId)
    const experimentNames = _.map(await getFolderExperiments(folderId), 'name')
    const path = _.map(await getParentFolders(folderId), 'name')
    const userVariables = await getUserVariables(project.id)
    const dictionary = await getDatasetDictionary([project])
    setDatasetDictionary(dictionary)
    setPlaceholder(
      newAvailName(dictionary?.list[pipelineId] ?? pipelineId, experimentNames),
    )
    return {
      datasetId: project.datasetId,
      appId: dataset.app_id,
      pipelines,
      experimentNames,
      path,
      userVariables,
    }
  }, [folderId, pipelineId])
  if (state.error !== undefined) {
    console.error('create experiment state error', state.error)
  }
  const stateError =
    state.error !== undefined ? t('messages.networkError') : undefined
  const datasetId = state.value?.datasetId ?? ''
  const path = state.value?.path ?? []
  const appId = state.value?.appId

  // loads pipeline fields
  const fieldsState = useAsync(async () => {
    const fieldsGroup =
      datasetId.length > 0 && pipelineId.length > 0
        ? await getPipelineFields(datasetId, pipelineId)
        : {}
    const defValues = _.mapValues(fieldsGroup, (fields) => {
      return _.mapValues(
        _.keyBy(fields, (x) => x.name),
        (x) => x.default,
      )
    })

    setValues(defValues)
    return fieldsGroup
  }, [datasetId, pipelineId])
  const fieldsGroup = _.mapValues(fieldsState.value ?? {}, (fields) =>
    _.sortBy(fields, ['order']),
  )

  const onFieldChange =
    (moduleId: string, fieldName: string) => (value?: unknown) => {
      const newFieldValues = { ...values }
      newFieldValues[moduleId][fieldName] = value
      setValues(newFieldValues)
    }

  const onChangeSelectedUserVariable = (
    val: string[] | { eql: string } | { in: string[] } | undefined,
  ): void => {
    setSelectedUserVariable(val)
  }

  const onCancel = (): void => {
    navigate('/')
  }

  const onSubmit = (): void => {
    const requiredFields = _.some(fieldsGroup, (fields, moduleId) =>
      fields.some(
        ({ required, name, type }) =>
          required &&
          isEmpty(values[moduleId][name]) &&
          !(
            selectedUserVariable !== undefined &&
            type === 'userVariableSelection'
          ),
      ),
    )
    if (requiredFields || pipelineId.length === 0) {
      const formRequiredFields = t('form.requiredFields')
      setError(formRequiredFields)
      return
    }
    for (const mod in values) {
      for (const f in values[mod]) {
        if (f === '$userVariable') {
          const v = (state.value?.userVariables ?? []).find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (v: any) =>
              v.name ===
              (selectedUserVariable !== undefined &&
                'eql' in selectedUserVariable &&
                selectedUserVariable.eql),
          )
          values[mod]._userVariable = v
        }
        if (f === '$subpopulation') {
          const v = values[mod].$subpopulation
          values[mod]._subpopulation = v
        }
      }
      delete values[mod].$subpopulation
    }
    createExperiment({
      name: name.length > 0 ? name : placeholder,
      folderId,
      pipelineId,
      values,
    })
    closeMenu()
    navigate('/')
  }
  const createExperimentDialogName = t('createExperimentDialog.name')
  const createExperimentDialogCompareHelper = t(
    'createExperimentDialog.compareHelper',
  )
  return (
    <ModalDialog
      title={`${t('createExperimentDialog.title')}${
        pipelineId.length > 0 ? ':' : ''
      } ${datasetDictionary?.list[pipelineId] ?? pipelineId}`}
      path={path}
      helper={
        initialPipelineId === 'Compare Graph'
          ? createExperimentDialogCompareHelper
          : undefined
      }
      error={stateError ?? error}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.continue'),
          disabled:
            state.loading ||
            pipelineId.length === 0 ||
            Object.keys(fieldError ?? {}).length > 0,
          type: 'submit',
          onClick: onSubmit,
        },
      ]}
    >
      <FormGroup>
        {!state.loading && appId !== undefined && (
          <>
            {pipelineId.length > 0 && (
              <TextField
                important
                required
                label={createExperimentDialogName}
                placeholder={placeholder}
                value={name}
                onChange={setName}
              />
            )}

            {_.map(fieldsGroup, (fields, moduleId) =>
              fields.map(({ name, ...rest }, i) => (
                <FieldFactory
                  datasetDictionary={datasetDictionary}
                  key={`${moduleId}:${i}`}
                  label={name}
                  prefix=""
                  value={values[moduleId][name]}
                  userVariables={state.value?.userVariables}
                  error={fieldError}
                  onError={setFieldError}
                  onChangeSelectedUserVariable={onChangeSelectedUserVariable}
                  selectedUserVariable={selectedUserVariable}
                  onChange={onFieldChange(moduleId, name)}
                  parentFolderId={folderId}
                  appId={appId}
                  {...rest}
                />
              )),
            )}
          </>
        )}
      </FormGroup>
    </ModalDialog>
  )
}

const mapDispatchToProps = { closeMenu, createExperiment }

export default connect(null, mapDispatchToProps)(CreateExperimentPage)
