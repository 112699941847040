import { uniqId } from '../lib/utils'
import { getStore, updateStore } from './store'
import type { Folder } from './folder'
import { getFolder, getParentFolders, getRootFolder } from './folder'
import { getDataset } from './dataset'

export { deleteFolder as deleteFilter } from './folder'

export type Filter = Folder & {
  folderId: string
  pipelineId: string
  values: {
    [moduleId: string]: {
      [fieldName: string]: unknown
    }
  }
}

export const getFilter = async (filterId: string): Promise<Filter> => {
  return (await getFolder(filterId)) as Filter
}

export const createFilter = async (
  body: Omit<Filter, 'id'>,
): Promise<string> => {
  const id = uniqId()
  const newFolder = { id, ...body }
  const project = await getRootFolder(newFolder.folderId)
  const dataset = await getDataset(project.datasetId)
  const { store } = await getStore(dataset.app_id)

  await updateStore(
    {
      ...store,
      folders: [newFolder, ...store.folders],
    },
    dataset.app_id,
  )

  return id
}

interface Pipe extends Folder {
  id: string
  name: string
  folderId: string
  pipelineId: string
  plan: Array<{
    module: string
    inputs: string[]
    outputs: string[]
    args: object
  }>
}

function transformPipelineToFilter(pipe: Filter | Pipe): Filter {
  if (!('plan' in pipe) || pipe.plan.length === 0) {
    return pipe as Filter
  }
  const values = pipe.plan.reduce<{
    [moduleId: string]: { [fieldName: string]: unknown }
  }>((acc, module) => {
    acc[module.module] = module.args as { [fieldName: string]: unknown }
    // hack to keep backward compatibility with old filters
    if (
      module.module
      === 'iris_backend.functionalities.case_mix.pipelines.user_variable.predictor'
    ) {
      acc[module.module]._userVariable = { modelId: module.inputs[0] }
    }
    return acc
  }, {})
  return {
    id: pipe.id,
    name: pipe.name,
    folderId: pipe.folderId,
    pipelineId: pipe.pipelineId,
    values,
  }
}

export const getParentFilters = async (filterId: string): Promise<Filter[]> => {
  // the first element is a project, so we have to exclude it
  const folders = (await getParentFolders(filterId)).slice(1) as Filter[]
  const filters = folders.map(transformPipelineToFilter)
  return filters
}
