import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import type { DictionaryContent } from '../../../../../api/providers/dictionary'
import { CloseIcon } from '../../../icons'
import FieldFactory from '../../../form/FieldFactory'
import type { FieldFactoryProps } from '../../../form/FieldFactory'
import type { FieldError, OnFieldError } from '../../../form/types'

interface Props {
  onDelete: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (v: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  firstChild: boolean
  lastChild: boolean
  items: FieldFactoryProps
  error?: FieldError
  onError?: OnFieldError
  datasetDictionary?: DictionaryContent | null
  appId: string
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: 0,
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: theme.palette.grey[200],
  },
  leftBarFirst: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      top: -35,
      height: 'calc(58% + 35px)',
      borderWidth: '2px 0 2px 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
    },
  },
  leftBarTop: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      height: 'calc(58%)',
      borderWidth: '0 0 2px 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
    },
  },
  leftBarBottom: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -15,
      width: 15,
      height: 'calc(100%)',
      borderWidth: '0 0 0 2px',
      borderColor: '#CCC',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      top: '57%',
    },
  },
  box: { padding: theme.spacing(0.3) },
}))

const ArrayRule = ({
  firstChild,
  lastChild,
  onDelete,
  value,
  items,
  datasetDictionary,
  error,
  onError,
  onChange,
  appId,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  return (
    <Card className={classes.card} elevation={0}>
      {firstChild ? (
        <div className={classes.leftBarFirst} />
      ) : (
        <div className={classes.leftBarTop} />
      )}
      {!lastChild && <div className={classes.leftBarBottom} />}

      <Box display="flex" p={1} className={classes.box}>
        <Box p={1} className={classes.box} flexGrow={1}>
          <FieldFactory
            {...items}
            value={value}
            onChange={onChange}
            advanced
            datasetDictionary={datasetDictionary}
            error={error}
            onError={onError as OnFieldError}
            appId={appId}
          />
        </Box>
        <Box p={1} className={classes.box}>
          <IconButton
            size="small"
            onClick={() => {
              onDelete()
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  )
}

export default ArrayRule
