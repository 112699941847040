import React from 'react'
import { omit } from 'lodash'
import type { ChangeEventHandler } from 'react'
import Select from '@mui/material/Select'
import type { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import type { Extend } from '../../../../lib/types'

type ReactText = string | number

export type SelectInputProps = Extend<
  SelectProps,
  {
    value: string
    options: Array<{ value: ReactText; label: string }>
    onChange?: ChangeEventHandler<HTMLSelectElement>
  }
>

const SelectInput = ({
  options,
  onChange,
  ...rest
}: SelectInputProps): React.JSX.Element => {
  const newRest = omit(rest, 'appId')

  return (
    <Select
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e: any) => {
        onChange?.(e)
      }}
      variant="standard"
      {...newRest}
    >
      {options.map((option, key) => (
        <MenuItem key={key} value={option.value}>
          {option.label}&nbsp;
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectInput
