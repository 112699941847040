import {
  REQUEST_START,
  REQUEST_STOP,
  REQUEST_FAIL,
  REQUEST_DISMISS_ERRORS,
} from './types'

import type { RequestAction, RequestState } from './types'

const initState = {
  count: 0,
  errors: [],
}

export default (
  state: RequestState = initState,
  action: RequestAction,
): RequestState => {
  if (action.type === REQUEST_START) {
    const { count, errors } = state

    return {
      ...state,
      count: count + 1,
      errors: count > 0 ? errors : [],
    }
  } else if (action.type === REQUEST_STOP) {
    const { count } = state

    return {
      ...state,
      count: Math.max(0, count - 1),
    }
  } else if (action.type === REQUEST_FAIL) {
    const { errors } = state
    const { error } = action

    return {
      ...state,
      errors: [...errors, error],
    }
  } else if (action.type === REQUEST_DISMISS_ERRORS) {
    return {
      ...state,
      errors: [],
    }
  }

  return state
}
