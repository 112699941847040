import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { useResizeObserver } from '../hooks/useResizeObserver'

export interface PyramidData {
  group: string
  trace0: number
  trace1: number
}

const render = (
  svgId: string,
  data: PyramidData[],
  t: (key: string) => string,
  { width }: { width: number },
): void => {
  const height = 600
  if (width < 633) width = 633

  const margin = { top: 70, right: 10, bottom: 40, left: 10, middle: 28 }
  const regionWidth = width / 2 - margin.middle
  const pointA = regionWidth
  const pointB = width - regionWidth - 20

  // append the svg object to the body of the page
  d3.select(`.${svgId}`).selectAll('*').remove()
  const svg = d3
    .select(`#${svgId}`)
    .attr('width', margin.left + width + margin.right)
    .attr('height', margin.top + height + margin.bottom)
    .append('g')
    .attr('class', 'inner-region')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  svg
    .append('circle')
    .attr('cx', width / 3 - 70)
    .attr('r', 6)
    .attr('cy', -30)
    .style('fill', 'steelblue')
    .attr('fill-opacity', 0.5)

  svg
    .append('circle')
    .attr('cx', (width / 3) * 2 - 70)
    .attr('r', 6)
    .attr('cy', -30)
    .style('fill', 'firebrick')
    .attr('fill-opacity', 0.5)

  svg
    .append('text')
    .attr('x', width / 3 - 50)
    .attr('y', -30)
    .text(t('statistics.legend.left')) // trace0 left
    .style('font-size', '15px')
    .attr('alignment-baseline', 'middle')

  svg
    .append('text')
    .attr('x', (width / 3) * 2 - 50)
    .attr('y', -30)
    .text(t('statistics.legend.right')) // trace1 right
    .style('font-size', '15px')
    .attr('alignment-baseline', 'middle')
  // find the maximum data value on either side
  //  since this will be shared by both of the x-axes
  const maxValue = Math.max(
    d3.max(data, function (d: PyramidData) {
      return d.trace0
    }) as number,
    d3.max(data, function (d: PyramidData) {
      return d.trace1
    }) as number,
  )
  // SET UP SCALES

  // the xScale goes from 0 to the width of a region
  //  it will be reversed for the left x-axis
  const xScale = d3
    .scaleLinear()
    .domain([0, maxValue])
    .range([0, regionWidth])
    .nice()

  const yScale = d3
    .scaleBand()
    .domain(data.map((d) => d.group))
    .range([height, 0])

  // SET UP AXES
  const yAxisLeft = d3
    .axisRight(yScale)
    .tickSize(4)
    .tickPadding(margin.middle - 15)
    .tickFormat((domainValue) =>
      parseInt(domainValue) % 10 === 0 ? domainValue : '',
    )

  const yAxisRight = d3
    .axisLeft(yScale)
    .tickSize(4)
    .tickFormat(() => '')

  const xAxisRight = d3.axisBottom(xScale).tickFormat(d3.format(''))

  const xAxisLeft = d3
    .axisBottom(xScale.copy().range([pointA, 0]))
    // REVERSE THE X-AXIS SCALE ON THE LEFT SIDE BY REVERSING THE RANGE
    .tickFormat(d3.format(''))

  // so sick of string concatenation for translations
  const translation = (x: number, y: number): string => {
    return `translate(${x},${y})`
  }
  // MAKE GROUPS FOR EACH SIDE OF CHART
  // scale(-1,1) is used to reverse the left side so the bars grow left instead of right
  const leftBarGroup = svg
    .append('g')
    .attr('transform', translation(pointA, 0) + 'scale(-1,1)')
  const rightBarGroup = svg
    .append('g')
    .attr('transform', translation(pointB, 0))

  // DRAW AXES
  svg
    .append('g')
    .attr('class', 'axis y left')
    .attr('transform', translation(pointA, 0))
    .call(yAxisLeft)
    .selectAll('text')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')

  svg
    .append('g')
    .attr('class', 'axis y right')
    .attr('transform', translation(pointB, 0))
    .call(yAxisRight)

  svg
    .append('g')
    .attr('class', 'axis x left')
    .attr('transform', translation(0, height))
    .call(xAxisLeft)
    .style('font-size', '14px')

  svg
    .append('g')
    .attr('class', 'axis x right')
    .attr('transform', translation(pointB, height))
    .call(xAxisRight)
    .style('font-size', '14px')

  const parentDiv = (d3.select(`#${svgId}`)?.nodes()?.[0] as HTMLDivElement)
    ?.parentElement
  let tooltip: HTMLDivElement | null = null
  if (parentDiv !== null && parentDiv.childElementCount === 1) {
    tooltip = document.createElement('div')
    tooltip.style.position = 'absolute'
    tooltip.style.backgroundColor = 'white'
    tooltip.style.padding = '5px'
    tooltip.style.border = 'solid'
    tooltip.style.borderWidth = '2px'
    tooltip.style.borderRadius = '5px'
    tooltip.style.opacity = '0'
    parentDiv.append(tooltip)
  } else {
    tooltip =
      parentDiv !== null ? (parentDiv.children[1] as HTMLDivElement) : null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseover = function (this: any): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '1'
      d3.select(this).style('stroke', 'black').style('opacity', 1)
    }
  }

  const mouseMove = (title: string, [x, y]: [number, number]): void => {
    if (tooltip !== null) {
      tooltip.innerHTML = title
      tooltip.style.left = `${x}px`
      tooltip.style.top = `${y}px`
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function mousemoveRight(this: any, event: any, d: PyramidData): void {
    const [x, y] = d3.pointer(event, this)
    mouseMove(`${d.group}: ${d.trace1}`, [x + pointA, y])
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function mousemoveLeft(this: any, event: any, d: PyramidData): void {
    const [x, y] = d3.pointer(event, this)
    mouseMove(`${d.group}: ${d.trace0}`, [-x + pointB, y])
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseleave = function (this: any): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '0'
    }
    d3.select(this).style('stroke', 'none')
  }

  // DRAW BARS
  leftBarGroup
    .selectAll('.bar.left')
    .data(data)
    .enter()
    .append('rect')
    .attr('fill', 'steelblue')
    .attr('fill-opacity', 0.5)
    .attr('x', 0)
    .attr('y', (d: PyramidData) => yScale(d.group) ?? 0)
    .attr('width', (d: PyramidData) => xScale(d.trace0))
    .attr('height', yScale.bandwidth())
    .on('mouseover', mouseover)
    .on('mousemove', mousemoveLeft)
    .on('mouseleave', mouseleave)

  rightBarGroup
    .selectAll('.bar.right')
    .data(data)
    .enter()
    .append('rect')
    .attr('fill', 'firebrick')
    .attr('fill-opacity', 0.5)
    .attr('x', 0)
    .attr('y', (d: PyramidData) => yScale(d.group) ?? 0)
    .attr('width', (d: PyramidData) => xScale(d.trace1))
    .attr('height', yScale.bandwidth())
    .on('mouseover', mouseover)
    .on('mousemove', mousemoveRight)
    .on('mouseleave', mouseleave)
}

const useStyles = makeStyles({
  svg: {
    overflow: 'visible',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  root: {
    position: 'relative',
    display: 'flex',
    height: 700,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
})

interface StatisticsPyramidProps {
  data: PyramidData[]
  experimentId: string
  folderId: string
}

const StatisticsPyramid = ({
  data,
  experimentId,
  folderId,
}: StatisticsPyramidProps): React.JSX.Element => {
  const { t } = useTranslation()
  const svgId = `svg-${experimentId}-${folderId}`
  const wrapperRef = useRef<HTMLDivElement | undefined>(undefined)
  const dimensions = useResizeObserver(wrapperRef)
  const classes = useStyles()
  useEffect(() => {
    render(svgId, data, t, dimensions ?? { width: 900 })
  }, [dimensions])
  return (
    // @ts-expect-error Stuff with ref
    <div ref={wrapperRef} className={classes.root}>
      <svg id={svgId} className={`${svgId} ${classes.svg}`} />
    </div>
  )
}

export default StatisticsPyramid
