import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import type { Extend } from '../../../../lib/types'
import type { FieldProps } from '../../../form/types'
import FieldWrapper from '../../../form/FieldWrapper'
import SelectField from '../../../form/fields/SelectField'

type Props = Extend<
  FieldProps<{ eql: boolean } | { in: boolean[] } | boolean | undefined>,
  {
    options?: string[]
    operations?: string[]
  }
>

const BooleanField = ({
  required,
  label,
  value: initValue,
  onChange,
  options,
  operations,
  datasetDictionary,
  advanced,
  fullName,
  appId,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const [originalValue] = useState(initValue)
  const onReset = (): void => {
    onChange?.(originalValue)
  }
  const onClear = (): void => {
    onChange?.(undefined)
  }
  // parse value
  const value = initValue
  let text: string = ''
  if (value === undefined || _.isBoolean(value)) {
    text = _.toString(value)
  } else if ('eql' in value) {
    text = _.toString(value.eql)
  } else if ('in' in value) {
    text = _.toString(_.reduce(value.in, (sum, item) => sum || item))
  }

  let opts = [
    {
      value: 'true',
      label:
        datasetDictionary?.list[options?.[0] ?? '']
        ?? t('boolean.true')
        ?? 'true',
    },
    {
      value: 'false',
      label:
        datasetDictionary?.list[options?.[1] ?? '']
        ?? t('boolean.false')
        ?? 'false',
    },
  ]
  if (required === undefined || !required) {
    opts = [{ value: '', label: t('boolean.all') }, ...opts]
  }
  return (
    <FieldWrapper
      required={required}
      label={
        datasetDictionary?.list[fullName ?? label ?? ''] ?? fullName ?? label
      }
      onReset={onReset}
      onClear={onClear}
      advanced={advanced}
    >
      <SelectField
        appId={appId}
        displayEmpty
        value={text}
        options={opts}
        onChange={(v) => {
          let val
          if (v === 'false') {
            val = false
          } else if (v === 'true') {
            val = true
          }
          const newVal
            = val !== undefined
            && operations?.find((o) => o === 'eql') !== undefined
              ? { eql: val }
              : val
          onChange?.(val !== undefined ? newVal : undefined)
        }}
      />
    </FieldWrapper>
  )
}

export default BooleanField
