import React from 'react'
import { Trans } from 'react-i18next'
import { THEME_NAME } from '../../../../../constants/env'

// A widget that shows instructions
const InstructionsWidget = (): React.JSX.Element => {
  const logoName = `logo-${THEME_NAME}-instructions`

  return (
    <div className="h-full overflow-auto pl-2 ">
      <div className="flex min-h-full p-4 items-center justify-center  shadow-sm border border-gray-200 bg-white ">
        <div className="w-1/2">
          <div className="text-center m-4">
            <Trans i18nKey="instructionsWidget.welcome">
              <h1 className="text-4xl font-semibold text-gray-800">WELCOME</h1>
              <div className="my-6"></div>
              <h2 className="text-3xl font-medium text-gray-700">
                Please, create{' '}
                <a
                  href="/create-project"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  a new project
                </a>{' '}
                or select an existing one from the menu in the top left.
              </h2>
              <div className="my-6"></div>
              <h3 className="text-2xl font-medium text-gray-700">
                For further instructions you can consult the
                <a
                  href="https://docs.amalfianalytics.com/docs/anis/intro/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  {' '}
                  user manual
                </a>
                .
              </h3>
              <div className="mt-10">
                <img
                  src={`logos/${logoName}.png`}
                  alt="logo-anis"
                  className="pb-8 mr-[60px] inline-block"
                />
                <img
                  src="logos/logo-amalfi.png"
                  alt="logo-amalfi"
                  width="280"
                  className="inline-block"
                />
              </div>
            </Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstructionsWidget
