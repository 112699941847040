import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import { HourglassIcon } from '../../../icons'

const useStyles = makeStyles((theme) => ({
  // centers the inner content vertically and horizontally
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  text: {
    color: theme.palette.grey[500],
  },
}))

const WaitingWidget = (): React.JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <HourglassIcon className={classes.icon} size="10x" />
      </Box>
    </Box>
  )
}

export default WaitingWidget
