import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import i18n from 'i18next'
import { getRootFolder } from '../../../../api/providers/folder'
import { getParentFilters } from '../../../../api/providers/filter'
import { getExperiment } from '../../../../api/providers/experiment'
import { getDataset } from '../../../../api/providers/dataset'
import { getDictionary } from '../../../../api/providers/dictionary'
import ProjectRow from '../row/ProjectRow'
import FilterRow from '../row/FilterRow'
import ExperimentRow from '../row/ExperimentRow'
import { getShortLang } from '../../../lib/i18n'

interface Props {
  experimentId: string
  folderId: string
}

const ExperimentDetails = ({
  experimentId,
  folderId,
}: Props): React.JSX.Element => {
  const [selectedId, setSelectedId] = useState<string | undefined>()

  // loads initial data
  const state = useAsync(async () => {
    const [experiment, project, filters] = await Promise.all([
      getExperiment(experimentId, folderId),
      getRootFolder(folderId),
      getParentFilters(folderId),
    ])
    const dataset = await getDataset(project.datasetId)
    const dictionary = await getDictionary(
      dataset.metadata.dictionary as string,
      getShortLang(i18n.language),
      dataset.app_id,
    )
    return { project, filters, experiment, dictionary }
  }, [experimentId, folderId])
  const project = state.value?.project
  const filters = state.value?.filters ?? []
  const experiment = state.value?.experiment
  const dictionary = state.value?.dictionary

  useEffect(() => {
    setSelectedId(experimentId)
  }, [experimentId])

  return (
    <>
      {project !== undefined && (
        <ProjectRow
          projectId={project.id}
          expanded={project.id === selectedId}
          onSelect={setSelectedId}
        />
      )}
      {filters.map((filter, i) => (
        <FilterRow
          key={i}
          filterId={filter.id}
          dictionary={dictionary}
          expanded={filter.id === selectedId}
          onSelect={setSelectedId}
        />
      ))}
      {experiment !== undefined && (
        <ExperimentRow
          experimentId={experiment.id}
          folderId={experiment.folderId}
          dictionary={dictionary}
          expanded={experiment.id === selectedId}
          onSelect={setSelectedId}
        />
      )}
    </>
  )
}

export default ExperimentDetails
