import React from 'react'
import { useTranslation } from 'react-i18next'
import { getRawExperiment } from '../../../../api/providers/experiment'
import Row from './Row'
import { useAsync } from 'react-use'

export interface Props {
  experimentId: string
  folderId: string
  expanded?: boolean
  dictionary?: { list: { [key: string]: string } }
  onSelect?: (id?: string) => void
}

const ExperimentRow = ({
  experimentId,
  folderId,
  expanded,
  onSelect,
  dictionary,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()

  const state = useAsync(async () => {
    const experiment = await getRawExperiment(experimentId, folderId)
    return experiment
  }, [experimentId, folderId])
  if (state.value === undefined) {
    return <></>
  }
  const experiment = state.value
  const args = experiment.plan[experiment.plan.length - 1].args
  const fields = Object.entries(args).map(([label, value]) => ({
    label,
    value: JSON.stringify(value, null, ''),
  }))
  if (experiment.name.startsWith('__statistics__')) {
    return <></>
  }
  return (
    <Row
      title={experiment.name}
      expanded={expanded}
      dictionary={dictionary}
      onChange={(expanded) => {
        onSelect?.(expanded ? experiment._id : undefined)
      }}
      values={[
        {
          label: t('experimentDetails.module'),
          value:
            dictionary !== undefined
              ? dictionary.list[experiment.description]
              : experiment.description,
        },
        ...fields,
      ]}
      createdAt={experiment.created_at}
    />
  )
}

export default ExperimentRow
