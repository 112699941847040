import _, { values } from 'lodash'
import { getAppIds, http } from '../lib/http'
import { getStore, updateStore } from './store'

import { getRootFolder } from './folder'
import { getParentFilters } from './filter'
import { getDataset, getPipelinePipes } from './dataset'
import i18n from 'i18next'
import { memoizeWithTTL } from '../lib/utils'

// experiment status
export const STATUS_WAITING = 'WAITING'
export const STATUS_EXECUTING = 'EXECUTING'
export const STATUS_ERROR = 'ERROR'
export const STATUS_DONE = 'DONE'
export type STATUS =
  | typeof STATUS_WAITING
  | typeof STATUS_EXECUTING
  | typeof STATUS_ERROR
  | typeof STATUS_DONE

interface SoilExperiment {
  app_id: string
  created_at: number
  description: string
  experiment_group: string | null
  experiment_status: STATUS
  inputs: string[]
  name: string
  outputs: {
    [key: string]: string
  }
  plan: Array<{
    module: string
    inputs: string[]
    outputs: string[]
    args: object
    name: string
  }>
  status: {
    [key: string]: STATUS
  }
  user_id: string
  _id: string
}

export interface Experiment {
  id: string
  name: string
  createdAt?: number
  folderId: string
  pipelineId: string
  values: {
    [moduleId: string]: {
      [fieldName: string]: unknown
    }
  }

  // provided by the external API
  outputs: {
    [key: string]: string
  }
}

export const getExperiment = async (
  experimentId: string,
  folderId: string,
): Promise<Experiment> => {
  const appIds = await getAppIds()
  const stores = await Promise.allSettled(
    appIds.map(async (appId) => (await getStore(appId)).store),
  )
  for (const store of stores) {
    if (store.status === 'rejected') {
      continue
    }
    const experiment = store.value.experiments.find(
      (x) => x.id === experimentId && x.folderId === folderId,
    )
    if (experiment !== undefined) {
      return experiment
    }
  }
  console.error(`Experiment ${experimentId} not found`)
  throw new Error('Experiment not found')
}

// NOTE: keep in mind that this function only updates the experiment in the Store, not in the external API, and this might cause conflicts
export const updateExperiment = async (
  experimentId: string,
  folderId: string,
  body: Pick<Experiment, 'name'>,
): Promise<void> => {
  const experiment = await getExperiment(experimentId, folderId)
  const project = await getRootFolder(experiment.folderId)
  const dataset = await getDataset(project.datasetId)
  const { store } = await getStore(dataset.app_id)
  await updateStore(
    {
      ...store,
      experiments: store.experiments.map((x) =>
        x.id === experimentId && x.folderId === folderId
          ? { ...x, ...body }
          : x,
      ),
    },
    dataset.app_id,
  )
}

export const deleteExperiment = async (
  experimentId: string,
  folderId: string,
): Promise<void> => {
  const experiment = await getExperiment(experimentId, folderId)
  const project = await getRootFolder(experiment.folderId)
  const dataset = await getDataset(project.datasetId)
  const { store } = await getStore(dataset.app_id)
  await updateStore(
    {
      ...store,
      experiments: store.experiments.filter(
        (x) => !(x.id === experimentId && x.folderId === folderId),
      ),
    },
    dataset.app_id,
  )
}

export const createExperiment = async (
  body: Omit<Experiment, 'id' | 'experimentId' | 'outputs'>,
): Promise<string> => {
  const { name, folderId, pipelineId } = body
  const project = await getRootFolder(folderId)
  const filters = await getParentFilters(folderId)
  const subpopulationModule = _.find(body.values, (b) =>
    Boolean(b._subpopulation),
  )
  const subpopulationFolderId = subpopulationModule?._subpopulation as string
  const subpopulationFilters =
    subpopulationFolderId !== undefined
      ? await getParentFilters(subpopulationFolderId)
      : []
  const ds = await getDataset(project.datasetId)
  let lastPipeSubopop: string | null = null

  const replaceTerm = (
    term: string,
    i: number,
    type: string,
    last: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: { [key: string]: any },
    prefix: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    if (term === '$input') {
      return i > 0 ? `${prefix}pipe_filter_${i}` : project.datasetId
    } else if (term === '$output') {
      return `${prefix}pipe_filter_${i + 1}`
    } else if (term === '$dataset') {
      return project.datasetId
    } else if (term === '$userVariable') {
      return values._userVariable.model_id
    } else if (term === '$subpopulation') {
      return lastPipeSubopop ?? project.datasetId // values['$subpopulation']
    }
    if (type === 'inputs' && !last) {
      return `${term}_${i}`
    }
    if (type === 'outputs' && !last) {
      return `${term}_${i}`
    }
    return term
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cleanValues = (values: { [key: string]: any }): _.Dictionary<any> =>
    _.pickBy(values, (_v, k) => k[0] !== '_' && k[0] !== '$')

  const buildPipe =
    (prefix: string, len: number) =>
    (
      {
        pipelineId,
        values,
      }: {
        pipelineId: string
        values: { [moduleId: string]: { [fieldName: string]: unknown } }
      },
      i: number,
    ) => {
      const pipes = getPipelinePipes(ds, pipelineId)
      return pipes.map((pipe) => {
        const moduleId = pipe.moduleId
        const inputs = _.get(pipe, 'inputs', [])
        const outputs = _.get(pipe, 'outputs', [])
        const vals = values[moduleId]
        return {
          module: moduleId,
          args: { ...pipe.default, ...cleanValues(vals) },
          inputs: inputs.map((item) =>
            replaceTerm(item, i, 'inputs', len === i + 1, vals, prefix),
          ),
          outputs: outputs.map((item) =>
            replaceTerm(item, i, 'outputs', len === i + 1, vals, prefix),
          ),
        }
      })
    }

  const planSubpop = _.flatten(
    subpopulationFilters.map(buildPipe('subpop_', subpopulationFilters.length)),
  )
  if (planSubpop.length > 0) {
    lastPipeSubopop = planSubpop[planSubpop.length - 1].outputs[0]
  }
  const lplan = [...filters, body]
  const plan = _.flatten(lplan.map(buildPipe('', lplan.length)))
  // starts the experiment
  const url = '/v2/experiments/'
  const payload = {
    experiment: {
      description: pipelineId,
      name,
      plan: [...plan, ...planSubpop],
    },
  }

  const res = await (
    await http(ds.app_id)
  ).post<{
    experiment: {
      _id: string
      outputs: {
        [key: string]: string
      }
    }
  }>(url, payload)
  const { _id, outputs } = res.data.experiment

  // ...and adds it to the store
  const experiment = {
    ...body,
    id: _id,
    outputs,
  }
  const { store } = await getStore(ds.app_id)
  await updateStore(
    {
      ...store,
      experiments: [experiment, ...store.experiments],
    },
    ds.app_id,
  )

  return _id
}

export const createExperimentFromPlan = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plan: any,
  appId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const url = '/v2/experiments/'
  const payload = {
    experiment: {
      description: 'Download Patients',
      name: 'Download Patients',
      plan,
    },
  }
  const res = await (
    await http(appId)
  ).post<{
    experiment: {
      _id: string
      outputs: {
        [key: string]: string
      }
    }
  }>(url, payload)
  return res.data.experiment
}

export const getExperimentStatus = async (
  externalExperimentId: string,
  appId?: string,
  folderId?: string,
): Promise<STATUS> => {
  if (appId === undefined) {
    if (folderId === undefined) {
      throw new Error('FolderId is required')
    }
    const project = await getRootFolder(folderId)
    const dataset = await getDataset(project.datasetId)
    appId = dataset.app_id
  }
  const url = `/v2/experiments/${externalExperimentId}/`
  const res = await (
    await http(appId)
  ).get<{
    experiment_status: STATUS
  }>(url)
  return res.data.experiment_status
}

async function getAppIdFromOutpuId(outputId: string): Promise<string> {
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    const outputInStore =
      store.experiments.find((experiment) =>
        values(experiment.outputs).includes(outputId),
      ) !== undefined
    if (outputInStore) {
      return appId
    }
  }
  throw new Error(`Output Id ${outputId} not found in any store.`)
}

export const getExperimentData = async <T, V, U>(
  outputId: string,
  args: U,
): Promise<{ results: T; metadata: V }> => {
  const queryParams = _.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args as { [key: string]: any },
    (v, k) => `${k}=${JSON.stringify(v)}`,
  ).join('&')
  const url = `/v2/results/${outputId}/data/?${queryParams}`
  const appId = await getAppIdFromOutpuId(outputId)
  const res = await (await http(appId)).get(url)
  return res.data
}

// Gets the 'short' part of a "locale code".
//
// For example:
//   getShortLang('en-US') // returns 'en'
//   getShortLang('es')    // returns 'es'
//
const getShortLang = (lang: string): string => {
  const matches = lang.match(/^\w+/)

  return matches !== null ? matches[0] : ''
}

export const getExportData = async <U>(
  outputId: string,
  args: U,
  appId?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const queryParams = _.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args as { [key: string]: any },
    (v, k) => `${k}=${JSON.stringify(v)}`,
  ).join('&')
  // const url = `results/${outputId}/export/?${queryParams}`
  const url = `/v2/results/${outputId}/export/?language=${JSON.stringify(
    getShortLang(i18n.language),
  )}&${queryParams}`
  if (appId === undefined) {
    appId = await getAppIdFromOutpuId(outputId)
  }
  const res = await (await http(appId)).get(url, { responseType: 'blob' })

  return res.data
}

export const getRawExperiment = memoizeWithTTL(
  async (experimentId: string, folderId: string): Promise<SoilExperiment> => {
    const project = await getRootFolder(folderId)
    const dataset = await getDataset(project.datasetId)
    const appId = dataset.app_id
    const url = `/v2/experiments/${experimentId}/`
    const res = await (await http(appId)).get<SoilExperiment>(url)
    return res.data
  },
  60000,
)

export const getExperimentLogs = async (
  externalExperimentId: string,
  folderId: string,
): Promise<
  Array<{
    date: string
    level: string
    message: string
    module_id: string
  }>
> => {
  const project = await getRootFolder(folderId)
  const dataset = await getDataset(project.datasetId)
  const appId = dataset.app_id
  const url = `/v2/experiments/${externalExperimentId}/logs/`
  const res = await (
    await http(appId)
  ).get<
    Array<{
      date: string
      level: string
      message: string
      module_id: string
    }>
  >(url)
  return res.data
}
