import { createTheme } from '@mui/material/styles'
import { THEME_NAME } from './env'

const themes = {
  anis: {
    palette: {
      primary: {
        main: '#689f38',
      },
      secondary: {
        main: '#f44336',
      },
      tertiary: {
        main: '#38989f',
      },
    },
  },
  traject: {
    palette: {
      primary: {
        main: '#094F77',
      },
      secondary: {
        main: '#f44336',
      },
      tertiary: {
        main: '#38989f',
      },
    },
  },
}

// Palette generator:
// https://material.io/resources/color/#!/?view.left=0&view.right=0
// @ts-expect-error Choose theme by name
export const THEME = createTheme(themes[THEME_NAME])
