import { useState } from 'react'
import { useAsync } from 'react-use'
import _ from 'lodash'
import i18n from 'i18next'
import { getDictionary } from '../../../../../api/providers'
import { getShortLang } from '../../../../lib/i18n'

interface NodeColor {
  rangeIni: string
  rangeFin: string
  color: string
}

export interface DictionaryT {
  list: { [key: string]: string }
  'node-color'?: NodeColor[]
}

export interface DictionariesT {
  [key: string]: DictionaryT
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SimpleDict = any

type UseDictsT = (
  names: { [id: string]: string[] },
  appId: string,
) => [
  { [id: string]: DictionaryT | SimpleDict },
  (newName: { [id: string]: string[] }) => void,
]

export const useDictionary = (
  name: string = '',
  appId: string,
): [DictionaryT | undefined, (name: string) => void] => {
  const [dictName, setDictName] = useState(name)
  // TODO get user language
  const state = useAsync(
    async () =>
      await getDictionary(dictName, getShortLang(i18n.language), appId),
    [dictName],
  )
  const value = state.value
  return [value, setDictName]
}

export const useDictionaries: UseDictsT = (
  names: { [id: string]: string[] } = {},
  appId: string,
) => {
  const [dictNames, setDictNames] = useState(names)
  const state = useAsync(async () => {
    const dictIds = _.values(dictNames)

    const settledDictionaries = await Promise.allSettled(
      dictIds.map(
        async (id) =>
          await getDictionary(id, getShortLang(i18n.language), appId),
      ),
    )
    const dictionaries = settledDictionaries.map((dict) => {
      if (dict.status === 'rejected') {
        console.error('Failed to load dictionary', dict.reason)
      }
      return dict.status === 'fulfilled' ? dict.value : undefined
    })

    return _.zipObject(_.flatten(dictIds), dictionaries)
  }, [dictNames])

  return [state.value ?? {}, setDictNames]
}

export const getDictAndCode = (
  label: string,
  encodingDict: { [key: string]: string[] },
): [string[], string] => {
  const [rest, code] = label.split('=')
  return [encodingDict[rest], code]
}

export const getDictVal = (
  dicts: DictionariesT,
  label: string,
  encodingDict: { [dictName: string]: string[] },
): string => {
  const [dict, code] = getDictAndCode(label, encodingDict)
  const dictName = _.isArray(dict) ? dict[0] : dict
  if (!(dictName in dicts)) {
    return code
  }
  return `${code} ${dicts[dictName].list[code] ?? ''}`
}
