import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { isString, isNumber, isArray, map, isBoolean, isObject } from 'lodash'
import { AngleDownIcon } from '../../icons'
import { replaceParallel } from '../../../lib/util'
import SubpopulationGrid from './SubpopulationGrid'

interface Props {
  defaultExpanded?: boolean
  expanded?: boolean
  title: string
  type?: string
  createdAt?: number
  values: Array<{
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  }>
  dictionary?: {
    list: {
      [key: string]: string
    }
  }
  onChange?: (expanded: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(0.75),
  },
  summary: {
    overflow: 'hidden',
  },
  label: {
    color: theme.palette.text.secondary,
  },
}))

const ParsedObject = ({
  obj,
  dictionary,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any
  dictionary?: { list: { [key: string]: string } }
}): React.JSX.Element => {
  const { t } = useTranslation()

  if (obj.gte !== undefined && obj.lte !== undefined) {
    const asDateG = moment(obj.gte, 'YYYYMMDD', true)
    const asDateL = moment(obj.lte, 'YYYYMMDD', true)

    return (
      <span>
        {t('experimentDetails.between', {
          max: asDateG.isValid() ? asDateG.format('DD/MM/YYYY') : obj.lte,
          min: asDateL.isValid() ? asDateL.format('DD/MM/YYYY') : obj.gte,
        })}
      </span>
    )
  }
  if (
    obj.eql !== undefined ||
    obj.regexp !== undefined ||
    isString(obj) ||
    isNumber(obj)
  ) {
    const val =
      obj.eql ??
      (isObject(obj) && 'regexp' in obj
        ? replaceParallel(
            ['\\.', '.'],
            ['.', '?'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (obj as any).regexp.replace(/\.\*/g, '*'),
          )
        : obj)
    const newVal = dictionary !== undefined ? dictionary.list[val] : val
    const asDate = moment(newVal, 'YYYYMMDD', true)
    if (asDate.isValid()) {
      return <span>{asDate.format('DD/MM/YYYY')}</span>
    }
    return <span>{newVal ?? obj}</span>
  }
  if (isArray(obj) || obj.in !== undefined) {
    return (
      <div style={{ paddingLeft: 5 }}>
        {'in' in obj && t('fields.string.in')}
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ('in' in obj ? obj.in : obj).map((o: any, i: number) => (
            <div key={String(i)} style={{ paddingLeft: 5 }}>
              <ParsedObject obj={o} dictionary={dictionary} />
            </div>
          ))
        }
      </div>
    )
  }
  if (isBoolean(obj)) {
    return <>{obj}</>
  }
  if ('has' in obj) {
    return (
      <div>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          obj.has.map((o: any, i: any) => {
            return (
              <div key={String(i)}>
                {map(o, (v, j) => {
                  return (
                    <div key={j}>
                      {dictionary !== undefined ? dictionary.list[j] : j} &nbsp;
                      <ParsedObject obj={v} dictionary={dictionary} />
                    </div>
                  )
                })}
              </div>
            )
          })
        }
      </div>
    )
  }
  try {
    const json = JSON.stringify(obj, null, 2)
    return <div title={json}>-</div>
  } catch (_e) {
    return <></>
  }
}

const ParseValue = ({
  val,
  dictionary,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val?: any
  dictionary?: { list: { [key: string]: string } }
}): React.JSX.Element => {
  try {
    const obj = JSON.parse(val)

    return <ParsedObject obj={obj} dictionary={dictionary} />
  } catch (_e) {
    return val ?? <></>
  }
}

const Row = ({
  defaultExpanded,
  title,
  values,
  dictionary,
  createdAt,
  type,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded?.(expanded)
      }}
    >
      <AccordionSummary
        classes={{ content: classes.summary }}
        expandIcon={<AngleDownIcon />}
      >
        <Typography variant="body1" noWrap title={title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {type === 'Subpopulation' ? (
          <SubpopulationGrid values={values} dictionary={dictionary} />
        ) : (
          <Grid container direction="column">
            {values.map(({ label, value }, i) => (
              <Grid key={i} className={classes.row} container spacing={1}>
                <Grid item className={classes.label}>
                  <Typography variant="body2">
                    {dictionary !== undefined ? dictionary.list[label] : label}:
                  </Typography>
                </Grid>
                <Grid item xs>
                  {<ParseValue val={value} dictionary={dictionary} />}
                </Grid>
              </Grid>
            ))}
            {createdAt !== undefined && (
              <Typography variant="body2" color="textSecondary">
                {new Date(createdAt).toLocaleString()}
              </Typography>
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Row
