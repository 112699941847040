import React, { useContext } from 'react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import {
  RegularEditIcon,
  RegularCopyIcon,
  ExportIcon,
  RegularDeleteIcon,
} from '../../icons'
import { ExportDataContext } from '../WidgetFactory/ExportDataContext'
import { LLM } from '../../../../constants/env'
import AISearchField from '../../form/fields/AISearchField/AISearchField'

interface Props {
  breadcrumbs: string[]
  experimentId: string
  folderId: string
  children: ReactNode
  pipelineId: string
}

const useStyles = makeStyles((theme) => {
  const s = theme.spacing(1)

  return {
    root: { height: '100%', paddingLeft: s, overflow: 'auto' },
    paper: {
      minHeight: '100%',
      padding: s,
      borderRadius: 0,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    header: { width: '100%', marginLeft: s },
    container: { width: '100%' },
  }
})

const WidgetWrapper = ({
  breadcrumbs,
  experimentId,
  folderId,
  pipelineId,
  children,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const exportData = useContext(ExportDataContext)
  const len = breadcrumbs.length
  const isStatistics =
    len > 0 && breadcrumbs[len - 1].startsWith('__statistics__')
  const exportArgs: { format?: string } = exportData.args ?? {}
  const deleteExperimentLabel = t('button.deleteExperiment')
  const exportExperimentLabel = t('button.exportExperiment')
  const editExperimentLabel = t('button.editExperiment')
  const copyExperimentLabel = t('button.copyExperiment')

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Grid className={classes.header} container alignItems="center">
          {LLM && (
            <Grid item xs={12}>
              <AISearchField folderId={folderId} experimentId={experimentId} />
            </Grid>
          )}
          <Grid item xs>
            <Breadcrumbs maxItems={4}>
              {breadcrumbs
                .filter((label) => !label.startsWith('__statistics__'))
                .map((label, i) => (
                  <Typography
                    key={i}
                    color={i < len - 1 ? 'textSecondary' : 'textPrimary'}
                  >
                    {label}
                  </Typography>
                ))}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <IconButton
              title={exportExperimentLabel}
              onClick={() => {
                navigate(`/export-experiment/${folderId}/${experimentId}`, {
                  state: {
                    data: exportData.data,
                    resultId: exportData.resultId,
                    args: exportArgs,
                    downloadablePatients: exportData.downloadablePatients,
                  },
                })
              }}
              disabled={pipelineId === 'Time Statistics'}
              size="large"
            >
              <ExportIcon size="xs" />
            </IconButton>
            {!isStatistics && (
              <>
                <IconButton
                  title={editExperimentLabel}
                  onClick={() => {
                    navigate(`/edit-experiment/${folderId}/${experimentId}`)
                  }}
                  size="large"
                >
                  <RegularEditIcon size="xs" />
                </IconButton>
                <IconButton
                  title={copyExperimentLabel}
                  onClick={() => {
                    navigate(`/copy-experiment/${folderId}/${experimentId}`)
                  }}
                  size="large"
                >
                  <RegularCopyIcon size="xs" />
                </IconButton>
                <IconButton
                  title={deleteExperimentLabel}
                  onClick={() => {
                    navigate(`/delete-experiment/${folderId}/${experimentId}`)
                  }}
                  size="large"
                >
                  <RegularDeleteIcon size="xs" />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
        <Box
          m={1}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {children}
        </Box>
      </Paper>
    </Box>
  )
}

export default WidgetWrapper
