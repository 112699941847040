import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import type { ButtonProps } from '@mui/material/Button'
import { SendIcon, HourglassIcon } from '../../icons'

type Props = ButtonProps

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      marginLeft: theme.spacing(1),
    },
  }
})

const SubmitButton = ({
  children,
  disabled = false,
  color = 'primary',
  ...rest
}: Props): React.JSX.Element => {
  const classes = useStyles()

  return (
    <Button disabled={disabled} color={color} {...rest}>
      {children}
      {disabled ? (
        <HourglassIcon className={classes.icon} />
      ) : (
        <SendIcon className={classes.icon} />
      )}
    </Button>
  )
}

export default SubmitButton
