import {
  EXPERIMENT_UPDATE,
  EXPERIMENT_DELETE,
  EXPERIMENT_CREATE,
} from './types'
import type {
  ExperimentCreateAction,
  ExperimentUpdateAction,
  ExperimentDeleteAction,
} from './types'

export const updateExperiment = (
  id: string,
  name: string,
  folderId: string,
  description?: string,
): ExperimentUpdateAction => ({
  type: EXPERIMENT_UPDATE,
  payload: { id, name, folderId, description },
})

export const deleteExperiment = (
  id: string,
  folderId: string,
): ExperimentDeleteAction => ({
  type: EXPERIMENT_DELETE,
  payload: { id, folderId },
})

export const createExperiment = (payload: {
  name: string
  folderId: string
  pipelineId: string
  values: {
    [moduleId: string]: {
      [fieldName: string]: unknown
    }
  }
}): ExperimentCreateAction => ({
  type: EXPERIMENT_CREATE,
  payload,
})
