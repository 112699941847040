import _ from 'lodash'
import { uniqId } from '../lib/utils'
import { getStore, updateStore } from './store'
import type { Folder } from './folder'
import { getRootFolder, getFolderFilters } from './folder'
import type { Filter } from './filter'
import { getDataset } from './dataset'
import { getAppIds } from '../lib/http'
export { deleteFolder as deleteProject } from './folder'

export type Project = Omit<Folder, 'folderId'> & {
  datasetId: string
  datasetAlias?: string
}

export const createProject = async (
  body: Omit<Project, 'id'>,
): Promise<string> => {
  const id = uniqId()
  const dataset = await getDataset(body.datasetId)
  const appId = dataset.app_id
  const { store } = await getStore(appId)
  const project: Project = { id, ...body }
  if (dataset.alias !== undefined) {
    project.datasetAlias = dataset.alias
  }
  await updateStore(
    {
      ...store,
      folders: [project, ...store.folders],
    },
    appId,
  )

  return id
}

export const getProject = async (projectId: string): Promise<Project> => {
  let project: Folder | undefined
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    project = store.folders.find(
      (x) => !_.has(x, 'folderId') && x.id === projectId,
    )
    if (project !== undefined) {
      return project as Project
    }
  }
  throw new Error('Project not found')
}

export interface FolderStructure {
  folder: Project | Filter
  children: InnerFolderStructure[]
}
export interface InnerFolderStructure {
  folder: Filter
  children: InnerFolderStructure[]
}

export const getInnerFolderStructure = async (
  folderId: string,
): Promise<InnerFolderStructure[]> => {
  const children = await getFolderFilters(folderId)
  const subfolders = await Promise.all(
    children.map(async (c) => await getInnerFolderStructure(c.id)),
  )
  const result = _.zip(children, subfolders).map(([folder, children]) => ({
    folder,
    children,
  })) as InnerFolderStructure[]
  return result
}

export const getProjectStructure = async (
  folderId?: string,
): Promise<FolderStructure> => {
  if (folderId === undefined) {
    throw new Error('Undefined folderId')
  }
  const root = await getRootFolder(folderId)
  return {
    folder: root,
    children: await getInnerFolderStructure(root.id),
  }
}
