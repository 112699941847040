import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { FALLBACK_LANG } from '../constants/env'
import resources from './translations'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: FALLBACK_LANG,
    defaultNS: 'default',
    resources,
    // debug: true,
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
    detection: {
      lookupCookie: 'app.locale',
      lookupQuerystring: 'locale',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
  })
  .then(
    () => {},
    () => {
      console.error('Failed to load i18n')
    },
  )

export default i18n
