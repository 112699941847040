import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { InfoIcon, RegularDeleteLeftIcon, WrenchIcon } from '../../../icons'
import AudioRecorder from './AudioRecorder'
import { useTranslation } from 'react-i18next'
import {
  type ActionResponse,
  speechToText,
  textToCaseMixAction,
} from '@/api/providers/llm'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { FieldsTree } from './AvailableFields'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Grow from '@mui/material/Grow'
import useAsync, { type AsyncState } from 'react-use/lib/useAsync'
import CircularProgress from '@mui/material/CircularProgress'
import {
  createExperiment,
  createExperimentCompare,
  getDatasetFromFolder,
} from './createExperiment'
import { newMenuItem } from '@/store/actions'
import type { MenuNewItemAction } from '@/store/namespaces/menu/types'
import { useDispatch } from 'react-redux'
import type { Dispatch } from 'redux'
import { getFilter } from '@/api/providers/filter'
import {
  type DictionaryContent,
  getDictionary,
} from '@/api/providers/dictionary'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { getStore } from '@/api/providers/store'
import { getFolder, getRootFolder } from '@/api/providers/folder'
import { getExperiment } from '@/api/providers/experiment'
import { useResizeObserver } from '@/ui/components/app/widgets/hooks/useResizeObserver'
import { DEPRECATED_FEATURES } from '@/constants/env'

interface AISearchFieldProps {
  folderId: string
  experimentId: string
}

export interface UseAIFilterProps {
  folderId: string
  experimentId: string
  userQuery: string
}

export function useAIFilter({
  folderId,
  userQuery,
  experimentId,
}: UseAIFilterProps): AsyncState<ActionResponse> {
  const { i18n } = useTranslation()
  const state = useAsync(async (): Promise<ActionResponse | undefined> => {
    if (!userQuery || userQuery.length === 0) {
      return undefined
    }

    try {
      console.log(
        `useAIFilter executing with query: ${userQuery.substring(0, 30)}...`,
      )
      const dataset = await getDatasetFromFolder(folderId)
      const appId = dataset.app_id
      const res = await textToCaseMixAction(
        appId,
        userQuery,
        i18n.language,
        dataset.id,
        folderId,
        experimentId,
      )
      if (res.error !== undefined) {
        throw new Error(res.error)
      }
      if (res.result === undefined) {
        throw new Error('No result')
      }

      console.log('useAIFilter generated result successfully')
      return { ...res.result }
    } catch (error) {
      console.error('Error in useAIFilter:', error)
      throw error
    }
  }, [userQuery, folderId, experimentId, i18n.language])
  return state
}

function onAudioFile(
  appId: string,
  audioBlob: Blob,
  language: string,
  setText: (text: string) => void,
  setUserQuery: (query: string) => void,
): void {
  speechToText(appId, audioBlob, language)
    .then((res) => {
      setText(res.result)
      setUserQuery(res.result)
    })
    .catch((err) => {
      console.error(err)
    })
}

export const dispatchAction = async (
  folderId: string,
  action: ActionResponse,
  dispatch: Dispatch<MenuNewItemAction>,
  modelId?: string,
  selectedSubpopulationFolder?: string,
): Promise<{ experimentId: string; folderId: string }> => {
  switch (action.action) {
    case 'Patients Filter':
    case 'Episodes Filter':
    case 'Assessment Filter':
      return createExperiment(
        folderId,
        action.action,
        [action.body.filter],
        action.body.title,
      )
        .then(({ experimentId, folderId }) => {
          dispatch(newMenuItem(folderId, experimentId, true))
          return { experimentId, folderId }
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    case 'Clustering':
      return createExperiment(
        folderId,
        action.action,
        [action.body.clustering],
        action.body.title,
      )
        .then(({ experimentId, folderId }) => {
          dispatch(newMenuItem(folderId, experimentId, true))
          return { experimentId, folderId }
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    case 'User Variable':
      return getExperiment(modelId ?? action.body.id_model, folderId)
        .then(async (experiment) => {
          const experimentName = action.body.title.replace(
            'selectModel()',
            experiment.name,
          )
          const experimentModelId = Object.values(experiment.outputs)[0]
          return createExperiment(
            folderId,
            action.action,
            [{}, action.body.filter],
            experimentName,
            experimentModelId,
          )
        })
        .then(({ experimentId, folderId }) => {
          dispatch(newMenuItem(folderId, experimentId, true))
          return { experimentId, folderId }
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    case 'Compare Graph':
      return getFolder(selectedSubpopulationFolder ?? action.body.folder_id)
        .then(({ name }) => {
          return createExperimentCompare(
            folderId,
            action.action,
            [action.body.compare_graph],
            action.body.title.replace('selectFolder()', name),
            action.body.folder_id,
          )
        })
        .then(({ experimentId, folderId }) => {
          dispatch(newMenuItem(folderId, experimentId, true))
          return { experimentId, folderId }
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    default:
      return Promise.reject(new Error('Unsupported action type'))
  }
}

function FolderCandidates({
  folderId,
  setSelectedCandidate,
  selectedCandidate,
}: {
  folderId: string
  setSelectedCandidate: (candidate: string) => void
  selectedCandidate?: string
}): React.JSX.Element {
  const candidates = useAsync(async () => {
    const dataset = await getDatasetFromFolder(folderId)
    const { store } = await getStore(dataset.app_id)
    const currentRootFolder = await getRootFolder(folderId)
    const candidates = (
      await Promise.all(
        store.folders.map(async (folder) => {
          let rootFolder = folder
          if (folder.folderId !== undefined) {
            rootFolder = await getRootFolder(folder.folderId)
          }
          return { folder, filter: rootFolder.id === currentRootFolder.id }
        }),
      )
    )
      .filter((candidate) => candidate.filter)
      .map((candidate) => candidate.folder)
    return candidates
  })
  return !candidates.loading ? (
    <Select
      value={selectedCandidate}
      onChange={(e) => {
        setSelectedCandidate(e.target.value)
      }}
      size="small"
      color="primary"
    >
      {candidates.value
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </Select>
  ) : (
    <CircularProgress size={24} />
  )
}

function ModelCandidates({
  folderId,
  setSelectedCandidate,
  selectedCandidate,
}: {
  folderId: string
  setSelectedCandidate: (candidate: string) => void
  selectedCandidate?: string
}): React.JSX.Element {
  const candidates = useAsync(async () => {
    const dataset = await getDatasetFromFolder(folderId)
    const currentRootFolder = await getRootFolder(folderId)
    const { store } = await getStore(dataset.app_id)
    const candidates = (
      await Promise.all(
        store.experiments.map(async (experiment) => {
          const rootFolder = await getRootFolder(experiment.folderId)
          return {
            experiment,
            filter:
              experiment.pipelineId === 'Clustering' &&
              rootFolder.id === currentRootFolder.id,
          }
        }),
      )
    )
      .filter((candidate) => candidate.filter)
      .map((candidate) => candidate.experiment)
    return candidates
  })
  return !candidates.loading ? (
    <Select
      value={selectedCandidate}
      onChange={(e) => {
        setSelectedCandidate(e.target.value)
      }}
      size="small"
      color="primary"
    >
      {candidates.value
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </Select>
  ) : (
    <CircularProgress size={24} />
  )
}

function Interpretation({
  interpretation,
  modelCandidates,
  folderCandidates,
}: {
  interpretation: string
  modelCandidates: React.JSX.Element
  folderCandidates: React.JSX.Element
}): React.ReactNode {
  if (interpretation.includes('selectModel()')) {
    const parts = interpretation.split('selectModel()')
    return (
      <>
        {parts[0]}
        {modelCandidates}
        {parts[1]}
      </>
    )
  }
  if (interpretation.includes('selectFolder()')) {
    const parts = interpretation.split('selectFolder()')
    return (
      <>
        {parts[0]}
        {folderCandidates}
        {parts[1]}
      </>
    )
  }
  return <span>{interpretation}</span>
}

function Details({
  details,
  selectedModelId,
  selectedFolderId,
  dimensions,
}: {
  details: string
  selectedModelId?: string
  selectedFolderId?: string
  dimensions: { width: number; height: number } | undefined
}): React.ReactNode {
  const { value: modelName, loading: modelLoading } = useAsync(async () => {
    if (selectedModelId !== undefined && selectedFolderId !== undefined) {
      const experiment = await getExperiment(selectedModelId, selectedFolderId)
      return experiment.name
    }
    return ''
  }, [selectedModelId])
  const { value: folderName, loading: folderLoading } = useAsync(async () => {
    if (selectedFolderId !== undefined) {
      const folder = await getFolder(selectedFolderId)
      return folder.name
    }
    return ''
  }, [selectedFolderId])
  if (modelLoading || folderLoading) {
    return <CircularProgress size={24} />
  }
  if (details.includes('selectedModel()')) {
    const parts = details.split('selectedModel()')
    return (
      <div>
        {parts[0]}&nbsp;&ldquo;
        <span style={{ fontStyle: 'italic' }}>{modelName}</span>&rdquo;&nbsp;
        {parts[1]}
      </div>
    )
  }
  if (details.includes('selectedFolder()')) {
    const parts = details.split('selectedFolder()')
    return (
      <div>
        {parts[0]}&nbsp;&ldquo;
        <span style={{ fontStyle: 'italic' }}>{folderName}</span>&rdquo;&nbsp;
        {parts[1]}
      </div>
    )
  }

  return (
    <div
      style={{
        overflowX: 'auto',
        maxWidth:
          dimensions !== undefined
            ? `${Math.floor(dimensions.width) - 100}px`
            : '100%',
        border: '0.5px solid',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRadius: '4px',
        scrollbarWidth: 'thin',
        scrollBehavior: 'smooth',
      }}
    >
      <pre>{details}</pre>
    </div>
  )
}

export default function AISearchField({
  folderId,
  experimentId,
}: AISearchFieldProps): React.JSX.Element {
  const [isTreeOpen, setIsTreeOpen] = useState(false)
  const [isTyping, setIsTyping] = useState(true)
  const [text, setText] = useState('')
  const [userQuery, setUserQuery] = useState<string>('')
  const [refineMode, setRefineMode] = useState(false)
  const { t, i18n } = useTranslation()
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [selectedModel, setSelectedModel] = useState<string | undefined>()
  const [selectedSubpopulationFolder, setSelectedSubpopulationFolder] =
    useState<string | undefined>()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const dimensions = useResizeObserver(wrapperRef)

  const { value, loading } = useAsync(async () => {
    await delay(200) // if this is not here sometimes fails
    const filter = await getFilter(folderId)
    const dataset = await getDatasetFromFolder(folderId)

    let dict: DictionaryContent = { list: {} }
    if (dataset.metadata.dictionary !== undefined) {
      dict = await getDictionary(
        dataset.metadata.dictionary ?? '',
        i18n.language.slice(0, 2),
        dataset.app_id,
      )
    }
    if (filter.pipelineId !== undefined) {
      return { dataset, filterName: filter.name, dict }
    }
    return { dataset, dict }
  }, [folderId])
  const dispatch = useDispatch()

  const state = useAIFilter({ folderId, userQuery, experimentId })
  useEffect(() => {
    if (textFieldRef.current !== null) {
      textFieldRef.current.focus()
    }
  }, [])

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLDivElement>,
  ): Promise<void> => {
    if (event.key === 'Enter' && !refineMode) {
      if (userQuery === text && text.length > 0) {
        textFieldRef.current?.blur()
        if (state.value !== undefined) {
          await dispatchAction(
            folderId,
            state.value,
            dispatch,
            selectedModel,
            selectedSubpopulationFolder,
          )
        }
      } else {
        setUserQuery(text)
      }
    }
    if (event.key === 'Escape') {
      setUserQuery('')
    }
  }
  let currentSelectedModel: string | undefined
  if (state.value?.action === 'User Variable') {
    currentSelectedModel = selectedModel ?? state.value?.body.id_model
  }
  let currentSelectedSubpopulationFolder: string | undefined
  if (state.value?.action === 'Compare Graph') {
    currentSelectedSubpopulationFolder =
      selectedSubpopulationFolder ?? state.value?.body.folder_id
  }

  return (
    <div ref={wrapperRef}>
      <Box sx={{ width: '100%', position: 'relative', zIndex: 100 }}>
        <Stack direction="column" alignItems="center">
          <TextField
            variant="outlined"
            placeholder={value?.filterName ?? t('fields.ai.placeholder')}
            fullWidth
            autoComplete="off"
            value={text}
            onChange={(e) => {
              if (text.length === 0 && e.target.value === ' ') {
                return
              }
              setText(e.target.value)
              setUserQuery('')
            }}
            inputRef={textFieldRef}
            onKeyUp={handleKeyPress}
            onFocus={() => {
              setIsTyping(true)
            }}
            onBlur={() => {
              setIsTyping(false)
            }}
            multiline={refineMode}
            minRows={1}
            maxRows={20}
            style={{ opacity: state.loading ? 0.5 : 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    opacity: isTyping ? 1 : 0.5, // Adjust opacity based on typing state
                    transition: 'opacity 0.3s', // Smooth transition
                  }}
                >
                  {state.loading && <CircularProgress size={24} />}
                  <IconButton
                    onClick={() => {
                      setUserQuery('')
                      setText('')
                    }}
                  >
                    <RegularDeleteLeftIcon />
                  </IconButton>
                  <span
                    style={{ color: 'gray', fontSize: '25px', opacity: 0.5 }}
                  >
                    |
                  </span>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setUserQuery(text)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  {!loading &&
                    /* the loading is needed otherwise does not work */ !DEPRECATED_FEATURES /* temporary disabled for next.anis */ && (
                      <AudioRecorder
                        pushToTalkEnabled={text.length === 0}
                        onAudioFile={(audioBlob) => {
                          if (value?.dataset !== undefined) {
                            onAudioFile(
                              value.dataset.app_id,
                              audioBlob,
                              i18n.language.slice(0, 2),
                              setText,
                              setUserQuery,
                            )
                          }
                        }}
                      />
                    )}
                  <IconButton
                    onClick={() => {
                      setIsTreeOpen(true)
                    }}
                    title={t('fields.ai.datasetSchema')}
                    color="primary"
                  >
                    <InfoIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grow
            in={
              !state.loading && state.value?.body.interpretation !== undefined
            }
            timeout={200}
          >
            <Box
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 100,
              }}
            >
              <Box
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography component="div">
                    {value?.dict.list[state.value?.action ?? ''] ??
                      state.value?.action ??
                      ''}
                    :&nbsp;
                  </Typography>
                  <Typography color="primary" fontWeight="bold" component="div">
                    <Interpretation
                      interpretation={state.value?.body.interpretation ?? ''}
                      modelCandidates={
                        <ModelCandidates
                          folderId={folderId}
                          setSelectedCandidate={setSelectedModel}
                          selectedCandidate={currentSelectedModel}
                        />
                      }
                      folderCandidates={
                        <FolderCandidates
                          folderId={folderId}
                          setSelectedCandidate={setSelectedSubpopulationFolder}
                          selectedCandidate={currentSelectedSubpopulationFolder}
                        />
                      }
                    />
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{t('fields.ai.details') ?? ''}:&nbsp;</Typography>
                  <Typography color="primary" fontWeight="bold" component="div">
                    <Details
                      details={state.value?.body.details ?? ''}
                      selectedModelId={selectedModel ?? currentSelectedModel}
                      selectedFolderId={currentSelectedSubpopulationFolder}
                      dimensions={
                        dimensions as
                          | { width: number; height: number }
                          | undefined
                      }
                    />
                  </Typography>
                </div>
                <Box display="flex" flexDirection="row" width="100%">
                  <Box display="flex">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setRefineMode(true)
                        setText(
                          (state.value?.body.details ?? '').replace(
                            /\t/g,
                            '    ',
                          ),
                        )
                      }}
                    >
                      <WrenchIcon size="xs" />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1} />
                  <Box>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setUserQuery('')
                        textFieldRef.current?.focus()
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={async () => {
                        if (state.value !== undefined) {
                          await dispatchAction(
                            folderId,
                            state.value,
                            dispatch,
                            selectedModel,
                            selectedSubpopulationFolder,
                          )
                        }
                        textFieldRef.current?.blur()
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grow>
        </Stack>
      </Box>
      <Dialog
        open={isTreeOpen}
        onClose={() => {
          setIsTreeOpen(false)
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t('fields.ai.datasetSchema')}</DialogTitle>
        <DialogContent>
          <FieldsTree folderId={folderId} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
