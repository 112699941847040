import { useDispatch } from 'react-redux'
import { createUserVariable as createUserVariableAction } from '../../../../../store/actions'
import type { Field } from '../../../../../api/providers'
import type { UserVariableCreateAction } from '../../../../../store/namespaces/userVariable/types'
import type { Dispatch } from 'redux'

export const useCreateUserVariable = (): ((
  folderId: string,
  modelId: string,
  name: string,
  type: Field,
) => UserVariableCreateAction) => {
  const dispatch = useDispatch<Dispatch<UserVariableCreateAction>>()
  return (folderId: string, modelId: string, name: string, type: Field) =>
    dispatch(createUserVariableAction(folderId, modelId, name, type))
}
