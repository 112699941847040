import { scan } from 'react-scan' // must be imported before React and React DOM
// @ts-expect-error react is not used but it is
import React, { type ReactElement } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { THEME } from './constants/theme'
import { THEME_NAME, SCAN_ENABLED } from './constants/env'
import store from './store/store'
import UI from './ui/UI'
import { useTitle } from 'react-use'
import './icons'
import './i18n'

// Import theme CSS files
import './assets/styles/flexlayout-react/anis.css'
import './styles.css'

scan({ enabled: SCAN_ENABLED })
const rootDOMElement = document.getElementById('app') as HTMLElement

const LoadStyles = (): ReactElement | null => {
  let title = ''
  switch (THEME_NAME) {
    case 'traject':
      title =
        'TRAJECT | Clinical Trajectories Exploration Tool | Amalfi Analytics'
      break
    case 'anis':
      title = 'ANIS | Clinical Data Exploration Tool | Amalfi Analytics'
      break
  }
  useTitle(title)
  return null
}

createRoot(rootDOMElement).render(
  <>
    <LoadStyles />
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={THEME}>
          <UI />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </>,
)
