import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import { ChevronDownIcon } from '../icons'
import './buttons.css'
const BACKGROUND_COLOR = '#f0f0f0'

export default function ArrowButton({
  onClick,
  isOpen,
}: {
  onClick: () => void
  isOpen?: boolean
}): React.JSX.Element {
  const [open, setOpen] = useState(isOpen ?? false)
  return (
    <IconButton
      className="hover-effect"
      style={{
        backgroundColor: BACKGROUND_COLOR,
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.3s ease-in-out',
      }}
      color="inherit"
      onClick={() => {
        setOpen(!open)
        onClick()
      }}
    >
      <ChevronDownIcon size="sm" />
    </IconButton>
  )
}
