import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useWindowSize, useRaf } from 'react-use'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Loading from '../Loading'

const useStyles = makeStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    zIndex: 0,
    minHeight: 60,
  },
})

// This is a 'fake' Header. The only purpose of this class is to add a vertical
// space (gap), so the rest of the components can be positioned correctly.
// it tries to obtain the height of app-header. It is not the best way to do this.
export const HeaderGap: React.FC = () => {
  const classes = useStyles()
  useRaf(5000, 200)
  useWindowSize()
  return (
    <AppBar
      position="static"
      className={classes.root}
      style={{
        height: Math.max(
          60,
          document.getElementById('app-header')?.offsetHeight ?? 0,
        ),
      }}
    >
      <Toolbar variant="dense" disableGutters />
      <Loading hidden />
    </AppBar>
  )
}
