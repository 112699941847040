import _ from 'lodash'
import React from 'react'
import type { MouseEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import type { TreeItem as TreeItemType } from '../../../api/providers'
import type { Item, Action, TreeExperimentItem } from './lib/types'
import Tree from './Tree'
import { ChevronRightIcon, ChevronDownIcon, MoreVerticalIcon } from '../icons'

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: 0, marginLeft: theme.spacing(2) },
  text: {
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  marquee: {
    display: 'inline-block',
    '&:hover': { animation: '$marquee 5s linear infinite' },
  },
  '@keyframes marquee': {
    '10%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
  openCloseIcon: {
    minWidth: 'auto',
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    '&:hover': { backgroundColor: 'rgb(220,220,220)' },
  },
  icon: { minWidth: 'auto' },
  button: { marginLeft: theme.spacing(1) },
  span: { paddingLeft: theme.spacing(2.5) },
}))

interface Props {
  item: Item
  level: number
  onToggleItem: (id: string, isOpen?: boolean) => void
  onSelectItem: (folderId: string, experimentId?: string) => void
  parseItem: (item: TreeItemType) => Item
}

const TreeItem = ({
  item: model,
  level,
  onToggleItem,
  onSelectItem,
  parseItem,
}: Props): React.JSX.Element => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isDirectory = _.has(model, 'items')
  const statisticsExperiment =
    isDirectory && model.type !== 'experiment'
      ? model.items.find((item) => item.label.startsWith('__statistics__'))
      : undefined
  const items = ('items' in model ? model.items : []).filter(
    (i) => i !== statisticsExperiment,
  )

  const onShowActions = (e: MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget)
    e.stopPropagation()
  }

  const onClickDir = (): void => {
    const defAction = _.find(item.actions, 'default')
    onToggleItem(item.id, true)
    onSelectItem(item.id)
    if (defAction !== undefined && statisticsExperiment !== undefined) {
      defAction.onClick(statisticsExperiment)
    }
  }

  const onClickDirIcon = (): void => {
    onToggleItem(item.id)
  }

  const onClickFile = (): void => {
    onSelectItem((item as TreeExperimentItem).folderId, item.id)
    const defAction = _.find(item.actions, 'default')
    defAction?.onClick(item)
  }

  const onAction = (action: Action) => (e: MouseEvent) => {
    action.onClick(item)
    setAnchorEl(null)
    e.stopPropagation()
  }

  const item = parseItem(model)

  const visibleActions = _.filter(
    item.actions,
    ({ hidden }) => hidden === undefined || !hidden,
  )
  const hiddenActions = _.filter(
    item.actions,
    ({ hidden }) => hidden !== undefined && hidden,
  )
  const isOpen = item.type !== 'experiment' && item.isOpen
  const itemIcon = isOpen ? (item.openIcon ?? item.icon) : item.icon
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {_.range(level).map((l) => {
          return (
            <span key={String(l)} className={classes.span}>
              {' '}
              &nbsp;{' '}
            </span>
          )
        })}
        <ListItemButton
          className={classes.root}
          onClick={isDirectory ? onClickDir : onClickFile}
          selected={item.isSelected}
        >
          {isDirectory && (
            <>
              <ListItemIcon
                className={classes.openCloseIcon}
                onClick={(e) => {
                  e.stopPropagation()
                  onClickDirIcon()
                }}
              >
                {isOpen ? (
                  <ChevronDownIcon key={String(isOpen)} />
                ) : (
                  <ChevronRightIcon key={String(isOpen)} />
                )}
              </ListItemIcon>
              &nbsp;
            </>
          )}
          {isDirectory && !isOpen && <>&nbsp;</>}
          {itemIcon !== undefined ? (
            <ListItemIcon className={classes.icon}>{itemIcon}</ListItemIcon>
          ) : undefined}
          <ListItemText
            title={item.label}
            primary={
              <span
                style={{ maxWidth: 265 - level * 25 }}
                className={
                  item.label.length > 40 - 5 * level ? classes.marquee : ''
                }
              >
                {item.label}
              </span>
            }
            primaryTypographyProps={{ className: classes.text }}
          />
          {item.actions !== undefined ? (
            <>
              {visibleActions.map((action, key) => (
                <IconButton
                  key={key}
                  className={classes.button}
                  size="small"
                  onClick={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    item.type !== 'experiment' && onToggleItem(item.id, true)
                    onAction(action)(e)
                  }}
                  title={action.tooltip}
                >
                  {action.icon}
                </IconButton>
              ))}
              {hiddenActions.length > 0 && (
                <IconButton
                  className={classes.button}
                  size="small"
                  onClick={onShowActions}
                >
                  <MoreVerticalIcon fixedWidth />
                </IconButton>
              )}
            </>
          ) : undefined}
        </ListItemButton>
      </div>
      {/* SUBMENU */}
      {isDirectory && isOpen && (
        <Tree
          items={items}
          level={level + 1}
          onToggleItem={onToggleItem}
          onSelectItem={onSelectItem}
          parseItem={parseItem}
        />
      )}
      {/* CONTEXTUAL MENU */}
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        {hiddenActions.map((action, key) => (
          <MenuItem key={key} onClick={onAction(action)}>
            {action.icon !== undefined ? (
              <ListItemIcon>{action.icon}</ListItemIcon>
            ) : undefined}
            <ListItemText primary={action.tooltip} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TreeItem
