import { call, put, select, delay } from 'redux-saga/effects'
import { values } from 'lodash'
import type { StartFilterDownloadsAction, DownloadsState } from './types'
import { newFilterDownload, endFilterDownload } from './actions'
import {
  getRawExperiment,
  createExperimentFromPlan,
  getExperimentStatus,
} from '../../../api/providers'
import { getExportData } from '../../../api/providers/experiment'
import { requestFail, requestStart, requestStop } from '../request/actions'
import type { AxiosError } from 'axios'

function* legacyDownload(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  experiment: any,
  downloadPerPatient: boolean,
  format: string | undefined,
  experimentId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  try {
    let newPlan = experiment.plan.slice(0, experiment.plan.length - 2)
    if (newPlan.length === 0) {
      // This is to prevent a plan without modules.

      newPlan = [
        {
          args: {},
          inputs: [experiment.plan[0].inputs[0]],
          module: 'modules.preprocessing.filters.row_filter.RowFilter',
          outputs: ['pipe_filter_1'],
        },
      ]
    }

    const newExperiment = yield call(
      createExperimentFromPlan,
      newPlan,
      experiment.app_id,
    )
    const newExperimentId = newExperiment._id
    const resultId = values(newExperiment.outputs)[0]
    yield put(newFilterDownload(experimentId, newExperimentId, resultId))
    let status = newExperiment.experiment_status
    while (status !== 'DONE' && status !== 'ERROR') {
      yield delay(500)
      status = yield call(
        getExperimentStatus,
        newExperimentId,
        experiment.app_id,
      )
    }
    if (status === 'error') {
      throw new Error('Error computing experiment')
    }
    interface PatientsFormat {
      patient_per_row: boolean
      format?: string
    }
    const args: PatientsFormat = { patient_per_row: downloadPerPatient }
    if (format !== undefined) {
      args.format = format
    }
    const exportData = yield getExportData(resultId, args, experiment.app_id)
    const url = window.URL.createObjectURL(exportData)
    const link = document.createElement('a')
    link.download = 'patients.zip'
    link.href = url
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(e as AxiosError<unknown, any>))
    console.error('Error downloading filter', e)
  } finally {
    yield put(requestStop())
  }
  yield put(endFilterDownload(experimentId))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* startFilterDownload(action: StartFilterDownloadsAction): any {
  yield put(requestStart())
  const { experimentId, downloadPerPatient, format } = action.payload
  const downloads = (yield select((state) => state.download)) as DownloadsState
  const { currentFilterDownloads } = downloads
  const experimentIsDownloading = currentFilterDownloads.find(
    (d) => d.experimentId === experimentId,
  )
  if (experimentIsDownloading !== undefined) {
    return
  }
  try {
    const experiment = yield call(
      getRawExperiment,
      action.payload.experimentId,
      action.payload.folderId,
    )
    if (experiment.outputs.statistics_2 === undefined) {
      for (const value of legacyDownload(
        experiment,
        downloadPerPatient,
        format,
        experimentId,
      )) {
        yield value
      }
      return
    }
    if (experiment.plan.length === 1) {
      experiment.plan.unshift({
        args: {},
        inputs: [experiment.plan[0].inputs[0]],
        module:
          'iris_backend.functionalities.case_mix.pipelines.patients_filter.compute_patients_filter',
        outputs: ['pipe_filter_1'],
      })
    }
    const newPlan = experiment.plan.slice(0, experiment.plan.length - 1)

    newPlan.push({
      args: {},
      inputs: [experiment.plan[newPlan.length - 1].outputs[0]],
      module:
        'iris_backend.functionalities.case_mix.patients_exporter.export_patients',
      outputs: ['pipe_filter_exporter'],
    })
    const newExperiment = yield call(
      createExperimentFromPlan,
      newPlan,
      experiment.app_id,
    )
    const newExperimentId = newExperiment._id
    const resultId = values(newExperiment.outputs)[0]
    yield put(newFilterDownload(experimentId, newExperimentId, resultId))
    let status = newExperiment.experiment_status
    while (status !== 'DONE' && status !== 'ERROR') {
      yield delay(500)
      status = yield call(
        getExperimentStatus,
        newExperimentId,
        experiment.app_id,
      )
    }
    if (status === 'error') {
      throw new Error('Error computing experiment')
    }
    const exportData = yield getExportData(resultId, {}, experiment.app_id)
    const url = window.URL.createObjectURL(exportData)
    const link = document.createElement('a')
    link.download = 'patients.zip'
    link.href = url
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(requestFail(e as AxiosError<unknown, any>))
    console.error('Error downloading filter', e)
  } finally {
    yield put(requestStop())
  }
  yield put(endFilterDownload(experimentId))
}
