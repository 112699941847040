import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import type { AxiosError } from 'axios'
import { connect } from 'react-redux'
import { UNAUTHORIZED_STATUS_CODE } from '../../../constants/api'
import type { AppState } from '../../../store/types'
import { dismissErrors, logout } from '../../../store/actions'
import { selectRequestError } from '../../../store/selectors'
import ErrorMessage from '../messages/ErrorMessage'
import AlertDialog from '../dialogs/AlertDialog'

interface Props {
  error?: AxiosError
  dismissErrors: () => void
  logout: () => void
}

const ErrorHandler = (props: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const { error, dismissErrors } = props
  const status = error?.response?.status
  const message =
    status !== undefined ? `http.${status}` : _.get(error, 'message')

  const logout = (): void => {
    dismissErrors()
    props.logout()
  }
  if (message !== undefined) {
    console.error('Error found', message)
  }
  return (
    <>
      {status === UNAUTHORIZED_STATUS_CODE ? (
        <AlertDialog
          open
          title={t('forbiddenDialog.title')}
          message={t('forbiddenDialog.message')}
          acceptLabel={t('forbiddenDialog.acceptLabel')}
          onAccept={logout}
        />
      ) : (
        message !== undefined && (
          <ErrorMessage
            open
            message={t(message, t('http.unknown'))}
            onClose={dismissErrors}
          />
        )
      )}
    </>
  )
}

const mapStateToProps = (
  state: AppState,
): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<unknown, any> | undefined
} => ({
  error: selectRequestError(state.request),
})

const mapDispatchToProps = { dismissErrors, logout }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler)
