import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { useNavigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import { getFolder } from '../../../../../api/providers'
import { updateFolder } from '../../../../../store/actions'
import ModalDialog from '../../../dialogs/ModalDialog'
import TextField from '../../../form/fields/TextField'

interface Props {
  updateFolder: typeof updateFolder
}

const EditFolderPage = ({ updateFolder }: Props): React.JSX.Element => {
  const navigate = useNavigate()
  const match = useMatch('/edit-folder/:folderId')
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const folderId = match?.params.folderId as string

  const state = useAsync(async () => {
    const folder = await getFolder(folderId)

    setName(folder.name)
  }, [folderId])
  const messagestNetworkError = 'messages.networkError'
  if (state.error !== undefined) {
    console.error('edit folder state error', state.error)
  }
  const stateError
    = state.error !== undefined ? messagestNetworkError : undefined

  const onCancel = (): void => {
    navigate('/')
  }
  const formRequiredFields = t('form.requiredFields')
  const onSubmit = (): void => {
    if (name.length === 0) {
      setError(formRequiredFields)
      return
    }

    updateFolder(folderId, name)
    navigate('/')
  }
  const editFolderDialogName = t('editFolderDialog.name')
  return (
    <ModalDialog
      title={t('editFolderDialog.title')}
      error={stateError ?? error}
      actions={[
        { label: t('button.cancel'), onClick: onCancel, type: 'close' },
        {
          label: t('button.accept'),
          disabled: state.loading,
          type: 'submit',
          onClick: onSubmit,
        },
      ]}
    >
      <FormGroup>
        <TextField
          autoFocus
          required
          label={editFolderDialogName}
          value={name}
          onChange={setName}
        />
      </FormGroup>
    </ModalDialog>
  )
}

const mapDispatchToProps = { updateFolder }

export default connect(null, mapDispatchToProps)(EditFolderPage)
