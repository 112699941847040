/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any*/

import React, { useRef, useState, useEffect } from 'react'
import * as d3 from 'd3'
import { min, max, round } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import type { Graph as GraphT, Link, Node, Size } from './GraphTypes'
import { useResizeObserver } from '../hooks/useResizeObserver'

interface Props {
  graph: GraphT
  // setCurrentMouseOver: (val: string, code: string) => void,
  setCenterNode: (val: string) => void
  setCurrentMouseOver: (name: string, code: string) => void
  callId: string
  loading: boolean
  experimentId: string
  folderId: string
  graphType: string
  metadata?: { size: number }
  renderDependencies: any[]
}

const TYPE_ASSOCIATIONS = 'TYPE_ASSOCIATIONS'

const linearInterpolation = (
  x: number,
  x0: number,
  x1: number,
  y0: number,
  y1: number,
): number => {
  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0)
}
const sqrtInterpolation = (
  x: number,
  x0: number,
  x1: number,
  y0: number,
  y1: number,
): number => {
  return linearInterpolation(Math.sqrt(x), Math.sqrt(x0), Math.sqrt(x1), y0, y1)
}

const render = (
  svgId: string,
  graph: GraphT,
  { height, width }: Size,
  setCenterNode: (val: string) => void,
  setCurrentMouseOver: (name: string, code: string) => void,
  graphType: string,
  metadata?: { size: number },
): void => {
  d3.select(`.${svgId}`).selectAll('*').remove()
  const svg = d3.select(`.${svgId}`).attr('width', width).attr('height', height)
  const g = svg.append('g').attr('class', 'everything').attr('id', 'everything')
  const maxNodeStrength = max(graph.nodes.map((n) => n.Strength)) ?? 0
  const minNodeStrength = min(graph.nodes.map((n) => n.Strength)) ?? 0
  const maxLinkW = max(graph.links.map((e) => e.width)) ?? 0
  const minLinkW = min(graph.links.map((e) => e.width)) ?? 0
  const nd3 = d3 as any
  const simulation = nd3
    .forceSimulation()
    .force(
      'link',
      nd3
        .forceLink()
        .distance(() => 30)
        .id((d: Node) => d.id),
    )
    .force('charge', nd3.forceManyBody().strength(-70))
    .force('center', nd3.forceCenter(width / 2, height / 2))

  const links = g
    .append('g')
    .attr('class', 'links')
    .attr('id', 'links')
    .selectAll('path')
    .data(graph.links)
    .enter()
    .append('path')
    .attr('stroke', (d) => d.strokeColor)
    .attr('stroke-opacity', 0.8)
    .attr('fill', 'none')
    .attr('stroke-width', (d: Link) =>
      linearInterpolation(d.width, minLinkW, maxLinkW, 0.3, 5),
    )
    .attr('d', (d: Link) => {
      if (typeof d.target === 'string' || typeof d.source === 'string') {
        return ''
      }
      const target = d.target
      const source = d.source
      const dx = target.x - source.x
      const dy = target.y - source.y
      const dr = Math.sqrt(dx * dx + dy * dy)
      return `M${source.x},${source.y}A${dr},${dr} 0 0,1 ${target.x}, ${target.y}`
    })

  // create a tooltip
  const parentDiv = (d3.select(`#${svgId}`)?.nodes()?.[0] as HTMLDivElement)
    ?.parentElement
  let tooltip: HTMLDivElement | null = null
  if (parentDiv !== null && parentDiv.childElementCount === 1) {
    tooltip = document.createElement('div')
    tooltip.style.position = 'absolute'
    tooltip.style.backgroundColor = 'white'
    tooltip.style.padding = '5px'
    tooltip.style.border = 'solid'
    tooltip.style.borderWidth = '2px'
    tooltip.style.borderRadius = '5px'
    tooltip.style.opacity = '0'
    parentDiv.append(tooltip)
  } else {
    tooltip =
      parentDiv !== null ? (parentDiv.children[1] as HTMLDivElement) : null
  }

  // Three function that change the tooltip when user hover / move / leave a cell
  function mouseover(event: any, d: Node): void {
    if (tooltip !== null) {
      const strength =
        graphType === TYPE_ASSOCIATIONS && metadata !== undefined
          ? Math.round(d.Strength * metadata.size)
          : d.Strength
      tooltip.innerHTML = `${d.Name} (${strength} ${round(
        (strength / (metadata as any).size) * 100,
        2,
      )}%)`
      tooltip.style.opacity = '1'
      setCurrentMouseOver(d.Name, d.id)
      mousemove(event, d)
    }
  }

  function mousemove(this: any, event: any, d: Node): void {
    const match = g
      .attr('transform')
      .match(
        /translate\((-?\d+\.?\d*), (-?\d+\.?\d*)\) scale\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/i,
      )
    if (match === null) {
      return
    }
    const translate = [Number(match[2]), Number(match[1])]
    const scale = [Number(match[3]), Number(match[4])]

    if (tooltip !== null) {
      const [x, y] = d3.pointer(event, this)
      tooltip.style.left = `${d.x * scale[0] + x + translate[0]}px`
      tooltip.style.top = `${d.y * scale[1] + y + translate[1]}px`
    }
  }

  const mouseleave = function (): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '0'
      setCurrentMouseOver('', '')
    }
  }

  const onClickNode = function (_e: any, d: Node): void {
    if (tooltip !== null) {
      tooltip.style.opacity = '0'
      setCurrentMouseOver('', '')
    }
    setCenterNode(d.id)
  }

  const nodes = g
    .append('g')
    .attr('class', 'nodes')
    .selectAll('circle')
    .data(graph.nodes)
    .enter()
    .append('g')
    .on('click', onClickNode)
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)

  nodes
    .append('circle')
    .attr('id', (d) => d.id)
    .attr('r', (d) =>
      sqrtInterpolation(d.Strength, minNodeStrength, maxNodeStrength, 3, 15),
    )
    .attr('fill', (d) => d.fill)
    .attr('stroke', 'black')
    .attr('stroke-width', '0.3px')

  nodes
    .append('text')
    .text((d) => d.label)
    .style('text-anchor', 'middle')
    .style('font-size', (d) => {
      return (
        String(
          (sqrtInterpolation(
            d.Strength,
            minNodeStrength,
            maxNodeStrength,
            3,
            15,
          ) /
            d.label.length) *
            3,
        ) + 'px'
      )
    })
    .attr('dy', '.3em')

  simulation.nodes(graph.nodes).on('tick', () => {
    ticked(nodes, links, svg, g)
  })
  simulation.force('link').links(graph.links)
}

const ticked = (
  nodes: d3.Selection<SVGGElement, Node, SVGGElement, unknown>,
  links: d3.Selection<SVGPathElement, Link, SVGGElement, unknown>,
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
  g: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
): void => {
  nodes.attr('cx', (d: Node) => d.x ?? 0).attr('cy', (d: Node) => d.y ?? 0)
  nodes.attr(
    'transform',
    (d: Node) => `translate(${[d.x ?? 0, d.y ?? 0].join(',')})`,
  )
  links.attr('d', (d: Link) => {
    const target = d.target as Node
    const source = d.source as Node
    const dx = target.x - source.x
    const dy = target.y - source.y
    const dr = Math.sqrt(dx * dx + dy * dy)
    return `M${source.x},${source.y}A${dr},${dr} 0 0,1 ${target.x},${target.y}`
  })

  zoomFit(0.9, 0, svg, g)
}

const zoomFit = (
  paddingPercent: number,
  transitionDuration: number,
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
  g: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
): void => {
  const gnode = g.node()
  const svgNode = svg.node()
  if (gnode === null || svgNode === null) {
    return
  }
  const bounds = gnode.getBBox()
  const parent = (svgNode as HTMLDivElement).parentElement

  const fullWidth = parent?.clientWidth ?? 200
  const fullHeight = parent?.clientHeight ?? 200
  const width = bounds.width
  const height = bounds.height
  const midX = bounds.x + width / 2
  const midY = bounds.y + height / 2
  if (width === 0 || height === 0) {
    return
  } // nothing to fit
  const scale =
    paddingPercent / Math.max(width / fullWidth, height / fullHeight)
  const translate = [
    fullWidth / 2 - scale * midX,
    fullHeight / 2 - scale * midY,
  ]

  g.transition()
    .duration(transitionDuration) // milliseconds
    .attr('transform', `translate(${translate.join(',')})scale(${scale})`)
}

const useStyles = makeStyles({
  svg: {
    overflow: 'visible',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
})

const Graph = ({
  graph,
  callId,
  setCenterNode,
  loading,
  experimentId,
  folderId,
  graphType,
  metadata,
  setCurrentMouseOver,
  renderDependencies,
}: Props): React.JSX.Element => {
  const [svgId] = useState(`svg-${experimentId}-${folderId}`)
  const classes = useStyles()
  const wrapperRef = useRef<HTMLDivElement | undefined>(undefined)
  const dimensions = useResizeObserver(wrapperRef)
  useEffect(() => {
    if (!loading) {
      render(
        svgId,
        graph,
        { height: 800, width: 800 },
        setCenterNode,
        setCurrentMouseOver,
        graphType,
        metadata,
      )
    }
  }, [callId, loading, dimensions, ...renderDependencies])

  return (
    // @ts-expect-error issues with ref
    <div ref={wrapperRef} className={classes.root}>
      <svg id={svgId} className={`${svgId} ${classes.svg}`} />
    </div>
  )
}

export default Graph
