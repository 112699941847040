import type { Field } from '../../../api/providers'
import type { UserVariableCreateAction } from './types'
import { USER_VARIABLE_CREATE } from './types'

export const createUserVariable = (
  folderId: string,
  modelId: string,
  name: string,
  type: Field,
): UserVariableCreateAction => ({
  type: USER_VARIABLE_CREATE,
  payload: { modelId, name, type, folderId },
})
