import React from 'react'
import type { ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { useTranslation } from 'react-i18next'
import ModalDialog from '../dialogs/ModalDialog'

export interface FilterType {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (newValue: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValue: any
  displayValue?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dictionary?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterProps?: any
}

interface FilterListProps {
  filters: FilterType[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedFilterDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(6),
      left: theme.spacing(2),
    },
  },
  fab: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
  },
  tooltip: {
    marginTop: theme.spacing(0.25),
    fontSize: theme.typography.fontSize,
    color: 'white',
    backgroundColor: '#666',
    width: 250,
  },
}))

export default function SpeedFilterDial({
  filters,
}: FilterListProps): React.JSX.Element {
  const classes = useStyles()

  const [open, setOpen] = React.useState<boolean>(false)
  const [currentFilter, setCurrentFilter] = React.useState<
    FilterType | undefined
  >(undefined)
  const [currentFilterValue, setCurrentFilterValue] = React.useState<string>('')
  const [currentFilterError, setCurrentFilterError] = React.useState<
    string | ReactElement | undefined
  >(undefined)
  const { t } = useTranslation()

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleOpen = (): void => {
    setOpen(true)
  }

  const onModalConfirm = (): void => {
    if (currentFilterError === undefined) {
      currentFilter?.onConfirm(currentFilterValue)
      setCurrentFilter(undefined)
    }
  }

  const onModalCancel = (): void => {
    setCurrentFilter(undefined)
  }

  const handleSpeedDialActionClick = (filter: FilterType): void => {
    setCurrentFilter(filter)
    setCurrentFilterValue(filter.currentValue)
    setCurrentFilterError(undefined)
  }

  return (
    <div>
      {currentFilter !== undefined && (
        <ModalDialog
          title={currentFilter.name}
          actions={[
            {
              label: t('button.cancel'),
              onClick: onModalCancel,
              type: 'close',
            },
            {
              label: t('button.accept'),
              onClick: onModalConfirm,
              type: 'submit',
              disabled: currentFilterError !== undefined,
            },
          ]}
          error={currentFilterError}
        >
          {currentFilter?.component(
            currentFilterValue,
            setCurrentFilterValue,
            currentFilterError,
            setCurrentFilterError,
            currentFilter.dictionary,
            currentFilter.filterProps,
          )}
        </ModalDialog>
      )}
      <SpeedDial
        ariaLabel="SpeedFilterDial"
        className={classes.speedFilterDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
        transitionDuration={500}
      >
        {filters.map((filter) => (
          <SpeedDialAction
            key={filter.name}
            icon={<filter.icon />}
            tooltipTitle={`${filter.name} ${
              filter.displayValue ?? (filter.currentValue as string)
            }`}
            tooltipPlacement="right"
            classes={{ staticTooltipLabel: classes.tooltip }}
            tooltipOpen={open}
            onClick={() => {
              handleSpeedDialActionClick(filter)
            }}
          />
        ))}
      </SpeedDial>
    </div>
  )
}
