import React, { useRef } from 'react'
import { useAsync, useEffectOnce } from 'react-use'
import _ from 'lodash'
import { INTERVAL_CHECK } from '../../../../constants/env'
import {
  STATUS_WAITING,
  STATUS_EXECUTING,
  STATUS_ERROR,
  type STATUS,
  getExperimentStatus,
} from '../../../../api/providers'
import {
  getExperiment,
  STATUS_DONE,
} from '../../../../api/providers/experiment'
import { getParentFolders } from '../../../../api/providers/folder'
import { getDatasetDictionary } from '../../../../api/providers/dictionary'
import { getDataset } from '../../../../api/providers/dataset'
import StatisticsWidget from '../../app/widgets/StatisticsWidget'
import AssociationsGraphWidget from '../../app/widgets/AssociationsGraphWidget'
import PopulationCompareWidget from '../../app/widgets/PopulationCompareWidget'
import TimeStatisticsWidget from '../../app/widgets/TimeStatisticsWidget'
import TrajectoriesWidget from '../../app/widgets/TrajectoriesWidget'
import WidgetWrapper from '../WidgetWrapper'
import LoadingWidget from '../widgets/LoadingWidget'
import ProcessingWidget from '../widgets/ProcessingWidget'
import ErrorWidget from '../widgets/ErrorWidget'
import UnknownWidget from '../widgets/UnknownWidget'
import { useResizeObserver } from '../../app/widgets/hooks/useResizeObserver'
import { ExportDataContext } from './ExportDataContext'
import ErrorBoundary from '../../ErrorHandler/ErrorBoundary'
import ClusteringWidget from '../../app/widgets/ClusteringWidget'
import WaitingWidget from '../widgets/WaitingWidget'

interface Props {
  experimentId: string
  folderId: string
}

const WidgetFactory = ({
  experimentId,
  folderId,
}: Props): React.JSX.Element => {
  const [status, setStatus] = React.useState<STATUS | null>(null)
  const [exportData, setExportData] = React.useState(null)
  const [exportArgs, setExportArgs] = React.useState(null)
  const [exportResultId, setExportResultId] = React.useState('')
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useResizeObserver(wrapperRef)
  // loads initial data
  const state = useAsync(async () => {
    const [experiment, folders] = await Promise.all([
      getExperiment(experimentId, folderId),
      getParentFolders(folderId),
    ])
    const breadcrumbs = _.map(folders, 'name').concat(experiment.name)
    const [dictionary, dataset] = await Promise.all([
      getDatasetDictionary(folders),
      folders.length > 0
        ? getDataset(folders[0].datasetId as string)
        : Promise.resolve(undefined),
    ])
    return { experiment, breadcrumbs, dictionary, dataset }
  }, [experimentId, folderId])
  const experiment = state.value?.experiment
  const breadcrumbs = state.value?.breadcrumbs ?? []
  const dictionary = state.value?.dictionary
  const dataset = state.value?.dataset

  // checks the experiment status periodically
  useEffectOnce(() => {
    const callback = (): void => {
      getExperimentStatus(experimentId, dataset?.app_id, folderId).then(
        (status) => {
          if (![STATUS_WAITING, STATUS_EXECUTING].includes(status)) {
            clearInterval(interval)
          }
          setStatus(status)
        },
        () => {},
      )
    }
    callback()
    const interval = setInterval(callback, INTERVAL_CHECK)
    return () => {
      clearInterval(interval)
    }
  })
  const { pipelineId, outputs } = experiment ?? { pipelineId: '', outputs: {} }
  let widget = null
  if (status === null) {
    widget = <WaitingWidget />
  } else if (status === STATUS_WAITING) {
    widget = <LoadingWidget />
  } else if (status === STATUS_EXECUTING) {
    widget = (
      <ProcessingWidget experimentId={experimentId} folderId={folderId} />
    )
  } else if (status === STATUS_ERROR) {
    widget = <ErrorWidget experimentId={experimentId} folderId={folderId} />
  } else if (
    experiment !== undefined &&
    status === STATUS_DONE &&
    dataset !== undefined
  ) {
    if (pipelineId === 'Associations Graph') {
      widget = (
        <AssociationsGraphWidget
          outputs={outputs}
          experimentId={experimentId}
          dictionary={dictionary}
          dimensions={dimensions}
          pipelineId={pipelineId}
          appId={dataset.app_id}
          folderId={folderId}
        />
      )
    } else if (pipelineId === 'Basic Statistics') {
      widget = (
        <StatisticsWidget
          outputs={outputs}
          experimentId={experimentId}
          dictionary={dictionary}
          dimensions={dimensions}
          appId={dataset.app_id}
          folderId={folderId}
        />
      )
    } else if (pipelineId === 'Clustering') {
      widget = (
        <ClusteringWidget
          outputs={outputs}
          experimentId={experimentId}
          folderId={folderId}
          dictionary={dictionary}
          dimensions={dimensions}
          appId={dataset.app_id}
        />
      )
    } else if (pipelineId === 'Compare Graph') {
      widget = (
        <PopulationCompareWidget
          outputs={outputs}
          experimentId={experimentId}
          folderId={folderId}
          dictionary={dictionary}
          dimensions={dimensions}
          pipelineId={pipelineId}
          appId={dataset.app_id}
        />
      )
      // widget = <AssociationsGraphWidget outputs={outputs} experimentId={experimentId} dictionary={dictionary} dimensions={dimensions} pipelineId={pipelineId} />
    } else if (pipelineId === 'Time Statistics') {
      widget = <TimeStatisticsWidget outputs={outputs} />
    } else if (pipelineId === 'Trajectories') {
      widget = (
        <TrajectoriesWidget
          outputs={outputs}
          experimentId={experimentId}
          folderId={folderId}
          dictionary={dictionary}
          dimensions={dimensions}
          pipelineId={pipelineId}
          appId={dataset.app_id}
        />
      )
    } else {
      widget = <UnknownWidget experimentId={experimentId} folderId={folderId} />
    }
  }

  return (
    <ErrorBoundary>
      <ExportDataContext.Provider
        value={{
          data: exportData,
          args: exportArgs,
          resultId: exportResultId,
          downloadablePatients: pipelineId === 'Basic Statistics',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onDataChange: (data: any) => {
            setExportData(data)
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onArgsChange: (args: any) => {
            setExportArgs(args)
          },
          onResultIdChange: (resultId: string) => {
            setExportResultId(resultId)
          },
        }}
      >
        <WidgetWrapper
          breadcrumbs={breadcrumbs}
          experimentId={experimentId}
          folderId={folderId}
          pipelineId={pipelineId}
        >
          <ErrorBoundary experimentId={experimentId} folderId={folderId}>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                maxWidth: '99%',
                height: '100%',
              }}
            >
              {widget}
            </div>
          </ErrorBoundary>
        </WidgetWrapper>
      </ExportDataContext.Provider>
    </ErrorBoundary>
  )
}

export default WidgetFactory
