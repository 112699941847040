import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import type { Filter } from '../../../../api/providers/filter'
import Row from './Row'
import { useAsync } from 'react-use'
import AIRow from './AIRow'
import { LLM } from '../../../../constants/env'
import { getStoreByFolderId } from '../../../../api/providers/store'
import { getCreatedAtFromStoreFolder } from './utils'

export interface Props {
  filterId: string
  expanded?: boolean
  onSelect?: (id?: string) => void
  dictionary?: { list: { [key: string]: string } }
}

const FilterRow = ({
  filterId,
  expanded,
  onSelect,
  dictionary,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const state = useAsync(async () => {
    const store = await getStoreByFolderId(filterId)
    if (store === undefined) {
      console.error(`Folder ${filterId} not found`)
      throw new Error(`Folder ${filterId} not found`)
    }
    const filter = store.folders.find(
      (folder) => folder.id === filterId,
    ) as Filter
    if (filter === undefined) {
      console.error(`Filter ${filterId} not found`)
      throw new Error(`Filter ${filterId} not found`)
    }
    const createdAt = await getCreatedAtFromStoreFolder(filterId, store)
    return { filter, createdAt }
  }, [filterId])

  if (state.loading || state.value === undefined) {
    return <></>
  }
  const { filter, createdAt } = state.value
  if (
    ['Patients Filter', 'Episodes Filter', 'Assessment Filter'].includes(
      filter.pipelineId,
    ) &&
    LLM
  ) {
    return (
      <AIRow
        title={filter.name}
        filterType={
          filter.pipelineId as
            | 'Patients Filter'
            | 'Episodes Filter'
            | 'Assessment Filter'
        }
        values={filter.values}
        folderId={filter.folderId}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plan={(filter as any).plan}
        createdAt={createdAt}
      />
    )
  }
  const fields = _.flatten(
    _.map(filter.values, (fields) => {
      return _.map(fields, (value, label) => ({
        label,
        value: JSON.stringify(value, null, ''),
      }))
    }),
  )
  return (
    <Row
      title={filter.name}
      expanded={expanded}
      dictionary={dictionary}
      onChange={(expanded) => {
        onSelect?.(expanded ? filter.id : undefined)
      }}
      type={filter.pipelineId}
      values={[
        {
          label: t('experimentDetails.module'),
          value:
            dictionary !== undefined
              ? dictionary.list[filter.pipelineId]
              : filter.pipelineId,
        },
        ...fields,
      ]}
    />
  )
}

export default FilterRow
